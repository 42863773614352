<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused"
     [class.control--readonly]="readonly">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <div class="control__content">
    <textarea class="control__input"
              #nativeTextArea=""
              [attr.placeholder]="placeholder"
              (focus)="focus()"
              (blur)="blur()"
              (input)="input($event.target.value)"
              [readonly]="readonly"
              [cdkTextareaAutosize]=""
              cdkAutosizeMinRows="2"></textarea>
  </div>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
