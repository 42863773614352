import { Component } from '@angular/core';

@Component({
  selector: 'table-multiselect-header',
  templateUrl: './data-table-multiselect-header.component.html',
  styleUrls: ['./data-table-multiselect-header.component.scss'],
})
export class DataTableMultiselectHeaderComponent {

}
