import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  CollectionHelpersService,
  RowsCollection,
  Dictionary,
  CollectionResponse,
} from 'asap-team/asap-tools';

import {
  ConnectedInvite,
  InviteRecommendation,
  LookUpPartner,
  PaginationParams,
  UserCard,
} from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';
import { environment } from 'environments/environment';

type CollectionCombination = [string, number];

@Injectable({ providedIn: 'root' })
export class PartnershipService {

  private per_page: number = 15;

  constructor(
    private http: HttpClient,
    private collectionHelpersService: CollectionHelpersService,
    private userService: UserService,
  ) {}

  lookUpPartners([search, page]: CollectionCombination): Observable<RowsCollection<LookUpPartner>> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        search,
        page: page.toString(),
        per_page: this.per_page.toString(),
      },
    });

    return this
      .http
      .get('v2/users/lookup_partners', { params })
      .pipe(
        this.collectionHelpersService.mapToCollection<LookUpPartner>(page),
      );
  }

  getConnectedInvites(params: Dictionary<string | number>): Observable<CollectionResponse<ConnectedInvite>> {
    return this
      .http
      .get('v2/partnerships/all', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.retrowError(),
      );
  }

  getPartnershipUser(uid: string): Observable<UserCard> {
    return this.http.get<UserCard>(`v2/partnerships/users/${uid}`);
  }

  getInviteRecommendations(params: PaginationParams): Observable<InviteRecommendation[]> {
    const { apps } = this.userService.syncGetProfile();

    if (!apps.intelligence?.enabled) {
      return of([]);
    }

    return this
      .http
      .get(`${environment.rozumAPI}/v1/partnerships/recommendations`, { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.retrowError(),
      );
  }

  inviteInternalPartner(user_uid: string): Observable<void> {
    return this.http.post<void>('v2/partnerships', { user_uid });
  }

  // eslint-disable-next-line max-len
  invitePartner(payload: { name: string; phone: string; email: string; role: string; invitation_text?: string; source?: string }): Observable<void> {
    return this.http.post<void>('v2/partnerships/invite', payload);
  }

  acceptInvite(token: string): Observable<void> {
    return this.http.patch<void>('v2/partnerships/accept', { token });
  }

  declineInvite(token: string): Observable<void> {
    return this.http.patch<void>('v2/partnerships/decline', { token });
  }

  resendInvite(id: string): Observable<void> {
    return this.http.patch<void>(`v2/partnerships/${id}/resend`, null);
  }

  setPrimary(username: string): Observable<void> {
    return this.http.patch<void>('v2/profile/primary_partner', { username });
  }

  unsetPrimary(): Observable<void> {
    return this.http.delete<void>('v2/profile/primary_partner');
  }

  cancelInvite(id: string): Observable<void> {
    return this.http.delete<void>(`v2/partnerships/${id}/cancel`);
  }

  break(id: string): Observable<any> {
    return this.http.delete<void>(`v2/partnerships/${id}/break`);
  }

}
