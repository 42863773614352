// Modules
import { NgxMaskModule } from 'ngx-mask';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ClipboardModule } from 'ngx-clipboard';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { NgModule } from '@angular/core';
import {
  CommonModule,
  CurrencyPipe,
  DecimalPipe,
  PercentPipe,
  DatePipe,
  TitleCasePipe,
} from '@angular/common';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from 'ngx-bootstrap/datepicker';
import { IqLoadingBarModule } from '@commons/iq-loading-bar/iq-loading-bar.module';
import { Angulartics2Module } from 'angulartics2';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TextFieldModule } from '@angular/cdk/text-field';
import { QuillModule } from 'ngx-quill';
import { AsapCommonModule } from 'asap-team/asap-tools';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Consts
import { environment } from 'environments/environment';
import {
  DEFAULT_MOMENT_DATE_FORMAT,
  QUILL_MODULE_CONFIG,
} from '@consts/consts';
import { sellerDigestCollectionConfig, KudosCollectionConfig } from '@consts/collection-providers';

// Pipes
import { DateByYearPipe } from '@core/pipes/date-by-year/date-by-year.pipe';
import { PadPipe } from '@core/pipes/pad/pad.pipe';
import { PermittedPipe } from '@core/pipes/permitted/permitted.pipe';
import { PermittedUnlessPipe } from '@core/pipes/permitted-unless/permitted-unless.pipe';

// Directives
import { PermittedIfDirective } from '@commons/directives/permissions/permitted-if/permitted-if.directive';
import { AnalyticAnchorDirective } from '@commons/directives/analytic-anchor/analytic-anchor.directive';
import { PermittedIfAllDirective } from '@commons/directives/permissions/permitted-if-all/permitted-if-all.directive';
import { PermittedIfAnyDirective } from '@commons/directives/permissions/permitted-if-any/permitted-if-any.directive';
import { PermittedUnlessDirective } from '@commons/directives/permissions/permitted-unless/permitted-unless.directive';
import { RoleIfDirective } from '@commons/directives/roles/role-if/role-if.directive';
import { RoleIfAnyDirective } from '@commons/directives/roles/role-if-any/role-if-any.directive';
import { ListAnimationEndDirective } from '@commons/directives/list-animation-end/list-animation-end.directive';
import { FeatureFlagDirective } from '@commons/directives/feature-flags/feature-flag.directive';

// Components
import { AlertsComponent } from '@commons/components/toolbar/alerts/alerts.component';
import { ToolbarComponent } from '@commons/components/toolbar/toolbar.component';
import { DesktopNavComponent } from '@commons/components/toolbar/desktop-nav/desktop-nav.component';
import { TabsNavComponent } from '@commons/components/toolbar/tabs-nav/tabs-nav.component';
import { NotificationsCounterComponent } from '@commons/components/toolbar/desktop-nav/notifications-counter/notifications-counter.component';
import { TabCountersComponent } from '@commons/components/toolbar/tabs-nav/tab-counters/tab-counters.component';
import { FormErrorComponent } from '@commons/components/form-error/form-error.component';
import { UserAvatarComponent } from '@commons/components/user-avatar/user-avatar.component';
import { ApplyPromoCodeComponent } from '@commons/components/order-checkout/apply-promo-code/apply-promo-code.component';
import { OrderCheckoutComponent } from '@commons/components/order-checkout/order-checkout.component';
import { ProfileActionsComponent } from '@commons/components/toolbar/profile-actions/profile-actions.component';
import { RewardsNavComponent } from '@commons/components/toolbar/rewards-nav/rewards-nav.component';
import { AvatarPlaceholderComponent } from '@commons/components/avatar-placeholder/avatar-placeholder.component';
import { CountdownComponent } from '@commons/components/countdown-timer/countdown-timer.component';
import { ActivityAvatarComponent } from '@commons/components/activity/activity-avatar/activity-avatar.component';
import { DateFlipComponent } from '@commons/components/date-flip/date-flip.component';
import { DiscountCountdownComponent } from '@commons/components/discount-countdown/discount-countdown.component';
import { ViralImportInfoResultComponent } from '@commons/components/import-csv/viral-import-info-result/viral-import-info-result.component';
import { ImportDatabaseInfoComponent } from '@commons/components/import-csv/import-database-info/import-database-info.component';
import { ViralImportInfoComponent } from '@commons/components/import-csv/viral-import-info/viral-import-info.component';
import { ViralUnsubscribedEmailsComponent } from '@commons/components/import-csv/viral-unsubscribed-emails/viral-unsubscribed-emails.component';
import { ImportDatabaseMembersComponent } from '@commons/components/import-csv/import-database-members/import-database-members.component';
import { PromoBannerComponent } from '@commons/components/promo-banner/promo-banner.component';
import { LeadActionsComponent } from '@commons/components/dropdown-actions/lead-actions/lead-actions/lead-actions.component';
import { IframeVideoComponent } from '@commons/components/iframe-video/iframe-video.component';
import { PaymentMethodsListComponent } from '@commons/components/payment-methods-list/payment-methods-list.component';
import { PaymentMethodComponent } from '@commons/components/payment-methods-list/payment-method/payment-method.component';
import { OrderSummaryComponent } from '@commons/components/order-checkout/order-summary/order-summary.component';
import { ProgressBarComponent } from '@commons/components/progress-bar/progress-bar.component';
import { LeadActivityItemComponent } from '@commons/components/activity/lead-activity-item/lead-activity-item.component';
import { LeadsActivityItemComponent } from '@commons/components/activity/leads-activity-item/leads-activity-item.component';
import { LoadingDotsComponent } from '@commons/components/loading-dots/loading-dots.component';
import { IqButtonComponent } from '@commons/components/iq-button/iq-button.component';
import { IqCardComponent } from '@commons/components/iq-card/iq-card.component';
import { BackBtnComponent } from '@commons/components/back-btn/back-btn.component';
import { PreMoverCountComponent } from '@commons/components/pre-mover-count/pre-mover-count.component';
import { IntegrationCardComponent } from '@commons/components/integration-card/integration-card.component';
import { ImagePreviewComponent } from '@commons/components/image-preview/image-preview.component';
import { SideNavComponent } from '@commons/components/sidenav/sidenav.component';
import { SocialsComponent } from '@commons/components/socials/socials.component';
import { BuyersLeadActionsComponent } from '@commons/components/dropdown-actions/buyers-lead-actions/buyers-lead-actions/buyers-lead-actions.component';
import { ProfileSupportComponent } from '@commons/components/profile-support/profile-support.component';
import { CalendlyComponent } from '@commons/components/calendly/calendly.component';
import { ProgressLineComponent } from '@commons/components/progress-line/progress-line.component';
import { ParticlesComponent } from '@commons/components/particles/particles.component';
import { TotalCounterComponent } from '@commons/components/total-counter/total-counter.component';
import {
  HalfDonutChartComponent,
  DonutChartComponent,
  StackbarChartComponent,
  LineDateGraphComponent,
} from '@commons/diagrams';
import { MortgageDetailsComponent } from './sidebars/refinance-scanner-sidebar/components/mortgage-details/mortgage-details.component';
import { IqUiDividerComponent } from './components/iq-ui-divider/iq-ui-divider.component';


// Collection components
import {
  CollectionPlaceholderComponent,
  TrafficCollectionPlaceholderComponent,
  TrafficBannerCollectionPlaceholderComponent,
  BuyerCollectionPlaceholderComponent,
  BuyerBannerCollectionPlaceholderComponent,
  CreateReportBtnComponent,
  ImportDatabaseBtnComponent,
  HistoryPendingLeadComponent,
  CollectionCellUserInfoComponent,
  CollectionCellHomeInfoComponent,
  DataTableMultiselectHeaderComponent,
} from '@commons/components/collection';

// Intelligence components
import {
  RealtorTransactionsGraphComponent,
  LoanOfficerTransactionsGraphComponent,
  TransactionsStatsGroupComponent,
  TransactionHomeInfoComponent,
  TransactionLoanOfficerComponent,
  TransactionRealtorComponent,
  TransactionSideCellComponent,
  GeneralInfoSidebarComponent,
  LoanTypeDiagramComponent,
  LoanTypeStatsItemComponent,
  RelationshipsListComponent,
  RelationshipsListItemComponent,
} from '@commons/components/intelligence';

// Form components
import { UserFormComponent } from '@commons/forms/form-components/user-form/user-form.component';
import { IqFormControlAddressComponent } from '@commons/forms/form-controls/iq-form-control-address/iq-form-control-address.component';
import { LandingBackgroundComponent } from '@commons/forms/form-components/landing-background/landing-background.component';
import { LandingLogoComponent } from '@commons/forms/form-components/landing-logo/landing-logo.component';
import { LandingLinkComponent } from '@commons/forms/form-components/landing-link/landing-link.component';
import { TrackingIdComponent } from '@commons/forms/form-components/tracking-id/tracking-id.component';
import { InvitePartnerFormComponent } from '@commons/forms/form-components/invite-partner-form/invite-partner-form.component';
import { LendingPadFormComponent } from '@commons/forms/form-components/lending-pad/lending-pad.component';
import { MessengerFormComponent } from '@commons/forms/form-components/messenger-form/messenger-form.component';
import { ZapierFormComponent } from '@commons/forms/form-components/zapier-form/zapier-form.component';
import { PreApproveFormComponent } from '@commons/forms/form-components/pre-approve-form/pre-approve-form.component';
import { StaffFormComponent } from '@commons/forms/form-components/staff-form/staff-form.component';
import { ManualAddressFormComponent } from '@commons/forms/form-components/manual-address/manual-address-form.component';
import { GoogleAddressFormComponent } from '@commons/forms/form-components/google-address/google-address-form.component';
import { IdxLinkComponent } from '@commons/forms/form-components/idx-link/idx-link.component';
import { IListFormComponent } from '@commons/forms/form-components/i-list-form/i-list-form.component';
import { RiskyEmailNotificationComponent } from '@commons/forms/form-components/risky-email-notification/risky-email-notification.component';

// Form Controls
import { FormListComponent } from '@commons/forms/form-controls/form-list/form-list.component';
import { IqFormControlEditorComponent } from '@commons/forms/form-controls/iq-form-control-editor/iq-form-control-editor.component';
import { IqFormControlTextComponent } from '@commons/forms/form-controls/iq-form-control-text/iq-form-control-text.component';
import { IqFormControlCustomMultiselectComponent } from '@commons/forms/form-controls/iq-form-control-custom-multiselect/iq-form-control-custom-multiselect.component';
import { IqFormControlDateSelectComponent } from '@commons/forms/form-controls/iq-form-control-date-select/iq-form-control-date-select.component';
import { IqFormControlNativeSelectComponent } from '@commons/forms/form-controls/iq-form-control-native-select/iq-form-control-native-select.component';
import { IqFormControlPasswordComponent } from '@commons/forms/form-controls/iq-form-control-password/iq-form-control-password.component';
import { IqFormControlPhoneComponent } from '@commons/forms/form-controls/iq-form-control-phone/iq-form-control-phone.component';
import { IqFormControlTextareaComponent } from '@commons/forms/form-controls/iq-form-control-textarea/iq-form-control-textarea.component';
import { IqFormControlCommissionComponent } from '@commons/forms/form-controls/iq-form-control-commission/iq-form-control-commission.component';
import { IqFormControlAmountComponent } from '@commons/forms/form-controls/iq-form-control-amount/iq-form-control-amount.component';
import { IqFormControlPercentComponent } from '@commons/forms/form-controls/iq-form-control-percent/iq-form-control-percent.component';
import { IqFormControlFileComponent } from '@commons/forms/form-controls/iq-form-control-file/iq-form-control-file.component';
import { IqFormControlCustomSelectComponent } from '@commons/forms/form-controls/iq-form-control-custom-select/iq-form-control-custom-select.component';
import { IqFormControlFbUsernameComponent } from '@commons/forms/form-controls/iq-form-control-fb-username/iq-form-control-fb-username.component';
import { IqFormControlMaskComponent } from '@commons/forms/form-controls/iq-form-control-mask/iq-form-control-mask.component';
import { IqFormControlCropImgComponent } from '@commons/forms/form-controls/iq-form-control-crop-img/iq-form-control-crop-img.component';

// Filters
import { CheckboxFilterComponent } from '@commons/filters/checkbox-filter/checkbox-filter.component';
import { ClearFiltersComponent } from '@commons/filters/clear-filters/clear-filters.component';
import { DateRangeFilterComponent } from '@commons/filters/date-range-filter/date-range-filter.component';
import { IqCheckboxFilterComponent } from '@commons/filters/iq-checkbox-filter/iq-checkbox-filter.component';
import { IqClearFiltersComponent } from '@commons/filters/iq-clear-filters/iq-clear-filters.component';
import { IqCurrencyFilterComponent } from '@commons/filters/iq-currency-filter/iq-currency-filter.component';
import { IqDateRangeFilterComponent } from '@commons/filters/iq-date-range-filter/iq-date-range-filter.component';
import { IqFiltersContainerComponent } from '@commons/filters/iq-filters-container/iq-filters-container.component';
import { IqFromToFilterComponent } from '@commons/filters/iq-from-to-filter/iq-from-to-filter.component';
import { IqGooglePlacesFilterComponent } from '@commons/filters/iq-google-places-filter/iq-google-places-filter.component';
import { IqInputFilterComponent } from '@commons/filters/iq-input-filter/iq-input-filter.component';
import { IqRadioFilterComponent } from '@commons/filters/iq-radio-filter/iq-radio-filter.component';
import { IqRangeFilterComponent } from '@commons/filters/iq-range-filter/iq-range-filter.component';
import { IqSearchFilterComponent } from '@commons/filters/iq-search-filter/iq-search-filter.component';
import { IqSelectFilterComponent } from '@commons/filters/iq-select-filter/iq-select-filter.component';
import { IqSimpleSearchFilterComponent } from '@commons/filters/iq-simple-search-filter/iq-simple-search-filter.component';
import { RadioFilterComponent } from '@commons/filters/radio-filter/radio-filter.component';
import { ValueRangeFilterComponent } from '@commons/filters/value-range-filter/value-range-filter.component';

// Modals
import { ConfirmActionRareComponent } from '@commons/modals/confirm-action-rare/confirm-action-rare.component';
import { ConfirmActionCommonComponent } from '@commons/modals/confirm-action-common/confirm-action-common.component';
import { VideoComponent } from '@commons/modals/video/video.component';
import { YoutubeVideoComponent } from '@commons/modals/youtube-video/youtube-video.component';
import { AddCardComponent } from '@commons/modals/add-card/add-card.component';
import { ConfirmPhoneComponent } from '@commons/modals/confirm-phone/confirm-phone.component';
import { GetInviteLinkComponent } from '@commons/modals/get-invite-link/get-invite-link.component';
import { LimitAttemptsComponent } from '@commons/modals/limit-attempts/limit-attempts.component';
import { CheckoutErrorComponent } from '@commons/modals/checkout-error/checkout-error.component';
import { ArchiveConfirmComponent } from '@commons/modals/archive-confirm/archive-confirm.component';
import { ChangePasswordComponent } from '@commons/modals/change-password/change-password.component';
import { CheckoutSuccessComponent } from '@commons/modals/checkout-success/checkout-success.component';
import { CheckoutSuccessDiscountComponent } from '@commons/modals/checkout-success-discount/checkout-success-discount.component';
import { ConfirmEmailModalComponent } from '@commons/modals/confirm-email/confirm-email-modal.component';
import { InviteProcessModalComponent } from '@commons/modals/invite-process-modal/invite-process-modal.component';
import { TransactionsReportModalComponent } from '@commons/modals/transactions-report-modal/transactions-report-modal.component';
import { ChangeMortgageRateComponent } from '@commons/modals/change-mortgage-rate/change-mortgage-rate.component';
import { PartnerCollectionItemComponent } from '@commons/sidebars/search-partner-sidebar/partner-collection-item/partner-collection-item.component';
import { InviteRecommendationsComponent } from '@commons/sidebars/search-partner-sidebar/invite-recommendations/invite-recommendations.component';
import { PromoBannerPreviewComponent } from '@commons/modals/promo-banner-preview/promo-banner-preview.component';
import { DeleteCardComponent } from '@commons/modals/delete-card/delete-card.component';
import { UpdateCardComponent } from '@commons/modals/update-card/update-card.component';
import { CropAvatarComponent } from '@commons/modals/crop-avatar/crop-avatar.component';
import { CountdownModalComponent } from '@commons/modals/countdown-modal/countdown-modal.component';
import { CompleteProfileComponent } from '@commons/modals/complete-profile/complete-profile.component';
import { AddMlsAgentIdComponent } from '@commons/modals/add-mls-agent-id/add-mls-agent-id.component';
import { ActivateReportConfirmComponent } from '@commons/modals/activate-report/activate-report-confirm.component';
import { BreakPartnershipComponent } from '@commons/modals/break-partnership/break-partnership.component';
import { LendingPadInfoComponent } from '@commons/modals/lending-pad-info/lending-pad-info.component';
import { UnpaidSlaveStatusComponent } from '@commons/modals/unpaid-slave-status/unpaid-slave-status.component';
import { RefinanceOpportunityExampleComponent } from '@commons/modals/refinance-opportunity-example/refinance-opportunity-example.component';

// Sidebars
import { UserCardSidebarComponent } from '@commons/sidebars/user-card-sidebar/user-card-sidebar.component';
import { SearchPartnerSidebarComponent } from '@commons/sidebars/search-partner-sidebar/search-partner-sidebar.component';
import { InvitePartnerSidebarComponent } from '@commons/sidebars/invite-partner-sidebar/invite-partner-sidebar.component';
import { RewardsSidebarComponent } from '@commons/sidebars/rewards-sidebar/rewards-sidebar.component';
import { RefinanceScannerSidebarComponent } from '@commons/sidebars/refinance-scanner-sidebar/refinance-scanner-sidebar.component';

// Alerts
import {
  ConfirmEmailComponent,
  PaymentProblemComponent,
  SubscriptionStatusWarningComponent,
} from '@commons/components/toolbar/alerts';
import { IqToggleFilterComponent } from '@commons/filters/iq-toggle-filter/iq-toggle-filter.component';
import { MenuComponent } from '@commons/components/toolbar/menu/menu.component';
import { ProfileComponent } from '@commons/components/toolbar/profile/profile.component';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { IqSidebarModule } from '@commons/iq-sidebar/iq-sidebar-module';
import { IqModalModule } from '@commons/iq-modal/iq-modal-module';
import { PropensityScoreComponent } from './modals/propensity-score/propensity-score.component';

// Intelligence components
const intelligenceComponents: any = [
  RealtorTransactionsGraphComponent,
  LoanOfficerTransactionsGraphComponent,
  TransactionsStatsGroupComponent,
  TransactionSideCellComponent,
  TransactionHomeInfoComponent,
  TransactionLoanOfficerComponent,
  TransactionRealtorComponent,
  GeneralInfoSidebarComponent,
  LoanTypeDiagramComponent,
  LoanTypeStatsItemComponent,
  RelationshipsListComponent,
  RelationshipsListItemComponent,
];

// Diagrams
const diagrams: any = [
  HalfDonutChartComponent,
  DonutChartComponent,
  StackbarChartComponent,
  LineDateGraphComponent,
];

const collectionComponents: any = [
  CollectionPlaceholderComponent,
  TrafficCollectionPlaceholderComponent,
  TrafficBannerCollectionPlaceholderComponent,
  BuyerCollectionPlaceholderComponent,
  BuyerBannerCollectionPlaceholderComponent,
  CreateReportBtnComponent,
  ImportDatabaseBtnComponent,
  HistoryPendingLeadComponent,
  CollectionCellUserInfoComponent,
  CollectionCellHomeInfoComponent,
  DataTableMultiselectHeaderComponent,
];

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    showWeekNumbers: false,
    containerClass: 'theme-iq',
    dateInputFormat: DEFAULT_MOMENT_DATE_FORMAT,
  });
}

const formControls: any = [
  IqFormControlTextComponent,
  IqFormControlCustomMultiselectComponent,
  IqFormControlDateSelectComponent,
  IqFormControlNativeSelectComponent,
  IqFormControlPasswordComponent,
  IqFormControlPhoneComponent,
  IqFormControlTextareaComponent,
  IqFormControlCommissionComponent,
  IqFormControlAmountComponent,
  IqFormControlPercentComponent,
  IqFormControlFileComponent,
  IqFormControlCustomSelectComponent,
  IqFormControlFbUsernameComponent,
  IqFormControlMaskComponent,
  IqFormControlEditorComponent,
  IqFormControlCropImgComponent,
];

const shared: any = [
  ...formControls,
  ...collectionComponents,
  ...diagrams,
  ...intelligenceComponents,
  ActivityAvatarComponent,
  AlertsComponent,
  AnalyticAnchorDirective,
  AvatarPlaceholderComponent,
  BackBtnComponent,
  BuyersLeadActionsComponent,
  CalendlyComponent,
  CheckboxFilterComponent,
  ClearFiltersComponent,
  ConfirmActionCommonComponent,
  CountdownComponent,
  DateByYearPipe,
  DateFlipComponent,
  DateRangeFilterComponent,
  DesktopNavComponent,
  DiscountCountdownComponent,
  FeatureFlagDirective,
  FormErrorComponent,
  FormListComponent,
  GoogleAddressFormComponent,
  IListFormComponent,
  IdxLinkComponent,
  IframeVideoComponent,
  ImagePreviewComponent,
  ImportDatabaseInfoComponent,
  ImportDatabaseMembersComponent,
  IntegrationCardComponent,
  InvitePartnerFormComponent,
  IqButtonComponent,
  IqCardComponent,
  IqCheckboxFilterComponent,
  IqClearFiltersComponent,
  IqCurrencyFilterComponent,
  IqDateRangeFilterComponent,
  IqFiltersContainerComponent,
  IqFormControlAddressComponent,
  IqFromToFilterComponent,
  IqGooglePlacesFilterComponent,
  IqInputFilterComponent,
  IqRadioFilterComponent,
  IqRangeFilterComponent,
  IqSearchFilterComponent,
  IqSelectFilterComponent,
  IqSimpleSearchFilterComponent,
  IqToggleFilterComponent,
  IqUiDividerComponent,
  LandingBackgroundComponent,
  LandingLinkComponent,
  LandingLogoComponent,
  LeadActionsComponent,
  LeadActivityItemComponent,
  LeadsActivityItemComponent,
  LendingPadFormComponent,
  ListAnimationEndDirective,
  LoadingDotsComponent,
  ManualAddressFormComponent,
  MenuComponent,
  MessengerFormComponent,
  NotificationsCounterComponent,
  OrderCheckoutComponent,
  PadPipe,
  ParticlesComponent,
  PaymentMethodsListComponent,
  PermittedIfAllDirective,
  PermittedIfAnyDirective,
  PermittedIfDirective,
  PermittedPipe,
  PermittedUnlessDirective,
  PermittedUnlessPipe,
  PreApproveFormComponent,
  PreMoverCountComponent,
  ProfileActionsComponent,
  ProfileComponent,
  ProfileSupportComponent,
  ProgressBarComponent,
  ProgressLineComponent,
  RadioFilterComponent,
  RewardsNavComponent,
  RiskyEmailNotificationComponent,
  RoleIfAnyDirective,
  RoleIfDirective,
  SideNavComponent,
  SocialsComponent,
  StaffFormComponent,
  TabCountersComponent,
  TabsNavComponent,
  ToolbarComponent,
  TotalCounterComponent,
  TrackingIdComponent,
  UserAvatarComponent,
  UserFormComponent,
  ValueRangeFilterComponent,
  ViralImportInfoComponent,
  ViralImportInfoResultComponent,
  ViralUnsubscribedEmailsComponent,
  ZapierFormComponent,
];

const modals: any = [
  VideoComponent,
  YoutubeVideoComponent,
  AddCardComponent,
  UserCardSidebarComponent,
  ConfirmPhoneComponent,
  GetInviteLinkComponent,
  CheckoutErrorComponent,
  SearchPartnerSidebarComponent,
  LimitAttemptsComponent,
  ArchiveConfirmComponent,
  ChangePasswordComponent,
  CheckoutSuccessComponent,
  CheckoutSuccessDiscountComponent,
  ChangeMortgageRateComponent,
  ConfirmEmailModalComponent,
  ConfirmActionRareComponent,
  ConfirmActionCommonComponent,
  InvitePartnerSidebarComponent,
  RewardsSidebarComponent,
  InviteProcessModalComponent,
  TransactionsReportModalComponent,
  PromoBannerPreviewComponent,
  PromoBannerComponent,
  DeleteCardComponent,
  UpdateCardComponent,
  CropAvatarComponent,
  CountdownModalComponent,
  CompleteProfileComponent,
  AddMlsAgentIdComponent,
  ActivateReportConfirmComponent,
  BreakPartnershipComponent,
  LendingPadInfoComponent,
  UnpaidSlaveStatusComponent,
  PropensityScoreComponent,
  RefinanceOpportunityExampleComponent,
  RefinanceScannerSidebarComponent,
];

const imports: any = [
  FormsModule,
  CommonModule,
  RouterModule,
  ClipboardModule,
  NgxSliderModule,
  ReactiveFormsModule,
  AngularSvgIconModule,
  ImageCropperModule,
  AlertModule.forRoot(),
  TooltipModule.forRoot(),
  NgxMaskModule.forRoot(),
  InlineSVGModule.forRoot({
    baseUrl: '/assets/icons/symbol-defs.svg',
    bypassHttpClientInterceptorChain: true,
  }),
  BsDropdownModule.forRoot(),
  BsDatepickerModule.forRoot(),
  Angular2PromiseButtonModule.forRoot({
    spinnerTpl: '',
    btnLoadingClass: 'busy',
  }),
  RxReactiveFormsModule,
  Angulartics2Module.forRoot(),
  GoogleMapsModule,
  NgxStripeModule.forRoot(environment.stripe_key),
  ToastrModule.forRoot({
    maxOpened: 1,
    autoDismiss: true,
    preventDuplicates: true,
    positionClass: 'toast-bottom-center',
    toastClass: 'notify',
  }),
  TextFieldModule,
  QuillModule.forRoot(QUILL_MODULE_CONFIG),
  AsapCommonModule,
  NgxSkeletonLoaderModule,
  IqSidebarModule,
  IqModalModule,
  IqLoadingBarModule,
];

const exported: any = [
  FormsModule,
  AlertModule,
  CommonModule,
  NgxMaskModule,
  TooltipModule,
  ClipboardModule,
  InlineSVGModule,
  BsDropdownModule,
  HttpClientModule,
  BsDatepickerModule,
  ReactiveFormsModule,
  AngularSvgIconModule,
  RxReactiveFormsModule,
  Angular2PromiseButtonModule,
  ImageCropperModule,
  AsapCommonModule,
  InfoTooltipComponent,
  ToastrModule,
  IqSidebarModule,
  IqModalModule,
  IqLoadingBarModule,
];

const alerts: any = [
  ConfirmEmailComponent,
  PaymentProblemComponent,
  SubscriptionStatusWarningComponent,
];

const declarations: any = [
  ApplyPromoCodeComponent,
  PartnerCollectionItemComponent,
  InviteRecommendationsComponent,
  PaymentMethodComponent,
  OrderSummaryComponent,
  InfoTooltipComponent,
  MortgageDetailsComponent,
];

@NgModule({
  imports,
  exports: [...exported, ...modals, ...shared],
  declarations: [...declarations, ...modals, ...shared, ...alerts],
  providers: [
    DatePipe,
    DecimalPipe,
    PercentPipe,
    CurrencyPipe,
    TitleCasePipe,
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
    sellerDigestCollectionConfig,
    KudosCollectionConfig,
  ],
})
export class CommonsModule {}
