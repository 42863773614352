import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

// Types
import type { PaymentMethod } from '@core/types';

// Consts
import { PAYMENT_METHOD, PAYMENT_METHODS_MODAL_RESULT } from '@consts/consts';

// Components
import { AddCardComponent } from '@commons/modals/add-card/add-card.component';
import { UpdateCardComponent } from '@commons/modals/update-card/update-card.component';
import { DeleteCardComponent } from '@commons/modals/delete-card/delete-card.component';
import { EditPaymentMethodsComponent } from '@modules/user/settings/billing/payment-info/modals/edit-payment-methods/edit-payment-methods.component';

@Injectable({ providedIn: 'root' })
export class PaymentMethodService {

  constructor(
    private toastr: ToastrService,
    private iqModalService: IqModalService,
  ) {}

  changePaymentMethod(): void {
    this.iqModalService
      .open(EditPaymentMethodsComponent)
      .closed
      .pipe(
        filter(Boolean),
      )
      .subscribe(
        (result: { card: PaymentMethod; event: string; canBePrimary?: boolean }) => {
          if (result?.event === PAYMENT_METHODS_MODAL_RESULT.EVENT.SHOW_ADD_CARD) {
            this.addCard(result?.canBePrimary);
          }

          if (result?.event === PAYMENT_METHODS_MODAL_RESULT.EVENT.SHOW_EDIT_CARD) {
            this.editCard(result.card);
          }

          if (result?.event === PAYMENT_METHODS_MODAL_RESULT.EVENT.SHOW_DELETE_CARD) {
            this.deleteCard(result.card);
          }
        },
      );
  }

  private addCard(primaryByDefault: boolean): void {
    this.iqModalService
      .open(AddCardComponent, { primaryByDefault })
      .closed
      .pipe(
        tap((result: { isPrimary: boolean }) => {
          if (!result?.isPrimary) {
            this.changePaymentMethod();
          }
        }),
        filter((result: { isPrimary: boolean }) => !!result?.isPrimary),
      )
      .subscribe(
        () => {
          this.toastr.success(PAYMENT_METHOD.PRIMARY_CHANGED);
        },
      );
  }

  private editCard(card: PaymentMethod): void {
    this.iqModalService
      .open(UpdateCardComponent, { card })
      .closed
      .pipe(
        tap((result: { success: boolean; isPrimary: boolean }) => {
          if (!result?.isPrimary) {
            this.changePaymentMethod();
          }
        }),
        filter((result: { success: boolean; isPrimary: boolean }) => !!result?.success),
      )
      .subscribe(
        () => {
          this.toastr.success(PAYMENT_METHOD.UPDATED);
        },
      );
  }

  private deleteCard(card: PaymentMethod): void {
    this.iqModalService
      .open(DeleteCardComponent, { card })
      .closed
      .pipe(
        tap(() => {
          this.changePaymentMethod();
        }),
        filter(Boolean),
      )
      .subscribe(
        () => {
          this.toastr.success(PAYMENT_METHOD.DELETED);
        },
      );
  }

}
