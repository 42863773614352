import type { NavItem } from '@core/types';

import { ROUTE, USER_ROLE, COLLECTION_COUNTER_NAME, PERMITTED_ACTION } from '@consts/consts';

export const Homeowners: NavItem[] = [
  {
    label: 'Reports',
    routerLink: ROUTE.alias.HOMEOWNERS_REPORTS,
    counter: COLLECTION_COUNTER_NAME.SELLER_DIGEST,
    isNewCounterDot: true,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_action: PERMITTED_ACTION.HOMEOWNERS_REPORTS_VIEW,
    icon: null,
  },
  {
    label: 'Refi',
    routerLink: ROUTE.alias.HOMEOWNERS_MORTGAGE,
    counter: COLLECTION_COUNTER_NAME.MORTGAGE_CHECKUPS,
    isNewCounterDot: true,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_action: PERMITTED_ACTION.REFI_MANAGE,
    icon: null,
  },
  {
    label: 'Activity',
    routerLink: ROUTE.alias.HOMEOWNERS_ACTIVITY,
    counter: null,
    roles: null,
    permitted_action: PERMITTED_ACTION.HOMEOWNERS_VIEW,
    icon: null,
  },
  {
    label: 'Leads',
    routerLink: ROUTE.alias.HOMEOWNERS_TRAFFIC,
    counter: COLLECTION_COUNTER_NAME.SELLER_TRAFFIC,
    isNewCounterDot: true,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_action: PERMITTED_ACTION.HOMEOWNERS_VIEW,
    icon: null,
  },
  {
    label: 'Banners',
    routerLink: ROUTE.alias.HOMEOWNERS_BANNERS,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_action: PERMITTED_ACTION.HOMEOWNERS_VIEW,
    icon: null,
  },
];
