import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'propensity-score',
  templateUrl: './propensity-score.component.html',
  styleUrls: ['./propensity-score.component.scss'],
})
export class PropensityScoreComponent {

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
