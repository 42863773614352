<div class="user-form">
  <div class="d-flex justify-content-center">
    <div class="settings__col">
      <ng-container *ngIf="isSignUp">
        <div class="user-form__col--title text-center m-t-6x f-c-gray-main f-26-700">Complete your profile</div>
        <div class="user-form__col--sub-title m-t-1x">A completed profile receives a higher client engagement</div>
      </ng-container>
      <div class="avatar m-t-3x">
        <user-avatar [avatar]="profile?.avatar?.medium"
                     [role]="profile?.role"
                     [username]="profile?.username"
                     [canEdit]="!isAccountEdit || isUserRole(USER_ROLE.owner)"
                     [loading]="avatarLoading"
                     (imageSelected)="uploadAvatar($event)"></user-avatar>
      </div>
      <div class="user-form__col--help-text">
        <span class="m-t-1x">Don’t forget to upload your photo.</span>
      </div>
    </div>
  </div>
  <form class="settings__form" [formGroup]="form" (ngSubmit)="updateProfile()">
    <div class="settings__form-group-wrap">
      <div class="settings__form-group p-b-70">
        <iq-form-control-text class="alternative-label m-b-2x"
                              *roleIfAny="[USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.customer_success]"
                              label="Username*"
                              placeholder="Username"
                              formControlName="username"
                              [errorsModel]="formErrors.username"></iq-form-control-text>
        <iq-form-control-text class="alternative-label m-b-2x"
                              label="Name*"
                              placeholder="First and last"
                              formControlName="name"
                              [errorsModel]="formErrors.name"></iq-form-control-text>
        <div class="flex-split">
          <div class="flex-split__half">
            <iq-form-control-text class="alternative-label m-b-2x"
                                  label="Email*"
                                  placeholder="myname@example.com"
                                  formControlName="email"
                                  [errorsModel]="formErrors.email">
              <div class="m-l-1x m-r-2x m-t-auto m-b-auto">
                <div class="confirmed inline-svg inline-svg--16"
                    *ngIf="profile?.email_confirmed"
                    [inlineSVG]="'#icon-check_done'"></div>
                <iq-button *ngIf="!profile?.email_confirmed && isRegistrationCompleted"
                          label="Confirm"
                          size="24"
                          [style]="'outline'"
                          (onClick)="confirmEmail()"></iq-button>
              </div>
            </iq-form-control-text>
          </div>
          <div class="flex-split__half">
            <iq-form-control-phone class="alternative-label m-b-2x"
                                  label="Cell Phone*"
                                  formControlName="phone"
                                  [errorsModel]="formErrors.phone">
              <div class="m-l-1x m-r-2x">
                <div class="confirmed inline-svg inline-svg--16"
                    *ngIf="profile?.phone_confirmed"
                    [inlineSVG]="'#icon-check_done'"></div>
                <iq-button *ngIf="!profile?.phone_confirmed"
                          label="Confirm"
                          size="24"
                          [style]="'outline'"
                          [disabled]="isPhoneChanged"
                          (onClick)="confirmPhoneNumber()"></iq-button>
              </div>
            </iq-form-control-phone>
          </div>
        </div>
        <iq-form-control-text class="alternative-label m-b-2x"
                              *roleIfAny="[USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.customer_success]"
                              label="Pipedrive"
                              placeholder="Pipedrive URL"
                              formControlName="pipe_drive_url"
                              [errorsModel]="formErrors.pipe_drive_url"></iq-form-control-text>
        <div class="pseudo-link" *ngIf="isRegistrationCompleted" (click)="changePassword()">Change Password</div>
        <h4 class="f-16-bold m-t-4x m-b-8">License</h4>
        <div class="flex-split">
          <div class="flex-split__half">
            <iq-form-control-text class="alternative-label m-b-2x"
                              [label]="isAgentRole ? 'License Number*' : 'NMLS Number*'"
                              placeholder="12345678"
                              formControlName="license_number"
                              [errorsModel]="formErrors.license_number"></iq-form-control-text>
          </div>
          <div class="flex-split__half">
            <iq-form-control-custom-multiselect class="alternative-label m-b-2x"
                                            *ngIf="states"
                                            label="State Licensed*"
                                            placeholder="Select licensed states"
                                            filterPlaceholder="Type state name"
                                            formControlName="license_states"
                                            [displayCode]="true"
                                            [model]="states"
                                            [errorsModel]="formErrors.license_states"></iq-form-control-custom-multiselect>
          </div>
        </div>
        
        <iq-form-control-text class="alternative-label m-b-2x"
                              *ngIf="isAgentRole"
                              label="MLS Agent ID"
                              placeholder="12345678"
                              formControlName="mls_number"
                              [errorsModel]="formErrors.mls_number">
          <div class="m-l-1x m-r-2x">
            <div class="user-form__tooltip">
              <div class="user-form__tooltip--icon icon inline-svg inline-svg--22"
                   [inlineSVG]="'#icon-question'"
                   [tooltip]="mlsTooltipText"
                   placement="top"
                   container="body"></div>
            </div>
          </div>
        </iq-form-control-text>
        <h4 class="f-16-bold m-t-4x m-b-8">Employment</h4>
        <iq-form-control-text class="alternative-label m-b-2x"
                              *roleIfAny="[USER_ROLE.lender, USER_ROLE.agent]"
                              label="Job title"
                              placeholder="Job title"
                              formControlName="job_title"
                              [errorsModel]="formErrors.job_title"></iq-form-control-text>
        <div class="flex-split">
          <div class="flex-split__half">
            <iq-form-control-text class="alternative-label m-b-2x"
                              label="Brokerage Name*"
                              placeholder="Acme Ltd."
                              formControlName="company_name"
                              [readonly]="'true' | permittedUnless : 'ENTERPRISE_RESTRICTIONS' : 'swap' | async"
                              [errorsModel]="formErrors.company_name"></iq-form-control-text>
          </div>
          <div class="flex-split__half">
            <iq-form-control-text class="alternative-label m-b-2x"
                              label="Brokerage License*"
                              placeholder="Brokerage license"
                              formControlName="company_license_number"
                              [readonly]="'true' | permittedUnless : 'ENTERPRISE_RESTRICTIONS' : 'swap' | async"
                              [errorsModel]="formErrors.company_license_number"></iq-form-control-text>
          </div>
        </div>
        <iq-form-control-text class="alternative-label m-b-2x"
                              *ngIf="isUserRole([USER_ROLE.sales])"
                              label="Camp. ID"
                              placeholder="Facebook campaign ID"
                              formControlName="facebook_campaign_id"
                              [errorsModel]="formErrors.facebook_campaign_id"></iq-form-control-text>
        <iq-form-control-text class="alternative-label m-b-2x"
                              *ngIf="isUserRole([USER_ROLE.sales])"
                              label="Lead Form ID"
                              placeholder="Facebook Lead Gen. Campaign ID"
                              formControlName="facebook_lead_gen_campaign_id"
                              [errorsModel]="formErrors.facebook_lead_gen_campaign_id"></iq-form-control-text>
        <div *ngIf="profile.socials">
          <div class="socials__title m-t-4x m-b-8">
            <h4 class="f-16-bold">Social Profiles&nbsp;&nbsp;</h4>
            <span class="socials__label">New</span>
          </div>
          <div class="socials__wrapper">
            <p class="socials__text">Showcase your social media profiles alongside myhomeIQ products, including lead-generation websites and homeowner reports.</p>
            <iq-button *ngIf="!showSocialsInputs"
                      label="Manage"
                      [style]="'outline'"
                      size="40"
                      (click)="expandSocialsInputs()"></iq-button>
            <iq-button *ngIf="showSocialsInputs"
                      label="Close"
                      [style]="'outline'"
                      color="grey"
                      size="40"
                      (click)="expandSocialsInputs()"></iq-button>
          </div>
          <div class="socials__list m-b-4x">
            <a *ngIf="profile.socials.facebook_url"
              class="socials__list--link"
              [href]="profile.socials.facebook_url"
              target="_blank">
              <img src="assets/components/profile/socials/facebook.svg" alt="facebook-link">
            </a>
            <a *ngIf="profile.socials.linkedin_url"
              class="socials__list--link"
              [href]="profile.socials.linkedin_url"
              target="_blank">
              <img src="assets/components/profile/socials/linkedin.svg" alt="linkedin-link">
            </a>
            <a *ngIf="profile.socials.instagram_url"
              class="socials__list--link"
              [href]="profile.socials.instagram_url"
              target="_blank">
              <img src="assets/components/profile/socials/instagram.svg" alt="instagram-link">
            </a>
            <a *ngIf="profile.socials.youtube_url"
              class="socials__list--link"
              [href]="profile.socials.youtube_url"
              target="_blank">
              <img src="assets/components/profile/socials/youtube.svg" alt="youtube-link">
            </a>
            <a *ngIf="profile.socials.zillow_url"
              class="socials__list--link"
              [href]="profile.socials.zillow_url"
              target="_blank">
              <img src="assets/components/profile/socials/zillow.svg" alt="youtube-link">
            </a>
            <a *ngIf="profile.socials.realtor_url"
              class="socials__list--link"
              [href]="profile.socials.realtor_url"
              target="_blank">
              <img src="assets/components/profile/socials/realtor.svg" alt="youtube-link">
            </a>
            <a *ngIf="profile.socials.personal_website_url"
              class="socials__list--link"
              [href]="profile.socials.personal_website_url"
              target="_blank">
              <img src="assets/components/profile/socials/website.svg" alt="youtube-link">
            </a>
          </div>
          <div *ngIf="showSocialsInputs" class="socials__controls">
            <iq-form-control-text class="alternative-label m-b-2x"
                        label="Facebook"
                        placeholder="ex. https://facebook.com/username"
                        formControlName="facebook_url"
                        [errorsModel]="formErrors.facebook_url"></iq-form-control-text>
            <iq-form-control-text class="alternative-label m-b-2x"
                        label="Instagram"
                        placeholder="ex. https://instagram.com/username"
                        formControlName="instagram_url"
                        [errorsModel]="formErrors.instagram_url"></iq-form-control-text>
            <iq-form-control-text class="alternative-label m-b-2x"
                        label="LinkedIn"
                        placeholder="ex. https://linkedin.com/in/username"
                        formControlName="linkedin_url"
                        [errorsModel]="formErrors.linkedin_url"></iq-form-control-text>
            <iq-form-control-text class="alternative-label m-b-2x"
                        label="Youtube"
                        placeholder="ex. https://youtube.com/@username"
                        formControlName="youtube_url"
                        [errorsModel]="formErrors.youtube_url"></iq-form-control-text>
            <iq-form-control-text class="alternative-label m-b-2x"
                        label="Zillow"
                        placeholder="ex. https://zillow.com/profile/username"
                        formControlName="zillow_url"
                        [errorsModel]="formErrors.zillow_url"></iq-form-control-text>
            <iq-form-control-text class="alternative-label m-b-2x"
                        label="Realtor.com"
                        placeholder="ex. https://realtor.com/realestateagents/user-identifier"
                        formControlName="realtor_url"
                        [errorsModel]="formErrors.realtor_url"></iq-form-control-text>
            <iq-form-control-text class="alternative-label m-b-2x"
                        label="Personal website"
                        placeholder="ex. https://example.com"
                        formControlName="personal_website_url"
                        [errorsModel]="formErrors.personal_website_url"></iq-form-control-text>
          </div>
        </div>
        <ng-container *ngIf="!isUserRole([USER_ROLE.owner])">
          <h4 class="f-16-bold m-t-4x m-b-8">Disclaimer</h4>
          <iq-form-control-editor formControlName="disclaimer_text"
                                  [errorsModel]="formErrors.disclaimer_text"></iq-form-control-editor>
          <ng-container *ngIf="isUserRole([USER_ROLE.lender])">
            <div class="user-form__company-disclaimer control control--readonly"
                 *ngIf="'true' | permitted : 'ENTERPRISE_RESTRICTIONS' | async">
              <div class="flex-1 control__content">
                <p class="user-form__company-disclaimer-title p-2x f-14-500 f-c-steel">Company disclaimer</p>
                <p class="user-form__company-disclaimer-content p-2x f-12-500 f-c-steel-light"
                   [innerHTML]="getValue('company_disclaimer_text')"></p>
              </div>
            </div>
            <ng-container *ngIf="'true' | permittedUnless : 'ENTERPRISE_RESTRICTIONS' | async">
              <h4 class="f-16-bold m-t-4x m-b-8">Compliance logo</h4>
              <div class="user-form__form-radio">
                <label class="user-form__form-radio-label" *ngFor="let item of disclaimerFormItems; let i=index;">
                  <input type="radio" [value]="item" data-lpignore="true" formControlName="disclaimer_logo"/>
                  <div class="user-form__form-radio-check"></div>
                  <img *ngIf="item != 0" [src]="'/assets/icons/disclaimer/'+item+'.svg'"/><span *ngIf="item === 0">Show none</span>
                </label>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isUserRole([USER_ROLE.lender, USER_ROLE.agent])">
          <h4 class="f-16-bold m-t-2x m-b-10">Time zone</h4>
          <p class="f-14-400 f-c-steel m-b-10">
            Your current time zone. Used to send summary and notification emails, for times in your activity feeds, and
            for reminders.
          </p>
          <iq-form-control-custom-select class="alternative-label m-b-5x"
                                         formControlName="time_zone"
                                         label=""
                                         placeholder="Choose timezone"
                                         [model]="(timeZones$ | async)"
                                         [errorsModel]="formErrors.time_zone"
                                         [selected]="profile.time_zone"></iq-form-control-custom-select>
        </ng-container>
        <iq-button class="block m-t-2x"
                   type="submit"
                   [label]="buttonName"
                   [disabled]="form.pristine || form.invalid"
                   [busy$]="updateSubscription"></iq-button>
        <p class="user-form__terms text-center" *ngIf="!isRegistrationCompleted">
          By clicking Complete Profile, you agree to our <a class="link" [href]="termsConditions">Terms of Use</a>
          and <a class="link" [href]="privacyPolicy">Privacy policy</a>.
        </p>
      </div>
    </div>
  </form>
</div>
