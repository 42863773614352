/* eslint-disable */
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';

// Services
import { EnvProviderService } from '@core/helpers/env-provider/env-provider.service';

@Injectable({ providedIn: 'root' })
export class FacebookSDKService {

  private ready = new BehaviorSubject<string>(null);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private envProviderService: EnvProviderService,
  ) {}

  init(): Observable<string> {
    const window = (this.window as any);

    if (typeof window.fbAsyncInit === 'function') {
      return this.ready.asObservable();
    }

    const load = () => {
      const script = this.document.createElement('script');

      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://connect.facebook.net/en_US/sdk.js';

      const scriptElement = this.document.getElementsByTagName('script')[0];

      scriptElement.parentNode.insertBefore(script, scriptElement);
    };

    window.fbAsyncInit = () => {
      window.FB.init({
        appId: this.envProviderService.getEnvironment().facebookApp,
        autoLogAppEvents: true,
        version: 'v6.0',
      });
      this.ready.next('ready');
    };

    if (this.document.readyState === 'complete') {
      load();
    } else if (window.attachEvent) {
      window.attachEvent('onload', load);
    } else {
      window.addEventListener('load', load, false);
    }

    return this.ready.asObservable();
  }

  sdk(): Observable<any> {
    return this
      .ready
      .pipe(
        filter(Boolean),
        map(() => (window as any).FB),
        shareReplay(1, 100),
      );
  }

}
