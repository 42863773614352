import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE } from '@consts/routes';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  templateUrl: './transactions-report-modal.component.html',
  styleUrls: ['./transactions-report-modal.component.scss'],
})
export class TransactionsReportModalComponent {

  reportId: string = '';

  errors: string[] = [];

  constructor(
    private router: Router,
    private dialogRef: DialogRef,
  ) {
  }

  viewReport(): void {
    this.router.navigate([ROUTE.alias.HOMEOWNERS_REPORT_DETAILS, this.reportId]);
    this.closeModal();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
