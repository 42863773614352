import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { required } from './conditions';

export const updateBalanceErrors: FormErrors = {
  new_balance: [
    required,
    {
      name: 'min',
      text: 'Min length is -$2,000',
      rules: ['dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than $2,000',
      rules: ['dirty'],
    },
  ],
  global: [],
};
