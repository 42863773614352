<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Payment Method</h4>
  </div>
  <div class="modal-body">
    <section class="payment-methods__body">
      <h5 class="payment-methods__title">
        {{ subscription?.payment_method ? 'Select primary payment method' : 'Add new or manage existing payment method' }}
      </h5>
      <payment-methods-list [isEditModeOn]="true"
                            [currentCardId]="selectedCard?.id"
                            (selectMethod)="selectCard($event)"
                            (add)="addCard()"
                            (edit)="editCard($event)"
                            (delete)="deleteCard($event)"
                            [isSelectable]="subscription?.payment_method"></payment-methods-list>
    </section>
  </div>
  <div class="modal-footer" *ngIf="subscription?.payment_method?.id; else closeBtn">
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block" label="Cancel" color="grey" [style]="'outline'" (click)="closeModal()"></iq-button>
      </div>
      <div class="col col-fix">
        <iq-button class="block"
                   label="Save Changes"
                   [promiseBtn]="action"
                   (onClick)="savePaymentMethod()"
                   [busy$]="action"></iq-button>
      </div>
    </div>
  </div>
  <ng-template #closeBtn="">
    <div class="modal-footer">
      <div class="row row-fix">
        <div class="col col-fix">
          <iq-button class="block" label="Close" color="grey" [style]="'outline'" (click)="closeModal()"></iq-button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
