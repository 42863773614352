import { isEqual } from 'lodash-es';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {
  distinctUntilChanged,
  debounceTime,
  filter,
} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dictionary, IntersectionObserverService, Page } from 'asap-team/asap-tools';

import type { InviteRecommendation, LookUpPartner, UserRole } from '@core/types';

// Consts
import { USER_ROLE, DEFAULT_PAGE_CONFIG, USER_LABEL, PARTNERSHIP_SIDEBAR_RESULT } from '@consts/consts';

// Services
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { UserService } from '@core/services/user/user.service';

// State
import { SearchPartnerSidebarActions } from './state/search-partner-sidebar.actions';
import { SearchPartnerSidebarState } from './state/search-partner-sidebar.state';
import { IqSidebarService } from '@commons/iq-sidebar/iq-sidebar.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({
  selector: 'search-partner-sidebar',
  templateUrl: './search-partner-sidebar.component.html',
  styleUrls: ['./search-partner-sidebar.component.scss'],
})
export class SearchPartnerSidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('search', { static: true }) search: ElementRef<HTMLDivElement>;

  @ViewChild('collection') collection: ElementRef<HTMLInputElement>;

  @ViewChild('ghost') ghost: ElementRef<HTMLDivElement>;

  @Select(SearchPartnerSidebarState.rows) rows$: Observable<LookUpPartner[]>;

  @Select(SearchPartnerSidebarState.loading) loading$: Observable<boolean>;

  @Select(SearchPartnerSidebarState.recommendations) recommendations$: Observable<InviteRecommendation[]>;

  @Select(SearchPartnerSidebarState.recommendationLoading) recommendationLoading$: Observable<boolean>;

  @Select(SearchPartnerSidebarState.recommendationsFetching) recommendationsFetching$: Observable<boolean>;

  @Select(SearchPartnerSidebarState.isLastRecommendationLoaded) isLastRecommendationLoaded$: Observable<boolean>;

  userRole: string = '';

  form: FormGroup = null;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  USER_LABEL: any = USER_LABEL;

  DEBOUNCE_TIME: number = 500;

  RESULT: Dictionary = PARTNERSHIP_SIDEBAR_RESULT;

  partners: LookUpPartner[] = [];

  page: Page = DEFAULT_PAGE_CONFIG;

  constructor(
    private fb: FormBuilder,
    private intersectionObserverService: IntersectionObserverService,
    private iqSidebarService: IqSidebarService,
    private asaplyticsService: AsaplyticsService,
    private userService: UserService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group<TypedFormGroup<{ query: string }>>({ query: [''] });

    if (this.search) {
      (this.search as any).getNativeElementRef().nativeElement.focus();
    }

    this.initCollection();

    if (this.userRole === USER_ROLE.lender) {
      this.store.dispatch(new SearchPartnerSidebarActions.LoadRecommendations());
    }
  }

  ngAfterViewInit(): void {
    this.sendGAEvent('sidebar_view');

    // LoadMore events
    this.intersectionObserverService.create({
      root: this.collection.nativeElement,
      target: this.ghost.nativeElement,
      isOnlyIntersecting: true,
    })
      .pipe(
        filter(() => !!this.queryValue),
        untilDestroyed(this),
      )
      .subscribe(() => {
        const { page, total_pages } = this.store.selectSnapshot(SearchPartnerSidebarState.pagination);

        if (page !== total_pages) {
          this.store.dispatch(new SearchPartnerSidebarActions.LoadMoreAction());
        }
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SearchPartnerSidebarActions.ResetState());
  }

  get queryValue(): string {
    return this.form.get('query')?.value || '';
  }

  private initCollection(): void {
    this.form.get('query')
      .valueChanges
      .pipe(
        debounceTime(this.DEBOUNCE_TIME),
        distinctUntilChanged(isEqual),
        untilDestroyed(this),
      )
      .subscribe(
        (value: string) => {
          if (value) {
            this.store.dispatch(new SearchPartnerSidebarActions.LookupPartners(value));
          } else {
            this.store.dispatch(new SearchPartnerSidebarActions.ResetState());
          }
        },
      );
  }

  closeModal(result: string): void {
    this.closeSidebar({ type: result, query: this.form.value.query });
  }

  emitResult(): void {
    this.setSidebarCloseResult({ type: PARTNERSHIP_SIDEBAR_RESULT.update });
  }

  sendGAEvent(label: string): void {
    this.asaplyticsService.sendEvent({
      action: `invite_by_${this.userService.getUserRole()}`,
      category: 'Invite_sidebar',
      label,
    });
  }

  closeSidebar(closeResult?: any): void {
    this.iqSidebarService.close(closeResult);
  }

  setSidebarCloseResult(closeResult: any): void {
    this.iqSidebarService.setRawResult(closeResult);
  }

  loadMoreRecommendations(): void {
    this.store.dispatch(new SearchPartnerSidebarActions.LoadMoreRecommendations());
  }

  recommendationInvite(row: InviteRecommendation): void {
    this.closeSidebar({ type: PARTNERSHIP_SIDEBAR_RESULT.manual, query: row.name });
  }

}
