import { Route } from '@angular/router';
import { RouteExtrasStateResolve, RouteExtrasStateGuard } from 'asap-team/asap-tools';

// Consts
import { ROUTE } from '@consts/consts';

// Components
import { SignInComponent } from '@modules/auth/sign-in/sign-in.component';

import { ConfirmPassComponent } from '@modules/auth/sign-in/confirm-pass/confirm-pass.component';
import { RestorePassComponent } from '@modules/auth/sign-in/restore-pass/restore-pass.component';
import { ExpiredLinkComponent } from '@modules/auth/sign-up/expired-link/expired-link.component';
import { CompleteAccountComponent } from '@modules/auth/sign-up/complete-account/complete-account.component';
import { ConfirmEmailComponent } from '@modules/auth/confirm-email/confirm-email.component';
import { EnterpriseComponent } from '@modules/auth/sign-up/enterprise/enterprise.component';
import { RestrictedAccessComponent } from '@modules/auth/restricted-access/restricted-access.component';
import { FTLAthByUIDComponent } from '@modules/auth/ftl-uth-by-uid/ftl-uth-by-uid.component';
import { TitleCompanyComponent } from '@modules/auth/sign-up/title-company/title-company.component';

// Resolvers
import { TokenResolve } from '@core/resolvers/token/token.resolve';
import { CompleteAccountResolve } from '@core/resolvers/complete-account/complete-account.resolve';
import { CompanyResolve } from '@core/resolvers/company/company.resolve';
import { StatesResolve } from '@core/resolvers/states/states.resolve';
import { FTLAuthByUIDResolve } from '@core/resolvers/ftl-auth-by-uid/ftl-auth-by-uid.resolve';
import { PropertiesStatesResolve } from '@core/resolvers/properties-states/properties-states.resolve';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';

// TODO: ftl demo
import { FtlComponent } from '@modules/auth/sign-up/ftl/components';

export const SIGN_IN: Route = {
  path: ROUTE.name.SIGN_IN,
  children: [
    {
      path: ROUTE.EMPTY,
      pathMatch: 'full',
      resolve: { profile: TokenResolve },
      component: SignInComponent,
    },
    {
      path: ROUTE.name.RESTORE,
      component: RestorePassComponent,
    },
    {
      path: ROUTE.name.CONFIRM,
      component: ConfirmPassComponent,
    },
  ],
};
export const SIGN_UP: Route = {
  path: ROUTE.name.SIGN_UP,
  children: [
    {
      path: ROUTE.EMPTY,
      pathMatch: 'full',
      component: FtlComponent,
      resolve: { states: PropertiesStatesResolve },
    },
    {
      path: ROUTE.name.COMPLETE_ACCOUNT,
      component: CompleteAccountComponent,
      resolve: { report: CompleteAccountResolve },
    },
    {
      path: ROUTE.name.EXPIRED_LINK,
      component: ExpiredLinkComponent,
    },
    {
      path: `${ROUTE.name.ENTERPRISE}/:company`,
      component: EnterpriseComponent,
      resolve: {
        company: CompanyResolve,
        states: StatesResolve,
      },
    },
    {
      path: `${ROUTE.name.TITLE_COMPANY}`,
      component: TitleCompanyComponent,
      resolve: { states: StatesResolve },
    },
  ],
};
export const CONFIRM_EMAIL: Route = {
  path: ROUTE.name.CONFIRM_EMAIL,
  component: ConfirmEmailComponent,
};
export const RESTRICTED_ACCESS: Route = {
  path: ROUTE.name.RESTRICTED_ACCESS,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, RouteExtrasStateGuard],
  component: RestrictedAccessComponent,
  resolve: { state: RouteExtrasStateResolve },
};
export const FTL_AUTH_BY_UID: Route = {
  path: ROUTE.name.FTL_AUTH,
  children: [
    {
      path: ':ftl_uid',
      component: FTLAthByUIDComponent,
      resolve: { profile: FTLAuthByUIDResolve },
    },
    {
      path: ROUTE.EMPTY,
      component: FTLAthByUIDComponent,
      pathMatch: 'full',
    },
  ],
};
