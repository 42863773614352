import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntelligenceTransactionsRowItem } from '@core/types';

@Component({
  selector: 'transaction-realtor',
  templateUrl: './transaction-realtor.component.html',
  styleUrls: ['./transaction-realtor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionRealtorComponent {

  @Input() row: IntelligenceTransactionsRowItem;

}
