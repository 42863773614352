import { Route } from '@angular/router';

// Consts
import { PERMITTED_ACTION, ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { PermissionGuard } from 'asap-team/asap-tools';
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';
import { RegistrationCompletedGuard } from '@core/guards/registration-completed/registration-completed.guard';

export const REFERRAL: Route = {
  path: ROUTE.name.REFERRAL,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, PermissionGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard],
  loadChildren: () => import('@modules/user/referral/referral.module').then((m: any) => m.ReferralModule),
  data: {
    canBeActivatedBy: [USER_ROLE.lender],
    permitted_actions: [
      PERMITTED_ACTION.REFERRAL_VIEW,
      PERMITTED_ACTION.REFERRAL_MANAGE,
      PERMITTED_ACTION.REFERRAL_EXPIRED,
    ],
  },
};
