import type { NavItem } from '@core/types';

import { PERMITTED_ACTION, ROUTE, USER_ROLE } from '@consts/consts';

export const Analytics: NavItem[] = [
  {
    label: 'Active Buyers',
    routerLink: ROUTE.alias.ANALYTICS_ACTIVE_BUYERS,
    counter: null,
    isNewCounterDot: true, // todo:: ??
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    icon: null,
  },
  {
    label: 'Refinance',
    routerLink: ROUTE.alias.ANALYTICS_MORTGAGE,
    counter: null,
    isNewCounterDot: true,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.REFI_MANAGE],
    icon: null,
  },
];
