// eslint-disable-next-line max-classes-per-file
export namespace SearchPartnerSidebarActions {
  export class LookupPartners {

    static get type(): string {
      return '[Search Partner Sidebar] Search partner among all active customers';
    }

    constructor(
      public searchQuery: string,
      public reset: boolean = true,
    ) {}

  }

  export class LoadMoreAction {

    static get type(): string {
      return '[Search Partner Sidebar] Load more active customers';
    }

  }

  export class ResetState {

    static get type(): string {
      return '[Search Partner Sidebar] Reset state';
    }

  }

  export class UpdateRowData {

    static get type(): string {
      return '[Search Partner Sidebar] Update row item data';
    }

    constructor(
      public id: string,
      public data: any,
    ) {}

  }
}
