import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { isBoolean } from 'lodash-es';
import { filter } from 'rxjs/operators';
import {
  Dictionary,
  VaultService,
} from 'asap-team/asap-tools';

import type {
  SellerDigestCollectionLead,
  SellerTrafficCollectionLead,
} from '@core/types';

// Consts
import { ARCHIVE_LEAD_SESSION, PERMITTED_ACTION, LEAD } from '@consts/consts';

// Components
import { ArchiveConfirmComponent } from '@commons/modals/archive-confirm/archive-confirm.component';
import { BuyersTrafficService } from '@core/services/collections/buyers-traffic/buyers-traffic.service';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

type Lead = SellerDigestCollectionLead | SellerTrafficCollectionLead;

@UntilDestroy()
@Component({
  selector: 'buyers-lead-actions',
  templateUrl: './buyers-lead-actions.component.html',
})
export class BuyersLeadActionsComponent {

  @Input() lead: Lead;

  @Input() class: string;

  @Input() useCustomButton: boolean = false;

  @Output() reload: EventEmitter<void> = new EventEmitter<void>();

  PERMITTED_ACTION: Dictionary<string> = PERMITTED_ACTION;

  constructor(
    private iqModalService: IqModalService,
    private toastr: ToastrService,
    private vaultService: VaultService,
    private buyersTrafficService: BuyersTrafficService,
  ) {
  }

  archive(id: string): void {
    if (!this.vaultService.get(ARCHIVE_LEAD_SESSION, 'session')) {
      this
        .iqModalService
        .open(ArchiveConfirmComponent)
        .closed
        .pipe(
          filter(isBoolean),
          untilDestroyed(this),
        )
        .subscribe(
          () => this.archiveLead(id),
        );
    } else {
      this.archiveLead(id);
    }
  }

  archiveLead(id: string): void {
    this
      .buyersTrafficService
      .archiveLead(id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(LEAD.ARCHIVED);
          this.reload.emit();
        },
      );
  }

}
