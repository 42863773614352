import type { SideNavItem } from '@core/types';

import { ROUTE, PERMITTED_ACTION, USER_ROLE } from '@consts/consts';

export const SettingsSidebarNav: SideNavItem[] = [
  {
    label: 'Profile',
    url: ROUTE.alias.PROFILE,
    logo: '/assets/images/toolbar-config/profile-icon.svg',
    permitted_actions: [PERMITTED_ACTION.PROFILE_MANAGE],
    collapsed: false,
    children: [],
  },
  {
    label: 'Billing',
    url: ROUTE.alias.BILLING,
    logo: '/assets/images/toolbar-config/billing-icon.svg',
    permitted_actions: [PERMITTED_ACTION.BILLING_MANAGE],
    children: [],
  },
  {
    label: 'Homeowners',
    url: null,
    logo: '/assets/images/toolbar-config/homeowners-icon-v2.svg',
    permitted_actions: [PERMITTED_ACTION.HOMEOWNERS_MANAGE],
    children: [
      {
        label: 'Fees',
        url: ROUTE.alias.HOMEOWNERS_FEES,
        permitted_actions: [PERMITTED_ACTION.HOMEOWNERS_FEES_MANAGE],
        permitted_roles: [USER_ROLE.agent],
      },
      {
        label: 'Refinance Fees',
        url: ROUTE.alias.HOMEOWNERS_FEES,
        permitted_actions: [PERMITTED_ACTION.HOMEOWNERS_FEES_MANAGE],
        permitted_roles: [USER_ROLE.lender],
      },
      {
        label: 'Listing fees',
        url: ROUTE.alias.HOMEOWNERS_SELLING_FEES,
        permitted_actions: null,
        permitted_roles: [USER_ROLE.lender],
      },
      {
        label: 'Website',
        url: ROUTE.alias.HOMEOWNERS_SETTINGS_LANDING_PAGES,
        permitted_actions: [
          PERMITTED_ACTION.HOME_WEALTH_LANDING_MANAGE,
          PERMITTED_ACTION.HOMEOWNERS_LANDING_MANAGE,
        ],
      },
      {
        label: 'White-label',
        url: ROUTE.alias.HOMEOWNERS_REPORT_PAGE,
      },
      {
        label: 'Report settings',
        url: ROUTE.alias.REPORT_SETTINGS_PAGE,
      },
      {
        label: 'Email template',
        url: ROUTE.alias.HOMEOWNERS_EMAIL,
        permitted_actions: [PERMITTED_ACTION.HOMEOWNERS_EMAIL_MANAGE],
        permitted_roles: [USER_ROLE.agent, USER_ROLE.lender],
      },
    ],
  },
  {
    label: 'Buyers',
    url: null,
    logo: '/assets/images/toolbar-config/buyers-icon-v2.svg',
    permitted_actions: [PERMITTED_ACTION.BUYERS_LANDING_MANAGE],
    children: [
      {
        label: 'Buying Fees',
        url: ROUTE.alias.BUYERS_FEES,
        permitted_roles: [USER_ROLE.lender],
      },
      {
        label: 'Website',
        url: ROUTE.alias.BUYERS_LP_PREVIEW,
        permitted_actions: [PERMITTED_ACTION.BUYERS_LANDING_MANAGE],
      },
      {
        label: 'Loan programs',
        url: ROUTE.alias.BUYERS_LP_PROGRAMS,
        permitted_roles: [USER_ROLE.lender],
      },
    ],
  },
  {
    label: 'Integrations',
    url: ROUTE.alias.INTEGRATIONS,
    logo: '/assets/images/toolbar-config/intelligence-icon.svg',
    permitted_actions: [PERMITTED_ACTION.INTEGRATIONS_MANAGE],
    children: [],
  },
];
