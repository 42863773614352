<div class="placeholder">
  <ng-container *ngIf="isAgentTrial; else subPause">
    <p class="placeholder__title f-32-bold" style="max-width: 750px">
      Invite your loan officer and get access to marketing banners
    </p>
    <p class="placeholder__subtitle f-16-normal">
      There is no cost to you for unlocking your homeIQ account, simply invite the lender you work and gain access to all the premium features homeIQ has to offer.
    </p>
    <div class="placeholder__buttons">
      <iq-button [label]="'Invite loan officer'"
                 [routerLink]="[ROUTE.alias.PARTNERSHIP]"
                 [queryParams]="{ 'invite_sidebar': true }"></iq-button>
      <iq-button [label]="'Get invitation link'"
                 [style]="'outline'"
                 (click)="showGetLinkModal()"></iq-button>
    </div>
  </ng-container>
  
  <ng-template #subPause>
    <p class="placeholder__title f-32-bold" style="max-width: 490px">
      Get access to marketing banners to capture online leads
    </p>
    <ng-container *ngIf="isSubPaused; else contactSupportState">
      <ng-container *roleIf="USER_ROLE.agent">
        <p class="placeholder__subtitle f-16-normal" style="max-width: 570px">
          This feature currently unavailable on your loan officer’s subscription.
          <br>
          If you want to start or continue using this feature, ask your loan officer to upgrade their subscription
        </p>
      </ng-container>
      <ng-container *roleIf="USER_ROLE.lender">
        <p class="placeholder__subtitle f-16-normal">
          Renew your subscription and get access to marketing banners
        </p>
        <div class="placeholder__buttons">
          <iq-button label="Renew subscription" [routerLink]="ROUTE.alias.BILLING"></iq-button>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
  
  <ng-template #contactSupportState>
    <p class="placeholder__subtitle f-16-normal" style="max-width: 570px">
      This feature currently unavailable on your loan officer’s subscription.
      <br>
      If you want to start or continue using this feature, ask your loan officer to upgrade their subscription
    </p>
    <div class="placeholder__buttons">
      <a href="mailto:support@myhomeiq.com">
        <iq-button label="Contact support"></iq-button>
      </a>
    </div>
  </ng-template>
</div>
<div class="placeholder__image">
  <img src="assets/components/collections/traffic/buyer-banner.png"/>
  <div class="placeholder__image--hint">
    <img src="assets/components/collections/traffic/banner-access.png"/>
  </div>
</div>
