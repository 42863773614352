import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'loan-type-stats-item',
  templateUrl: './loan-type-stats-item.component.html',
  styleUrls: ['./loan-type-stats-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanTypeStatsItemComponent {

  @Input() title: string;

  @Input() count: number = 0;

  @Input() percent: number = 0;

  @Input() color: string;

}
