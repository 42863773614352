<div class="invoice invoice--line" *ngFor="let checkoutItem of info">
  <div class="invoice-description">
    <div class="invoice-title">
      {{ checkoutItem.title }}
      <div class="labeled" *ngIf="checkoutItem.frequency">
        {{ checkoutItem.frequency }}
      </div>
    </div>
  </div>
  <div class="invoice-value">{{ checkoutItem.amount | amount }}</div>
</div>
<div class="m-t-3x m-b-4x">
  <div class="invoice invoice--total" *ngFor="let checkoutItem of total">
    <div class="invoice-description">
      <div class="invoice-title">
        <span>
          {{ checkoutItem.title?.toLowerCase() === ADDED_TO_ACCOUNT_BALANCE ? 'Account balance' :  checkoutItem.title }}
        </span>
        <div
          *ngIf="checkoutItem.title?.toLowerCase() === ADDED_TO_ACCOUNT_BALANCE"
          class="invoice__icon m-l-1x"
          [inlineSVG]="'#icon-question'"
          [tooltip]="tooltipText"
        ></div>
      </div>
    </div>
    <div class="invoice-value">{{ checkoutItem.amount | amount }}</div>
  </div>
</div>
