import { Component } from '@angular/core';
import { Dictionary } from 'asap-team/asap-tools';

import type { UserRole } from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';

@Component({
  selector: 'profile-support',
  templateUrl: './profile-support.component.html',
  styleUrls: ['./profile-support.component.scss'],
})
export class ProfileSupportComponent {

  public readonly USER_ROLE: Dictionary<UserRole> = USER_ROLE;

}
