import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { upperCase } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from 'asap-team/asap-tools';

import type { UserCard } from '@core/types';

// Consts
import { USER_CARD_SIDEBAR_TYPE, USER_CARD_SIDEBAR_RESULT, SIDEBAR_CONFIG, ROUTE } from '@consts/consts';

// Components
import { UserCardSidebarComponent } from '@commons/sidebars/user-card-sidebar/user-card-sidebar.component';

// Services
import { PartnershipService } from '@core/services/partnership/partnership.service';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { UserService } from '@core/services/user/user.service';

// Store
import { SearchPartnerSidebarActions } from '@commons/sidebars/search-partner-sidebar/state/search-partner-sidebar.actions';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'partner-collection-item',
  templateUrl: './partner-collection-item.component.html',
  styleUrls: ['./partner-collection-item.component.scss'],
})
export class PartnerCollectionItemComponent implements OnInit {

  @Input() partner: UserCard;

  @Output() emitAction: EventEmitter<string> = new EventEmitter<string>();

  invitationStates: any = {
    engaged: 'engaged',
    active: 'active',
    invitation_received: 'invitation_received',
    invitation_sent: 'invitation_sent',
  };

  modalType: string;

  action: Subscription;

  constructor(
    private store: Store,
    private modalService: ModalService,
    private partnershipService: PartnershipService,
    private asaplyticsService: AsaplyticsService,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.modalType = this.setModalType(this.partner.invitation_status);
  }

  invite(): void {
    this.action = this
      .partnershipService
      .inviteInternalPartner(this.partner.uid)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.updateStatePartner(this.invitationStates.invitation_sent);
          this.sendGAEvent('send_invite_to_user');
        },
        () => {
          this.router.navigate([ROUTE.alias.UPGRADE_SUBSCRIPTION]);
          this.modalService.removeAll();
        },
      );
  }

  declineInvite(): void {
    this.action = this
      .partnershipService
      .declineInvite(this.partner.partnership_invitation_token)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.updateStatePartner(this.invitationStates.active);
        },
      );
  }

  acceptInvite(): void {
    this.action = this
      .partnershipService
      .acceptInvite(this.partner.partnership_invitation_token)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => this.updateStatePartner(this.invitationStates.engaged),
      );
  }

  cancelInvite(): void {
    this.action = this
      .partnershipService
      .cancelInvite(this.partner.partnership_id.toString())
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => this.updateStatePartner(this.invitationStates.active),
      );
  }

  updateStatePartner(state: string): void {
    const payload: any = { ...this.partner, invitation_status: state };

    this.store.dispatch(new SearchPartnerSidebarActions.UpdateRowData(this.partner.id, payload));
    this.modalType = this.setModalType(state);
    this.emitAction.emit('update');
  }

  formatToUpper(value: string): string {
    return upperCase(value);
  }

  openUserCard(user: UserCard, type: string): void {
    this.sendGAEvent('view_user_card');

    this
      .modalService
      .open(
        UserCardSidebarComponent,
        {
          user,
          type,
        },
        SIDEBAR_CONFIG,
      )
      .pipe(
        filter(Boolean),
        untilDestroyed(this),
      )
      .subscribe(
        (result: string) => {
          switch (result) {
            case USER_CARD_SIDEBAR_RESULT.sentInvitation: {
              this.invite();
              break;
            }
            case USER_CARD_SIDEBAR_RESULT.rejectInvitation: {
              this.declineInvite();
              break;
            }
            case USER_CARD_SIDEBAR_RESULT.acceptInvitation: {
              this.acceptInvite();
              break;
            }
            case USER_CARD_SIDEBAR_RESULT.cancelInvitation: {
              this.cancelInvite();
              break;
            }
            default: {
              break;
            }
          }
        },
      );
  }

  private setModalType(type: string): string {
    switch (type) {
      case this.invitationStates.active: {
        return USER_CARD_SIDEBAR_TYPE.invite;
      }
      case this.invitationStates.engaged: {
        return USER_CARD_SIDEBAR_TYPE.engaged;
      }
      case this.invitationStates.invitation_sent: {
        return USER_CARD_SIDEBAR_TYPE.outgoing;
      }
      case this.invitationStates.invitation_received: {
        return USER_CARD_SIDEBAR_TYPE.incoming;
      }
      default: {
        return '';
      }
    }
  }

  private sendGAEvent(label: string): void {
    this.asaplyticsService.sendEvent({
      action: `invite_by_${this.userService.getUserRole()}`,
      category: 'Invite_sidebar',
      label,
    });
  }

}
