<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">This report cannot be activated</h4>
    <div class="close inline-svg"
         aria-label="Close"
         [inlineSVG]="'#icon-close'"
         (click)="close()"></div>
  </div>
  <div class="modal-body">
    <img class="report-activate-image m-b-3x"
         src="assets/components/dashboard/report-error.png"
         alt="activate report from transaction">
    <div class="f-16-600">
      <span>You can`t activate this report due to the following reasons:</span>
    </div>
    <ul class="errors-list f-12-400">
      <li class="errors-list_item p-t-2x m-l-4x f-16-400"
          *ngFor="let error of errors">
        {{ error }}
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block m-b-2x"
                   label="View report details"
                   (click)="viewReport()"></iq-button>
        <iq-button class="block"
                   label="Cancel"
                   color="grey"
                   (click)="close()"></iq-button>
      </div>
    </div>
  </div>
</div>
