import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ModalService, isPermitted } from 'asap-team/asap-tools';

// Consts
import {
  ROUTE,
  USER_ROLE,
  PERMITTED_ACTION,
  BILLING_PLAN,
} from '@consts/consts';
import type { Profile } from '@core/types/Profile';

// Services
import { UserService } from '@core/services/user/user.service';

// Components
import { GetInviteLinkComponent } from '@commons/modals/get-invite-link/get-invite-link.component';
import { isEqual } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'buyer-banner-collection-placeholder',
  templateUrl: 'buyer-banner-collection-placeholder.component.html',
  styleUrls: ['buyer-banner-collection-placeholder.component.scss'],
})
export class BuyerBannerCollectionPlaceholderComponent implements OnInit {

  profile: Profile;

  USER_ROLE: any = USER_ROLE;

  ROUTE: any = ROUTE;

  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.userService.profile$
      .pipe(
        distinctUntilChanged(isEqual),
        untilDestroyed(this),
      ).subscribe((profile: Profile) => {
        this.profile = profile;
      });
  }

  get isSubPaused(): boolean {
    return isPermitted(
      this.profile?.permitted_actions,
      [PERMITTED_ACTION.BUYER_BANNERS_LOCK_SUBSCRIPTION_RENEW, PERMITTED_ACTION.BUYER_BANNERS_LOCK_BY_LENDER_UPGRADE],
    );
  }

  get isAgentTrial(): boolean {
    // eslint-disable-next-line max-len
    return (this.profile?.plan_name === BILLING_PLAN.TRIAL || this.profile?.plan_name === BILLING_PLAN.SOLO_PLAN) && this.profile?.role === USER_ROLE.agent;
  }

  showGetLinkModal(): void {
    this.modalService
      .open(GetInviteLinkComponent)
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe();
  }

}
