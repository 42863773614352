import { Observable, Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import type { PaymentMethod } from '@core/types';

// Services
import { BillingService } from '@core/services/billing/billing.service';

@Component({ templateUrl: './delete-card.component.html' })
export class DeleteCardComponent extends SimpleModalComponent<{ card: PaymentMethod }, boolean> {

  card: PaymentMethod;

  action: Subscription;

  constructor(
    private billingService: BillingService,
  ) {
    super();
  }

  submit(): void {
    const observable: Observable<any> = this.billingService.deletePaymentMethod(this.card.id);

    this.action = observable.subscribe(
      () => {
        this.billingService.paymentMethodsChange();
        this.result = true;
        this.close();
      },
    );
  }

}
