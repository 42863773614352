import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HTMLInputEvent } from 'asap-team/asap-tools';

@Component({
  selector: 'iq-toggle-filter',
  templateUrl: './iq-toggle-filter.component.html',
  styleUrls: ['./iq-toggle-filter.component.scss'],
})
export class IqToggleFilterComponent {

  @Input() title: string;

  @Input() filterName: string;

  @Input() stateValue: any;

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  valueChanged({ target: { checked } }: HTMLInputEvent): void {
    this.emitFilterValue.emit({ filterName: this.filterName, value: checked });
  }

}
