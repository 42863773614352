import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { StackbarGraphData } from '@commons/diagrams';

@Component({
  selector: 'loan-officer-transactions-graph',
  templateUrl: './loan-officer-transactions-graph.component.html',
  styleUrls: ['../transactions-graph.scss'],
})
export class LoanOfficerTransactionsGraphComponent {

  @Input() graphData: StackbarGraphData[];

  @Input() loading: boolean = false;

  tooltipData: StackbarGraphData = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  setTooltipData(event: StackbarGraphData): void {
    this.tooltipData = event;
    this.changeDetectorRef.detectChanges();
  }

}
