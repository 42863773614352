import { Route } from '@angular/router';
import { PermissionGuard } from 'asap-team/asap-tools';

// Consts
import { PERMITTED_ACTION, ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';

export const PARTNERS: Route = {
  path: ROUTE.name.PARTNERS,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, PermissionGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard, PermissionGuard],
  loadChildren: () => import('@modules/admin/collections/partners/partners.module').then((m: any) => m.PartnersModule),
  data: {
    permitted_actions: [PERMITTED_ACTION.ENTERPRISES_MANAGE],
    redirectTo: ROUTE.alias.SIGN_IN,
    canBeActivatedBy: [USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.marketing],
  },
};
