import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ResponseErrorHandler } from 'asap-team/asap-tools';

// Consts
import { ROUTE } from '@consts/consts';

// Service
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class FTLAuthByUIDResolve implements Resolve<any> {

  constructor(
    private router: Router,
    private userService: UserService,
    private responseErrorHandler: ResponseErrorHandler,
    private toastr: ToastrService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): any {
    const ftl_uid: string = route.paramMap.get('ftl_uid');

    if (!ftl_uid) {
      this.router.navigate([ROUTE.name.SIGN_IN]);

      return EMPTY;
    }

    return this
      .userService
      .registerUserFromViral(ftl_uid, route.queryParams)
      .pipe(
        switchMap(({ id }: { id: string }) => {
          this.userService.setToken(id);

          return this.userService.getRawProfile();
        }),
        catchError((error: any) => {
          const message: string = this.responseErrorHandler.format(error.error.errors);

          this.toastr.error(message);
          this.router.navigate([ROUTE.name.SIGN_IN]);

          return throwError(error);
        }),
      );
  }

}
