import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const confirmPhoneErrors: FormErrors = {
  code: [
    {
      name: 'required',
      text: 'Code can’t be blank',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'minlength',
      text: 'Incorrect code',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'maxlength',
      text: 'Incorrect code',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  global: [],
};
