import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CollectionHelpersService, Dictionary, BaseHttpService, CollectionResponse } from 'asap-team/asap-tools';

import type { IncomingInvite } from '@core/types';

@Injectable({ providedIn: 'root' })
export class IncomingInvitesService {

  constructor(
    private http: BaseHttpService,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(params: Dictionary<string | number>): Observable<CollectionResponse<IncomingInvite>> {
    return this
      .http
      .get('v2/partnerships/incomings', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.retrowError(),
      );
  }

}
