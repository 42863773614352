<input class="file-input"
       #nativeInput=""
       type="file"
       accept="image/gif,image/jpg,image/jpeg,image/svg,image/png"
       (change)="onImageSelected($event)"/>
<div class="control">
  <div class="control__prepend">
    <div class="control__label flex-center">
      <iq-button *ngIf="fileName && !isFileLoading"
                 size="24"
                 color="grey"
                 [style]="'transparent'"
                 iconLeft="#icon-close"
                 (onClick)="remove()"></iq-button>
      <div class="control__label-text flex-1">
        <div class="text-overflow" *ngIf="!fileName">Default background</div>
        <div class="text-overflow" *ngIf="!isFileLoading">{{ fileName }}</div>
      </div>
      <div class="m-l-1x">
        <iq-button label="Select Background"
                   size="24"
                   [style]="'outline'"
                   [busy$]="isFileLoading"
                   (onClick)="nativeInput.click($event)"></iq-button>
      </div>
    </div>
  </div>
</div>
<div class="control-error-message" *ngFor="let error of fileError">{{ error.detail }}</div>
