import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';

// Services
import { CacheRegistrationService } from '@core/helpers/cache-registration/cache-registration.service';

@Injectable()
export class RequestCachingInterceptor implements HttpInterceptor {

  private cachedData: Map<string, any> = new Map<string, any>();

  constructor(
    private cacheRegistrationService: CacheRegistrationService,
  ) {}

  intercept(
    httpRequest: HttpRequest<any>,
    httpHandler: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Don't cache if
    // 1. It's not a GET request
    // 2. If URI is not supposed to be cached
    if (httpRequest.method !== 'GET' || !this.cacheRegistrationService.isAddedToCache(httpRequest.url)) {
      return httpHandler.handle(httpRequest);
    }

    // Also leave scope of resetting already cached data for a URI
    if (httpRequest.headers.get('reset-cache')) {
      this.cachedData.delete(httpRequest.urlWithParams);
    }

    // Checked if there is cached data for this URI
    const lastResponse: any = this.cachedData.get(httpRequest.urlWithParams);

    if (lastResponse) {
      // In case of parallel requests to same URI,
      // return the request already in progress
      // otherwise return the last cached data
      return (lastResponse instanceof Observable) ? lastResponse : of(lastResponse.clone());
    }

    // If the request of going through for first time
    // then let the request proceed and cache the response
    const requestHandler: Observable<HttpEvent<any>> = httpHandler
      .handle(httpRequest)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.cachedData.set(
              httpRequest.urlWithParams,
              event.clone(),
            );
          }
        }),
        share(),
      );

    // Meanwhile cache the request Observable to handle parallel request
    this.cachedData.set(httpRequest.urlWithParams, requestHandler);

    return requestHandler;
  }

}
