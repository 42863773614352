<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Change Password</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <form class="confirm-phone__section" [formGroup]="form">
      <iq-form-control-password class="m-b-2x"
                                *ngIf="step.current"
                                label="Current"
                                placeholder="********"
                                formControlName="current_password"
                                [errorsModel]="formErrors.password"></iq-form-control-password>
      <iq-form-control-password class="m-b-2x"
                                *ngIf="step.new"
                                label="New Password"
                                placeholder="********"
                                formControlName="password"
                                [errorsModel]="formErrors.password"></iq-form-control-password>
    </form>
    <div class="control-error-message m-b-2x" *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block" label="Cancel" color="grey" [style]="'outline'" (onClick)="close()"></iq-button>
      </div>
      <div class="col col-fix" *ngIf="step.current">
        <iq-button class="block"
                   label="Continue"
                   [busy$]="validateAction"
                   [disabled]="form.get('current_password').invalid"
                   (onClick)="validateCurrentPassword()"></iq-button>
      </div>
      <div class="col col-fix" *ngIf="step.new">
        <iq-button class="block"
                   label="Change"
                   [busy$]="saveAction"
                   [disabled]="form.get('password').invalid"
                   (onClick)="save()"></iq-button>
      </div>
    </div>
  </div>
</div>
