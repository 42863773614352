<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <div class="control__content">
    <div class="d-flex justify-content-center align-items-center">
      <div class="flex-1">
        <input class="control__input"
               #nativeInput=""
               [attr.type]="inputType"
               [attr.placeholder]="placeholder"
               [attr.data-lpignore]="lpignore"
               (focus)="focused = true"
               (blur)="blur()"
               (input)="input($any($event).target.value)"/>
      </div>
      <div class="m-l-1x m-r-1x">
        <iq-button *ngIf="inputType === 'password'"
                   size="40"
                   [style]="'transparent'"
                   color="grey"
                   iconLeft="#icon-eye"
                   (onClick)="changeInputType('text')"></iq-button>
        <iq-button *ngIf="inputType === 'text'"
                   size="40"
                   [style]="'transparent'"
                   color="grey"
                   iconLeft="#icon-eye_closed"
                   (onClick)="changeInputType('password')"></iq-button>
      </div>
    </div>
  </div>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
