<form class="control" [formGroup]="form" [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
      [class.control--focus]="focused">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <div class="control__content">
    <input class="control__input"
           #nativeInput=""
           type="text"
           readonly=""
           bsDatepicker=""
           formControlName="date"
           [bsConfig]="bsConfig"
           [attr.placeholder]="placeholder"
           (focus)="focused = true"
           (blur)="blur()"/>
  </div>
</form>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
