/* eslint-disable */
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';

// Consts
import { INTERCOM_ID } from '@consts/consts';
import { Intercom } from '@core/interfaces/intercom.interface';

declare global {
  interface Window {
    Intercom: Intercom;
  }
}

@Injectable({ providedIn: 'root' })
export class IntercomService {

  private intercom = new BehaviorSubject<string>(null);

  intercom$ = this.intercom.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
  ) {}

  init(): void {
    const ic = (this.window as any).Intercom;

    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', (this.window as any).intercomSettings);
    } else {
      const i: any = function () {
        i.c(arguments);
      };

      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      (this.window as any).Intercom = i;

      const emit = () => {
        this.intercom.next('loaded');
      };
      const load = () => {
        const script = this.document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;
        script.onload = emit;
        script.src = `https://widget.intercom.io/widget/${INTERCOM_ID}`;

        const scriptElement = this.document.getElementsByTagName('script')[0];

        scriptElement.parentNode.insertBefore(script, scriptElement);
      };

      if (this.document.readyState === 'complete') {
        load();
      } else if ((this.window as any).attachEvent) {
        (this.window as any).attachEvent('onload', load);
      } else {
        (this.window as any).addEventListener('load', load, false);
      }
    }
  }

  start({ email, user_id }: { email: string; user_id: string }): void {
    (this.window as any).Intercom('boot', {
      email,
      user_id,
      app_id: INTERCOM_ID,
    });
  }

  showMessageScreen(nameForGreeting?: string): void {
    if (nameForGreeting) {
      this.window.Intercom('update', {name: nameForGreeting});
    }
    this.window.Intercom('showNewMessage');
  }
}
