import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import type { Member, Lead } from '@core/types';

// Services
import { LeadDetailsService } from '../details/lead/lead-details.service';

@Injectable({ providedIn: 'root' })
export class LeadMembershipsService {

  constructor(
    private http: HttpClient,
    private leadDetailsService: LeadDetailsService,
  ) {}

  getUnassignedMembers(lead_id: string, search: string): Observable<Member[]> {
    const params: HttpParams = new HttpParams({ fromObject: { search } });

    return this
      .http
      .get<Member[]>(`v2/leads/${lead_id}/members/search_unassigned`, { params });
  }

  addMemberToLead(lead_id: string, payload: { user_id: string; permission: string; primary: string }): Observable<Lead> {
    return this
      .http
      .post(`v2/leads/${lead_id}/members`, payload)
      .pipe(
        tap((lead: any) => this.leadDetailsService.emitLeadUpdate(lead)),
      );
  }

  leaveLead(lead_id: string): Observable<void> {
    return this
      .http
      .delete<void>(`v2/leads/${lead_id}/members/leave`);
  }

  removeLeadMember(lead_id: string, id: string): Observable<Lead> {
    return this
      .http
      .delete(`v2/leads/${lead_id}/members/${id}/removing`)
      .pipe(
        map((lead: Lead) => this.leadDetailsService.emitLeadUpdate(lead)),
      );
  }

}
