import type { ClaimedStatus, ImportDatabaseSteps, UploadDatabaseSteps } from '@core/types';

export const IMPORT_DATABASE_STEPS: ImportDatabaseSteps = {
  select_file: 'select_file',
  upload_db_file_progress: 'upload_db_file_progress',
  validation_db_file_progress: 'validation_db_file_progress',
  validation_db_file_failed: 'validation_db_file_failed',
  validation_info: 'validation_info',
  import_progress: 'import_progress',
  import_success: 'import_success',
  import_failed: 'import_failed',
};

export const UPLOAD_DATABASE_STEPS: UploadDatabaseSteps = {
  select_file: 'select_file',
  upload_validation: 'upload_validation',
  allow_co_borrowers: 'allow_co_borrowers',
  allow_fill_blanks: 'allow_fill_blanks',
  allow_send_sms: 'allow_send_sms',
  upload_failed: 'upload_failed',
  upload_success: 'upload_success',
};

export const CLAIMED_STATUS: ClaimedStatus = {
  claimed: 'Claimed',
  notClaimed: 'Not Claimed',
  imported: 'Imported',
  deleted: 'Deleted',
};
