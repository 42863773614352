<div class="modal-content">
  <div class="modal-header">
    <h4 class="custom-title">Refinance Opportunity Example</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
  </div>
  <div class="modal-body">
      <h4 class="modal-title">See an example of what your client will receive when you send this refinance opportunity from myhomeIQ.</h4>
      <div class="mode-toggle">
        <div class="mode-item pointer" (click)="setMode('email')" [class.active]="mode === 'email'">Email</div>
        <div class="mode-item pointer" (click)="setMode('certificate')" [class.active]="mode === 'certificate'">Certificate</div>
      </div>
      <div class="document-wrapper" [ngClass]="{ 'email': mode === 'email', 'certificate': mode === 'certificate'}">
          <img class="document" [src]="'/assets/components/refinance-opportunity-example/' + mode + '-example.png'" alt="example">
      </div>
  </div>
  <div class="modal-footer">
    <div class="flex flex-center">
      <iq-button class="block" label="Got It" [style]="'solid'" (onClick)="closeModal()"></iq-button>
    </div>
  </div>
</div>
