<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Confirm phone</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <form novalidate="" name="form" [formGroup]="form" (ngSubmit)="confirmPhone()">
      <div class="modal-body__text">
        We’ve sent you SMS with confirmation code. Please enter the 6 digits number below.
      </div>
      <iq-form-control-mask class="m-t-3x m-b-2x"
                            label="Confirmation code"
                            labelStyle="wide"
                            placeholder="6 digits"
                            mask="000000"
                            formControlName="code"
                            [errorsModel]="formErrors.code"></iq-form-control-mask>
      <div class="row row-fix">
        <div class="col col-fix">
          <iq-button class="block"
                     type="submit"
                     label="Submit"
                     [disabled]="form.invalid"
                     [busy$]="confirmPhone$"></iq-button>
        </div>
        <div class="col col-fix">
          <iq-button class="block"
                     label="Resend code"
                     color="grey"
                     [style]="'outline'"
                     [busy$]="resendCode$"
                     (onClick)="resendCode()"></iq-button>
        </div>
      </div>
    </form>
  </div>
</div>
