import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required } from './conditions';

export const applicationLinkErrors: FormErrors = {
  external_app_url: [
    required,
    apiError(),
  ],
  thank_you_video_url: [
    required,
    apiError(),
  ],
  global: [],
};
