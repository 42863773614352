import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
} from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  private bypassUrls: string[] = [
    'assets',
    'vimeo',
    'amazonaws',
    'api.rozum',
  ];

  intercept(
    httpRequest: HttpRequest<any>,
    httpHandler: HttpHandler,
  ): Observable<HttpEvent<any>> {

    const shouldBypass: boolean = this.bypassUrls.some((url: string) => httpRequest.url.includes(url));

    if (!shouldBypass) {
      return httpHandler.handle(httpRequest.clone({ url: `${environment.api}/${httpRequest.url}` }));
    }

    return httpHandler.handle(httpRequest);
  }

}
