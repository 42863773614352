import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { FTLStep } from '@core/types';

// Services
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';

// Store
import { FTLActions } from '@modules/auth/sign-up/ftl/state/ftl.actions';
import { FTLStepName } from '@modules/auth/sign-up/ftl/state/ftl.model';
import { FtlState } from '@modules/auth/sign-up/ftl/state/ftl.state';

@Component({
  selector: 'agent-invited',
  templateUrl: './agent-invited.component.html',
  styleUrls: ['../ftl.component.scss', './agent-invited.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentInvitedComponent implements OnInit {

  @Select(FtlState.ftlStepData) ftlStepData$: Observable<FTLStep>;

  constructor(
    private store: Store,
    private asaplyticsService: AsaplyticsService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new FTLActions.GetStepDataAction(FTLStepName.agent_invited));
  }

  submitAgentInvited(code: string): void {
    this.asaplyticsService.sendEvent({ action: 'click_reaction_success_step_agent', category: 'ftl_agent_2024' });
    this.store.dispatch(new FTLActions.SubmitFtlStepAction(FTLStepName.agent_invited, { code }));
  }

  getFormattedName(fullName: string): string {
    if (fullName?.length >= 18) {
      const spittedName: string[] = fullName.split(' ');

      return spittedName.length > 1 ? spittedName.map((name: string, index: number) => (index === 0 ? name : `${name[0]}.`)).join(' ') : spittedName[0];
    }

    return fullName;
  }

  skipStep(): void {
    this.asaplyticsService.sendEvent({ action: 'click_skip_success_step_agent', category: 'ftl_agent_2024' });
    this.store.dispatch(new FTLActions.SkipFtlStepAction(FTLStepName.agent_invited));
  }

}
