import {
  AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild,
} from '@angular/core';
import { select, pie, scaleOrdinal, arc } from 'd3';
import { isEqual } from 'lodash-es';

export type DonutChartData = {
  value: number;
  label: string;
  key: string;
  color: string;
};

const EMPTY_DATA: DonutChartData[] = [
  {
    key: 'total_conv_loans',
    label: 'Conventional',
    value: 1,
    color: '#DAE1E6',
  },
  {
    key: 'total_fha_loans',
    label: 'FHA',
    value: 1,
    color: '#EBEFF2',
  },
  {
    key: 'total_va_loans',
    label: 'VA',
    value: 1,
    color: '#F4F8F9',
  },
  {
    key: 'total_other_loans',
    label: 'Other',
    value: 1,
    color: '#F7FAFC',
  },
];

@Component({
  selector: 'donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements AfterViewInit, OnChanges {

  @Input() data!: DonutChartData[];

  @Input() chartCenterLabel!: string;

  @Input() width: number = 160;

  @Input() height: number = 160;

  @Input() innerRadius: number = 62;

  @ViewChild('svg') svg!: ElementRef<HTMLElement>;

  graph!: any;

  donut!: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data) {
      const { previousValue, currentValue } = changes.data;

      if (!isEqual(previousValue, currentValue) && this.graph) {
        const graphData: DonutChartData[] = currentValue || EMPTY_DATA;

        this.setData(graphData);
      }
    }
  }

  ngAfterViewInit(): void {
    this.init();
  }

  get radius(): number {
    return Math.min(this.width, this.height) / 2 - 2;
  }

  setData(chartData: DonutChartData[]): void {
    // Set pie data
    const pieData: any = pie().value((d: any) => d.value).sort(() => 1)(chartData as any);

    // set the color scale
    const color: any = scaleOrdinal().range(chartData.map((value: DonutChartData) => value.color));

    this.graph.selectAll('.pie-item').remove();
    this.graph
      .selectAll('g')
      .data(pieData)
      .join(
        (enter: any) => enter
          .append('path')
          .attr('id', ({ data }: { data: DonutChartData }) => data.key)
          .attr('class', 'pie-item')
          .attr('d', arc().innerRadius(this.innerRadius).outerRadius(this.radius) as any)
          .attr('fill', (d: any) => color(d.index) as any)
          .attr('stroke', '#fff')
          .attr('stroke-width', 2)
          .attr('stroke-linecap', 'square')
          .attr('title', ({ data }: { data: DonutChartData }) => data.label),
      );

    // Remove existing label
    this.graph.selectAll('.center-label').remove();

    // Add label if the condition is met
    if (this.chartCenterLabel) {
      this.graph
        .append('text')
        .attr('class', 'center-label')
        .attr('text-anchor', 'middle')
        .attr('dy', '.35em')
        .attr('font-size', '24px')
        .attr('font-weight', '600')
        .text(this.chartCenterLabel);
    }
  }

  private init(): void {
    this.graph = select(this.svg.nativeElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`);

    this.setData(this.data || EMPTY_DATA);
  }

}
