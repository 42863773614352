<div class="demo">
  <ng-container *ngIf="profile">
    <ng-container *roleIf="USER_ROLE.agent">
      <avatar-placeholder
        class="demo-avatar"
        [name]="'Chris Prescott'"
        [image]="'assets/components/customer-support/chris.jpg'"
        [tooltip]="'Chris Prescott'"
        placement="top"
        [tooltipAppendToBody]="true"
      ></avatar-placeholder>
    </ng-container>
    <ng-container *roleIf="USER_ROLE.lender">
      <avatar-placeholder
        class="demo-avatar"
        [name]="profile?.assigned_staff?.name || 'Tawd Frensley'"
        [image]="profile?.assigned_staff?.avatar?.medium || 'assets/components/customer-support/tawd.png'"
        [tooltip]="profile?.assigned_staff?.name || 'Tawd Frensley'"
        placement="top"
        [tooltipAppendToBody]="true"
      ></avatar-placeholder>
    </ng-container>

    <h2 class="ftl-title m-b-1x text-center">
      <ng-container *roleIf="USER_ROLE.agent">
        {{ profile?.name }}, book a quick demo to see how top agents are winning
        more deals in today's market with myhomeIQ.
      </ng-container>
      <ng-container *roleIf="USER_ROLE.lender">
        {{ profile?.name }}, schedule a call to see how we can connect you with
        10 Realtors in 30 days, Guaranteed.
      </ng-container>
    </h2>
    <p class="ftl-regular-text m-b-3x text-center">
      Discover strategies that work and watch your opportunities soar—all within
      a month.
    </p>

    <ng-container *roleIf="USER_ROLE.agent">
      <iq-button
        size="56"
        label="Skip and access your account"
        [style]="'solid'"
        color="grey"
        iconRight="#icon-arrow-forward"
        (onClick)="submitDemo(true)"
      ></iq-button>
    </ng-container>

    <ng-container *roleIf="USER_ROLE.agent">
      <calendly-calendar
        class="m-t-5x"
        style="min-width: 320px"
        [customUrl]="agentDemoUrl"
        (onEventScheduled)="submitDemo()"
      ></calendly-calendar>
    </ng-container>
    <ng-container *roleIf="USER_ROLE.lender">
      <calendly-calendar
        class="m-t-5x m-b-4x"
        style="min-width: 320px"
        [customUrl]="lenderDemoUrl"
        (onEventScheduled)="submitDemo()"
      ></calendly-calendar>
    </ng-container>
    <ng-container *roleIf="USER_ROLE.lender">
      <a class="booked-link" type="button" (click)="skipStep()">I already booked a demo</a>
    </ng-container>
  </ng-container>
</div>
