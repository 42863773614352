import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { UserService } from '@core/services/user/user.service';

@Component({
  selector: 'ftl-header',
  templateUrl: './ftl-header.component.html',
  styleUrls: ['./ftl-header.component.scss'],
})
export class FtlHeaderComponent {

  steps: { steps: string[]; ftl_flow: string } = this.route.snapshot?.data?.ftlSteps ?? [];

  progress: Observable<string> = this.userService.ftlProgress(this.steps?.steps);

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) {}

}
