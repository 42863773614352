import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { Dictionary } from 'asap-team/asap-tools';
import type { PaymentMethod } from '@core/types';

// Services
import { BillingService } from '@core/services/billing/billing.service';

@UntilDestroy()
@Component({
  selector: 'payment-methods-list',
  templateUrl: './payment-methods-list.component.html',
  styleUrls: ['./payment-methods-list.component.scss'],
})
export class PaymentMethodsListComponent implements OnInit {

  @Input() isEditModeOn: boolean;

  @Input() isSelectable: boolean = true;

  @Input() currentCardId: string | null;

  @Output() add: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() selectMethod: EventEmitter<PaymentMethod> = new EventEmitter<PaymentMethod>();

  @Output() edit: EventEmitter<PaymentMethod> = new EventEmitter<PaymentMethod>();

  @Output() delete: EventEmitter<PaymentMethod> = new EventEmitter<PaymentMethod>();

  methods: PaymentMethod[] = [];

  methodsLoading: boolean = false;

  constructor(
    private billingService: BillingService,
  ) {}

  ngOnInit(): void {
    this
      .billingService
      .paymentMethodsChanged$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.loadMethods();
        },
      );

    this.loadMethods();
  }

  private loadMethods(): void {
    this.methodsLoading = true;

    this
      .billingService
      .getPaymentMethods()
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.methodsLoading = false;
        }),
      )
      .subscribe(
        (result: PaymentMethod[]) => {
          const currentCard: PaymentMethod = result.find((card: PaymentMethod) => card.primary);

          this.methods = result;
          this.methodsLoading = false;
          this.currentCardId = currentCard?.id || null;
          this.selectMethod.emit(currentCard);
        },
      );
  }

  isCardSelected(card: PaymentMethod): boolean {
    if (!this.isSelectable) return false;

    return card.id === this.currentCardId;
  }

  isCardExpired(card: PaymentMethod): boolean {
    return card.expired;
  }

  getCardStyle(card: PaymentMethod): Dictionary<boolean> {
    return {
      expired: this.isCardExpired(card),
      selected: this.isCardSelected(card),
    };
  }

  addCard(): void {
    this.add.emit(true);
  }

  editCard(card: PaymentMethod): void {
    this.edit.emit(card);
  }

  deleteCard(card: PaymentMethod): void {
    this.delete.emit(card);
  }

  selectCard(card: PaymentMethod): void {
    if (this.isSelectable) {
      this.selectMethod.emit(card);
    }
  }

}
