<div *ngIf="isSellerSide"
     tooltip="Seller side"
     placement="top"
     container="body"
     class="side-item seller">S</div>
<div *ngIf="isBuyerSide"
     tooltip="Buyer side"
     placement="top"
     container="body"
     class="side-item buyer">B</div>
<div *ngIf="isAllSides"
     class="side-item all"
     tooltip="Buyer side / Seller side"
     placement="top"
     container="body">B/S</div>
