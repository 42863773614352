<div class="placeholder">
  <div *roleIf="USER_ROLE.agent">
    <ng-container *ngIf="isAgentTrial; else agentConnectedOrPremiumCase">
      <p class="placeholder__title f-32-bold">Invite your lender and get access to high converting buyer funnels</p>
      <p class="placeholder__subtitle f-16-normal">
        There is no cost to you for unlocking your homeIQ account, simply invite the lender you work and gain access to all the premium features homeIQ has to offer.
      </p>
      <div class="placeholder__buttons">
        <iq-button [label]="'Invite your lender'"
                   [routerLink]="[ROUTE.alias.PARTNERSHIP]"
                   [queryParams]="{ 'invite_sidebar': true }"></iq-button>
        <iq-button [label]="'Get invitation link'" [style]="'outline'" (onClick)="showGetLinkModal()"></iq-button>
      </div>
    </ng-container>
    
    <ng-template #agentConnectedOrPremiumCase>
      <ng-container *ngIf="(isConnectedAgent || isPremiumAgent) && !isSubPaused; else subPaused">
        <p class="placeholder__title f-32-bold">Capture motivated buyer leads with automated pre-qualifications</p>
        <p class="placeholder__subtitle f-16-normal">
          homeIQ offers a high converting landing page funnel that allows online buyers to pre-qualify for over 16 different loan programs. Click the video below to see how it works.
        </p>
        <div class="placeholder__buttons">
          <iq-button [label]="'Customize landing page'" [routerLink]="ROUTE.alias.BUYERS_LP_PREVIEW"></iq-button>
          <a class="btn" [href]="landingUrl" target="_blank">
            <iq-button [label]="'View your landing page'" [style]="'outline'"></iq-button>
          </a>
        </div>
      </ng-container>
    </ng-template>
    
    <ng-template #subPaused>
      <!--SUB PAUSED CASE-->
      <p class="placeholder__title f-32-bold">Capture motivated buyer leads with automated pre-qualifications</p>
      <p class="placeholder__subtitle f-16-normal">
        This feature currently unavailable on your loan officer’s subscription. <br>
        If you want to start or continue using this feature, ask your loan officer to upgrade their subscription
      </p>
      <div class="placeholder__buttons" *ngIf="!isSubPaused">
        <ng-container [ngTemplateOutlet]="contactSupportState"></ng-container>
      </div>
    </ng-template>
  </div>
  
  <ng-container *roleIf="USER_ROLE.lender">
    <ng-container *ngIf="isSubPaused; else lenderCustomizeLanding">
      <p class="placeholder__title f-32-bold">Capture motivated buyer leads with automated pre-qualifications</p>
      <p class="placeholder__subtitle f-16-normal">
        homeIQ offers a high converting landing page funnel that allows online buyers to pre-qualify for over 16 different loan programs. Click the video below to see how it works.
      </p>
      <div class="placeholder__buttons">
        <iq-button label="Renew subscription" [routerLink]="ROUTE.alias.BILLING"></iq-button>
      </div>
    </ng-container>
    
    <ng-template #lenderCustomizeLanding>
      <p class="placeholder__title f-32-bold">Capture motivated buyer leads with automated pre-qualifications</p>
      <p class="placeholder__subtitle f-16-normal">
        homeIQ offers a high converting landing page funnel that allows online buyers to pre-qualify for over 16 different loan programs. Click the video below to see how it works.
      </p>
      <div class="placeholder__buttons">
        <ng-container *ngIf="isContactSupportState; else customizeActions">
          <ng-container [ngTemplateOutlet]="contactSupportState"></ng-container>
        </ng-container>
        
        <ng-template #customizeActions>
          <iq-button [label]="'Customize landing page'" [routerLink]="ROUTE.alias.BUYERS_LP_PREVIEW"></iq-button>
          <a class="btn" [href]="profile?.buyer_lp_link" target="_blank">
            <iq-button [label]="'View your landing page'" [style]="'outline'"></iq-button>
          </a>
        </ng-template>
      </div>
    </ng-template>
  </ng-container>
</div>

<ng-template #contactSupportState>
  <a href="mailto:support@myhomeiq.com">
    <iq-button label="Contact support"></iq-button>
  </a>
</ng-template>

<div class="placeholder__image">
  <img src="assets/components/collections/traffic/buyer-placeholder.png"/>
  <button class="placeholder__image--button" (click)="showVideoModal()">
    <img src="assets/components/collections/traffic/play-button.svg"/>
  </button>
</div>
