<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused"
     [class.control--readonly]="readonly">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <form class="control__content" [formGroup]="form">
    <input class="control__input"
           #nativeInput=""
           type="text"
           formControlName="value"
           [patterns]="negativePatterns"
           mask="M?00?P000"
           placeholder="0.000%"
           suffix="%"
           [dropSpecialCharacters]="false"
           [validation]="true"
           [attr.data-lpignore]="lpignore"
           [readonly]="readonly"
           (focus)="focus()"
           (blur)="blur()"/>
  </form>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
