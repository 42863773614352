import { Component } from '@angular/core';

import type { Dictionary } from 'asap-team/asap-tools';
import type { UserRole } from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  templateUrl: './invite-process-modal.component.html',
  styleUrls: ['./invite-process-modal.component.scss'],
})
export class InviteProcessModalComponent {

  step: number = 1;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  changeStep(step: number, type: 'prev' | 'next'): unknown {
    switch (type) {
      case 'next': {
        if (step === 3) { return this.closeModal() }

        this.step = step + 1;

        break;
      }
      case 'prev': {
        if (step === 1) { return this.closeModal() }

        this.step = step - 1;

        break;
      }
      default: {
        break;
      }
    }

    return null;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
