<div class="card" [class.expired]="expired" [class.selected]="selected" (click)="!expired && selectCard(card)">
  <div class="card-check" *ngIf="isSelectable"></div>
  <div class="card-icon">
    <img [src]="getCardIcon(card?.card_type)"/>
  </div>
  <div class="card-label">Ending in {{ card?.last4 }}</div>
  <div class="card-date">{{ getExpiration(card) }}</div>
  <div class="card-actions" *ngIf="isEditModeOn" (click)="$event.stopPropagation()">
    <div class="tab-nav__menu m-l-5">
      <div class="control-dropdown" dropdown="" container="body" placement="bottom end">
        <div class="control-btn dots inline-svg" dropdownToggle="" [inlineSVG]="'#icon-dots'"></div>
        <div class="control-dropdown__menu" *dropdownMenu>
          <div class="control-dropdown__item" (click)="editCard(card)">
            <span class="control-dropdown__label">Edit</span>
          </div>
          <div class="control-dropdown__item"
               *ngIf="isPermittedAction(PERMITTED_ACTION.DELETE)"
               (click)="deleteCard(card)">
            <span class="control-dropdown__label">Delete Card</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
