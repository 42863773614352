import type { NavItem } from '@core/types';

import { ROUTE, USER_ROLE } from '@consts/consts';

export const Banners: NavItem[] = [
  {
    label: 'Homeowners',
    routerLink: ROUTE.alias.BANNERS_HOMEOWNERS,
    counter: null,
    roles: [USER_ROLE.sales, USER_ROLE.owner],
    permitted_action: null,
    icon: null,
  },
  {
    label: 'Buyers',
    routerLink: ROUTE.alias.BANNERS_BUYERS,
    counter: null,
    roles: [USER_ROLE.sales, USER_ROLE.owner],
    permitted_action: null,
    icon: null,
  },
];
