import { filter, map } from 'lodash-es';
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { chain, CollectionFilter } from 'asap-team/asap-tools';

@Component({
  selector: 'iq-radio-filter',
  templateUrl: './iq-radio-filter.component.html',
  styleUrls: ['./iq-radio-filter.component.scss'],
})
export class IqRadioFilterComponent implements OnChanges {

  @ViewChild('items') items: ElementRef;

  @Input() model: Omit<CollectionFilter, 'id' | 'selected'>[];

  @Input() stateValue: any;

  @Input() title: string;

  @Input() filterName: string;

  @Input() toggleButtonStyle: 'outline' | 'solid' | 'transparent' = 'solid';

  @Input() toggleButtonSize: '24' | '40' | '56' = '40';

  @Input() hideAllOption: boolean = false;

  @Input() appendSelector: string = 'body';

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  innerModel: CollectionFilter[] = [];

  get selected(): string {
    return chain(this.innerModel, { filter, map })
      .filter((item: CollectionFilter) => item.selected)
      .map((item: CollectionFilter) => item.title)
      .value()
      .join('');
  }

  get isShowLabel(): boolean {
    const selected: CollectionFilter[] = filter(this.innerModel, (item: CollectionFilter) => item.selected);
    const isAllSelected: boolean = selected.length === this.innerModel.length;

    return !this.selected || isAllSelected;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { stateValue, model } = changes;

    if (stateValue || model) {
      this.innerModel = this.model.map(
        (item: CollectionFilter) => (item.name === this.stateValue ? { ...item, selected: true } : { ...item, selected: false }),
      );
    }
  }

  select(selectedItem: CollectionFilter): void {
    this.emitFilterValue.emit({ filterName: this.filterName, value: selectedItem.name });
  }

  clear(): void {
    this.emitFilterValue.emit({ filterName: this.filterName, value: null });
  }

  // Used on mobile resolution
  close(event: Event, checkboxDropdown: any): void {
    const targetElement: HTMLElement = event.target as HTMLElement;

    if (targetElement && !this.items.nativeElement.contains(targetElement)) {
      checkboxDropdown.hide();
    }
  }

}
