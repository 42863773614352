/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';

export const environment: any = {
  name: 'development',
  firebaseCollection: 'dev',
  production: false,
  mapsApiKey: 'AIzaSyCyPyVFYCWyES6Nu5FlNOJbL_4nJb0KWiE',
  stripe_key: 'pk_test_UdOxLU4PJiXJywo2lCJ737aS',
  appUrl: 'https://app.dev.myhomeiq.report',
  herUrl: 'https://dev.homequityreport.com',
  hgUrl: 'https://dev.myhomeiq.report',
  buyerIQ: 'https://dev.buyerprequalify.com',
  partnerAppUrl: 'https://partner.dev.myhomeiq.report',
  api: 'https://api.dev.myhomeiq.report/api',
  rozumAPI: 'https://api.rozum.dev.myhomeiq.report/api',
  lendingPad: 'https://test.lendingpad.com',
  loginLink: 'https://app.dev.myhomeiq.report/sign-in',
  HomeIQLanding: 'https://dev.myhomeiq.com/',
  HomeIQLandingAgent: 'https://dev.myhomeiq.com/agent/',
  HomeIQLandingLender: 'https://dev.myhomeiq.com/lender/',
  HomeIQTrafficLandingAgent: 'https://dev.myhomeiq.com/agent/traffic/',
  HomeIQTrafficLandingLender: 'https://dev.myhomeiq.com/lender/traffic/',
  privacyPolicy: 'http://dev.myhomeiq.com/privacy-policy/',
  termsConditions: 'http://dev.myhomeiq.com/terms-of-use/',
  firebase: {
    apiKey: 'AIzaSyCnM_Di4IDDV31H4BduI1hinMJBLSrHoco',
    authDomain: 'analog-arbor-213518.firebaseapp.com',
    databaseURL: 'https://analog-arbor-213518.firebaseio.com',
    projectId: 'analog-arbor-213518',
    storageBucket: 'analog-arbor-213518.appspot.com',
    messagingSenderId: '43232078976',
  },
  videos: {
    PROMO_VIDEO_CHARGE_IMPORT: '325379924',
    HIQ_DIGEST_VIDEO_ID: '325379325',
    HIQ_TRAFFIC_VIDEO_ID: '325379532',
    TUTORIAL_VIDEO_ID: '325379717',
  },
  sentryDSN: 'https://079e2f315b054e0b8f5e98eb747b73b3@sentry.io/1832852',
  sentryWhitelist: [
    'http://app.dev.myhomeiq.report',
    'https://app.dev.myhomeiq.report',
  ],
  facebookApp: '413767595803812',
  zapierInviteLink: 'https://zapier.com/developer/public-invite/5494/39bd5fa38c87acfe4bfde7be5f68714a',
  calendlyLinks: {
    agent: 'https://calendly.com/em-homeiq/new-agent-group?hide_event_type_details=1',
    lender: 'https://calendly.com/shanamyhomeiq/15min?hide_event_type_details=1',
    lenderTraining: 'https://calendly.com/myhomeiq-customer-success/lender-training',
    activatePremium: 'https://calendly.com/myhomeiq-chris-prescott/activate-premium',
    activeBuyer: 'https://calendly.com/myhomeiq-customer-success/active-buyer-upgrade',
    lenderOnboarding: 'https://calendly.com/jas-33/30',
    default: 'https://calendly.com/soa-onboarding',
  },
  analytics: {
    bypassTracking: false,
    env: 'dev',
    gtm: 'GTM-5LCWMFR',
  },
};
