import { Component, Input, OnInit } from '@angular/core';

import type { Alert } from '@core/types';
// Consts
import { ROUTE } from '@consts/consts';
import { ALERTS_TYPE } from '@consts/alerts';

// Services
import { AlertsService } from '@core/services/alerts/alerts.service';

@Component({
  templateUrl: './subscription-status-warning.component.html',
  styleUrls: ['../../alerts.component.scss'],
})
export class SubscriptionStatusWarningComponent implements OnInit {

  @Input() type: Alert;

  ROUTE: any = ROUTE;

  message: string;

  constructor(private alertsService: AlertsService) {}

  ngOnInit(): void {
    if (this.type === ALERTS_TYPE.PAUSED_SUBSCRIPTION) {
      this.message = 'Your subscription is paused.';
    } else if (this.type === ALERTS_TYPE.MONITORING_PROGRAM) {
      this.message = 'You’ve been enrolled homeowners monitoring program.';
    }
  }

  closeAlert(type: Alert): void {
    this.alertsService.dismiss(type);
  }

}
