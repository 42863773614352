/**
 * Returns default text or truncated text
 *
 * @return {string}
 */
export function truncateText(text: string, maxLength: number): string {
  if (text?.length > maxLength) {
    return `${text.slice(0, maxLength).trim()}...`;
  }

  return text;
}
