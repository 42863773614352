import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { required } from './conditions';

export const currencyFilterErrors: FormErrors = {
  min: [
    required,
    {
      name: 'max',
      text: 'This value must be less than 500,000,000',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'moreThanMax',
      text: 'This value must be less than Max value',
      rules: ['touched', 'dirty'],
    },
  ],
  max: [
    required,
    {
      name: 'max',
      text: 'This value must be less than 500,000,000',
      rules: ['touched', 'dirty'],
    },
  ],
  global: [],
};
