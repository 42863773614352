import { Route } from '@angular/router';

// Consts
import { PERMITTED_ACTION, ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { PermissionGuard } from 'asap-team/asap-tools';
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';
import { RegistrationCompletedGuard } from '@core/guards/registration-completed/registration-completed.guard';

// Resolvers
import { BillingPlansResolve } from '@core/resolvers/billing-plans/billing-plans.resolve';

export const UPGRADE_SUBSCRIPTION: Route = {
  path: ROUTE.name.UPGRADE_SUBSCRIPTION,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, PermissionGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, PermissionGuard],
  resolve: { plans: BillingPlansResolve },
  loadChildren: () => import('@modules/user/upgrade-subscription/upgrade-subscription.module').then((m: any) => m.UpgradeSubscriptionModule),
  data: {
    canBeActivatedBy: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [
      PERMITTED_ACTION.BILLING_UPGRADE,
    ],
    redirectTo: ROUTE.alias.HOMEOWNERS_REPORTS,
  },
};
