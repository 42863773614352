<div class="auth__decor">
  <div class="auth__logo"></div>
</div>
<div class="auth__content d-flex flex-column justify-content-center">
  <div class="align-self-center">
    <h1 class="auth__title">Oops, link has expired</h1>
    <h5 class="auth__subtitle">Please contact to admin</h5>
    <a class="link btn btn--block btn--blue" href="mailto:sales@homeiq.report">Contact admin</a>
  </div>
</div>
