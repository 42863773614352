<div class="promo-banner">
  <img
    class="promo-banner__image"
    [src]="banner?.medium_url"
    width="460"
    height="240"
    [alt]="banner?.id"
    onerror="this.remove()"
  />
  <iq-button
    class="btn"
    label="Share banner"
    size="40"
    (onClick)="openPreview(banner)"
  ></iq-button>
</div>
