import { filter, map } from 'lodash-es';
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { chain, CollectionFilter } from 'asap-team/asap-tools';

@Component({
  selector: 'iq-checkbox-filter',
  templateUrl: './iq-checkbox-filter.component.html',
  styleUrls: ['./iq-checkbox-filter.component.scss'],
})
export class IqCheckboxFilterComponent implements OnChanges {

  @ViewChild('form') form: ElementRef;

  @Input() model: CollectionFilter[];

  @Input() title: string;

  @Input() filterName: string;

  @Input() stateValue: any;

  @Input() returnRawCollection: boolean = false;

  @Input() appendSelector: string = 'body';

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  innerModel: CollectionFilter[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    const { stateValue, model } = changes;

    if (stateValue || model) {
      const queryValues: string[] = this.stateValue?.toString()?.split(',') || [];

      this.innerModel = this.model?.map((item: CollectionFilter) => {
        return { ...item, selected: queryValues.includes(item.name) };
      });
    }
  }

  get selected(): string {
    return chain(this.innerModel, { filter, map })
      .filter((item: CollectionFilter) => item.selected)
      .map((item: CollectionFilter) => item.title)
      .value()
      .join(', ');
  }

  get isShowLabel(): boolean {
    const selected: CollectionFilter[] = filter(this.innerModel, (item: CollectionFilter) => item.selected);
    const isAllSelected: boolean = selected.length === this.innerModel.length;

    return !this.selected || isAllSelected;
  }

  select(event: Event, selectedItem: CollectionFilter): void {
    let selected: any = chain(this.innerModel, { filter, map })
      .filter((item: CollectionFilter) => item.selected)
      .map((item: CollectionFilter) => item.name)
      .value();

    if (selected.includes(selectedItem.name)) {
      selected.splice(selected.findIndex((item: string) => item === selectedItem.name), 1);
    } else {
      selected.push(selectedItem.name);
    }

    if (!this.returnRawCollection) {
      selected = selected.join(',');
    }

    this.emitFilterValue.emit({
      filterName: this.filterName,
      value: selected,
    });

    event.stopPropagation();
  }

  clear(): void {
    if (this.form) {
      this.form.nativeElement.reset();
    }

    this.emitFilterValue.emit({
      filterName: this.filterName,
      value: null,
    });
  }

  // Used on mobile resolution
  close(event: Event, checkboxDropdown: any): void {
    const targetElement: HTMLElement = event.target as HTMLElement;

    if (targetElement && !this.form.nativeElement.contains(targetElement)) {
      checkboxDropdown.hide();
    }
  }

}
