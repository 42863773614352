<form [formGroup]="form">
  <div class="control wide-prepend">
    <div class="control__prepend"
         [class.control__prepend--focus]="fullAddressFocus"
         [class.control__prepend--invalid]="form.invalid && addressControl.touched"
         [class.control__prepend--valid]="form.valid && addressControl.touched">
      <input class="control__input"
             #address
             type="text"
             data-lpignore="true"
             [attr.placeholder]="placeholder"
             formControlName="full_address"
             (focus)="fullAddressFocus = true"
             (blur)="blur()"/>
      <div class="location inline-svg inline-svg--16" [inlineSVG]="'#icon-location'"></div>
    </div>
    <div class="control__content"
         [class.control__content--focus]="fullAddressFocus"
         [class.control__content--invalid]="form.controls.unit.invalid || (addressControl.invalid && form.touched)"
         [class.control__content--valid]="form.valid && form.touched">
      <div class="control__content__control-wrapper flex-center">
        <div class="flex-1 h-100">
          <input class="control__input"
                 type="text"
                 placeholder="Apt."
                 data-lpignore="true"
                 formControlName="unit"
                 (focus)="fullAddressFocus = true"
                 (blur)="blur()"/>
        </div>
      </div>
    </div>
  </div>
</form>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
