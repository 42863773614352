import { CollectionApi, CollectionStoreService, SeriesApi, SeriesStoreService } from 'asap-team/asap-tools';
import type {
  Kudos,
  UserBanner,
  AdminBanner,
  CollectionGlobalActivity,
  MortgageCheckupsCollectionItem,
  ArchiveCollectionLead,
  UploadsCollectionItem,
  ViralCollectionAccount,
  CollectionStaffUser,
  HistoryPendingCollectionLead,
  HistoryProcessedCollectionLead,
  OutgoingInvite,
  IncomingInvite,
  ActivePartner,
  AccountDetailsPartnerItem,
} from '@core/types';

// Services
import { HomeownerBannersService } from '@core/services/collections/promo-banners/user-banners/homeowner-banners.service';
import { BuyerBannersService } from '@core/services/collections/promo-banners/user-banners/buyer-banners.service';
import { AdminBuyerBannersService } from '@core/services/collections/promo-banners/admin-banners/buyer-banners.service';
import { AdminHomeownerBannersService } from '@core/services/collections/promo-banners/admin-banners/homeowner-banners.service';
import { LeadsActivityService } from '@core/services/collections/leads-activity/leads-activity.service';
import { SellerDigestService } from '@core/services/collections/seller-digest/seller-digest.service';
import { ArchiveLeadService } from '@core/services/collections/archived-leads/archived-leads.service';
import { CsvUploadsService } from '@core/services/collections/csv-uploads/csv-uploads.service';
import { ViralAccountsService } from '@core/services/collections/accounts/viral/viral-accounts.service';
import { ArchivedAccountService } from '@core/services/collections/accounts/archived/archived-user.service';
import { StaffService } from '@core/services/collections/staff/staff.service';
import { HistoryPendingService } from '@core/services/collections/history-pending/history-pending.service';
import { HistoryProcessedService } from '@core/services/collections/history-processed/history-processed.service';
import { ActivePartnersService } from '@core/services/collections/partnership/active-partners.service';
import { IncomingInvitesService } from '@core/services/collections/partnership/incoming-invites.service';
import { OutgoingInvitesService } from '@core/services/collections/partnership/outgoing-invites.service';
import { AccountActivePartnersService } from '@core/services/collections/partnership/account-active-partners.service';
import { HomeownersWealthBannersService } from '@core/services/collections/promo-banners/user-banners/homeowners-wealth-banners.service';
import { KudosService } from '@core/services/collections/kudos/kudos.service';

import {
  HOMEOWNERS_BANNERS,
  BUYERS_BANNERS,
  ADMIN_BUYERS_BANNERS,
  ADMIN_HOMEOWNERS_BANNERS,
  LEADS_ACTIVITY,
  SELLER_DIGEST_COLLECTION,
  ARCHIVE_LEAD_COLLECTION,
  CSV_UPLOADS_COLLECTION,
  VIRAL_ACCOUNTS_COLLECTION,
  ARCHIVED_ACCOUNTS_COLLECTION,
  STAFF_COLLECTION,
  HISTORY_PENDING_COLLECTION,
  HISTORY_PROCESSED_COLLECTION,
  ACTIVE_PARTNERS_COLLECTION,
  INCOMING_INVITES_COLLECTION,
  OUTGOING_INVITES_COLLECTION,
  ACCOUNT_ACTIVE_PARTNERS_COLLECTION,
  HOMEOWNERS_WEALTH_BANNERS,
  KUDOS_COLLECTION,
} from './injection-tokens';

function buyersBannersCollectionFactory(service: CollectionApi<UserBanner>): any {
  return new CollectionStoreService<UserBanner, CollectionApi<UserBanner>>(service);
}

export const BuyersBannersCollectionConfig: any = {
  provide: BUYERS_BANNERS,
  useFactory: buyersBannersCollectionFactory,
  deps: [
    BuyerBannersService,
  ],
};

function homeownersBannersCollectionFactory(service: CollectionApi<UserBanner>): any {
  return new CollectionStoreService<UserBanner, CollectionApi<UserBanner>>(service);
}

function homeownersWealthBannersCollectionFactory(service: CollectionApi<UserBanner>): any {
  return new CollectionStoreService<UserBanner, CollectionApi<UserBanner>>(service);
}

function kudosCollectionFactory(service: CollectionApi<Kudos>): any {
  return new CollectionStoreService<Kudos, CollectionApi<Kudos>>(service);
}

export const HomeownersBannersCollectionConfig: any = {
  provide: HOMEOWNERS_BANNERS,
  useFactory: homeownersBannersCollectionFactory,
  deps: [
    HomeownerBannersService,
  ],
};

export const HomeownersWealthBannersCollectionConfig: any = {
  provide: HOMEOWNERS_WEALTH_BANNERS,
  useFactory: homeownersWealthBannersCollectionFactory,
  deps: [
    HomeownersWealthBannersService,
  ],
};

export const KudosCollectionConfig: any = {
  provide: KUDOS_COLLECTION,
  useFactory: kudosCollectionFactory,
  deps: [
    KudosService,
  ],
};

function adminBuyersBannersCollectionFactory(service: CollectionApi<AdminBanner>): any {
  return new CollectionStoreService<AdminBanner, CollectionApi<AdminBanner>>(service);
}

export const AdminBuyersBannersCollectionConfig: any = {
  provide: ADMIN_BUYERS_BANNERS,
  useFactory: adminBuyersBannersCollectionFactory,
  deps: [
    AdminBuyerBannersService,
  ],
};

function adminHomeownersBannersCollectionFactory(service: CollectionApi<AdminBanner>): any {
  return new CollectionStoreService<AdminBanner, CollectionApi<AdminBanner>>(service);
}

export const AdminHomeownersBannersCollectionConfig: any = {
  provide: ADMIN_HOMEOWNERS_BANNERS,
  useFactory: adminHomeownersBannersCollectionFactory,
  deps: [
    AdminHomeownerBannersService,
  ],
};

function leadsActivityCollectionFactory(service: SeriesApi<CollectionGlobalActivity[]>): any {
  return new SeriesStoreService<CollectionGlobalActivity[], SeriesApi<CollectionGlobalActivity[]>>(service);
}

export const leadsActivityCollectionConfig: any = {
  provide: LEADS_ACTIVITY,
  useFactory: leadsActivityCollectionFactory,
  deps: [
    LeadsActivityService,
  ],
};

function sellerDigestCollectionFactory(service: CollectionApi<MortgageCheckupsCollectionItem>): any {
  return new CollectionStoreService<MortgageCheckupsCollectionItem, CollectionApi<MortgageCheckupsCollectionItem>>(service);
}

export const sellerDigestCollectionConfig: any = {
  provide: SELLER_DIGEST_COLLECTION,
  useFactory: sellerDigestCollectionFactory,
  deps: [
    SellerDigestService,
  ],
};

function historyPendingCollectionFactory(service: CollectionApi<HistoryPendingCollectionLead>): any {
  return new CollectionStoreService<HistoryPendingCollectionLead, CollectionApi<HistoryPendingCollectionLead>>(service);
}

export const historyPendingCollectionConfig: any = {
  provide: HISTORY_PENDING_COLLECTION,
  useFactory: historyPendingCollectionFactory,
  deps: [
    HistoryPendingService,
  ],
};

function historyProcessedCollectionFactory(service: CollectionApi<HistoryProcessedCollectionLead>): any {
  return new CollectionStoreService<HistoryProcessedCollectionLead, CollectionApi<HistoryProcessedCollectionLead>>(service);
}

export const historyProcessedCollectionConfig: any = {
  provide: HISTORY_PROCESSED_COLLECTION,
  useFactory: historyProcessedCollectionFactory,
  deps: [
    HistoryProcessedService,
  ],
};

function archivedLeadsCollectionFactory(service: CollectionApi<ArchiveCollectionLead>): any {
  return new CollectionStoreService<ArchiveCollectionLead, CollectionApi<ArchiveCollectionLead>>(service);
}

export const archivedLeadsCollectionConfig: any = {
  provide: ARCHIVE_LEAD_COLLECTION,
  useFactory: archivedLeadsCollectionFactory,
  deps: [
    ArchiveLeadService,
  ],
};

function csvUploadCollectionFactory(service: CollectionApi<UploadsCollectionItem>): any {
  return new CollectionStoreService<UploadsCollectionItem, CollectionApi<UploadsCollectionItem>>(service);
}

export const csvUploadCollectionConfig: any = {
  provide: CSV_UPLOADS_COLLECTION,
  useFactory: csvUploadCollectionFactory,
  deps: [
    CsvUploadsService,
  ],
};

function staffCollectionFactory(service: CollectionApi<CollectionStaffUser>): any {
  return new CollectionStoreService<CollectionStaffUser, CollectionApi<CollectionStaffUser>>(service);
}

export const staffCollectionConfig: any = {
  provide: STAFF_COLLECTION,
  useFactory: staffCollectionFactory,
  deps: [
    StaffService,
  ],
};

function viralAccountsCollectionFactory(service: CollectionApi<ViralCollectionAccount>): any {
  return new CollectionStoreService<ViralCollectionAccount, CollectionApi<ViralCollectionAccount>>(service);
}

export const viralAccountsCollectionConfig: any = {
  provide: VIRAL_ACCOUNTS_COLLECTION,
  useFactory: viralAccountsCollectionFactory,
  deps: [
    ViralAccountsService,
  ],
};

function archivedAccountsCollectionFactory(service: CollectionApi<ArchiveCollectionLead>): any {
  return new CollectionStoreService<ArchiveCollectionLead, CollectionApi<ArchiveCollectionLead>>(service);
}

export const archivedAccountsCollectionConfig: any = {
  provide: ARCHIVED_ACCOUNTS_COLLECTION,
  useFactory: archivedAccountsCollectionFactory,
  deps: [
    ArchivedAccountService,
  ],
};

function activePartnersCollectionFactory(service: CollectionApi<ActivePartner>): any {
  return new CollectionStoreService<ActivePartner, CollectionApi<ActivePartner>>(service);
}

export const activePartnersCollectionConfig: any = {
  provide: ACTIVE_PARTNERS_COLLECTION,
  useFactory: activePartnersCollectionFactory,
  deps: [
    ActivePartnersService,
  ],
};

function accountActivePartnersCollectionFactory(service: CollectionApi<AccountDetailsPartnerItem>): any {
  return new CollectionStoreService<AccountDetailsPartnerItem, CollectionApi<AccountDetailsPartnerItem>>(service);
}

export const accountActivePartnersCollectionConfig: any = {
  provide: ACCOUNT_ACTIVE_PARTNERS_COLLECTION,
  useFactory: accountActivePartnersCollectionFactory,
  deps: [
    AccountActivePartnersService,
  ],
};

function incomingInvitesCollectionFactory(service: CollectionApi<IncomingInvite>): any {
  return new CollectionStoreService<IncomingInvite, CollectionApi<IncomingInvite>>(service);
}

export const incomingInvitesCollectionConfig: any = {
  provide: INCOMING_INVITES_COLLECTION,
  useFactory: incomingInvitesCollectionFactory,
  deps: [
    IncomingInvitesService,
  ],
};

function outgoingInvitesCollectionFactory(service: CollectionApi<OutgoingInvite>): any {
  return new CollectionStoreService<OutgoingInvite, CollectionApi<OutgoingInvite>>(service);
}

export const outgoingInvitesCollectionConfig: any = {
  provide: OUTGOING_INVITES_COLLECTION,
  useFactory: outgoingInvitesCollectionFactory,
  deps: [
    OutgoingInvitesService,
  ],
};
