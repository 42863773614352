import { SimpleModalComponent } from 'ngx-simple-modal';
import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss'],
})
export class YoutubeVideoComponent extends SimpleModalComponent<{ id: string }, null> implements OnInit {

  id: string;

  videoUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit(): void {
    this.videoUrl = this.sanitizeVideoUrl(this.id);
  }

  sanitizeVideoUrl(id: string): SafeResourceUrl {
    const url: string = id;

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
