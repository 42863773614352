<bs-alert type="primary" (onClose)="closeAlert(type)">
  <div class="global-alert__container">
    <div class="global-alert__message">
      <img class="global-alert__message-icon" src="assets/common/alerts/alert-upgrade-icon.svg"/>
      <div class="message-body">
        <span class="text-overflow">You have&#32;<b>{{ message }}&#32;</b>with your trial account</span>
        <div class="btn-detail" [routerLink]="ROUTE.alias.UPGRADE_SUBSCRIPTION">
          <iq-button label="Learn more" size="24" color="grey"></iq-button>
        </div>
      </div>
    </div>
  </div>
</bs-alert>
