<div class="leads">
  <div class="import-errors" *ngIf="isShowFailedCount">
    <div class="leads-failed text m-b-5">
      <span>Failed lines & duplicates</span><b>{{ info.failed_items + info.duplicated_items }}</b></div>
    <button class="btn link m-l-0 text-left" (click)="toggle()">{{ isInfoOpen ? 'Less' : 'More'}} info</button>
    <div class="leads-failed-info m-t-5" *ngIf="isInfoOpen">
      <div *ngIf="renderFailedItems(info.failed_lines)">
        <h4>Failed lines</h4>
        <p [innerHTML]="renderFailedItems(info.failed_lines)"></p>
      </div>
      <div *ngIf="renderFailedItems(info.duplicated_lines)">
        <h4>Duplicates</h4>
        <p [innerHTML]="renderFailedItems(info.duplicated_lines)"></p>
      </div>
    </div>
  </div>
</div>
