<div class="auth__decor"><a class="auth__logo" [routerLink]="[ROUTE.alias.SIGN_IN]"></a></div>
<div class="auth__content d-flex flex-column justify-content-center">
  <form class="auth__form align-self-center"
        novalidate=""
        name="form"
        [formGroup]="confirmPasswordForm"
        (ngSubmit)="confirmPasswordForm.valid && confirm(confirmPasswordForm)">
    <h1 class="auth__title">Please enter your new&nbsp;password.</h1>
    <iq-form-control-password class="m-b-2x"
                              label="Password"
                              placeholder="6+ characters"
                              lpignore="false"
                              formControlName="password"
                              [errorsModel]="formErrors.password"></iq-form-control-password>
    <iq-form-control-password class="m-b-2x"
                              label="Confirm"
                              placeholder="Confirm password"
                              lpignore="false"
                              formControlName="password_confirmation"
                              [errorsModel]="formErrors.password_confirmation"></iq-form-control-password>
    <div class="control-error-message" *ngFor="let error of formErrors.global">{{ error }}</div>
    <div class="m-t-2x">
      <iq-button class="block"
                 type="submit"
                 label="Set new password"
                 size="56"
                 [busy$]="action"
                 [disabled]="confirmPasswordForm.invalid"></iq-button>
    </div>
    <div class="row">
      <div class="col text-center align-self-center"><a class="link" [routerLink]="[ROUTE.alias.SIGN_IN]">Back&nbsp;to
        Sign&nbsp;in</a></div>
    </div>
  </form>
</div>
