<bs-alert type="danger" [dismissible]="false" (onClose)="closeAlert(type)">
  <div class="global-alert__container">
    <div class="global-alert__message">
      <img class="global-alert__message-icon" src="assets/common/alerts/alert-warning-icon.svg"/>
      <div class="message-body">
        Payment problem&#32;
        <a class="global-alert__danger-button" [routerLink]="ROUTE.alias.BILLING">View Details</a>
      </div>
    </div>
  </div>
</bs-alert>
