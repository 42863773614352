import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, interval } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from '@ng-web-apis/common';

// Services
import { CalendlyService } from '@core/services/calendly/calendly.service';

export type CalendlyModeType = 'inline' | 'dialog';

@UntilDestroy()
@Component({
  selector: 'calendly-calendar',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.scss'],
})
export class CalendlyComponent implements AfterViewInit {

  @Input() hideCookie: boolean = true;

  @Input() customUrl!: string;

  @Input() type: CalendlyModeType = 'inline';

  @Input() immediatelyOpen: boolean = true;

  @Input() toggleBtnText: string = 'Book a Demo';

  @Output() eventScheduled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('calendar') calendar: ElementRef<HTMLElement>;

  constructor(
    @Inject(WINDOW) private window: any,
    private calendlyService: CalendlyService,
  ) {}

  ngAfterViewInit(): void {
    if (this.type === 'inline') {
      this.initCalendly();
    } else if (this.immediatelyOpen) {
      this.openDialog();
    }
  }

  openDialog(): void {
    this.calendlyService.openInModal(this.customUrl || this.getUrlWithParams(this.calendlyService.getTrainingUrl()));
  }

  private initCalendly(): void {
    this.calendlyService.appendToElement(
      this.customUrl || this.getUrlWithParams(this.calendlyService.getTrainingUrl()),
      this.calendar.nativeElement,
    );

    fromEvent(this.window, 'message')
      .pipe(
        untilDestroyed(this),
        switchMap((event: any) => {
          if (this.isCalendlyEvent(event) && event?.data?.event === 'calendly.event_scheduled') {
            return interval(3000).pipe(
              take(1),
              untilDestroyed(this),
            );
          }

          return [];
        }),
      )
      .subscribe(() => {
        this.eventScheduled.emit();
      });
  }

  private isCalendlyEvent(e: any): boolean {
    return e?.data?.event && e?.data?.event.indexOf('calendly') === 0;
  }

  private getUrlWithParams(url: string): string {
    const formattedUrl: URL = new URL(url);

    if (this.hideCookie) {
      formattedUrl.searchParams.append('hide_gdpr_banner', '1');
    }

    return formattedUrl.href;
  }

}
