import momentMini from 'moment-mini';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

export const DEFAULT_MOMENT_DATE_FORMAT: string = 'MMMM D, YYYY';
export const API_DATE_FORMAT: string = 'YYYY-MM-DD';
export const DEFAULT_DATEPICKER_CONFIG: Partial<BsDatepickerConfig> = {
  minDate: new Date(),
  isAnimated: true,
  adaptivePosition: true,
  dateInputFormat: DEFAULT_MOMENT_DATE_FORMAT,
};
export const LOAN_FORM_DATEPICKER_CONFIG: Partial<BsDatepickerConfig> = {
  ...DEFAULT_DATEPICKER_CONFIG,
  minMode: 'month',
  dateInputFormat: 'MMMM YYYY',
  minDate: momentMini({ year: new Date().getFullYear() - 99 }).toDate(),
  maxDate: momentMini({ year: new Date().getFullYear(), month: new Date().getMonth() }).toDate(),
};
export const CREATE_LEAD_FORM_DATEPICKER_CONFIG: Partial<BsDatepickerConfig> = {
  ...DEFAULT_DATEPICKER_CONFIG,
  minMode: 'month',
  dateInputFormat: 'MMMM YYYY',
  minDate: momentMini({ year: new Date().getFullYear() - 99 }).toDate(),
  maxDate: momentMini({ year: new Date().getFullYear(), month: new Date().getMonth() }).toDate(),
};
export const EDIT_LEAD_FORM_DATEPICKER_CONFIG: Partial<BsDatepickerConfig> = {
  ...DEFAULT_DATEPICKER_CONFIG,
  minMode: 'month',
  dateInputFormat: 'MMMM YYYY',
  minDate: momentMini({ year: new Date().getFullYear() - 99 }).toDate(),
  maxDate: momentMini({ year: new Date().getFullYear(), month: new Date().getMonth() }).toDate(),
};
export const CREATE_BANNER_FORM_DATEPICKER_CONFIG: Partial<BsDatepickerConfig> = {
  ...DEFAULT_DATEPICKER_CONFIG,
  dateInputFormat: DEFAULT_MOMENT_DATE_FORMAT,
  minDate: null,
};
export const LOAN_EXPIRED_DATE_DATEPICKER_CONFIG: Partial<BsDatepickerConfig> = {
  ...DEFAULT_DATEPICKER_CONFIG,
  minDate: new Date(Date.now() + (3600 * 1000 * 24)),
};
export const NEXT_PAYMENT_DATEPICKER_CONFIG: Partial<BsDatepickerConfig> = {
  ...DEFAULT_DATEPICKER_CONFIG,
  minDate: new Date(),
  dateInputFormat: DEFAULT_MOMENT_DATE_FORMAT,
  showWeekNumbers: false,
  adaptivePosition: true,
  isAnimated: true,
  minMode: 'day',
};
