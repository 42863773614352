import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ModalService } from 'asap-team/asap-tools';

import { UserRole } from '@core/types';

// Consts
import { SIDEBAR_CONFIG } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

// Components
import { RewardsSidebarComponent } from '@commons/sidebars/rewards-sidebar/rewards-sidebar.component';

@UntilDestroy()
@Component({
  selector: 'rewards-nav',
  templateUrl: './rewards-nav.component.html',
  styleUrls: ['./rewards-nav.component.scss'],
})
export class RewardsNavComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {

  }

  openRewards(): void {
    const userRole: UserRole = this.userService.getUserRole();

    this
      .modalService
      .open(RewardsSidebarComponent, { userRole }, SIDEBAR_CONFIG)
      .pipe(
        filter(Boolean),
        untilDestroyed(this),
      )
      .subscribe();
  }

}
