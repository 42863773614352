import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  CollectionHelpersService,
  Dictionary,
  propertyImagePlaceholder,
  BaseHttpService,
  CollectionResponse,
  CollectionApi,
} from 'asap-team/asap-tools';

import type { HistoryPendingCollectionLead } from '@core/types';

// Consts
import { LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class HistoryPendingService implements CollectionApi<HistoryPendingCollectionLead> {

  constructor(
    private http: BaseHttpService,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(params: Dictionary<string>): Observable<CollectionResponse<HistoryPendingCollectionLead>> {
    return this
      .http
      .get('v2/history/leads', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.mapResponseFilters(),
        propertyImagePlaceholder<HistoryPendingCollectionLead>(LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER),
        switchMap((response: CollectionResponse<HistoryPendingCollectionLead>) => {
          const newLeadIDs: string = response
            .data
            .filter((item: HistoryPendingCollectionLead) => item.new)
            .map((item: HistoryPendingCollectionLead) => item.id)
            .join();

          if (!newLeadIDs) {
            return of(response);
          }

          return this.review(newLeadIDs).pipe(
            map(() => response),
          );
        }),
        this.collectionHelpersService.retrowError(),
      );
  }

  import(uid: string, payload: { owner_name: string; email: string; phone: string }): Observable<any> {
    return this
      .http
      .patch(`v2/history/leads/${uid}/import`, payload);
  }

  dismiss(uid: string): Observable<any> {
    return this
      .http
      .delete(`v2/history/leads/${uid}/dismiss`);
  }

  review(lead_ids: string): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('lead_ids', lead_ids);

    return this.http.patch('v2/history/leads/review', formData);
  }

}
