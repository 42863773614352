import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@Component({
  templateUrl: './complete-account.component.html',
  styleUrls: ['./complete-account.component.scss'],
})
export class CompleteAccountComponent implements OnInit {

  ROUTE: any = ROUTE;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this
      .userService
      .getRawProfile()
      .subscribe(() => {
        this.router.navigate([ROUTE.alias.SIGN_UP]);
      });
  }

}
