<global-alert-item *ngFor="let alert of alerts$ | async" [type]="alert"></global-alert-item>
<header class="toolbar hiq-container">
  <div (click)="openMenu()" class="burger-menu-button pointer">
      <div></div>
      <div></div>
      <div></div>
  </div>
  <div class="toolbar__logo" (click)="logoRedirect()">
    <img alt="logo" src="assets/components/toolbar/logo.svg"/>
  </div>
  <desktop-nav class="toolbar__nav" [navigation]="menuService.navigation"></desktop-nav>
  <div class="toolbar__profile-wrapper">
    <rewards-nav *permittedIf="PERMITTED_ACTION.KUDOS_VIEW" class="toolbar__rewards-nav"></rewards-nav>
    <profile-actions class="toolbar__profile-actions" [navigationItems]="profileMenuItems"></profile-actions>
  </div>
</header>
<ng-container #menuViewContainer></ng-container>
