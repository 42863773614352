import {
  Component,
  Input,
  HostBinding,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

// Services
import { AvatarService } from '@core/services/avatar/avatar.service';

@Component({
  selector: 'activity-avatar',
  templateUrl: './activity-avatar.component.html',
  styleUrls: ['./activity-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityAvatarComponent implements OnInit {

  @HostBinding('class.alert') get isAlert(): boolean {
    return this.type === 'alert';
  }

  @HostBinding('class.warning') get isWarning(): boolean {
    return this.type === 'warning';
  }

  @Input() uid: string;

  @Input() client_name: string;

  @Input() user_name: string;

  @Input() type: string;

  avatarUrl: string;

  constructor(
    private avatarService: AvatarService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.uid) {
      this
        .avatarService
        .getAvatarUrl(this.uid)
        .subscribe(
          (url: string) => {
            if (!url) { return }

            this.avatarUrl = url;
            this.cd.detectChanges();
          },
        );
    }
  }

  get isShowAlert(): boolean {
    return this.type === 'alert';
  }

  get isShowWarning(): boolean {
    return this.type === 'warning';
  }

  get isShowClock(): boolean {
    return this.type === 'clock';
  }

  get isShowDownload(): boolean {
    return this.type === 'download';
  }

  get isShowFacebook(): boolean {
    return this.type === 'facebook';
  }

  get isShowGoogle(): boolean {
    return this.type === 'google';
  }

  get isSystem(): boolean {
    return this.isShowAlert
      || this.isShowWarning
      || this.isShowClock
      || this.isShowDownload;
  }

  get isShowAvatar(): boolean {
    return !this.isSystem && !this.client_name && !!this.user_name;
  }

  get isShowRobot(): boolean {
    return !this.isSystem && !!this.client_name;
  }

}
