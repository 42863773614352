import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TJsonaModel } from 'jsona/lib/JsonaTypes';
import {
  HttpEventType, HttpResponse, HttpProgressEvent, HttpParams, HttpClient,
} from '@angular/common/http';
import { CollectionHelpersService, Dictionary, RowsCollection } from 'asap-team/asap-tools';

import type { Observable } from 'rxjs';
import type { ImportDatabaseDetails, UploadDBEvent, UploadDBAnswer, UploadDBCollectionPendingItem } from '@core/types';

@Injectable({ providedIn: 'root' })
export class CsvService {

  constructor(
    private http: HttpClient,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  /**
  * Admin claims imported database
  *
  * @param {string} uid - UID of imported CSV file
  */
  claim(uid: string): Observable<any> {
    return this
      .http
      .patch(`v2/admin/imports/${uid}/claim`, null);
  }

  /**
  * Admin marks import as completed
  *
  * @param {string} uid - UID of imported CSV file
  */
  complete(uid: string): Observable<any> {
    return this
      .http
      .patch(`v2/admin/imports/${uid}/complete`, null);
  }

  /**
  * Admin validate database provided by User
  *
  * @param {FormData} form - Form with file to validate.
  */
  validateDatabase(form: FormData): Observable<UploadDBEvent> {
    return this
      .http
      .post('v2/admin/imports', form, {
        observe: 'events',
        reportProgress: true,
      })
      .pipe(
        map((event: HttpProgressEvent | HttpResponse<{ uid: string; user_uid: string }>) => {
          if (event.type === HttpEventType.UploadProgress) {
            return {
              // eslint-disable-next-line no-mixed-operators
              progress: Math.round(100 * event.loaded / event.total),
              done: false,
              payload: null,
            };
          }

          if (event instanceof HttpResponse) {
            return {
              progress: null,
              done: true,
              payload: {
                uid: event.body?.uid,
                user_uid: event.body?.user_uid,
              },
            };
          }

          return {
            progress: null, done: false, payload: null,
          };
        }),
      );
  }

  /**
  * Import Database info
  *
  * @param {string} uid - UID of imported Database (CSV, XLS, XLSX, TXT) file.
  */
  getImportDetails(uid: string): Observable<ImportDatabaseDetails> {
    return this.http.get<ImportDatabaseDetails>(`v2/admin/imports/${uid}`);
  }

  /**
  * Admin import database for User
  *
  * @param {string} uid - UID of imported Database (CSV, XLS, XLSX, TXT) file.
  * @param {boolean} allow_send_sms - checkbox
  */
  importDatabase({ uid, allow_send_sms }: { uid: string; allow_send_sms: boolean }): Observable<void> {
    return this.http.patch<void>(`v2/admin/imports/${uid}`, { allow_send_sms });
  }

  /**
  * Get all pending uploaded DB
  *
  * @param {Dictionary<number>} combination page and pagination
  */
  getUploadedDataBases({ page, per_page }: Dictionary<number>): Observable<RowsCollection<UploadDBCollectionPendingItem>> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        page: `${page + 1}`,
        per_page: `${per_page}`,
      },
    });

    return this.http
      .get('v2/imports', { params })
      .pipe(this.collectionHelpersService.mapToCollection<UploadDBCollectionPendingItem>(page + 1));
  }

  /**
  * User uploading database
  *
  * @param {FormData} form - form with file to upload
  */
  uploadDataBase(form: FormData): Observable<TJsonaModel> {
    return this.http.post<TJsonaModel>('v2/imports', form);
  }

  /**
  * User cancels pending database upload
  *
  * @param {string} uid - uploaded file uid
  */
  cancelUploadDataBase(uid: string): Observable<TJsonaModel> {
    return this.http.delete<TJsonaModel>(`v2/imports/${uid}`);
  }

  /**
  * User answers for uploading database
  *
  * @param {string} uid - UID of imported Database (CSV, XLS, XLSX, TXT) file.
  * @param {UploadDBAnswer []} answers - array of answers
  */
  sendAnswers({ uid, answers }: { uid: string; answers: UploadDBAnswer [] }): Observable<TJsonaModel> {
    return this.http.patch<TJsonaModel>(`v2/imports/${uid}/answers`, { answers });
  }

  /**
  * Admin upgrade billing plan for User
  *
  * @param {string} user_id - UID of User that will be upgraded by Admin
  */
  upgradeUserPlan(user_id: string): Observable<any> {
    return this.http.patch(`v2/admin/users/${user_id}/reservations/upgrade`, null);
  }

}
