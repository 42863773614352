import type { NavItem } from '@core/types';

import { ROUTE, PERMITTED_ACTION, USER_ROLE } from '@consts/consts';

export const HealthMetrics: NavItem[] = [
  {
    label: 'Health Metrics',
    routerLink: ROUTE.alias.HEALTH_METRICS,
    counter: null,
    roles: [USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.marketing],
    permitted_action: PERMITTED_ACTION.SYSTEM_METRICS_VIEW,
    icon: null,
  },
];
