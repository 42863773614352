<div class="ftl-viral__container">
  <ftl-header></ftl-header>
  <div class="ftl-viral__content">
    <h2 class="f-32-bold">A sample report is being generated</h2>
    <p class="f-16-normal m-t-1x">This will only take a few seconds...</p>
    <div class="generation-container m-t-5x">
      <ul class="analyzing-list">
        <li class="analyzing-item m-b-1x" *ngFor="let step of generationSteps" #listAnimationItem>
          <div class="analyzing-icon">
            <div class="circle-loader">
              <div class="check-mark"></div>
            </div>
          </div>
          <div class="analyzing-text f-16-normal">{{ step }}</div>
          <div class="analyzing-status">
            <div class="analyzing-status-text"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
