<div class="home-info">

  <div class="d-flex flex-column text-wrapper"
       [ngClass]="{'link': isInternalUser}"
       (click)="viewLeadDetails(transaction)">

    <ng-container *ngIf="getAddress() as address; else no_address">
      <div [tooltip]="address"
           placement="top"
           container="text-wrapper"
           class="l-h-22 f-14-600 text-overflow">{{ address }}</div>
    </ng-container>

    <ng-container *ngIf="hasBadge; else full_address">
      <div *ngIf="transaction.active_shopper" class="home-shopper-badge">
        <img height="12" width="12" src="assets/images/fire.png" alt="fire">
        Active Buyer
      </div>
      <div *ngIf="transaction.lost_refinance" class="lost-refinance">
        Lost Refinance
      </div>
    </ng-container>

  </div>
</div>

<!--Templates-->
<!--Active buyer full address-->
<ng-template #full_address>
      <span [tooltip]="getFullAddress()"
            class="text-overflow l-h-18 f-12-400 f-c-steel">
        {{ getFullAddress(true) }}
      </span>
</ng-template>

<!--No address-->
<ng-template #no_address>

  <div class="l-h-22 f-14-400 f-c-steel">
        <span tooltip="The property address is hidden in accordance with compliance requirements."
              placement="top"
              container="body">
          Hidden
          <svg xmlns="http://www.w3.org/2000/svg"
               width="14"
               height="14"
               viewBox="0 0 14 14"
               fill="none">
            <path fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 3.16582C9.41182 3.16582 11.5627 4.52128 12.6127 6.66582C12.2373 7.44218 11.7091 8.11037 11.0791 8.65128L11.9764 9.54855C12.8609 8.76582 13.5609 7.78582 14 6.66582C12.8991 3.87218 10.1818 1.89309 7 1.89309C6.19182 1.89309 5.41545 2.02037 4.68364 2.25582L5.73364 3.30582C6.14727 3.22309 6.56727 3.16582 7 3.16582ZM6.31909 3.89128L7.63636 5.20855C7.99909 5.36764 8.29182 5.66037 8.45091 6.02309L9.76818 7.34037C9.81909 7.124 9.85727 6.89491 9.85727 6.65946C9.86364 5.08128 8.57818 3.80219 7 3.80219C6.76455 3.80219 6.54182 3.834 6.31909 3.89128ZM0.642727 1.81037L2.34818 3.51583C1.31091 4.33037 0.49 5.41219 0 6.66583C1.10091 9.45947 3.81818 11.4386 7 11.4386C7.96727 11.4386 8.89636 11.254 9.74909 10.9167L11.9255 13.0931L12.8227 12.1958L1.54 0.906738L0.642727 1.81037ZM5.41545 6.58309L7.07636 8.244C7.05091 8.25036 7.02545 8.25673 7 8.25673C6.12182 8.25673 5.40909 7.544 5.40909 6.66582C5.40909 6.634 5.41545 6.61491 5.41545 6.58309ZM3.25182 4.41948L4.36545 5.53311C4.21909 5.88311 4.13636 6.26493 4.13636 6.66584C4.13636 8.24402 5.42182 9.52948 7 9.52948C7.40091 9.52948 7.78273 9.44675 8.12636 9.30039L8.75 9.92402C8.19 10.0768 7.60455 10.1658 7 10.1658C4.58818 10.1658 2.43727 8.81039 1.38727 6.66584C1.83273 5.75584 2.48182 5.00493 3.25182 4.41948Z"
                  fill="#9CACBE" />
          </svg>
        </span>
  </div>
</ng-template>
