import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MortgageWidgetData } from '@modules/user/collections/homeowners/report-details/components/features/refinance-scanner/types/refinance-scanner-data.type';

@Component({
  selector: 'mortgage-details',
  templateUrl: './mortgage-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./mortgage-details.component.scss'],
})
export class MortgageDetailsComponent {

  @Input() mortgage: MortgageWidgetData;

}
