import { Component, Input, OnChanges } from '@angular/core';

// Types
import { Integration } from '@core/types';

// Consts
import { INTEGRATION_STATUS } from '@consts/integrations';
import { Dictionary } from 'asap-team/asap-tools';
@Component({
  selector: 'integration-card',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.scss'],
})
export class IntegrationCardComponent implements OnChanges {

  @Input() integration: Integration;

  isColored: boolean = false;

  INTEGRATION_STATUS: Dictionary = INTEGRATION_STATUS;

  ngOnChanges(): void {
    this.isColored = [INTEGRATION_STATUS.CONNECTED, INTEGRATION_STATUS.RECEIVING_ONLY].includes(this.integration.status);
  }

}
