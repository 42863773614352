import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateByYear' })
export class DateByYearPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: string, currentYearFormat: string, otherFormat: string): string {
    const date: Date = new Date(value);
    const currentYear: number = new Date().getFullYear();

    if (date.getFullYear() === currentYear) {
      return this.datePipe.transform(date, currentYearFormat);
    }

    return this.datePipe.transform(date, otherFormat);
  }

}
