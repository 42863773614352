import { throwError, Subscription, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OnInit, Component, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StripeService } from 'ngx-stripe';
import {
  StripeCardElement,
  StripeElements,
  StripeElementsOptions,
  StripeError,
  Token,
  TokenResult,
} from '@stripe/stripe-js';
import { FormErrors, ResponseErrorHandler } from 'asap-team/asap-tools';

import { AddCardModalResult, APIErrorResponse, PaymentMethod } from '@core/types';

// Consts
import { addCardErrors } from '@consts/consts';

// Services
import { BillingService } from '@core/services/billing/billing.service';
import { DialogRef } from '@angular/cdk/dialog';

@UntilDestroy()
@Component({
  selector: 'add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddCardComponent implements OnInit {

  card: StripeCardElement;

  elements: StripeElements;

  addCardAction: Subscription;

  formErrors: FormErrors = addCardErrors;

  elementsOptions: StripeElementsOptions = { locale: 'en' };

  primary: boolean = false;

  primaryByDefault: boolean = false;

  cardConfig: any = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#363b40',
        lineHeight: '56px',
        fontWeight: 300,
        fontSize: '18px',
        '::placeholder': { color: '#b6babf' },
      },
    },
  };

  constructor(
    private stripeService: StripeService,
    private billingService: BillingService,
    private responseErrorHandler: ResponseErrorHandler,
    private dialogRef: DialogRef,
  ) {
  }

  ngOnInit(): void {
    if (this.primaryByDefault) {
      this.primary = this.primaryByDefault;
    }

    this.subscribes();
  }

  private subscribes(): void {
    this.formErrors.global = [];
    this
      .stripeService
      .elements(this.elementsOptions)
      .subscribe((elements: StripeElements) => {
        this.elements = elements;

        if (!this.card) {
          this.card = this.elements.create('card', this.cardConfig);
          this.card.mount('#new-card');
          this.card.on('change', () => {
            this.formErrors.global = [];
          });
        }
      });
  }

  addCard(): void {
    const observable: Observable<{ token?: Token; error?: StripeError }> = this.stripeService.createToken(this.card, { name: '' });

    this.addCardAction = observable
      .pipe(
        untilDestroyed(this),
        switchMap<TokenResult, Observable<PaymentMethod[]>>(
          (result: TokenResult) => {
            if (result.token) {
              return this.billingService.savePaymentMethod(result.token.id, this.primary);
            }

            if (result.error) {
              this.formErrors.global = [result.error.message];

              return throwError(() => new Error(result.error.message));
            }

            return throwError(() => new Error('Unknown error occurred'));
          },
        ),
      )
      .subscribe({
        next: () => {
          this.billingService.paymentMethodsChange();
          this.closeModal({ isPrimary: this.primary });
        },
        error: (error: APIErrorResponse) => this.responseErrorHandler.process(error, null, this.formErrors),
      });
  }

  closeModal(closeResult?: AddCardModalResult): void {
    this.dialogRef.close(closeResult);
  }

}
