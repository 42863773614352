import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { VaultService } from 'asap-team/asap-tools';

// Consts
import { ARCHIVE_LEAD_SESSION } from '@consts/consts';

@Component({ templateUrl: './archive-confirm.component.html' })
export class ArchiveConfirmComponent extends SimpleModalComponent<{ mode?: string }, boolean> implements OnInit {

  mode: string;

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private vaultService: VaultService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({ show: [false] });
  }

  confirm(): void {
    if (this.form.controls.show.value) {
      this.vaultService.set(ARCHIVE_LEAD_SESSION, true, 'session');
    }

    this.result = !this.form.controls.show.value;
    this.close();
  }

}
