import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';

// Consts
import { profileErrors } from '@consts/form-errors';
import { USER_ROLE } from '@consts/user';

// Types
import { CheckboxItem, UserRole, Profile } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';
import { DictionaryService } from '@core/services/dictionary/dictionary.service';
import {
  APIErrorResponse,
  COMMON_TOAST,
  FormErrors,
  ResponseErrorHandler,
  Dictionary,
} from 'asap-team/asap-tools';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';

@UntilDestroy()
@Component({
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss'],
})
export class CompleteProfileComponent extends SimpleModalComponent<null, boolean> implements OnInit {

  form: UntypedFormGroup;

  formErrors: FormErrors = profileErrors;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  states$: Observable<CheckboxItem[]>;

  profile: Profile;

  updateProfileAction$: Subscription;

  timeZones$: Observable<CheckboxItem []> = this.dictionaryService.timeZones$;

  mlsTooltipText: string = 'As a member of an MLS, you receive an number known as an MLS ID, Agent ID, or Public ID.. This serves as your primary "username" or "login" credential for accessing the MLS system, typically comprising a combination of letters, numbers, or both.';

  constructor(
    private builder: UntypedFormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private responseErrorHandler: ResponseErrorHandler,
    private dictionaryService: DictionaryService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.states$ = this.userService.getAvailablePropertiesStates();
    this.getProfile();

    this.form = this.builder.group({
      license_number: [this.profile?.license_number, [Validators.required, Validators.minLength(3)]],
      license_states: [this.profile?.license_states, [Validators.required]],
      mls_number: [this.profile?.mls_number, [Validators.required]],
      company_name: [this.profile?.company_name, [Validators.required, Validators.minLength(3)]],
      company_license_number: [
        this.profile?.company_license_number,
        [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
      ],
      time_zone: [this.profile?.time_zone || null, [Validators.required]],
    });
  }

  private getProfile(): void {
    this
      .userService
      .profile$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((profile: Profile) => {
        this.profile = profile;
      });
  }

  completeProfile(): void {
    this.updateProfileAction$ = this
      .userService
      .updateProfile(this.form.value)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(COMMON_TOAST.CHANGES_SAVED);
          this.close();
        },
        (error: APIErrorResponse) => {
          this.responseErrorHandler.process(error, this.form, this.formErrors);
        },
      );
  }

  get isAgentRole(): boolean {
    return this.userService.isUserRole(USER_ROLE.agent);
  }

}
