<div class="control-dropdown" *ngIf="isShowQuickActions(lead)" dropdown placement="auto" container="body">
  <div class="control-btn dots inline-svg" dropdownToggle [inlineSVG]="'#icon-dots'"></div>
  <div class="control-dropdown__menu" *dropdownMenu>
    <div class="control-dropdown__item"
         *ngIf="isRowPermitted(lead, PERMITTED_ACTION.SEND_REPORT_VIA_EMAIL)"
         [promiseBtn]="action$"
         (click)="sendReport(lead.id, true, false)">
      <span class="control-dropdown__label">Email Report to Homeowner</span>
    </div>
    <div class="control-dropdown__item"
         *ngIf="isRowPermitted(lead, PERMITTED_ACTION.SEND_REPORT_VIA_SMS)"
         [promiseBtn]="action$"
         (click)="sendReport(lead.id, false, true)">
      <span class="control-dropdown__label">SMS Report to Homeowner</span>
    </div>
    <div class="control-dropdown__item"
         *ngIf="isRowPermitted(lead, PERMITTED_ACTION.SEND_REPORT_VIA_SMS) && isRowPermitted(lead, PERMITTED_ACTION.SEND_REPORT_VIA_EMAIL)"
         [promiseBtn]="action$"
         (click)="sendReport(lead.id, true, true)">
      <span class="control-dropdown__label">SMS & Email Report to Homeowner</span>
    </div>
    <div class="control-dropdown__item"
         *ngIf="isRowPermitted(lead, PERMITTED_ACTION.ACTIVATE) || isRowPermitted(lead, PERMITTED_ACTION.ACTIVATE_NEED_COMPLETE_ACCOUNT)"
         [promiseBtn]="action$"
         (click)="activate(lead.id)">
      <span class="control-dropdown__label">Activate Report</span>
    </div>
    <div class="control-dropdown__item"
         *ngIf="isRowPermitted(lead, PERMITTED_ACTION.DEACTIVATE)"
         [promiseBtn]="action$"
         (click)="deactivate(lead.id)">
      <span class="control-dropdown__label">Deactivate Report</span>
    </div>
    <div class="control-dropdown__item" (click)="open(lead.uid)">
      <span class="control-dropdown__label">View Report</span>
    </div>
    <div class="control-dropdown__item"
         *ngIf="isRowPermitted(lead, PERMITTED_ACTION.ARCHIVE)"
         [promiseBtn]="action$"
         (click)="archive(lead.id)">
      <span class="control-dropdown__label">Archive report</span>
    </div>
  </div>
</div>
