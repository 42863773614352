import { Component, Input } from '@angular/core';

// Const
import { COMMON_TOAST } from '@consts/consts';

// Types
import { IntelligenceGeneralInfo } from '@core/types';

// Services
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'general-info-sidebar',
  templateUrl: './general-info-sidebar.component.html',
  styleUrls: ['./general-info-sidebar.component.scss'],
})
export class GeneralInfoSidebarComponent {

  @Input() generalInfo: IntelligenceGeneralInfo;

  @Input() type: 'agent' | 'lender' = 'agent';

  COMMON_TOAST: any = COMMON_TOAST;

  constructor(
    private toastr: ToastrService,
  ) {}

  copy(): void {
    this.toastr.success(COMMON_TOAST.COPIED_TO_CLIPBOARD);
  }

}
