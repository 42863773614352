<div class="control"
     #checkboxDropdown="bs-dropdown"
     dropdown=""
     [dropup]="dropup"
     [insideClick]="true"
     [isDisabled]="isDisabled || readonly"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused"
     (isOpenChange)="onOpenChange($event)">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <div class="control__content">
    <div class="flex-1">
      <input class="control__input pointer"
             #nativeInput=""
             type="text"
             readonly=""
             data-lpignore="true"
             dropdownToggle=""
             [attr.placeholder]="placeholder"
             (focus)="focused = true"/>
      <div class="dropdown-menu" *dropdownMenu>
        <div class="search" *ngIf="isShowFilter">
          <input class="search__input"
                 #filterInput
                 type="text"
                 data-lpignore="true"
                 [attr.placeholder]="filterPlaceholder"
                 (input)="applyFilter($event.target.value)"/>
          <div class="inline-svg search__clear-btn"
               *ngIf="filterBy"
               [inlineSVG]="'#icon-close'"
               (click)="clearFilter($event, filterInput)"></div>
          <div class="inline-svg search__search-icon" *ngIf="!filterBy" [inlineSVG]="'#icon-search'"></div>
        </div>
        <div class="items scrollbar" #itemsView>
          <div class="checkbox" *ngFor="let item of filtered">
            <input class="checkbox__input" type="checkbox" [attr.id]="item.uid" [checked]="item.selected"/>
            <label class="checkbox__label d-flex"
                   [attr.for]="item.uid"
                   [attr.title]="item.name"
                   (click)="toggle($event, item)">
              <div class="flex-1 text-overflow">{{ item.name }}</div>
              <div class="checkbox__label-code" *ngIf="displayCode">{{ item.code }}</div>
            </label>
          </div>
        </div>
        <div class="dropdown-controls d-flex">
          <div class="flex-1 align-self-center">
            <div class="pseudo-btn f-14-500" *ngIf="isAnySelected" (click)="toggleAll($event, false)">Clear all</div>
          </div>
          <div class="flex">
            <iq-button label="Cancel"
                       size="40"
                       color="grey"
                       [style]="'outline'"
                       (onClick)="cancel(checkboxDropdown)"></iq-button>
            <iq-button class="m-l-2x" label="Apply" size="40" (onClick)="apply(checkboxDropdown)"></iq-button>
          </div>
        </div>
      </div>
    </div>
    <div class="chevron inline-svg" *ngIf="!readonly" [inlineSVG]="'#icon-chevron'"></div>
  </div>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
