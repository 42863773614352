<ng-container *ngIf="isShowForm else loading">
  <div class="sign-content">
    <form class="sign-form" name="form" [formGroup]="form" (ngSubmit)="signIn()">
      <img class="sign-form__logo" src="assets/common/logo/my-homeiq-logo-dark.svg"/>
      <iq-form-control-text class="alternative-label m-b-2x"
                            #email=""
                            label="Email"
                            type="email"
                            placeholder="Email"
                            lpignore="false"
                            formControlName="email"
                            [errorsModel]="formErrors.email"></iq-form-control-text>
      <iq-form-control-password class="alternative-label m-b-2x"
                                #password=""
                                label="Password"
                                placeholder="********"
                                lpignore="false"
                                formControlName="password"
                                [errorsModel]="formErrors.password"></iq-form-control-password>
      <div class="control-error-message m-b-1x" *ngFor="let error of formErrors.global">{{ error }}</div>
      <div class="m-t-3x text-center">
        <iq-button class="block m-b-3x" type="submit" label="Sign In" size="56" [busy$]="action"></iq-button>
        <a class="link f-14-500" [routerLink]="[ROUTE.alias.RESTORE]">Forgot password?</a>
      </div>
    </form>
    <div class="text-center m-t-2x">
      <span class="f-c-steel">Don’t have an account?&nbsp;</span>
      <a class="f-c-blue-main" [href]="signUpLink">Start free trial</a>
    </div>
  </div>
  <div class="sign-preview f-14-500">
    <h1 class="sign-preview__title">
      Unlock myhomeIQ<br>Intelligence
      <img class="sign-preview__badge m-b-1x" src="/assets/components/sign-in/intelligence/badge.png" alt=""/>
    </h1>
    <p class="sign-preview__text">
      Gain valuable insights into your performance, transactions, and potential<br>
      business opportunities. Get real-time alerts when clients visit any listed property<br>
      or open house with the new Active Buyer Feature.</p>
    <img class="sign-preview__image-lg m-b-1x" src="/assets/components/sign-in/intelligence/sign-in-lg.png" alt=""/>
    <img class="sign-preview__image-md m-b-1x" src="/assets/components/sign-in/intelligence/sign-in.png" alt=""/>
  </div>
</ng-container>
<ng-template #loading="">
  <div class="loading-dots__main">
    <div class="loading-dots__main-item loading-dots__main-item--1"></div>
    <div class="loading-dots__main-item loading-dots__main-item--2"></div>
    <div class="loading-dots__main-item loading-dots__main-item--3"></div>
  </div>
</ng-template>
