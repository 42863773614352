<bs-alert type="warning" [dismissible]="true" (onClose)="closeAlert(type)">
  <div class="global-alert__container">
    <div class="global-alert__message">
      <img class="global-alert__message-icon" src="assets/common/alerts/alert-warning-icon.svg" alt="warning" />
      <div class="message-body email-confirm">
        <div class="message-text">
          Check your email address:<br class="mobile-break"><span class="bold">{{ (profile$ | async)?.email }}</span>to confirm it.&#32;
        </div>

        <div class="message-actions">
          <a [routerLink]="ROUTE.alias.PROFILE">Change email</a>&#32;
          <a (click)="resendConfirmationEmail()">Resend email</a>
        </div>
      </div>
    </div>
  </div>
</bs-alert>
