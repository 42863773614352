<div class="header">
  <h3 class="title" [innerHTML]="copy?.title"></h3>
  <p class="subtitle" [innerHTML]="copy?.subtitle"></p>
</div>
<div class="video-container"
     (click)="showVideo()"
     [style.backgroundImage]="'url(/assets/components/collections/placeholders/phone.png)'">
  <img class="icon" src="/assets/icons/play.svg"/>
</div>
<div class="controls flex" *ngIf="isEmpty">
  <create-report-btn></create-report-btn>
  <import-database-btn *permittedIf="PERMITTED_ACTION.DATABASES_UPLOAD"></import-database-btn>
</div>
