export const LeadsActivityModules: any = {
  not_implemented: {
    load: (): any => import('../common/not-implemented/not-implemented.component')
      .then((c: any) => c.NotImplementedComponent),
  },
  lead_deleted: {
    load: (): any => import('../global-activities/lead-deleted/lead-deleted.component')
      .then((c: any) => c.LeadDeletedComponent),
  },
  lead_archived: {
    load: (): any => import('../global-activities/lead-archived/lead-archived.component')
      .then((c: any) => c.LeadArchivedComponent),
  },
  lead_restored: {
    load: (): any => import('../global-activities/lead-restored/lead-restored.component')
      .then((c: any) => c.LeadRestoredComponent),
  },
  lead_member_removed: {
    load: (): any => import('../global-activities/lead-member-removed/lead-member-removed.component')
      .then((c: any) => c.LeadMemberRemovedComponent),
  },
  lead_member_leaved: {
    load: (): any => import('../global-activities/lead-member-removed/lead-member-removed.component')
      .then((c: any) => c.LeadMemberRemovedComponent),
  },
  lead_member_assigned: {
    load: (): any => import('../global-activities/lead-member-assigned/lead-member-assigned.component')
      .then((c: any) => c.LeadMemberAssignedComponent),
    icon: '👥',
  },
  lead_member_autoassigned: {
    load: (): any => import('../global-activities/lead-member-assigned/lead-member-assigned.component')
      .then((c: any) => c.LeadMemberAssignedComponent),
    icon: '👥',
  },
  lead_member_assigned_from_mls: {
    load: (): any => import('../global-activities/lead-member-assigned/lead-member-assigned.component')
      .then((c: any) => c.LeadMemberAssignedComponent),
    icon: '👥',
  },
  lead_created_from_traffic: {
    load: (): any => import('../global-activities/lead-created-from-traffic/lead-created-from-traffic.component')
      .then((c: any) => c.LeadCreatedFromTrafficComponent),
  },
  lead_created_from_seller_traffic_stepper: {
    load: (): any => import('../global-activities/lead-created-from-traffic/lead-created-from-traffic.component')
      .then((c: any) => c.LeadCreatedFromTrafficComponent),
  },
  leads_bulk_imported: {
    load: (): any => import('../global-activities/leads-bulk-imported/leads-bulk-imported.component')
      .then((c: any) => c.LeadsBulkImportedComponent),
  },
  client_update_loan_info: {
    load: (): any => import('../global-activities/client-update-loan-info/client-update-loan-info.component')
      .then((c: any) => c.ClientUpdateLoanInfoComponent),
  },
  client_update_homeowner_name: {
    load: (): any => import('../global-activities/client-update-homeowner-details/client-update-homeowner-details.component')
      .then((c: any) => c.ClientUpdateHomeownerNameComponent),
  },
  client_update_homeowner_details: {
    load: (): any => import('../global-activities/client-update-homeowner-details/client-update-homeowner-details.component')
      .then((c: any) => c.ClientUpdateHomeownerNameComponent),
  },
  seller_digest_verify_cma: {
    load: (): any => import('../global-activities/seller-digest-verify-cma/seller-digest-verify-cma.component')
      .then((c: any) => c.SellerDigestVerifyCmaComponent),
  },
  seller_digest_ask_question: {
    load: (): any => import('../global-activities/seller-digest-ask-question/seller-digest-ask-question.component')
      .then((c: any) => c.SellerDigestAskQuestionComponent),
  },
  seller_digest_user_activate: {
    load: (): any => import('../global-activities/seller-digest-user-activate/seller-digest-user-activate.component')
      .then((c: any) => c.SellerDigestSentActivateComponent),
  },
  seller_digest_user_deactivate: {
    load: (): any => import('../global-activities/seller-digest-user-deactivate/seller-digest-user-deactivate.component')
      .then((c: any) => c.SellerDigestSentDeactivateComponent),
  },
  seller_digest_request_cma: {
    load: (): any => import('../global-activities/seller-digest-request-cma/seller-digest-request-cma.component')
      .then((c: any) => c.SellerDigestRequestCmaComponent),
  },
  seller_digest_client_subscribe: {
    load: (): any => import('../global-activities/seller-digest-client-subscribe/seller-digest-client-subscribe.component')
      .then((c: any) => c.SellerDigestClientSubscribeComponent),
  },
  seller_digest_client_unsubscribe: {
    load: (): any => import('../global-activities/seller-digest-client-unsubscribe/seller-digest-client-unsubscribe.component')
      .then((c: any) => c.SellerDigestClientUnsubscribeComponent),
  },
  user_update_loan_info: {
    load: (): any => import('../global-activities/user-update-loan-info/user-update-loan-info.component')
      .then((c: any) => c.UserUpdateLoanInfoComponent),
    icon: '🏠',
  },
  updating_homeowner_details: {
    load: (): any => import('../global-activities/updating-homeowner-details/updating-homeowner-details.component')
      .then((c: any) => c.UpdatingHomeownerDetailsComponent),
  },
  updating_homeowner_name: {
    load: (): any => import('../global-activities/updating-homeowner-name/updating-homeowner-name.component')
      .then((c: any) => c.UpdatingHomeownerNameComponent),
  },
  // 230
  user_update_property_details: {
    load: (): any => import('../global-activities/user-update-loan-info/user-update-loan-info.component')
      .then((c: any) => c.UserUpdateLoanInfoComponent),
  },
  // 235
  lead_property_details_changed: {
    load: (): any => import('../common/system-activity/system-activity.component')
      .then((c: any) => c.SystemActivityComponent),
    icon: '🏠',
  },
  // 423
  system_update_loan_info: {
    load: (): any => import('../common/system-activity/system-activity.component')
      .then((c: any) => c.SystemActivityComponent),
    icon: '🏠',
  },
};
