import { Route } from '@angular/router';

// Const
import { ROUTE } from '@consts/routes';
import { PERMITTED_ACTION } from '@consts/permitted-actions';
import { USER_ROLE } from '@consts/user';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';
import { RegistrationCompletedGuard } from '@core/guards/registration-completed/registration-completed.guard';
import { PermissionGuard } from 'asap-team/asap-tools';

export const DASHBOARD: Route = {
  path: ROUTE.name.DASHBOARD,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, PermissionGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, PermissionGuard],
  data: {
    canBeActivatedBy: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [
      PERMITTED_ACTION.DASHBOARD_VIEW,
    ],
    redirectTo: ROUTE.alias.HOMEOWNERS_REPORTS,
  },
  loadChildren: () => import('@modules/user/dashboard/dashboard.module').then((m: any) => m.DashboardModule),
};
