<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ typo?.title || 'Upload Avatar' }}</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <h4 *ngIf="typo?.subtitle" class="m-b-2x f-16-normal">{{ typo?.subtitle }}</h4>
    <image-cropper [maintainAspectRatio]="maintainAspectRatio"
                   [cropper]="cropperPosition"
                   [imageChangedEvent]="imageChangedEvent"
                   [roundCropper]="roundCropper"
                   [autoCrop]="true"
                   [transform]="transform"
                   [hideResizeSquares]="hideResizeSquares"
                   [aspectRatio]="cropWidth / cropHeight || 1/1"
                   (wheel)="zoom($event)"
                   (cropperReady)="setCropArea($event)"
                   (imageCropped)="imageCropped($event)"></image-cropper>
  </div>
  <div class="modal-footer">
    <div class="flex flex-center">
      <iq-button class="block" label="Cancel" [style]="'outline'" (onClick)="close()"></iq-button>
      <iq-button class="block" [label]="typo?.confirm_action_label || 'Done'" (onClick)="crop()"></iq-button>
    </div>
  </div>
</div>
