<div class="control__prepend">
  <div class="control__label">
    <div class="control__label-text flex-1">
      <div class="f-c-gray-main text-overflow pointer"
           ngxClipboard=""
           tooltip="Click to copy"
           [cbContent]="url">
        {{ url }}
      </div>
    </div>
    <div class="m-l-2x">
      <iq-button size="40"
                 color="grey"
                 tooltip="Copy"
                 [style]="'transparent'"
                 iconLeft="#icon-copy"
                 ngxClipboard=""
                 [cbContent]="url"></iq-button>
      <iq-button size="40"
                 color="grey"
                 tooltip="Preview"
                 [style]="'transparent'"
                 iconLeft="#icon-link_out"
                 (onClick)="open()"></iq-button>
    </div>
  </div>
</div>
