import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class GlobalErrorStatusHandler {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  performActionFor(status: number): void {
    if (status === 401) {
      this.userService.logout(false);
      this.router.navigate([ROUTE.alias.SIGN_IN]);
    }
  }

}
