import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Directive,
  Input,
  ViewContainerRef,
  TemplateRef,
  OnInit,
  EmbeddedViewRef,
} from '@angular/core';
import { assertTemplate } from 'asap-team/asap-tools';

import type { Profile } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';

@UntilDestroy()
@Directive({ selector: '[roleIfAny]' })
export class RoleIfAnyDirective implements OnInit {

  /**
   * The rule to evaluate as the condition for showing a template.
   */
  @Input()
  set roleIfAny(roles: string[]) {
    this.roles = roles;
    this.updateView();
  }

  /**
   * A template to show if the permission is not present in profile.
   */
  @Input()
  set roleIfAnyElse(templateRef: TemplateRef<any> | null) {
    assertTemplate('roleIfElse', templateRef);
    this.elseTemplateRef = templateRef;
    this.elseViewRef = null; // clear previous view if any.
    this.updateView();
  }

  private elseTemplateRef: TemplateRef<any> = null;

  private elseViewRef: EmbeddedViewRef<any> | null = null;

  private profile: Profile;

  private roles: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this
      .userService
      .profile$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((profile: Profile) => {
        this.profile = profile;
        this.updateView();
      });
  }

  private updateView(): void {
    if (this.checkPermission()) {
      this.viewContainer.clear();
      this.elseViewRef = null;
      this.viewContainer.createEmbeddedView(this.templateRef);

      return;
    }

    if (!this.elseViewRef) {
      this.viewContainer.clear();

      if (this.elseTemplateRef) {
        this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      }
    }
  }

  private checkPermission(): boolean {
    let permitted: boolean = false;

    if (!this.roles.length) { return false }

    if (this.profile?.role) {
      permitted = this.roles.some((role: string) => this.profile.role === role);
    }

    return permitted;
  }

}
