import { Component, Input } from '@angular/core';

@Component({
  selector: 'iq-ui-divider',
  template: `
    <div class="iq-ui-divider" [style.background-color]="color"></div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .iq-ui-divider {
        height: 1px;
      }
    `,
  ],
})
export class IqUiDividerComponent {

  @Input() color: string = '#9CACBE26';

}
