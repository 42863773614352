import { Component, OnInit } from '@angular/core';
import { MenuService } from '@core/services/menu/menu.service';
import { ROUTE } from '@consts/routes';
import { UserService } from '@core/services/user/user.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavItem, Profile, SideNavItem } from '@core/types';
import { UserSettings } from '@consts/nav-config/user-settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  profile$: Observable<Profile> = this.userService.profile$;

  discount: NavItem;

  readonly route: any = ROUTE;

  constructor(
    private menuService: MenuService,
    private userService: UserService,
    private readonly router: Router,
  ) {
  }

  ngOnInit(): void {
    this.profile$
      .pipe(untilDestroyed(this))
      .subscribe((profile: Profile) => {
        const discountItems: NavItem[] = UserSettings
          .filter((item: NavItem) => this.menuService.discountPermittedActions.includes(item.permitted_action));

        [this.discount] = discountItems
          .filter((navItem: NavItem) => navItem.roles.includes(profile.role)
            && profile.permitted_actions.includes(navItem.permitted_action));
      });
  }

  get profileMenu(): SideNavItem[] {
    return this.menuService.profileMenu;
  }

  closeMenu(): void {
    this.menuService.closeAllMenus();
  }

  logout(): void {
    this
      .userService
      .logout(true)
      .subscribe(() => {
        this.router.navigate([ROUTE.alias.SIGN_IN]);
        this.menuService.closeAllMenus();
      });
  }

}
