import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Directive,
  Input,
  ViewContainerRef,
  TemplateRef,
  OnInit,
} from '@angular/core';

import type { PermittedAction, Profile } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';

@UntilDestroy()
@Directive({ selector: '[permittedIfAll]' })
export class PermittedIfAllDirective implements OnInit {

  @Input() set permittedIfAll(permissions: PermittedAction[]) {
    this.permissions = permissions;
    this.updateView();
  }

  private profile: Profile;

  private permissions: PermittedAction[] = [];

  private isHidden: boolean = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this
      .userService
      .profile$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((profile: Profile) => {
        this.profile = profile;
        this.updateView();
      });
  }

  private updateView(): void {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let permitted: boolean = false;

    if (!this.permissions.length) { return true; }

    if (this.profile?.permitted_actions) {
      permitted = this.permissions.every((permission: PermittedAction) => this.profile.permitted_actions.includes(permission));
    }

    return permitted;
  }

}
