<div class="modal-content complete-profile">
  <div class="modal-header">
    <h4 class="modal-title">Add your MLS Agent ID</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body text-left">
    <form class="settings__form" [formGroup]="form" (ngSubmit)="completeProfile()">
      <div class="modal-body__text">
        <div class="f-14-normal m-b-2x">To ensure the best experience and full functionality of our service, we kindly request that you provide us with your MLS ID number.</div>
      </div>
      <iq-form-control-text class="m-b-2x"
                            label="MLS Agent ID"
                            placeholder="12345678"
                            formControlName="mls_number"
                            [errorsModel]="formErrors.mls_number">
      </iq-form-control-text>
      <iq-button class="block" type="submit" [busy$]="updateProfileAction$" label="Save"></iq-button>
      <div class="mls__divider"></div>
      <div class="modal-body__text">
        <p class="f-16-600 m-b-1x">What is an MLS ID?</p>
        <p class="f-14-normal">
          As a member of an MLS, you receive an number known as an&#32;
          <b>MLS ID, Agent ID, or Public ID.</b>&#32;
          This serves as your primary "username" or "login" credential for accessing the MLS system, typically comprising a combination of letters, numbers, or both.
        </p>
      </div>
    </form>
  </div>
</div>
