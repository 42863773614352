import { Component, Input, OnInit, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { IqLoadingBarService } from '@commons/iq-loading-bar/services/iq-loading-bar.service';

@Component({
  selector: 'iq-loading-bar',
  templateUrl: './iq-loading-bar.component.html',
  styleUrls: ['./iq-loading-bar.component.scss'],
})
export class IqLoadingBarComponent implements OnInit {

  @Optional() @Input() color: string = '#0389ff';

  @Optional() @Input() height: string = '4px';

  progress$: Observable<number>;

  constructor(
    private readonly loadingBarService: IqLoadingBarService,
  ) {
  }

  ngOnInit(): void {
    this.progress$ = this.loadingBarService.progress$;
  }

}
