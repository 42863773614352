import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngxs/store';
import { BackButtonActions } from '@commons/components/back-btn/state';

@Component({
  selector: 'relationships-list-item',
  templateUrl: './relationships-list-item.component.html',
  styleUrls: ['../relationships-list.component.scss'],
})
export class RelationshipsListItemComponent {

  @Input() relationship: any;

  @Input() link: string = null;

  @Input() linkState: { route: string; title: string; data?: Params } = null;

  constructor(
    private store: Store,
  ) {}

  goToDetails(): void {
    this.store.dispatch(new BackButtonActions.SetItem({
      url: this.linkState?.route,
      title: this.linkState?.title,
      data: this.linkState?.data,
    }));
  }

}
