import type { Dictionary } from 'asap-team/asap-tools';
import type { LandingTheme } from '@core/types';

export const HOMEOWNER_PREVIEW_DEFAULT: Dictionary = {
  logo: '/assets/components/landing-settings/homeiq.svg',
  preview: '/assets/components/landing-settings/homeowner-preview-top-layer.svg',
  previewBottom: '/assets/components/landing-settings/preview-bottom-layer.svg',
  previewReport: '/assets/components/landing-settings/lender-report.svg',
};

export const BUYER_PREVIEW_DEFAULT: Dictionary = {
  logo: '/assets/components/landing-settings/homeiq.svg',
  background: '/assets/components/landing-settings/buyer-property.jpg',
  preview: '/assets/components/landing-settings/buyer-preview-top-layer.svg',
  previewBottom: '/assets/components/landing-settings/buyer-preview-bottom-layer.svg',
};

export const LANDING_THEMES: LandingTheme[] = [
  {
    name: 'flamingo',
    hex: '#f25a21',
    secondaryColor: '#fde2d8',
  },
  {
    name: 'sundance',
    hex: '#c5a95e',
    secondaryColor: '#f4efe1',
  },
  {
    name: 'alizarin-crimson',
    hex: '#e72221',
    secondaryColor: '#fbdada',
  },
  {
    name: 'turquoise',
    hex: '#30c6c6',
    secondaryColor: '#def7f7',
  },
  {
    name: 'sushi',
    hex: '#7da747',
    secondaryColor: '#ecf3e2',
  },
  {
    name: 'dodger-blue',
    hex: '#2681ff',
    secondaryColor: '#d6e7ff',
  },
  {
    name: 'dark-grey',
    hex: '#2E3338',
    secondaryColor: '#e9ebed',
  },
];

export const HOMEOWNER_LANDING_API: Dictionary = {
  HOME_VALUE: 'seller_traffic',
  HOME_WEALTH: 'home_wealth_traffic',
};

export const BANNER_SUBTYPE: string[] = [
  'main',
  'police',
  'teacher',
  'firefighter',
  'engineer',
  'va',
  'healthcare',
  'entrepreneur',
  'business',
  'first_time_buyer',
  'second_time_buyer',
  'jumbo',
];
