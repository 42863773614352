import { Dictionary } from 'asap-team/asap-tools';

export const QUERY_PARAM: Dictionary = {
  EDIT_CMA: 'edit_cma',
  INVITE_MANUAL_SIDEBAR: 'invite_manual_sidebar',
  INVITE_SIDEBAR: 'invite_sidebar',
  SIDEBAR: 'sidebar',
  UID: 'uid',
  EDIT_LOAN_INFO: 'edit_loan_info',
};

export const QUERY_PARAM_KEY: Dictionary = {
  ACTION: 'action',
  FTL_SEQUENCE: 'ftl_sequence',
};

export const QUERY_PARAM_VALUE: Dictionary = {
  GRANT_ACCESS: 'grant_access',
  INVITE: 'invite',
  INVITE_ACCEPT: 'invite_accept',
  SHOW_IMPORT: 'show_import',
  VIRAL_FLOW: 'viral_flow',
  REGULAR_FLOW: 'regular_flow',
};
