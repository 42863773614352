<div class="transactions-header">
  <ng-content select="[headerSlot]"></ng-content>
</div>

<div class="stats">
  <ng-content select="[statsSlot]"></ng-content>
</div>

<stackbar-diagram [data]="graphData"
                  [loading]="loading"
                  [stackBarMeta]="{ keys: ['buyers_total', 'homeowners_total'], colors: ['#0DAAFF', '#0C509E'] }"
                  (changeTooltipData)="setTooltipData($event);">
  <div class="tooltip-title">{{ tooltipData?.xAxisValue }}</div>
  <div class="tooltip-row">
    <div class="tooltip-mark" [ngStyle]="{ 'background-color': '#0DAAFF' }"></div>
    <div class="tooltip-label">Buyers</div>
    <div class="tooltip-count">{{ tooltipData?.buyers_total }}</div>
    <div class="tooltip-percent">
      {{ (tooltipData?.buyers_total * 100 / (tooltipData?.homeowners_total + tooltipData?.buyers_total)) | number:'1.0-0' }}
      %
    </div>
  </div>
  <div class="tooltip-row">
    <div class="tooltip-mark" [ngStyle]="{ 'background-color': '#0C509E' }"></div>
    <div class="tooltip-label">Listings</div>
    <div class="tooltip-count">{{ tooltipData?.homeowners_total }}</div>
    <div class="tooltip-percent">
      {{ (tooltipData?.homeowners_total * 100 / (tooltipData?.homeowners_total + tooltipData?.buyers_total)) | number:'1.0-0' }}
      %
    </div>
  </div>
</stackbar-diagram>
