import {
  Component,
  Input,
  Inject,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WINDOW } from '@ng-web-apis/common';

import type { Profile } from '@core/types';

// Consts
import { environment } from 'environments/environment';

// Services
import { ActivePartnersService } from '@core/services/collections/partnership/active-partners.service';

@UntilDestroy()
@Component({
  selector: 'landing-link',
  templateUrl: './landing-link.component.html',
  styleUrls: ['./landing-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingLinkComponent implements OnChanges {

  @Input() type: string;

  @Input() profile: Profile;

  url: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private cdr: ChangeDetectorRef,
    private activePartnersService: ActivePartnersService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile.currentValue) {
      this.setLink(changes.profile.currentValue, this.type);
    }
  }

  open(): void {
    this.window.open(this.url, '_blank');
  }

  private setLink(profile: Profile, type: string): void {
    switch (type) {
      case 'homeowner': {
        this.url = `${environment.herUrl}/${profile.username}`;

        return;
      }

      case 'home-value': {
        this.url = `${environment.herUrl}/${profile.username}/home-value`;

        return;
      }

      case 'buyer': {
        this.url = this.profile.buyer_lp_link;

        break;
      }
      default: {
        break;
      }
    }
  }

}
