<div class="user-avatar">
  <img class="user-avatar__img" [src]="avatarSrc"/>
  <label class="user-avatar__label" *ngIf="canEdit">
    <input #imageControl="" type="file" accept="image/*" (change)="onImageSelected($event)"/>
    <ng-container *ngIf="!loading; else loadingDots">
      <img class="user-avatar__camera"
           src="assets/components/profile/photo-camera.svg"
           alt=""/>
      <p class="user-avatar__title">Update</p>
    </ng-container>
    <ng-template #loadingDots>
      <loading-dots class="loading-dots--sm visible"></loading-dots>
    </ng-template>
  </label>
</div>
