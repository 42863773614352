import { Injectable } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { BehaviorSubject } from 'rxjs';
import { SingleAnalyticEvent } from '@consts/analytics-events.interface';

// Consts
import { FTL_FLOW, USER_ROLE } from '@consts/user';

// Services
import { UserService } from '@core/services/user/user.service';
import { TrackingScriptsService } from '@core/helpers/tracking/tracking-scripts.service';
import { AsaplyticsUrlProcessor } from '@core/helpers/tracking/asaplytics-url-processor';
import type { Profile } from '@core/types';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AsaplyticsService {

  private ftlFlow: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private readonly bypassTracking: string = environment.analytics.bypassTracking;

  constructor(
    private trackingScriptsService: TrackingScriptsService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private userService: UserService,
  ) {}

  setFtlFlow(name: string): void {
    if (!name) { return; }

    this.ftlFlow.next(name);
  }

  trackBannerDownload(id: string): void {
    const role: string = this.userService.getUserRole();

    this.sendEvent({
      action: 'download', category: 'banner', label: `Banner with id ${id} downloaded by ${role}.`,
    });
  }

  trackBannerShare(id: string): void {
    const role: string = this.userService.getUserRole();

    this.sendEvent({
      action: 'share', category: 'banner', label: `Banner with id ${id} shared by ${role}.`,
    });
  }

  public get forViralFlow(): AsaplyticsService | null {
    if (this.ftlFlow.getValue() === FTL_FLOW.VIRAL_FLOW) {
      return this;
    }

    return null;
  }

  public get forManualFlow(): AsaplyticsService | null {
    if (this.ftlFlow.getValue() === FTL_FLOW.REGULAR_FLOW) {
      return this;
    }

    return null;
  }

  public get forAgentRole(): AsaplyticsService | null {
    if (this.userService.isUserRole(USER_ROLE.agent)) {
      return this;
    }

    return null;
  }

  public get forLenderRole(): AsaplyticsService | null {
    if (this.userService.isUserRole(USER_ROLE.lender)) {
      return this;
    }

    return null;
  }

  sendEvent(event: SingleAnalyticEvent): void {
    const { action, category, label } = event;
    const props: any = {
      category,
      ...(label && { label }),
    };

    if (!this.bypassTracking) {
      this.angulartics2GoogleTagManager.eventTrack(action, props);
    }
  }

  sendDataLayerEvent(event: string, params: Partial<SingleAnalyticEvent>): void {
    if (!this.bypassTracking && globalThis.dataLayer) {
      globalThis.dataLayer.push({ event, ...(!!params && params) });
    }
  }

  init(): void {
    if (!this.bypassTracking) {
      // Init GoogleAnalytics, GoogleTagManager, FacebookPixel, etc,,,
      this.trackingScriptsService.setupTrackingScripts();
    }
  }

  trackPageForUrl(url: string): void {
    const isPageTrackingAllowed: boolean = url !== '/sign-up' && !this.bypassTracking;

    if (isPageTrackingAllowed) {
      // (Tracking providers) Please, place calls for page view here.
      this.angulartics2GoogleTagManager.pageTrack(AsaplyticsUrlProcessor.cleanUrlIfNeeded(url));
    }
  }

  setCustomDimensions(profile: Profile): void {
    globalThis.dataLayer.push({
      username: profile.username || 'none',
      soa_user_id: profile.id || 'none',
      uuid: profile.uid || 'none',
      role: profile.role || 'none',
      plan: profile.plan_name || 'none',
      subscription_status: profile.subscription_status || 'none',
      source: profile.source || 'none',
      env: environment.analytics.env || 'none',
    });
  }

}
