<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Meet IQ Intelligence</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="handleClose()"></div>
  </div>
  <div class="release-notes__content">
    <img class="release-notes__image m-b-4x" src="assets/images/release-notes/release-notes-intelligence.png"/>
    <div class="release-notes__title">Comprehensive Realtor Insights</div>
    <div class="release-notes__text m-b-3x">Gain valuable insights into each realtor's performance,&#32;
      transactions, and potential business opportunities.
    </div>
    <div class="release-notes__subtitle">What’s new:</div>
    <ul class="release-notes__list m-b-3x">
      <li class="release-notes__list-item">Find realtors you've worked with or any realtor countrywide</li>
      <li class="release-notes__list-item">View realtor’s transactions and volume</li>
      <li class="release-notes__list-item">Filter transactions by mortgage loan type</li>
      <li class="release-notes__list-item">Discover the loan officers they partner with</li>
      <li class="release-notes__list-item">Get insights on potential and lost listings</li>
      <li class="release-notes__list-item">Enhanced connected realtors dashboard</li>
    </ul>
    <iq-button class="block"
               label="Discover IQ Intelligence"
               (click)="handleRedirect()"></iq-button>
  </div>
</div>
