<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{modalData?.isPlural ? 'Activate 𝘮𝘺HomeIQ Reports' : 'Activate 𝘮𝘺HomeIQ Report'}}</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <div class="modal-body__title" *ngIf="modalData?.isPlural">{{ modalData?.title }}</div>
    <div class="modal-body__text">
      Once you activate the report{{modalData?.isPlural ? 's' : ''}}, we will update the data and the homeowner will
      receive an email or sms to view the report{{modalData?.isPlural ? 's' : ''}}.
    </div>
    <div class="working-hours-alert" *ngIf="showScheduleAlert">
      <div class="icon inline-svg icon__info" [inlineSVG]="'#icon-alert_round'"></div>
      <span class="f-12-400 f-c-grey-main">
        Report{{modalData?.isPlural ? 's' : ''}} sending will be scheduled to {{scheduleAlertMessage}}
      </span>
      <div class="icon inline-svg question-mark-icon"
           [inlineSVG]="'#icon-question'"
           [tooltip]="scheduleAlertTooltip"></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block" label="Cancel" color="grey" [style]="'outline'" (onClick)="close()"></iq-button>
      </div>
      <div class="col col-fix">
        <iq-button class="block" label="Activate" (onClick)="confirm()"></iq-button>
      </div>
    </div>
  </div>
</div>
