import { QuillConfig } from 'ngx-quill';

import type { Dictionary } from 'asap-team/asap-tools';
import type { CheckboxItem, BaseLeadLoanType } from '@core/types';
import type { LoaderOptions } from '@googlemaps/js-api-loader/src';

import { environment } from '../../environments/environment';

export * from '@consts/alerts';
export * from '@consts/apps';
export * from '@consts/billing';
export * from '@consts/calendly-links';
export * from '@consts/collection';
export * from '@consts/copy';
export * from '@consts/datepicker';
export * from '@consts/errors';
export * from '@consts/form-errors';
export * from '@consts/ftl';
export * from '@consts/import-db';
export * from '@consts/integrations';
export * from '@consts/lead';
export * from '@consts/modals';
export * from '@consts/permitted-actions';
export * from '@consts/query-params';
export * from '@consts/regex';
export * from '@consts/routes';
export * from '@consts/referral';
export * from '@consts/settings';
export * from '@consts/toasts';
export * from '@consts/user';
export * from '@consts/warnings';
export * from '@consts/feature-flags';
export * from '@consts/us-states';

export const JWT_TOKEN: string = 'jwt';
export const PROFILE: string = 'profile';
export const PROFILE_HASH: string = 'profile_hash';
export const ADD_MLS_ID_MODAL_DISPLAYED: string = 'add_mls_id_displayed';
export const UNPAID_SLAVE_MODAL_DISPLAYED: string = 'unpaid_slave_modal_displayed';
export const ARCHIVE_LEAD_SESSION: string = 'archive_lead_session';
export const LAST_ROUTING_STATE: string = 'last_routing_state';
export const VIRAL: string = 'viral';
export const INTERCOM_ID: string = 'l7je0xhc';
export const PROPERTY_IMAGE_PLACEHOLDER: string = 'assets/components/custom-table/house.svg';
export const INTELLIGENCE_PROPERTY_IMAGE_PLACEHOLDER: string = 'assets/components/collections/intelligence/house.svg';

export const LOCK_IMAGE_PLACEHOLDER: string = 'assets/components/custom-table/lock.svg';
export const LEAD_LOAN_TYPES: BaseLeadLoanType[] = [
  {
    title: 'VA',
    value: 'va',
  },
  {
    title: 'FHA',
    value: 'fha',
  },
  {
    title: 'Conventional',
    value: 'conventional',
  },
  {
    title: 'USDA',
    value: 'usda',
  },
  {
    title: 'Cash buyer',
    value: 'cash',
  },
  {
    title: 'Jumbo',
    value: 'jumbo',
  },
  {
    title: 'Other Loan',
    value: 'other_loan',
  },
];

export const DECLINE_REASON: Dictionary = {
  NO_CONTACT_OR_CANCEL: 'Can\'t contact agent, or agent canceled order',
  SOLD: 'Order can\'t be completed',
};
export const INVOICE_STATUS: Dictionary = { PAID: 'paid' };
export const HIDE_REFINANCE_BLOCK_MONTHS: CheckboxItem[] = [
  {
    name: 'Never to hide',
    code: null,
  },
  {
    name: '1 month',
    code: '1',
  },
  {
    name: '2 months',
    code: '2',
  },
  {
    name: '3 months',
    code: '3',
  },
  {
    name: '6 months',
    code: '6',
  },
  {
    name: '1 year',
    code: '12',
  },
  {
    name: '2 years',
    code: '24',
  },
  {
    name: '3 years',
    code: '36',
  },
  {
    name: 'Forever',
    code: '600',
  },
];
export const QUILL_MODULE_CONFIG: QuillConfig = {
  modules: {
    syntax: false,
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['link'],
      ['clean'],
    ],
  },
  formats: [
    'bold',
    'italic',
    'underline',
    'link',
  ],
};

export const GoogleAPILoaderConfig: LoaderOptions = {
  apiKey: environment.mapsApiKey,
  libraries: ['places'],
};
