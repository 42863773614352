import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OnboardingResponse, WidgetStep } from '../models/widget.model';

@Injectable({ providedIn: 'root' })
export class WidgetService {

  private readonly onboardingProgressUpdated: Subject<OnboardingResponse> = new Subject<OnboardingResponse>();

  public readonly onboardingProgress$: Observable<OnboardingResponse> = this.onboardingProgressUpdated.asObservable();

  constructor(
    private readonly http: HttpClient,
  ) {}

  public getOnboardingProgress(): Observable<OnboardingResponse> {
    return this.http.get<OnboardingResponse>('v2/onboarding').pipe(
      tap((response: OnboardingResponse): void => {
        this.onboardingProgressUpdated.next(response);
      }),
    );
  }

  public updateOnboardingProgress(step: WidgetStep['name']): Observable<OnboardingResponse> {
    const formData: FormData = new FormData();

    formData.append(step, 'true');

    return this.http.patch<OnboardingResponse>('v2/onboarding', formData).pipe(
      tap((response: OnboardingResponse): void => {
        this.onboardingProgressUpdated.next(response);
      }),
    );
  }

}
