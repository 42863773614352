<div class="current-mortgage">
  <h4 class="mortgage-title">Current mortgage</h4>
  <div class="mortgage-tiles">
    <div class="mortgage-tile">
      <div class="tile-title">Loan Balance</div>
      <div class="tile-value">{{mortgage.loanBalance ? (mortgage.loanBalance | currency: 'USD' : 'symbol' : '1.0-0') : '-'}}</div>
    </div>
    <div class="mortgage-tile with-graph">
      <div class="tile-data">
        <div class="tile-title">Paid Off</div>
        <div class="tile-value">{{mortgage.paid_off   | number : '1.2-2'}}{{mortgage.paid_off !== undefined ? '%' : '-'}}</div>
      </div>
      <div *ngIf="mortgage.paid_off" class="tile-graph">
        <donut-chart [data]="mortgage.chartData" [strokeWidth]="0" [width]="36" [height]="36" [innerRadius]="11.46"></donut-chart>
      </div>
    </div>
    <div class="mortgage-tile">
      <div class="tile-title">LTV</div>
      <div class="tile-value">{{mortgage.ltv  | number : '1.2-2'}}{{mortgage.ltv   !== undefined ? '%' : '-'}}</div>
    </div>
    <div class="mortgage-tile">
      <div class="tile-title">Open lien amount</div>
      <div class="tile-value">{{mortgage.open_lien_amount ? (mortgage.open_lien_amount | currency: 'USD' : 'symbol' : '1.0-0') : '-'}}</div>
    </div>
    <div class="mortgage-tile">
      <div class="tile-title">Interest rate</div>
      <div class="tile-value">{{mortgage.interest_rate}}{{mortgage.interest_rate ? '%' : '-'}}</div>
    </div>
    <div class="mortgage-tile">
      <div class="tile-title">Loan Term</div>
      <div class="tile-value">{{mortgage.loan_term}} year{{mortgage.loan_term > 1 ? 's' : ''}}</div>
    </div>
    <div class="mortgage-tile">
      <div class="tile-title">Type</div>
      <div class="tile-value">{{mortgage.type | titlecase}}</div>
    </div>
    <div class="mortgage-tile">
      <div class="tile-title">Loan date</div>
      <div class="tile-value">{{mortgage.loan_date | date : 'MMM yyyy'}}</div>
    </div>
    <div class="mortgage-tile">
      <div class="tile-title">PMI</div>
      <div class="tile-value">{{mortgage.pmi ? (mortgage.pmi | currency: 'USD' : 'symbol' : '1.0-0') : '-'}}</div>
    </div>
  </div>
</div>
