// eslint-disable-next-line max-classes-per-file
import { BackBtnLink } from './back-btn.model';

const ACTION_SCOPE: string = '[Back Button State]';

export namespace BackButtonActions {

  export class SetItem {

    static readonly type: string = `${ACTION_SCOPE} Set new item with data`;

    constructor(
      public item: BackBtnLink,
      public isCreateNewStash: boolean = false,
    ) {}

  }

}
