import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CollectionHelpersService, Dictionary, BaseHttpService, CollectionResponse } from 'asap-team/asap-tools';

import type { Observable } from 'rxjs';
import type { AccountDetailsPartnerItem } from '@core/types';

@Injectable({ providedIn: 'root' })
export class AccountActivePartnersService {

  constructor(
    private http: BaseHttpService,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(queryParams: Dictionary, pathParams: Dictionary): Observable<CollectionResponse<AccountDetailsPartnerItem>> {
    const { accountId } = pathParams;

    return this
      .http
      .get(`v2/admin/users/${accountId}/partnerships`, { params: new HttpParams({ fromObject: queryParams }) })
      .pipe(
        this.collectionHelpersService.retrowError(),
      );
  }

}
