import { ComponentRef, Injectable, Type } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { IqSidebarConfig } from '@commons/iq-sidebar/models/iq-sidebar-config.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { IqSidebarResult } from '@commons/iq-sidebar/models/iq-sidebar-result.model';
import { sidebarDefaultConfiguration } from '@commons/iq-sidebar/configurations/sidebar.configuration';
import { overlayDefaultConfiguration } from '@commons/iq-sidebar/configurations/overlay.configuration';

@Injectable({ providedIn: 'root' })
export class IqSidebarService {

  // References to all opened overlays
  private refs: IqSidebarResult<any>[] = [];

  constructor(
    private readonly overlay: Overlay,
  ) {
  }

  public open<T, R = any>(component: Type<T>, data?: Partial<T>, config: Partial<IqSidebarConfig> = {}): IqSidebarResult<R> {

    // Creating an overlay
    const overlayRef: OverlayRef = this.overlay.create(this.fetchConfig(config));

    // Attach component to overlay
    const attachedComponent: T = this.attachComponent(component, overlayRef);

    // Setup close behaviour
    const result$: IqSidebarResult<R> =
      this.setCloseBehaviour<R, T>(overlayRef, { ...sidebarDefaultConfiguration, ...config }, attachedComponent);

    // Setup animations
    this.setAnimations(overlayRef);

    // Map data fields on existing component`s props if match by key.
    if (data) {
      Object.keys(data).forEach((key: string) => attachedComponent[key] = data[key]);
    }

    return result$;

  }

  public setRawResult<R = any>(closeResult: R): void {
    const lastRef: IqSidebarResult<R> = this.getLastSidebarRef<R>();

    if (lastRef) {
      lastRef.setRawResult(closeResult);
    }
  }

  public close<R = any>(closeResult?: R): void {
    const lastRef: IqSidebarResult<R> = this.refs.pop();

    if (lastRef) {
      lastRef.close(closeResult || lastRef.actions$.value);
    }
  }

  public closeAll(): void {
    this.refs.forEach((result: IqSidebarResult<any>) => result.overlayRef?.dispose());
    this.unblockBodyScroll();
  }

  public updateData<C, T, R = any>(data: T, fieldName: keyof C): void {
    const lastRef: IqSidebarResult<R> = this.getLastSidebarRef<R>();

    if (lastRef && data) {
      lastRef.component[fieldName] = data;
    }
  }

  private fetchConfig(config: Partial<IqSidebarConfig>): OverlayConfig {
    // Prepare sidebar config
    const sidebarConfig: IqSidebarConfig = { ...sidebarDefaultConfiguration, ...config };

    // Prepare overlay related config
    const overlayConfig: OverlayConfig = { ...overlayDefaultConfiguration };

    // Applying sidebar config
    if (sidebarConfig.bodyClass) (overlayConfig.panelClass as string[]).push(sidebarConfig.bodyClass);

    if (sidebarConfig.wrapperClass) (overlayConfig.backdropClass as string[]).push(sidebarConfig.wrapperClass);

    return overlayConfig;
  }

  private setCloseBehaviour<R, T>(overlayRef: OverlayRef, sidebarConfig: IqSidebarConfig, component: T): IqSidebarResult<R> {
    // Dispose trigger
    const dispose$: Subject<R> = new Subject<R>();

    // Raw result state
    const rawResult$: BehaviorSubject<R> = new BehaviorSubject<R>(null);

    // Method to set raw result state for current instance
    const setRawResult: (result: R) => void = (result: R) => rawResult$.next({ ...result });

    // Close method for current instance
    const close: (closeResult: R) => void = (closeResult: R) => {
      const animationDuration: number = sidebarConfig.animationDuration || 0;
      const sidebarElement: HTMLDivElement = overlayRef.hostElement?.firstElementChild as HTMLDivElement;

      if (sidebarElement) {
        sidebarElement.classList.add('iq-sidebar-fade-out-animation');
      }

      setTimeout(() => {
        overlayRef.dispose();
        this.removeOverlayRef(overlayRef);
        dispose$.next(closeResult);
        rawResult$.complete();
      }, animationDuration);
    };

    if (sidebarConfig.closeOnBackdrop) {
      overlayRef.backdropClick()
        .pipe(takeUntil(dispose$))
        .subscribe(() => close(rawResult$.value));
    }

    if (sidebarConfig.closeOnEscape) {
      overlayRef.keydownEvents()
        .pipe(
          takeUntil(dispose$),
          filter((event: KeyboardEvent) => event.key === 'Escape'),
        )
        .subscribe(() => close(rawResult$.value));
    }

    this.blockBodyScroll();

    overlayRef.detachments().pipe(take(1)).subscribe(() => this.unblockBodyScroll());

    const result: IqSidebarResult<R> = {
      actions$: rawResult$,
      afterClosed$: dispose$.pipe(take(1)),
      initialConfig: sidebarConfig,
      overlayRef: overlayRef,
      close: close.bind(this),
      setRawResult: setRawResult.bind(this),
      component,
    };

    // Store an overlay ref
    this.refs.push(result);

    return result;
  }

  private setAnimations(overlayRef: OverlayRef): void {
    // Adjusting open-close animation duration
    (overlayRef.hostElement.firstElementChild as HTMLDivElement).style.animationDuration =
      `${this.refs.find((i: IqSidebarResult<any>) => i.overlayRef === overlayRef)?.initialConfig.animationDuration || 0}ms`;
  }

  private attachComponent<T>(component: Type<T>, overlayRef: OverlayRef): T {
    // Creating portal and attaching a component to view
    const componentPortal: ComponentPortal<T> = new ComponentPortal<T>(component);
    const componentRef: ComponentRef<T> = overlayRef.attach(componentPortal);

    return componentRef.instance;
  }

  private blockBodyScroll(): void {
    document.body.style.overflow = 'hidden';
  }

  private unblockBodyScroll(): void {
    document.body.style.overflow = '';
  }

  private removeOverlayRef(overlayRef: OverlayRef): void {
    const index: number = this.refs.findIndex((i: IqSidebarResult<any>) => i.overlayRef === overlayRef);

    if (index > -1) {
      this.refs.splice(index, 1);
    }

    if (this.refs.length === 0) {
      this.unblockBodyScroll();
    }
  }

  private getLastSidebarRef<R>(): IqSidebarResult<R> {
    return this.refs[this.refs.length - 1];
  }

}

