import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { CommonsModule } from '@commons/commons.module';

// Components
import { SignInComponent } from '@modules/auth/sign-in/sign-in.component';
import { RestorePassComponent } from '@modules/auth/sign-in/restore-pass/restore-pass.component';
import { ConfirmPassComponent } from '@modules/auth/sign-in/confirm-pass/confirm-pass.component';
import { CompleteAccountComponent } from '@modules/auth/sign-up/complete-account/complete-account.component';
import { ExpiredLinkComponent } from '@modules/auth/sign-up/expired-link/expired-link.component';
import { ConfirmEmailComponent } from '@modules/auth/confirm-email/confirm-email.component';
import { RestrictedAccessComponent } from '@modules/auth/restricted-access/restricted-access.component';
import { EnterpriseComponent } from '@modules/auth/sign-up/enterprise/enterprise.component';

import { FtlHeaderComponent } from '@modules/auth/sign-up/ftl/ftl-header/ftl-header.component';
import { FtlIllustrationsComponent } from '@modules/auth/sign-up/ftl/ftl-sidebar/ftl-illustrations/ftl-illustrations.component';
import { FtlErrorComponent } from '@modules/auth/sign-up/ftl/ftl-error/ftl-error.component';
import { FTLAthByUIDComponent } from '@modules/auth/ftl-uth-by-uid/ftl-uth-by-uid.component';
import { FtlViewSimpleReportComponent } from './sign-up/ftl/ftl-view-simple-report/ftl-view-simple-report.component';
import { TitleCompanyComponent } from './sign-up/title-company/title-company.component';

import {
  FtlComponent,
  FtlDemoComponent,
  FtlPassStepComponent,
  FtlAgentProfileComponent,
  FtlLenderProfileComponent,
  AgentInvitedComponent,
  AllSetComponent,
} from './sign-up/ftl/components';

@NgModule({
  imports: [
    FormsModule,
    RouterModule,
    CommonsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SignInComponent,
    ConfirmPassComponent,
    RestorePassComponent,
    CompleteAccountComponent,
    ExpiredLinkComponent,
    ConfirmEmailComponent,
    RestrictedAccessComponent,
    EnterpriseComponent,
    FtlHeaderComponent,
    FtlIllustrationsComponent,
    FtlErrorComponent,
    FTLAthByUIDComponent,
    FtlViewSimpleReportComponent,
    TitleCompanyComponent,
    FtlComponent,
    FtlPassStepComponent,
    FtlAgentProfileComponent,
    FtlLenderProfileComponent,
    FtlDemoComponent,
    AgentInvitedComponent,
    AllSetComponent,
  ],
})
export class AuthModule {}
