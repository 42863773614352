<div class="flex zapier-form__title m-b-3x"><i class="inline-svg--40 m-r-2x" [inlineSVG]="'#icon-zapier-logo'"></i>
  <h1 class="f-34-600">Zapier</h1>
  <div class="zapier-form__status f-14-normal m-l-2x" *ngIf="isConnected">connected</div>
</div>
<div class="f-14-500 f-c-steel m-b-2x" *ngIf="!isConnected">
  Use this code on Zapier to connect <i>my</i> HomeIQ account with it.
</div>
<div class="f-14-500 f-c-steel m-b-2x m-t-3x" *ngIf="isConnected">
  You have {{ zapierInvite.zaps_count }} active Zaps connected with the current <i>my</i> homeIQ account.
</div>
<div class="control m-b-2x">
  <div class="control__prepend w-100">
    <div class="control__label w-100">
      <div class="flex-center w-100">
        <div class="control__label-text flex-1">
          <div class="text-overflow">{{ zapierInvite?.token }}</div>
        </div>
        <div class="m-l-1x m-r-1x">
          <iq-button size="40"
                     color="grey"
                     [style]="'transparent'"
                     iconLeft="#icon-copy"
                     ngxClipboard=""
                     [cbContent]="zapierInvite?.token"></iq-button>
        </div>
      </div>
    </div>
  </div>
</div>
<iq-button class="block"
           *ngIf="!isConnected"
           [label]="isConnectButtonClicked ? 'Please refresh the page' : 'Connect myhomeIQ to zapier'"
           (onClick)="handleConnect()"
           [disabled]="isConnectButtonClicked"></iq-button>
<div class="f-12-400 f-c-steel m-b-2x m-t-3x" *ngIf="isConnected">
  <span class="m-r-1x" *ngIf="zapierInvite.last_activity_at">
    Last connect: {{ zapierInvite.last_activity_at | date: 'dd MMM, yyyy - hh:mm a' }}
  </span>
  <span class="f-12-600 f-c-blue-main pointer" (click)="handleDisableIntegration()">Disconnect</span>
</div>
