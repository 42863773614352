import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { FormError, provideRefs } from 'asap-team/asap-tools';

// Consts
import { DEFAULT_DATEPICKER_CONFIG } from '@consts/consts';

@UntilDestroy()
@Component({
  selector: 'iq-form-control-date-select',
  templateUrl: './iq-form-control-date-select.component.html',
  styleUrls: ['./iq-form-control-date-select.component.scss'],
  providers: provideRefs(IqFormControlDateSelectComponent),
})
export class IqFormControlDateSelectComponent implements ControlValueAccessor, OnInit {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @Input() bsConfig: Partial<BsDatepickerConfig> = DEFAULT_DATEPICKER_CONFIG;

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() placeholder: string | null = null;

  @Input() errorsModel: FormError[];

  @Input() formControlName: string;

  focused: boolean = false;

  form: UntypedFormGroup = this.fb.group({ date: [''] });

  constructor(
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this
      .form
      .valueChanges
      .pipe(
        distinctUntilChanged(isEqual),
        untilDestroyed(this),
      )
      .subscribe(({ date }: { date: Date }) => {
        this.onChange(date.toISOString());
      });
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(date: string): void {
    if (!date) { return; }

    this.form.patchValue({ date: parseISO(date) }, { emitEvent: false });
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  validate(): ValidationErrors | null {
    return null;
  }

  blur(): void {
    this.focused = false;
    this.onTouched();
  }

  getNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeInput;
  }

}
