import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {
  RxwebValidators,
  RxFormBuilder,
  RxFormGroup,
} from '@rxweb/reactive-form-validators';

import type { Dictionary, FormErrors } from 'asap-team/asap-tools';
import type { Company, CheckboxItem } from '@core/types';

// Consts
import { environment } from 'environments/environment';
import { ROUTE, EMAIL_PATTERN, enterpriseSignUpErrors } from '@consts/consts';

// Validators
import { phoneValidator } from '@core/validators/phone/phone.validator';

// Services
import { UserService } from '@core/services/user/user.service';
import { DictionaryService } from '@core/services/dictionary/dictionary.service';

type CropedImage = { image: Blob; name: string };

@UntilDestroy()
@Component({
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss'],
})
export class EnterpriseComponent implements OnInit {

  form: RxFormGroup;

  company: Company = this.route.snapshot.data.company;

  states: CheckboxItem[] = this.route.snapshot.data.states;

  timeZones$: Observable<CheckboxItem []> = this.dictionaryService.timeZones$;

  action$: Subscription;

  eyeControlMode: string = 'password';

  formErrors: FormErrors = enterpriseSignUpErrors;

  ROUTE: any = ROUTE;

  termsConditionsLink: any = environment.termsConditions;

  privacyPolicyLink: any = environment.privacyPolicy;

  cropedImage: CropedImage = null;

  imageUrl: any = null;

  constructor(
    private domSanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private fb: RxFormBuilder,
    private userService: UserService,
    private dictionaryService: DictionaryService,
  ) {}

  ngOnInit(): void {
    this.form = <RxFormGroup> this.fb.group({
      name: ['', [RxwebValidators.required()]],
      email: [
        '',
        [RxwebValidators.required(), Validators.pattern(EMAIL_PATTERN)],
      ],
      phone: ['', [RxwebValidators.required(), phoneValidator]],
      password: ['', [RxwebValidators.required(), Validators.minLength(6)]],
      license_number: ['', [RxwebValidators.required()]],
      license_states: [''],
      time_zone: [''],
    });
  }

  signUp(): void {
    const formData: FormData = this.form.toFormData();

    if (this.cropedImage) {
      formData.append('avatar', this.cropedImage.image, this.cropedImage.name);
    }

    this.action$ = this.userService
      .enterpriseRegistration(formData, this.company.code)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate([ROUTE.alias.DASHBOARD_PRODUCTION]);
      });
  }

  toggle(mode: string): void {
    this.eyeControlMode = mode;
  }

  getEnterpriseLogoStyle(company: Company): Dictionary {
    return { 'background-image': company.logo ? `url('${company.logo}')` : 'none' };
  }

  addAvatar(cropedImage: CropedImage): void {
    this.cropedImage = cropedImage;
    this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(cropedImage.image),
    );
  }

}
