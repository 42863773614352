import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import type { Company } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { AdminCompaniesService } from '@core/services/admin-companies/admin-companies.service';
import { Store } from '@ngxs/store';
import { NewUsersCollectionActions } from '@modules/admin/collections/users/new/state/new-users.actions';
import { TrialUsersCollectionActions } from '@modules/admin/collections/users/trial/state/trial-users.actions';
import { ArchivedAccountsActions } from '@modules/admin/collections/users/archived-accounts/state';
import { StaffAdminsActions } from '@modules/admin/collections/users/staff/state';

@Injectable({ providedIn: 'root' })
export class CompanyResolve implements Resolve<Company> {

  constructor(
    private router: Router,
    private store: Store,
    private adminCompanies: AdminCompaniesService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Company> {
    const code: string = route.paramMap.get('company');

    if (code === ROUTE.name.HOME_IQ) {
      this.setCompanyToState(null);

      return of(null);
    }

    return this
      .adminCompanies
      .getCompany(code)
      .pipe(
        tap((company: Company) => this.setCompanyToState(company)),
        catchError((error: any) => {
          this.router.navigate([ROUTE.name.SIGN_IN]);

          return throwError(error);
        }),
      );
  }

  private setCompanyToState(company: Company): void {
    this.store.dispatch(new NewUsersCollectionActions.SetCompanyState(company));
    this.store.dispatch(new TrialUsersCollectionActions.SetCompanyState(company));
    this.store.dispatch(new ArchivedAccountsActions.SetCompanyState(company));
    this.store.dispatch(new StaffAdminsActions.SetCompanyState(company));
  }

}
