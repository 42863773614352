import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormErrors, ResponseErrorHandler } from 'asap-team/asap-tools';

// Consts
import { ROUTE, EMAIL_PATTERN, restorePasswordErrors } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@UntilDestroy()
@Component({
  templateUrl: './restore-pass.component.html',
  styleUrls: ['./restore-pass.component.scss'],
})
export class RestorePassComponent implements OnInit, OnDestroy {

  ROUTE: any = ROUTE;

  EMAIL_PATTERN: RegExp = EMAIL_PATTERN;

  showSuccessScreen: boolean = false;

  form: UntypedFormGroup;

  action$: Subscription;

  formErrors: FormErrors = restorePasswordErrors;

  constructor(
    private builder: UntypedFormBuilder,
    private userService: UserService,
    private responseErrorHandler: ResponseErrorHandler,
  ) {}

  ngOnInit(): void {
    this.form = this.builder.group({ email: ['', [Validators.required, Validators.pattern(this.EMAIL_PATTERN)]] });
    this.subscribes();
  }

  ngOnDestroy(): void {
    if (this.action$) {
      this.action$.unsubscribe();
    }
  }

  subscribes(): void {
    this.form.valueChanges.subscribe(() => { this.formErrors.global = []; });
  }

  restore(form: any): void {
    if (form.status === 'INVALID') { return; }

    this.action$ = this
      .userService
      .resetPassword(form.value)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.showSuccessScreen = true;
        },
        (error: any) => {
          this.responseErrorHandler.process(error, form, this.formErrors);
        },
      );
  }

}
