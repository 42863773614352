import { Route } from '@angular/router';

// Consts
import { ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';

export const PROMO_CODES: Route = {
  path: ROUTE.name.PROMO_CODES,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard],
  loadChildren: () => import('@modules/admin/collections/promo-codes/promo-codes.module').then((m: any) => m.PromoCodesModule),
  data: { canBeActivatedBy: [USER_ROLE.sales, USER_ROLE.owner] },
};
