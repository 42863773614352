import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnInit, Output, EventEmitter, OnChanges, SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  debounceTime,
  filter,
  distinctUntilChanged,
} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'iq-search-filter',
  templateUrl: './iq-search-filter.component.html',
  styleUrls: ['./iq-search-filter.component.scss'],
})
export class IqSearchFilterComponent implements OnInit, OnChanges {

  @ViewChild('searchElementRef', { static: true }) searchElementRef: ElementRef;

  @Input() stateValue: string;

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  searchControl: UntypedFormControl = new UntypedFormControl('');

  isShow: boolean = false;

  isShowSearchIcon: boolean = true;

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        filter((value: string) => value?.length > 2 || value?.length === 0),
        distinctUntilChanged(),
        debounceTime(500),
        untilDestroyed(this),
      )
      .subscribe((value: string) => {
        this.search(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { stateValue } = changes;

    if (stateValue) {
      this.searchControl.patchValue(stateValue?.currentValue || '');
      this.isShowSearchIcon = !stateValue?.currentValue;
      this.isShow = !!stateValue?.currentValue;
    }
  }

  private reset(): void {
    if (!this.searchControl.value) { return; }

    this.isShow = false;
    this.isShowSearchIcon = true;
  }

  get buttonIcon(): 'search' | 'close' {
    return this.isShowSearchIcon ? 'search' : 'close';
  }

  show(): void {
    if (!this.isShow) {
      this.isShow = true;

      return;
    }

    this.reset();
    this.emitFilterValue.emit({ filterName: 'search', value: '' });
  }

  hide(): void {
    if (!this.searchControl.value) {
      this.isShow = false;
    }
  }

  transitionEnd(event: TransitionEvent): void {
    if (event.propertyName === 'visibility') {
      this.searchElementRef.nativeElement.focus();
    }
  }

  search(value: string): void {
    this.isShowSearchIcon = !value;
    this.emitFilterValue.emit({ value: value ? value.trim() : '', filterName: 'search' });
  }

}
