import { Component, Input } from '@angular/core';

@Component({
  selector: 'transactions-stats-group',
  templateUrl: './transactions-stats-group.component.html',
  styleUrls: ['./transactions-stats-group.component.scss'],
})
export class TransactionsStatsGroupComponent {

  @Input() align: 'vertical' | 'horizontal' = 'horizontal';

  @Input() loading: boolean = false;

  @Input() color: string = '';

  @Input() countTitle!: string;

  @Input() countValue!: number;

  @Input() volumeTitle!: string;

  @Input() volumeValue!: number;

  @Input() avgTitle!: string;

  @Input() avgValue!: number;

}
