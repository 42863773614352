import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { inRange } from 'lodash-es';

@Component({
  selector: 'pre-mover-count',
  templateUrl: './pre-mover-count.component.html',
  styleUrls: ['./pre-mover-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreMoverCountComponent implements OnInit, OnChanges {

  public tooltipTemplate: TemplateRef<unknown>;

  public range: number = null;

  @Input() public rate: number;

  @Input() public desktopOnly: boolean = false;

  @ViewChild('low') lowTooltip: TemplateRef<unknown>;

  @ViewChild('medium') mediumTooltip: TemplateRef<unknown>;

  @ViewChild('likely') likelyTooltip: TemplateRef<unknown>;

  @ViewChild('high') highTooltip: TemplateRef<unknown>;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setRange();
  }

  ngOnChanges(): void {
    this.setRange();
  }

  private setRange(): void {
    this.cdr.detectChanges();

    if (inRange(this.rate, 50)) {
      this.range = 1;
      this.tooltipTemplate = this.lowTooltip;
    }

    if (inRange(this.rate, 50, 70)) {
      this.range = 2;
      this.tooltipTemplate = this.mediumTooltip;
    }

    if (inRange(this.rate, 70, 90)) {
      this.range = 3;
      this.tooltipTemplate = this.likelyTooltip;
    }

    if (inRange(this.rate, 90, 101)) {
      this.range = 4;
      this.tooltipTemplate = this.highTooltip;
    }

    this.cdr.markForCheck();
  }

}
