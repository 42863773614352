<div class="modal-content complete-profile">
  <div class="modal-header">
    <h4 class="modal-title">Complete your profile</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body text-left">
    <form class="settings__form" [formGroup]="form" (ngSubmit)="completeProfile()">
      <div class="modal-body__text">
        <div class="f-16-normal m-t-1x">To generate a homeowner report, please complete your profile. This is a crucial step for a seamless experience with myhomeIQ.</div>
      </div>
      <ng-container *ngIf="states$ | async as states; else loading">
        <h4 class="f-16-bold m-t-3x m-b-1x">License</h4>
        <div class="license-alert m-b-1x">
          <span class="inline-svg" [inlineSVG]="'#icon-license-alert'"></span>
          <span class="license-alert__message">To ensure accurate data import, myhomeIQ will validate your license.</span>
        </div>
        <iq-form-control-text class="m-b-2x"
                              [label]="isAgentRole ? 'License*' : 'NMLS Number*'"
                              placeholder="12345678"
                              formControlName="license_number"
                              [errorsModel]="formErrors.license_number"></iq-form-control-text>
        <iq-form-control-custom-multiselect class="m-b-2x"
                                            label="State Licensed*"
                                            placeholder="Select licensed states"
                                            filterPlaceholder="Type state name"
                                            formControlName="license_states"
                                            [displayCode]="true"
                                            [model]="states"
                                            [errorsModel]="formErrors.license_states"></iq-form-control-custom-multiselect>
        <iq-form-control-text class="m-b-5x"
                              *roleIfAny="[USER_ROLE.agent]"
                              label="MLS Agent ID*"
                              placeholder="12345678"
                              formControlName="mls_number"
                              [errorsModel]="formErrors.mls_number">
          <div class="mls-tooltip m-l-1x m-r-2x">
            <div class="user-form__tooltip">
              <div class="user-form__tooltip--icon icon inline-svg inline-svg--22"
                    [inlineSVG]="'#icon-question'"
                    [tooltip]="mlsTooltipText"
                    placement="top"
                    container="body"></div>
            </div>
          </div>
        </iq-form-control-text>
        <h4 class="f-16-bold m-t-2x m-b-1x">Employment</h4>
        <iq-form-control-text class="m-b-2x"
                              label="Brokerage Name*"
                              placeholder="Acme Ltd."
                              formControlName="company_name"
                              [errorsModel]="formErrors.company_name"></iq-form-control-text>
        <iq-form-control-text class="m-b-5x"
                              label="Brokerage License.*"
                              placeholder="Brokerage license"
                              formControlName="company_license_number"
                              [errorsModel]="formErrors.company_license_number"></iq-form-control-text>
        <h4 class="f-16-bold m-t-2x m-b-1x">Choose timezone</h4>
        <iq-form-control-custom-select class="m-b-5x"
                                       formControlName="time_zone"
                                       label="Time zone"
                                       placeholder="Choose timezone"
                                       [model]="(timeZones$ | async)"
                                       [errorsModel]="formErrors.time_zone"></iq-form-control-custom-select>
        <iq-button class="block" type="submit" [busy$]="updateProfileAction$" label="Save changes"></iq-button>
        <iq-button class="block m-t-2x" label="Cancel" color="grey" [style]="'outline'" (onClick)="close()"></iq-button>
      </ng-container>
      <ng-template #loading="">
        <div class="m-t-8x m-b-8x">
          <loading-dots class="loading-dots--lg visible"></loading-dots>
        </div>
      </ng-template>
    </form>
  </div>
</div>
