import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { InviteRecommendation } from '@core/types';
import { IntersectionObserverService } from 'asap-team/asap-tools';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'invite-recommendations',
  templateUrl: './invite-recommendations.component.html',
  styleUrls: ['./invite-recommendations.component.scss'],
})
export class InviteRecommendationsComponent implements AfterViewInit {

  @Input() rows: InviteRecommendation[] = [];

  @Input() fetching: boolean;

  @Input() isLastRecommendationLoaded: boolean;

  @Output() loadMore: EventEmitter<void> = new EventEmitter();

  @Output() invite: EventEmitter<InviteRecommendation> = new EventEmitter();

  @ViewChild('ghost') ghost: ElementRef<HTMLInputElement>;

  constructor(
    private intersectionObserverService: IntersectionObserverService,
  ) {}

  ngAfterViewInit(): void {
    this.intersectionObserverService.create({
      target: this.ghost.nativeElement,
      isOnlyIntersecting: true,
    })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onLoadMore());
  }

  onLoadMore(): void {
    this.loadMore.emit();
  }

  onInvite(row: InviteRecommendation): void {
    this.invite.emit(row);
  }

}
