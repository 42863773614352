import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VaultService } from 'asap-team/asap-tools';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { RELEASE_NOTE_MODAL_DISPLAYED, ROUTE } from '@consts/consts';

@Component({
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
})
export class ReleaseNotesComponent extends SimpleModalComponent<null, boolean> {

  constructor(
    private vaultService: VaultService,
    private router: Router,
  ) {
    super();
  }

  async handleClose(): Promise<void> {
    this.vaultService.set(RELEASE_NOTE_MODAL_DISPLAYED, true);

    return this.close();
  }

  async handleRedirect(): Promise<void> {
    try {
      await this.handleClose();

      await this.router.navigate([ROUTE.alias.INTELLIGENCE_COLLEAGUES]);
    } catch (err) {
      console.log('handleRedirect ERR', err);
    }
  }

}
