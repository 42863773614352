<div class="counters" *ngIf="isLoadEnd else loading">
  <div class="counters__total"
       *ngIf="!!totalCounter || !!totalLeadsCounter">{{ (totalCounter || totalLeadsCounter) | number | nozero }}</div>
  <div class="counters__new" *ngIf="!!newLeadsCounter && !isNewCounterDot">+{{ newLeadsCounter | number }}</div>
  <div class="counters__new--dot" *ngIf="!!newLeadsCounter && isNewCounterDot"></div>
</div>
<ng-template #loading="">
  <div class="counters--loading">
    <loading-dots></loading-dots>
  </div>
</ng-template>
