import { Route } from '@angular/router';
import { RouteExtrasStateGuard } from 'asap-team/asap-tools';

// Consts
import { ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';

export const CHECKOUT: Route = {
  path: ROUTE.name.CHECKOUT,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, RouteExtrasStateGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard, RouteExtrasStateGuard],
  loadChildren: () => import('@modules/user/checkout/checkout.module').then((m: any) => m.CheckoutModule),
  data: { canBeActivatedBy: [USER_ROLE.agent, USER_ROLE.lender] },
};
