<div class="dp-container" (clickOutside)="hide()">
  <iq-button class="dp-toggle"
             label="{{ selected || title }}"
             size="40"
             color="grey"
             iconRight="#icon-chevron"
             (onClick)="toggle()"></iq-button>
  <div class="range-datepicker" [class.open]="isShow">
    <div class="dp-ranges">
      <iq-button *ngFor="let range of defaultDateRanges"
                 label="{{ range.name }}"
                 size="24"
                 color="grey"
                 [style]="'outline'"
                 classes="{{ range.active ? 'active' : '' }}"
                 (onClick)="onValueChange([range.date_from, range.date_to])"></iq-button>
    </div>
    
    <bs-daterangepicker-inline [bsValue]="bsInlineRangeValue"
                               [bsConfig]="bsConfig"
                               (bsValueChange)="onValueChange($event)"></bs-daterangepicker-inline>
    <div class="dp-ctrl m-2x">
      <iq-button label="Clear"
                 size="24"
                 color="grey"
                 [style]="'transparent'"
                 iconLeft="#icon-close"
                 (onClick)="cancel()"></iq-button>
    </div>
  </div>
</div>
