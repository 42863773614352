<bs-alert type="warning" (onClose)="closeAlert(type)">
  <div class="global-alert__container">
    <div class="global-alert__message">
      <img class="global-alert__message-icon" src="assets/common/alerts/alert-info-icon.svg"/>
      <div class="message-body">
        <span class="text-overflow">{{ message }}</span>
        <div class="btn-detail" [routerLink]="ROUTE.alias.BILLING">
          <iq-button label="Learn more" size="24"></iq-button>
        </div>
      </div>
    </div>
  </div>
</bs-alert>
