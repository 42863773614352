import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import type { Dictionary } from 'asap-team/asap-tools';
import { Store } from '@core/types/Store';

type RateValues = {
  max: number;
  max_default: number;
  min: number;
  min_default: number;
};

@UntilDestroy()
@Component({
  selector: 'value-range-filter',
  templateUrl: './value-range-filter.component.html',
  styleUrls: ['./value-range-filter.component.scss'],
})
export class ValueRangeFilterComponent implements OnInit, OnChanges {

  @Input() model: RateValues = null;

  @Input() provider: Store;

  options: Options = {};

  selected: string;

  defaults: Options = {
    hideLimitLabels: true,
    hidePointerLabels: true,
    floor: 0,
    ceil: 5,
    step: 0.25,
  };

  maxValue: number = 0;

  minValue: number = 0;

  ngOnInit(): void {
    this.subscribes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const model: RateValues = changes?.model?.currentValue;

    if (!model) { return; }

    if (!changes?.model?.previousValue) {
      this.maxValue = model.max || model.max_default;
      this.minValue = model.min || model.min_default;
    }

    this.options = {
      ...this.defaults,
      ceil: model.max || this.defaults.ceil,
      floor: model.min || this.defaults.floor,
    };
  }

  private subscribes(): void {
    this
      .provider
      .queryParams$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((filters: Dictionary<string>) => {
        if (!filters) { return; }

        if (!filters?.rate_from && !filters?.rate_to) {
          this.reset();
        }
      });
  }

  private reset(): void {
    this.selected = undefined;
    this.maxValue = this.model.max || this.model.max_default;
    this.minValue = this.model.min || this.model.min_default;
  }

  clear(): void {
    this.reset();
    this.provider.emitFilters({
      rate_from: '',
      rate_to: '',
    });
  }

  select({ value, highValue }: ChangeContext): void {
    if (this.selected === this.formatSelected(value, highValue)) { return; }

    this.selected = this.formatSelected(value, highValue);

    this.provider.emitFilters({
      rate_from: value.toString(),
      rate_to: highValue.toString(),
    });
  }

  formatSelected(value: number, highValue: number): string {
    return `${value}% - ${highValue}%`;
  }

}
