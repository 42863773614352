import type { Dictionary } from 'asap-team/asap-tools';

export const GOOGLE_STREET_ERROR: string = 'Wrong address or format. Select proper address from suggested options.';
export const REQUIRED_ERROR: string = 'This field is required';
export const PROMO_CODE_ERROR: string = 'Promo code not exists';
export const ERROR: Dictionary = {
  NOT_AN_ARRAY: 'Array expected',
  AUTH: 'Your authorization has been expired',
  LEAD_NOT_FOUND: 'Lead not found',
  SOMETHING_WENT_WRONG: 'Something went wrong',
};
