<div class="general-info">
  <ng-content select="[backBtnSlot]"></ng-content>
  <ng-content select="[inviteActionsSlot]"></ng-content>

  <ng-container *ngIf="generalInfo">
    <h4 class="info-title m-t-3x p-t-3x p-b-3x f-16-600 f-c-grey-main l-h-24">
      {{ type === 'lender' ? 'Loan Officer' : 'Agent' }} info
    </h4>
    <div class="agent-info">
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">Email</div>
        <div class="agent-info-col agent-info-col--value text-overflow pointer"
             [attr.title]="generalInfo?.email"
             ngxClipboard
             [cbContent]="generalInfo?.email || null"
             (click)="generalInfo?.email && copy();">
          {{ generalInfo?.email || '-' }}
        </div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">Phone</div>
        <div class="agent-info-col agent-info-col--value text-overflow pointer"
             ngxClipboard
             [cbContent]="generalInfo?.phone || null"
             (click)="generalInfo?.phone && copy();">
          {{ (generalInfo?.phone | phone) || '-' }}
        </div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">License</div>
        <div class="agent-info-col agent-info-col--value text-overflow">{{ generalInfo?.license_number || '-' }}</div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">
          {{ type === 'lender' ? 'Brokerage' : 'Company`'}}
        </div>
        <div class="agent-info-col agent-info-col--value">{{ generalInfo?.company_name || '-' }}</div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">
          {{ type === 'lender' ? 'Broker Lic.' : 'Company Lic.'}}
        </div>
        <div class="agent-info-col agent-info-col--value text-overflow">
          {{ generalInfo?.company_license_number || '-' }}
        </div>
      </div>

      <div *ngIf="type === 'agent'" class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">BA Comm.</div>
        <div class="agent-info-col agent-info-col--value text-overflow">
          <ng-container *ngIf="generalInfo?.commissions?.length && generalInfo?.commissions[0] as baComm; else baCommission">
            {{ baComm.value + '%' }}
          </ng-container>
          <ng-template #baCommission>2.5% (Average)</ng-template>
        </div>
      </div>
      <div *ngIf="type === 'agent'" class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">LA Comm.</div>
        <div class="agent-info-col agent-info-col--value text-overflow">
          <ng-container *ngIf="generalInfo?.commissions?.length && generalInfo?.commissions[1] as laComm; else laCommission">
            {{ laComm.value + '%' }}
          </ng-container>
          <ng-template #laCommission>2.5% (Average)</ng-template>
        </div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">Socials</div>
        <div class="agent-info-col agent-info-col--value text-overflow">
          <div class="socials__list">
            <a *ngIf="generalInfo?.socials?.facebook_url"
               class="socials__list--link"
               [href]="generalInfo?.socials?.facebook_url"
               target="_blank">
              <img src="assets/components/profile/socials/facebook.svg" alt="facebook-link">
            </a>
            <a *ngIf="generalInfo?.socials?.linkedin_url"
               class="socials__list--link"
               [href]="generalInfo?.socials?.linkedin_url"
               target="_blank">
              <img src="assets/components/profile/socials/linkedin.svg" alt="linkedin-link">
            </a>
            <a *ngIf="generalInfo?.socials?.instagram_url"
               class="socials__list--link"
               [href]="generalInfo?.socials?.instagram_url"
               target="_blank">
              <img src="assets/components/profile/socials/instagram.svg" alt="instagram-link">
            </a>
            <a *ngIf="generalInfo?.socials?.youtube_url"
               class="socials__list--link"
               [href]="generalInfo?.socials?.youtube_url"
               target="_blank">
              <img src="assets/components/profile/socials/youtube.svg" alt="youtube-link">
            </a>
            <a *ngIf="generalInfo?.socials?.zillow_url"
               class="socials__list--link"
               [href]="generalInfo?.socials?.zillow_url"
               target="_blank">
              <img src="assets/components/profile/socials/zillow.svg" alt="zillow-link">
            </a>
            <a *ngIf="generalInfo?.socials?.realtor_url"
               class="socials__list--link"
               [href]="generalInfo?.socials?.realtor_url"
               target="_blank">
              <img src="assets/components/profile/socials/realtor.svg" alt="realtor-link">
            </a>
            <a *ngIf="generalInfo?.socials?.personal_website_url"
               class="socials__list--link"
               [href]="generalInfo?.socials?.personal_website_url"
               target="_blank">
              <img src="assets/components/profile/socials/website.svg" alt="website-link">
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
