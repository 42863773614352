import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, merge, EMPTY } from 'rxjs';
import {
  filter,
  shareReplay,
  switchMap,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { VaultService } from 'asap-team/asap-tools';

import type { Profile } from '@core/types';

// Consts
import { PROFILE, PROFILE_HASH, USER_ROLE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { FirebaseService } from '@core/vendors/firebase/firebase.service';

@Injectable({ providedIn: 'root' })
export class ProfileHashService {

  private hash: BehaviorSubject<any> = new BehaviorSubject(this.vaultService.get(PROFILE_HASH) || null);

  private hash$: Observable<string> = this
    .hash
    .asObservable()
    .pipe(
      filter<string>(Boolean),
      distinctUntilChanged(),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  constructor(
    private vaultService: VaultService,
    private userService: UserService,
    private firebaseService: FirebaseService,
  ) {}

  emitProfileHashUpdate(hash: string): void {
    if (!hash) { return; }

    this.hash.next(hash);
  }

  startProfileHashObservation(): Observable<any> {
    const firebaseHash: Observable<string> = this.firebaseService.getProfileHash();

    return merge(
      this.hash$.pipe(
        map((hash: string) => {
          return { hash, requestProfile: false };
        }),
      ),
      firebaseHash.pipe(
        map((hash: string) => {
          return { hash, requestProfile: true };
        }),
      ),
    )
      .pipe(
        distinctUntilChanged((previous: any, current: any) => previous.hash === current.hash),
        switchMap(({ hash, requestProfile }: { hash: string; requestProfile: boolean }) => {
          this.persistProfileHash(hash);

          if (!requestProfile) { return EMPTY; }

          return this.requestProfile();
        }),
      );
  }

  private persistProfileHash(hash: string): void {
    const storedHash: string = this.vaultService.get<string>(PROFILE_HASH);

    if (storedHash !== hash || !storedHash) {
      this.vaultService.set(PROFILE_HASH, hash);
    }
  }

  private requestProfile(): Observable<any> {
    const storedProfile: Profile = this.vaultService.get<Profile>(PROFILE);

    if (!storedProfile) {
      return this.userService.getRawProfile();
    }

    if (storedProfile.role === USER_ROLE.sales
      || storedProfile.role === USER_ROLE.owner
      || storedProfile.role === USER_ROLE.marketing
    ) {
      return this.userService.getAdminProfile();
    }

    return this.userService.getRawProfile();
  }

}
