import type { Dictionary } from 'asap-team/asap-tools';
import type { LeadStatus, LeadType } from '@core/types';

export const LEAD_STATUS: Dictionary<LeadStatus> = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DRAFT: 'Draft',
  PENDING: 'Pending',
  DEACTIVATED: 'Deactivated',
  FAILED: 'Failed',
  UNSUBSCRIBED: 'Unsubscribed',
};

export const LEAD_TYPE: Dictionary<LeadType> = {
  SELLING: 'selling',
  REFINANCE: 'refinance',
};
