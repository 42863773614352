<div class="history-pending-lead" [class.new]="lead?.new">
  <collection-cell-home-info class="pending-lead" type="closings" [lead]="lead"></collection-cell-home-info>
  <form class="lead-form" [formGroup]="form" (ngSubmit)="import()">
    <div class="lead-form__group">
      <div class="lead-form__group-item">
        <iq-form-control-text placeholder="Name*"
                              formControlName="owner_name"
                              [errorsModel]="formErrors.owner_name"></iq-form-control-text>
      </div>
      <div class="lead-form__group-item">
        <iq-form-control-text placeholder="Email*"
                              formControlName="email"
                              [errorsModel]="formErrors.email"></iq-form-control-text>
      </div>
      <div class="lead-form__group-item">
        <iq-form-control-phone placeholder="Phone (optional)"
                               formControlName="phone"
                               [errorsModel]="formErrors.phone"></iq-form-control-phone>
      </div>
    </div>
    <div class="lead-form__actions flex flex-1">
      <iq-button class="import-btn flex-1"
                 type="submit"
                 label="Import"
                 size="40"
                 classes="flex-1"
                 [disabled]="form.pristine || form.invalid"
                 [busy$]="importAction$"></iq-button>
      <iq-button class="m-l-2x"
                 size="40"
                 color="grey"
                 iconLeft="#icon-close"
                 [busy$]="removeAction$"
                 (onClick)="removeLead()"></iq-button>
    </div>
  </form>
</div>
