<div class="stats-item">
  <div class="stats-item__info">
    <div class="f-14-400 f-c-slate l-h-22">{{ title }}</div>
    <div class="stats-item__count">
      <span class="f-14-600 l-h-22">{{ count || 0 }}</span>
      <div class="stats-item__percent">{{ percent | number:'1.0-0' }}%</div>
    </div>
  </div>
  <div class="stats-item__progress">
    <div [ngStyle]="{ backgroundColor: color, 'width': percent + '%' }"></div>
  </div>
</div>
