import { Component } from '@angular/core';

// Consts
import { ROUTE } from '@consts/consts';

@Component({
  selector: 'import-database-btn',
  templateUrl: './import-database-btn.component.html',
  styleUrls: ['./import-database-btn.component.scss'],
})
export class ImportDatabaseBtnComponent {

  ROUTE: any = ROUTE;

}
