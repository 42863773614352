import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Params } from '@angular/router';
import { isEmpty, pickBy, identity } from 'lodash-es';

@Component({
  selector: 'iq-clear-filters',
  templateUrl: './iq-clear-filters.component.html',
  styleUrls: ['./iq-clear-filters.component.scss'],
})
export class IqClearFiltersComponent implements OnChanges {

  @Input() size?: '24' | '40' = '40';

  @Input() borderStyle?: 'solid' | 'outline' = 'solid';

  @Input() stateValue: Params;

  @Output() clearFilters: EventEmitter<any> = new EventEmitter();

  isShow: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    const { stateValue } = changes;

    if (stateValue) {
      const filtersWithoutDefaultValue: Params = {
        ...stateValue.currentValue,
        date_range: stateValue.currentValue?.date_range === '12' ? null : stateValue.currentValue?.date_range,
      };

      this.isShow = !isEmpty(pickBy(filtersWithoutDefaultValue, identity));
    }
  }

  clear(): void {
    this.clearFilters.emit();
  }

}
