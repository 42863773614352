import {
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { getInitials } from 'asap-team/asap-tools';

@Component({
  selector: 'avatar-placeholder',
  templateUrl: './avatar-placeholder.component.html',
  styleUrls: ['./avatar-placeholder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarPlaceholderComponent {

  @Input() image: string;

  @Input() name: string;

  @Input() type: string;

  @Input() isOwner: string;

  @Input() bgColor: string;

  @Input() initialsColor: string;

  @Input() formattedInitials: string;

  getInitials: any = getInitials;

  get background(): any {
    return this.bgColor ? { background: this.bgColor } : null;
  }

  get fontColor(): any {
    return this.initialsColor ? { color: this.initialsColor } : null;
  }

}
