import { Component, Input } from '@angular/core';
import { Dictionary, ModalService } from 'asap-team/asap-tools';

// Consts
import { COLLECTION_PLACEHOLDER_COPY, PERMITTED_ACTION } from '@consts/consts';

// Components
import { VideoComponent } from '@commons/modals/video/video.component';

@Component({
  selector: 'collection-placeholder',
  templateUrl: './collection-placeholder.component.html',
  styleUrls: ['./collection-placeholder.component.scss'],
})
export class CollectionPlaceholderComponent {

  @Input() videoID: string = null;

  @Input() isEmpty: boolean = null;

  PERMITTED_ACTION: any = PERMITTED_ACTION;

  constructor(
    private modalService: ModalService,
  ) {}

  get copy(): Dictionary {
    return COLLECTION_PLACEHOLDER_COPY[this.isEmpty ? 'collection_empty' : 'not_subscribed'];
  }

  showVideo(): void {
    this.modalService.open(VideoComponent, { id: this.videoID });
  }

}
