import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { addressErrors, apiError, invalidPhone, required } from './conditions';

export const editLeadErrors: FormErrors = {
  address: [
    required,
    ...addressErrors,
  ],
  owner_name: [
    {
      name: 'maxlength',
      text: 'Max length is 40',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  email: [
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  phone: [
    invalidPhone,
    apiError(),
  ],
  global: [],
};
