import { NgModule } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterEvent,
  RouterModule,
  Routes,
} from '@angular/router';
import { ROUTE_EXTRAS_STATE_GUARD_CONFIG } from 'asap-team/asap-tools';
import { filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import posthog from 'posthog-js';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { PreloadingStrategyService } from '@core/helpers/preloading-strategy/preloading-strategy.service';

// Routes
import { ACCOUNT } from '@modules/admin/details/account/account.route';
import { USERS } from '@modules/admin/collections/users/users.route';
import { ADMIN_SETTINGS } from '@modules/admin/admin-settings/admin-settings.route';
import { ARCHIVED_LEADS } from '@modules/user/collections/archived-leads/archived-leads.route';
import { BUYERS } from '@modules/user/collections/buyers/buyers.route';
import { CHECKOUT } from '@modules/user/checkout/checkout.route';
import { CLIENT_ERROR } from '@modules/client-error/client-error.route';
import { CSV } from '@modules/admin/csv/csv.route';
import { HOMEOWNERS } from '@modules/user/collections/homeowners/homeowners.route';
import { PARTNERSHIP } from '@modules/user/collections/partnerships/partnerships.route';
import { HEALTH_METRICS } from '@modules/admin/collections/health-metrics/health-metrics.route';
import { BANNERS } from '@modules/admin/collections/banners/banners.route';
import { PROMO_CODES } from '@modules/admin/collections/promo-codes/promo-codes.route';
import {
  SIGN_IN,
  SIGN_UP,
  CONFIRM_EMAIL,
  RESTRICTED_ACCESS,
  FTL_AUTH_BY_UID,
} from '@modules/auth/auth.routes';
import { STAFF_USER } from '@modules/admin/details/staff-user/staff-user.route';
import { TRAINING } from '@modules/user/training/training.route';
import { REFERRAL } from '@modules/user/referral/referral.route';
import { UPGRADE_SUBSCRIPTION } from '@modules/user/upgrade-subscription/upgrade-subscription.route';
import { UPLOADS } from '@modules/admin/collections/uploads/uploads.route';
import { EMAIL_SUBSCRIPTION } from '@modules/email-subscription/email-subscription.route';
import { PARTNERS } from '@modules/admin/collections/partners/partners.route';
import { SETTINGS } from '@modules/user/settings/settings.route';
import { DASHBOARD } from '@modules/user/dashboard/dashboard.route';
import { ANALYTICS } from '@modules/user/collections/analytics/analytics.route';
import { MARKETING } from '@modules/user/marketing/marketing.route';
import { MAINTENANCE } from '@modules/maintenance/maintenance.route';

// **************

const routes: Routes = [
  // static
  SIGN_IN,
  SIGN_UP,
  CONFIRM_EMAIL,
  RESTRICTED_ACCESS,
  FTL_AUTH_BY_UID,
  MAINTENANCE,
  // lazy
  ACCOUNT,
  ADMIN_SETTINGS,
  ARCHIVED_LEADS,
  BANNERS,
  BUYERS,
  CHECKOUT,
  CLIENT_ERROR,
  CSV,
  EMAIL_SUBSCRIPTION,
  DASHBOARD,
  ANALYTICS,
  HOMEOWNERS,
  PARTNERS,
  PARTNERSHIP,
  PROMO_CODES,
  SETTINGS,
  STAFF_USER,
  TRAINING,
  MARKETING,
  REFERRAL,
  UPGRADE_SUBSCRIPTION,
  UPLOADS,
  USERS,
  HEALTH_METRICS,
  {
    path: ROUTE.EMPTY, redirectTo: ROUTE.alias.DASHBOARD, pathMatch: 'full',
  },
  { path: ROUTE.ANY, redirectTo: ROUTE.alias.DASHBOARD },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      preloadingStrategy: PreloadingStrategyService,
      enableTracing: false,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTE_EXTRAS_STATE_GUARD_CONFIG,
      useValue: { redirectTo: ROUTE.alias.DASHBOARD },
    },
  ],
})
export class AppRoutingModule {

  constructor(private router: Router) {
    if (environment.production) {
      this.router.events.pipe(
        filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd),
      ).subscribe({ next: () => posthog.capture('$pageview') });
    }
  }

}
