import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit, Input } from '@angular/core';

import type { Alert, Profile } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Utils
import { getSubscriptionMessage } from '@core/utils/utils';

// Services
import { UserService } from '@core/services/user/user.service';
import { AlertsService } from '@core/services/alerts/alerts.service';

@UntilDestroy()
@Component({
  templateUrl: './upgrade-subscription.component.html',
  styleUrls: ['../../alerts.component.scss'],
})
export class UpgradeSubscriptionComponent implements OnInit {

  @Input() type: Alert;

  ROUTE: any = ROUTE;

  message: string;

  constructor(
    private userService: UserService,
    private alertsService: AlertsService,
  ) {}

  ngOnInit(): void {
    this
      .userService
      .profile$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        ({ remaining_limits }: Profile) => { this.message = getSubscriptionMessage(remaining_limits); },
      );
  }

  closeAlert(type: Alert): void {
    this.alertsService.dismiss(type);
  }

}
