<div class="filter-dropdown" dropdown #rangeFilter="bs-dropdown" [insideClick]="true">
  <iq-button size="40" color="grey" iconRight="#icon-chevron" dropdownToggle>
    <span class="filter-dropdown__toggle--label" [class.selected]="selected">{{ title }}</span>
    <span class="filter-dropdown__toggle--values">{{ selected | titlecase }}</span>
  </iq-button>
  <div class="filter-dropdown__menu dropdown-menu" *dropdownMenu>
    <div class="filter-dropdown__menu--container range-slider">
      <h4 class="filter-dropdown__menu--title">Rate adjustment</h4>
      <div class="range-slider__label">
        <div class="range-slider__label--item">
          <span>FROM</span>
          <span class="value">{{ (minValue / 100) | percent:'1.2-2' }}</span>
        </div>
        <div class="range-slider__label--item">
          <span>TO</span>
          <span class="value">{{ (maxValue / 100) | percent:'1.2-2' }}</span>
        </div>
      </div>
      <ngx-slider [(value)]="minValue"
                  [(highValue)]="maxValue"
                  [options]="options"
                  (userChangeEnd)="select($event)"></ngx-slider>
      <div class="range-slider__clear" (click)="clear()">
        <i class="inline-svg inline-svg--16" [inlineSVG]="'#icon-close'"></i>
        <span>Clear</span>
      </div>
      <div class="filter-modal-close inline-svg" [inlineSVG]="'#icon-close'" (click)="rangeFilter.hide()"></div>
    </div>
  </div>
</div>
