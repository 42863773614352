<div class="wrapper text-left d-flex align-items-center">
  <iq-button
    [label]="title"
    size="40"
    color="grey"
    [style]="'solid'"
    (click)="checkbox.click()"
  ></iq-button>
  <label class="form-switch">
    <input class="form-switch__control"
           #checkbox
           type="checkbox"
           (change)="valueChanged($any($event))"
           [checked]="stateValue"/>
    <div class="form-switch__slider"></div>
    <div class="form-switch__bg"></div>
  </label>
</div>
