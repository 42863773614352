<div class="list">
  <div class="item" *ngFor="let commisionGroup of controls; let idx = index">
    <iq-form-control-commission [form]="commisionGroup"
                                [isBroadPrepend]="true"
                                [readonly]="isReadonly"></iq-form-control-commission>
    <div class="remove-btn" *ngIf="!isReadonly" (click)="removeControl(idx)">
      <div class="inline-svg" [inlineSVG]="'#icon-close'"></div>
    </div>
  </div>
</div>
<iq-button class="block"
           *ngIf="!isReadonly"
           label="Add fee"
           [style]="'outline'"
           [iconLeft]="addButtonIcon || null"
           (onClick)="addControl()"></iq-button>
