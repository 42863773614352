import { cloneDeep } from 'lodash-es';
import { Fees, Lead, LeadCommissions, LeadFeeSubtotal } from '@core/types';

type FeesSubItem = {
  name: string;
  group: string;
  buyer_amount: string;
  seller_amount: string;
  other_amount: string;
};

/**
 * Returns response with formatted User Fees and Lead Fees
 *
 */
export function remapCommissions(data: LeadCommissions | null): LeadCommissions | null {
  if (!data) { return null }

  return {
    ...cloneDeep(data),
    subtotal: data.commissions.reduce((previous: number, current: { value: number }) => previous + current.value, 0),
  };
}

function remapLeadFees(item: Fees): Fees {
  const subtotal: LeadFeeSubtotal = {
    other: 0,
    buyer: 0,
    seller: 0,
  };

  item.fees.forEach((subItem: FeesSubItem) => {
    if (subItem.other_amount) {
      subtotal.other += +subItem.other_amount;
    }

    if (subItem.buyer_amount) {
      subtotal.buyer += +subItem.buyer_amount;
    }

    if (subItem.seller_amount) {
      subtotal.seller += +subItem.seller_amount;
    }
  });

  return { ...item, subtotal };
}

export function formatFees(lead: Lead): Lead {
  return {
    ...lead,
    selling: {
      ...lead.selling,
      commissions: remapCommissions(lead.selling.commissions),
      fees: lead.selling?.fees?.map(remapLeadFees) || [],
    },
    refinance: {
      ...lead.refinance,
      ...lead.cash_out,
      commissions: remapCommissions(lead.refinance?.commissions || lead.cash_out?.commissions),
      fees: lead.refinance?.fees?.map(remapLeadFees) || lead.cash_out?.fees?.map(remapLeadFees) || [],
    },
  };
}
