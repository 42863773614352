// eslint-disable-next-line max-classes-per-file
import { NewUsersCollectionFilters, NewUsersCollectionSort } from '@modules/admin/collections/users/new/state/new-users.model';
import { Company } from '@core/types';

export namespace NewUsersCollectionActions {

  export class GetNewUsersCollection {

    static get type(): string {
      return '[NewUsersCollection] Get NewUsers Collection';
    }

    constructor(
      public firstLoad?: boolean,
    ) {
    }

  }

  export class ReloadNewUsersCollection {

    static get type(): string {
      return '[NewUsersCollection] Reload NewUsers Collection';
    }

  }

  export class ResetNewUsersCollection {

    static get type(): string {
      return '[NewUsersCollection] Reset NewUsers Collection';
    }

  }

  export class EmitChangePageNewUsersCollection {

    static get type(): string {
      return '[NewUsersCollection] Emit Change Page Number NewUsers Collection';
    }

    constructor(public offset: number) {
    }

  }

  export class EmitSortNewUsersCollection {

    static get type(): string {
      return '[NewUsersCollection] Emit Sort NewUsers Collection';
    }

    constructor(public payload: NewUsersCollectionSort) {
    }

  }

  export class EmitFiltersUpdateNewUsersCollection {

    static get type(): string {
      return '[NewUsersCollection] Emit Filters Update NewUsers Collection';
    }

    constructor(public payload: Partial<NewUsersCollectionFilters>) {
    }

  }

  export class EmitClearFiltersNewUsersCollection {

    static get type(): string {
      return '[NewUsersCollection] Emit Clear Filters NewUsers Collection';
    }

  }

  export class SetCompanyState {

    static get type(): string {
      return '[NewUsersCollection] Set company state';
    }

    constructor(
      public company: Company,
    ) {
    }

  }

}
