<form class="manual-address" [formGroup]="form">
  <iq-form-control-text class="alternative-label  m-b-2x"
                        label="Street Address*"
                        placeholder="Street number and name"
                        formControlName="street"
                        [errorsModel]="formErrors.street"></iq-form-control-text>
  <iq-form-control-text class="alternative-label  m-b-2x"
                        label="Unit / Apt."
                        placeholder="Unit / Apt."
                        formControlName="unit"
                        [errorsModel]="formErrors.unit"></iq-form-control-text>
  <iq-form-control-text class="alternative-label m-b-2x"
                        label="City*"
                        placeholder="City"
                        formControlName="city"
                        [errorsModel]="formErrors.city"></iq-form-control-text>
  <div class="manual-address__row">
    <iq-form-control-custom-select class="alternative-label m-b-2x manual-address__item"
                                   label="State*"
                                   placeholder="Select state"
                                   filterPlaceholder="Type state"
                                   formControlName="state"
                                   [displayCode]="true"
                                   [model]="states"
                                   [errorsModel]="formErrors.state"></iq-form-control-custom-select>
    <iq-form-control-text class="alternative-label m-b-2x manual-address__item"
                          label="Zip*"
                          placeholder="ZIP"
                          formControlName="zip"
                          [errorsModel]="formErrors.zip"></iq-form-control-text>
  </div>
</form>
