import { Component, Input } from '@angular/core';

@Component({
  selector: 'total-counter',
  templateUrl: './total-counter.component.html',
  styleUrls: ['./total-counter.component.scss'],
})
export class TotalCounterComponent {

  @Input() loading: boolean;

  @Input() total: number;

}
