import { Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'back-btn',
  templateUrl: './back-btn.component.html',
  styleUrls: ['./back-btn.component.scss'],
})
export class BackBtnComponent {

  @Input() label: string;

  @Input() link: string;

  @Input() queryParams: Params = null;

  @Input() busy$: Subscription;

  constructor(
    private router: Router,
    private location: Location,
  ) {}

  goBack(): void {
    if (this.link) {
      this.router.navigate([this.link], { queryParams: this.queryParams });
    } else {
      this.location.back();
    }
  }

}
