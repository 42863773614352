import type { NavItem } from '@core/types';

import { ROUTE, USER_ROLE, PERMITTED_ACTION } from '@consts/consts';

export const AdminSettings: NavItem[] = [
  {
    label: 'Profile',
    routerLink: ROUTE.alias.ADMIN_SETTINGS_PROFILE,
    counter: null,
    roles: [USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.customer_success],
    permitted_action: PERMITTED_ACTION.STAFF_PROFILE_MANAGE,
    icon: 'account_circle',
  },
];
