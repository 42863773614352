import { SimpleModalOptions } from 'ngx-simple-modal';

import type { Dictionary } from 'asap-team/asap-tools';
import type { ConfirmActionModalCopy } from '@core/types';

export const COMMON_MODAL_TYPE: Dictionary = {
  EXIT: 'exit',
  CANCEL_INVOICE: 'cancel_invoice',
  UPGRADE_PLAN: 'upgrade_plan',
  NOT_HAVE_LIMIT: 'not_have_limit',
  DELETE_LEAD: 'delete_lead',
  DELETE_REPORT: 'delete_report',
  DELETE_ACCOUNT: 'delete_account',
  DELETE_BANNER: 'delete_banner',
  DELETE_DATABASE: 'delete_database',
  DELETE_LAST_IMPORT: 'delete_last_import',
  DISABLE_ACCOUNT: 'disable_account',
  MONTHLY_FEE: 'monthly_fee',
  CONTACTS_FEE: 'contacts_fee',
  INVITE_ACCEPT: 'invite_accept',
  UPGRADE_PLAN_ON_IMPORT: 'upgrade_plan_on_import',
  BREAK_PARTNERSHIP: 'break_partnership',
};

export const ORDER_MODAL_TYPE: Dictionary = {
  EDIT: 'Edit',
  NEW: 'New',
};

export const PAYMENT_METHODS_MODAL_RESULT: any = {
  EVENT: {
    ADD_CARD: {
      SUCCESS: 'ADD_CARD_SUCCESS',
      ERROR: 'ADD_CARD_ERROR',
    },
    DELETE_CARD: {
      SUCCESS: 'DELETE_CARD_SUCCESS',
      ERROR: 'DELETE_CARD_ERROR',
    },
    UPDATE_CARD: {
      SUCCESS: 'UPDATE_CARD_SUCCESS',
      ERROR: 'UPDATE_CARD_ERROR',
    },
    SHOW_ADD_CARD: 'SHOW_ADD_CARD',
    SHOW_EDIT_CARD: 'SHOW_EDIT_CARD',
    SHOW_DELETE_CARD: 'SHOW_DELETE_CARD',
  },
};

export const defaultModalOptions: SimpleModalOptions = {
  bodyClass: 'modal-open',
  closeOnEscape: true,
  closeOnClickOutside: true,
  wrapperDefaultClasses: 'modal fade-anim',
  wrapperClass: 'in',
  animationDuration: 200,
  autoFocus: true,
  draggableClass: null,
  draggable: false,
};

export const TOP_MODAL_CONFIG: SimpleModalOptions = {
  ...defaultModalOptions,
  wrapperDefaultClasses: 'modal fade-anim modal-top',

};

export const MODAL_CONFIG_WITHOUT_FOCUS: SimpleModalOptions = {
  ...defaultModalOptions,
  autoFocus: false,
};

export const SIDEBAR_CONFIG: SimpleModalOptions = {
  ...defaultModalOptions,
  wrapperDefaultClasses: 'modal fade-anim modal-sidebar',
  autoFocus: false,
};

export const CONFIRM_ACTION_MODAL_COPY: Dictionary<ConfirmActionModalCopy> = {
  EXIT_FORM: {
    icon: null,
    type: COMMON_MODAL_TYPE.EXIT,
    title: 'You have entered new data on this page.',
    subtitle: null,
    body: 'If you navigate away from this page without first saving your data, the changes will be lost.',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'OK',
      },
    },
  },
  CANCEL_INVOICE: {
    icon: null,
    type: COMMON_MODAL_TYPE.CANCEL_INVOICE,
    title: 'Cancel Invoice',
    subtitle: null,
    body: 'Are you sure you want to cancel this invoice?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'No, keep invoice',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Cancel Invoice',
      },
    },
  },
  FINALIZE_INVOICE: {
    icon: null,
    type: COMMON_MODAL_TYPE.CANCEL_INVOICE,
    title: 'Finalize Invoice',
    subtitle: null,
    body: 'Are you sure you want to finalize this invoice?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'No',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Finalize Invoice',
      },
    },
  },
  UPGRADE_PLAN_ON_IMPORT: {
    icon: null,
    type: COMMON_MODAL_TYPE.UPGRADE_PLAN_ON_IMPORT,
    title: 'Upgrade plan',
    subtitle: null,
    body: 'Are you sure you want to upgrade plan for this user?',
    actions: {
      dismiss: {
        style: 'outline',
        color: 'blue',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Upgrade',
      },
    },
  },
  DELETE_LEAD: {
    icon: null,
    type: COMMON_MODAL_TYPE.DELETE_LEAD,
    title: 'Delete This Lead?',
    subtitle: null,
    body: 'You deleting the lead. All data inside will be lost forever. \nAre you sure?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete Lead',
      },
    },
  },
  DELETE_REPORT: {
    icon: null,
    type: COMMON_MODAL_TYPE.DELETE_REPORT,
    title: 'Delete this report?',
    subtitle: null,
    body: 'This report will be deleted from your account.',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete report',
      },
    },
  },
  DELETE_ACCOUNT: {
    icon: null,
    type: COMMON_MODAL_TYPE.DELETE_ACCOUNT,
    title: 'Delete Account?',
    subtitle: null,
    body: 'You deleting the account. All data inside will be lost forever. \n Are you sure?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete Account',
      },
    },
  },
  DISABLE_ACCOUNT: {
    icon: null,
    type: COMMON_MODAL_TYPE.DISABLE_ACCOUNT,
    title: 'Disable Account?',
    subtitle: null,
    body: 'Are you sure you want to disable this account?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Disable account',
      },
    },
  },
  DELETE_DATABASE: {
    icon: null,
    type: COMMON_MODAL_TYPE.DELETE_DATABASE,
    title: 'Delete Entire User’s Database?',
    subtitle: null,
    body: 'You are going to delete all user’s reports. Are you sure?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete Database',
      },
    },
  },
  DELETE_LAST_IMPORT: {
    icon: null,
    type: COMMON_MODAL_TYPE.DELETE_LAST_IMPORT,
    title: 'Delete Leads from the import?',
    subtitle: null,
    body: 'You are going to delete all leads from the latest import. Are you sure?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete Leads',
      },
    },
  },
  MONTH_FEE: {
    icon: null,
    type: COMMON_MODAL_TYPE.MONTHLY_FEE,
    title: 'Monthly Platform Fee',
    subtitle: null,
    body: '',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: false,
        label: 'Delete',
      },
    },
  },
  CONTACTS_FEE: {
    icon: null,
    type: COMMON_MODAL_TYPE.CONTACTS_FEE,
    title: 'Contacts Fee',
    subtitle: null,
    body: '',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: false,
        label: 'Delete',
      },
    },
  },
  EMAIL_TEMPLATE_RESTORE_DEFAULTS: {
    icon: null,
    type: '',
    title: 'Restore defaults?',
    subtitle: null,
    body: `
    Are you sure you want to restore default text?
    All your text will be lost.Are you sure you want to disable this account?
  `,
    actions: {
      dismiss: {
        style: 'outline',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Restore Defaults',
      },
    },
  },
  INVITE_PARTNER: {
    icon: null,
    type: COMMON_MODAL_TYPE.INVITE_ACCEPT,
    title: 'Accept Invitation',
    subtitle: null,
    body: 'If you accept this invitation, all other invitations will be rejected, and this loan officer will become your permanent.',
    actions: {
      dismiss: {
        style: 'outline',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Accept',
      },
    },
  },
  BREAK_PARTNERSHIP: {
    icon: null,
    type: COMMON_MODAL_TYPE.BREAK_PARTNERSHIP,
    title: 'Important notice before disconnecting partnership',
    subtitle: null,
    body: '',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Stay connected',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Disconnect agent',
      },
    },
  },
  DELETE_BANNER: {
    icon: null,
    type: COMMON_MODAL_TYPE.DELETE_BANNER,
    title: 'Delete banner',
    subtitle: null,
    body: 'Are you sure you want to delete banner?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete',
      },
    },
  },
  MARKETING_PROMOTE_SUCCESS: {
    icon: null,
    type: '',
    title: 'Your request has been sent',
    subtitle: null,
    body: 'Thank you for requesting more information on social marketing from MediaBoost.',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Close',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: false,
        label: 'Cancel Invoice',
      },
    },
  },
  MARKETING_PROMOTE_LENDERS_SUCCESS: {
    icon: null,
    type: '',
    title: 'Your request has been sent',
    subtitle: null,
    body: 'Thank you for the request. Our partner professional will contact you soon.',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Got it',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: false,
        label: 'Cancel Invoice',
      },
    },
  },
  DELETE_STAFF_USER: {
    icon: null,
    type: '',
    title: 'Delete user',
    subtitle: null,
    body: 'Are you sure you want to delete user?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete',
      },
    },
  },
  BLOCK_USER: {
    icon: null,
    type: '',
    title: 'Block User',
    subtitle: null,
    body: `
    If you manually block the user, then when he logs in, he will see the lock screen and will not be able to see the interface. The auto-update of his active reports will be stopped.<br/>
    Everything related to the partnership will remain untouched. The reports of his partners will work.
  `,
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Dismiss',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Block User',
      },
    },
  },
  UNBLOCK_USER: {
    icon: null,
    type: '',
    title: 'Unblock User',
    subtitle: null,
    body: 'Are you sure you want to unblock this user? This will allow the user access to the system even if he has not paid for the subscription.',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Dismiss',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Unlock User',
      },
    },
  },
  DELETE_LOAN: {
    icon: null,
    type: '',
    title: 'Delete loan',
    subtitle: null,
    body: 'Are you sure you want to delete a loan?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete',
      },
    },
  },
  DEACTIVATE_HOME_REPORT: {
    icon: null,
    type: '',
    title: 'Deactivate 𝘮𝘺HomeIQ Report',
    subtitle: null,
    body: `Once the report is deactivated, we will stop updating the data and
           the homeowner will no longer receive emails or sms to view the report.`,
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep Active',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Deactivate',
      },
    },
  },
  DEACTIVATE_MULTIPLE_HOME_REPORTS: {
    icon: null,
    type: '',
    title: 'Deactivate 𝘮𝘺HomeIQ Reports',
    subtitle: null,
    body: `Once the reports are deactivated, we will stop updating the data and
           the homeowners will no longer receive emails or sms to view the reports.`,
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep Active',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Deactivate',
      },
    },
  },
  ACTIVATE_HOME_REPORT: {
    icon: null,
    type: '',
    title: 'Activate 𝘮𝘺HomeIQ Reports',
    subtitle: null,
    body: `Once you activate the report, we will update the data and
           the homeowner will receive an email or sms to view the report.`,
    actions: {
      dismiss: {
        style: 'outline',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Activate',
      },
    },
  },
  ACTIVATE_MULTIPLE_HOME_REPORTS: {
    icon: null,
    type: '',
    title: 'Activate 𝘮𝘺HomeIQ Reports',
    subtitle: null,
    body: `Once you activate the reports, we will update the data and
           the homeowners will receive emails or sms to view the reports.`,
    actions: {
      dismiss: {
        style: 'outline',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Activate',
      },
    },
  },
  SEND_MULTIPLE_HOME_REPORTS: {
    icon: null,
    type: '',
    title: 'Email 𝘮𝘺HomeIQ Reports',
    subtitle: null,
    body: 'The homeowners will receive emails or sms to view the reports.',
    actions: {
      dismiss: {
        style: 'outline',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Send',
      },
    },
  },
  ARCHIVE_MULTIPLE_HOME_REPORTS: {
    icon: null,
    type: '',
    title: 'Archive 𝘮𝘺HomeIQ Reports',
    subtitle: null,
    body: 'Once the reports are deactivated, we will stop updating the data and the homeowners will no longer receive emails or sms.',
    actions: {
      dismiss: {
        style: 'outline',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Archive',
      },
    },
  },
  INTEGRATION_ILIST_CONFIRM: {
    icon: null,
    type: '',
    title: 'Security Alert',
    subtitle: null,
    body: `
      <img class="m-b-1x" src="assets/components/integrations/warning_amber_24px.svg" alt="warning" >
      <h4 class="m-b-1x f-16-bold">The current connections to the iList system will be severed. Are you sure?</h4>
      <p class="f-16-normal">By generating a new key, the old key will immediately stop working and the connection will be broken. The connection will resume after you add the new homeIQ key to the iList system.</p>
    `,
    actions: {
      dismiss: {
        style: 'solid',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Generate key',
      },
    },
  },
  INTEGRATION_ILIST_CONFIRM_DISABLING: {
    icon: null,
    type: '',
    title: 'Security Alert',
    subtitle: null,
    body: `
      <img class="m-b-1x" src="assets/components/integrations/warning_amber_24px.svg" alt="warning" >
      <h4 class="m-b-1x f-16-bold">The current connections to the iList system will be severed. Are you sure?</h4>
      <p class="f-16-normal">By generating a new key, the old key will immediately stop working and the connection will be broken. The connection will resume after you add the new homeIQ key to the iList system.</p>
    `,
    actions: {
      dismiss: {
        style: 'solid',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Disconnect',
      },
    },
  },
  INTEGRATION_PREAPPROVE_CONFIRM: {
    icon: null,
    type: '',
    title: 'Security Alert',
    subtitle: null,
    body: `
      <img class="m-b-1x" src="assets/components/integrations/warning_amber_24px.svg">
      <h4 class="m-b-1x f-16-bold">The current connections to the Pre-Approve Me system will be severed. Are you sure?</h4>
      <p class="f-16-normal">By generating a new key, the old key will immediately stop working and the connection will be broken. The connection will resume after you add the new homeIQ key to the Pre-Approve Me system.</p>
    `,
    actions: {
      dismiss: {
        style: 'solid',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Generate key',
      },
    },
  },
  INTEGRATION_PREAPPROVE_CONFIRM_DISABLING: {
    icon: null,
    type: '',
    title: 'Security Alert',
    subtitle: null,
    body: `
      <img class="m-b-1x" src="assets/components/integrations/warning_amber_24px.svg">
      <h4 class="m-b-1x f-16-bold">The current connections to the Pre-Approve Me system will be severed. Are you sure?</h4>
    `,
    actions: {
      dismiss: {
        style: 'solid',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Disconnect',
      },
    },
  },
  INTEGRATION_ZAPIER_CONFIRM: {
    icon: null,
    type: '',
    title: 'Security Alert',
    subtitle: null,
    body: `
      <div class="row no-gutters align-items-center justify-content-center" >
        <div class="col-auto m-r-8">
            <img src="assets/components/integrations/shield-pic.svg">
        </div>
        <div class="col-auto text-left">
          <img width="52" src="assets/common/forms/form-components/user-form/zapier_logo.svg">
          <br/>
          <span class="f-16-bold">Integrations</span>
        </div>
      </div>

      <h4 class="m-t-3x m-b-1x f-16-bold">
        You have active Zaps connected
        <br />
        with your homeIQ account.
      </h4>
      <p class="f-16-normal">Do you really want to disconnect Zapier?</p>
    `,
    actions: {
      dismiss: {
        class: 'order-2',
        style: 'outline',
        color: 'grey',
        is_show: true,
        label: 'Keep connected',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Disconnect',
      },
    },
  },
  CREATE_REPORT_LIMIT_REACHED_TRIAL: {
    icon: null,
    type: '',
    title: 'Upgrade subscription',
    subtitle: null,
    body: 'Creating reports only available on paid plans. Upgrade your subscription to get full access.',
    actions: {
      dismiss: {
        style: 'outline',
        color: 'grey',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Upgrade',
      },
    },
  },
  INTELLIGENCE_COVERAGE: {
    icon: null,
    type: '',
    title: 'Intelligence coverage',
    subtitle: null,
    body: `
      <div class="image-full-width">
        <img class="m-b-3x" src="assets/components/intelligence/coverage.svg"/>
        <p class="m-b-1x p-l-5x p-r-5x text-left">
          While myhomeIQ Intelligence provides 80% coverage across the US, there are some states that may not be fully covered:
        </p>
        <p class="m-b-1x p-l-5x p-r-5x text-left">States with no coverage AK, DE, MD, ND, NE, NM, SD, UT, VT, WY.</p>
        <p class="p-l-5x p-r-5x text-left">There may also be coverage limitations across other states.</p>
      </div>
    `,
    actions: {
      dismiss: {
        style: 'outline',
        color: 'grey',
        is_show: false,
        label: '',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'blue',
        is_show: false,
        label: '',
      },
    },
  },
};

export const PARTNERSHIP_SIDEBAR_RESULT: Dictionary = {
  manual: 'manual',
  update: 'update',
};

export const USER_CARD_SIDEBAR_TYPE: Dictionary = {
  invite: 'invite',
  member: 'member',
  partner: 'partner',
  engaged: 'engaged',
  outgoing: 'outgoing',
  incoming: 'incoming',
};

export const USER_CARD_SIDEBAR_RESULT: Dictionary = {
  unsetPrimary: 'unsetPrimary',
  changePrimary: 'changePrimary',
  sentInvitation: 'sentInvitation',
  cancelInvitation: 'cancelInvitation',
  acceptInvitation: 'acceptInvitation',
  rejectInvitation: 'rejectInvitation',
  breakPartnership: 'breakPartnership',
};

export const BANNER_MODAL_TYPE: Dictionary = {
  BUYER: 'buyer',
  HOMEOWNERS: 'homeowners',
};

export const SIDEBAR_TYPE: Dictionary = {
  LOAN: 'loan',
  LEAD: 'lead',
  HOME: 'home',
  HOME_VALUE: 'home_value',
};
