<div class="agent-invited">

  <h2 class="ftl-title m-b-5x text-center">Your account is ready!</h2>
  <ng-container *ngIf="ftlStepData$ | async as data">
    <div class="partner-frame">
      <div class="partnership-avatars">
        <avatar-placeholder class="agent"
                            [image]="data?.agent_avatar_url"
                            [name]="data?.agent_initials"
                            bgColor="#d8e1e8"
                            initialsColor="#fff"></avatar-placeholder>
        <avatar-placeholder class="lender"
                            [image]="data?.lender_avatar_url"
                            [name]="data?.lender_initials"
                            bgColor="#d8e1e8"
                            initialsColor="#fff"></avatar-placeholder>
      </div>
      <img src="assets/components/ftl/agent_invited_frame.svg" alt="agent invited frame" width="197" height="186">
      <div class="partner-name">
        {{ getFormattedName(data?.lender_name) }}
      </div>
    </div>
  </ng-container>

  <h3 class="comments-title m-b-3x">Click below to proceed</h3>
  <p class="ftl-regular-text m-b-3x text-center">
    Hit a button below to express your gratitude for this collaboration, and gain immediate access to your myhomeIQ account!
  </p>

  <ng-container *ngIf="(ftlStepData$ | async)?.comments as comments">
    <particles></particles>
    <div class="comments">
      <span *ngFor="let comment of comments | slice:0:6" class="comments-item"
            (click)="submitAgentInvited(comment.code)">
        {{ comment?.title }}
      </span>
    </div>
    <a class="skip-btn" type="button" (click)="skipStep()">
      Skip and access your account
      <i class="row__counters-icon inline-svg" [inlineSVG]="'#icon-arrow-forward-small'"></i>
    </a>
  </ng-container>
</div>
