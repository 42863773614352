<div class="placeholder">
  <ng-container *ngIf="isAgentTrial || isAgentSoloPlan; else contactSupportState">
    <p class="placeholder__title f-32-bold">
      Invite your loan officer and get access to high converting seller funnels
    </p>
    <p class="placeholder__subtitle f-16-normal">
      There is no cost to you for unlocking your homeIQ account, simply invite the lender you work and gain access to all the premium features homeIQ has to offer.
    </p>
    <div class="placeholder__buttons">
      <iq-button [label]="'Invite loan officer'"
                 [routerLink]="[ROUTE.alias.PARTNERSHIP]"
                 [queryParams]="{ 'invite_sidebar': true }"></iq-button>
      <iq-button [label]="'Get invitation link'"
                 [style]="'outline'"
                 (click)="showGetLinkModal()"></iq-button>
    </div>
  </ng-container>
  
  <ng-template #contactSupportState>
    <p class="placeholder__title f-32-bold">
      Use your branded landing pages to generate leads
    </p>
    <p class="placeholder__subtitle f-16-normal">
      homeIQ offers a proven formula to generate and nurture your online refi and homeowner leads. Click the video below to see how it works.
    </p>
    <div class="placeholder__buttons">
      <ng-container *ngIf="isContactSupportState; else connectedAgentState">
        <a href="mailto:support@myhomeiq.com">
          <iq-button label="Contact support"></iq-button>
        </a>
      </ng-container>
      
      <ng-template #connectedAgentState>
        <ng-container *ngIf="isAgentConnected; else pausedSubState">
          <iq-button [label]="'Customize landing page'" [routerLink]="ROUTE.alias.HOME_VALUE_LANDING"></iq-button>
          <a class="btn" [href]="lenderLendingUrl" target="_blank">
            <iq-button [label]="'View your landing page'" [style]="'outline'"></iq-button>
          </a>
        </ng-container>
        
        <ng-template #pausedSubState>
          <ng-container *ngIf="isLenderSubIsPaused; else defaultLandingActions">
            <iq-button [label]="'Renew subscription'" [routerLink]="ROUTE.alias.BILLING"></iq-button>
          </ng-container>
          
          <ng-template #defaultLandingActions>
            <iq-button class="placeholder__button"
                       [label]="'View home wealth page'"
                       [style]="'outline'"
                       (onClick)="goToHomeWealthLanding()"
                       classes="w-100"></iq-button>
            <iq-button class="placeholder__button"
                       [label]="'View home value page'"
                       [style]="'outline'"
                       (click)="goToHomeValueLanding()"
                       classes="w-100"></iq-button>
          </ng-template>
        </ng-template>
      </ng-template>
    </div>
  </ng-template>
</div>

<div class="placeholder__image">
  <img src="assets/components/collections/traffic/traffic-placeholder-2.png"/>
  <button class="placeholder__image--button" (click)="showVideoModal()">
    <img src="assets/components/collections/traffic/play-button.svg"/>
  </button>
</div>
