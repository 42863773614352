import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Router, UrlTree } from '@angular/router';
import { take, map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { AuthToken, RedirectInfo } from '@core/types';

// Consts
import { LAST_ROUTING_STATE, ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { VaultService } from 'asap-team/asap-tools';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private userService: UserService,
    private vaultService: VaultService,
  ) { }

  private doCheck(): Observable<boolean | UrlTree> {

    return this
      .userService
      .token$
      .pipe(
        take(1),
        map(({ authorized }: AuthToken) => {
          // Store the attempted URL for redirecting after successful login
          if (!authorized) {
            const url: UrlTree = this.router.getCurrentNavigation()?.extractedUrl;
            const { queryParams } = url;

            const attemptedUrl: string = url ? url.toString() : '';

            if (attemptedUrl) {
              const redirectInfo: RedirectInfo = {
                path: attemptedUrl.split('?')[0],
                queryParams,
              };

              this.vaultService.set(LAST_ROUTING_STATE, redirectInfo);
            }

            this.userService.logout(false);
          }

          return authorized || this.router.parseUrl(ROUTE.alias.SIGN_IN);
        }),
      );
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.doCheck();
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.doCheck();
  }

}
