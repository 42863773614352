import { Component, Input } from '@angular/core';
import { Dictionary } from 'asap-team/asap-tools';

import type { UserRole } from '@core/types';

// Consts
import { FTL_SIDEBAR_ILLUSTRATION_TEMPLATE, USER_ROLE } from '@consts/consts';

@Component({
  selector: 'ftl-sidebar',
  templateUrl: './ftl-illustrations.component.html',
  styleUrls: ['./ftl-illustrations.component.scss'],
})
export class FtlIllustrationsComponent {

  @Input() name: string = '';

  TEMPLATE: Dictionary = FTL_SIDEBAR_ILLUSTRATION_TEMPLATE;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  isCurrentTemplate(name: string): boolean {
    if (!this.name) { return false; }

    return this.name === name;
  }

}
