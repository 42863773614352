import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Profile } from '@core/types';

// Const
import { ROUTE, USER_ROLE } from '@consts/consts';

// Services
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { UserService } from '@core/services/user/user.service';

// State
import { FTLActions } from '@modules/auth/sign-up/ftl/state/ftl.actions';
import { FTLStepName } from '@modules/auth/sign-up/ftl/state/ftl.model';
import { FtlState } from '@modules/auth/sign-up/ftl/state/ftl.state';

@UntilDestroy()
@Component({
  selector: 'all-set',
  templateUrl: './all-set.component.html',
  styleUrls: ['../ftl.component.scss', './all-set.component.scss'],
})
export class AllSetComponent implements OnInit {

  @Select(FtlState.steps) steps$: Observable<FTLStepName[]>;

  FTLStepName: typeof FTLStepName = FTLStepName;

  constructor(
    private router: Router,
    private store: Store,
    private userService: UserService,
    private asaplyticsService: AsaplyticsService,
  ) {}

  ngOnInit(): void {
    this
      .userService
      .profile$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((profile: Profile) => {
        if (profile.registration_completed) {
          if (this.userService.getUserRole() === USER_ROLE.LENDER) {
            this.router.navigate([ROUTE.alias.DASHBOARD]);
          } else {
            this.router.navigate([ROUTE.alias.DASHBOARD_PRODUCTION]);
          }

          return;
        }

        if (this.store.selectSnapshot(FtlState.steps).includes(FTLStepName.agent_invited)) {
          this.submitAllSetStep();
        } else {
          setTimeout(() => {
            this.submitAllSetStep();
          }, 4500);
        }
      });
  }

  submitAllSetStep(): void {
    this.store.dispatch(new FTLActions.SubmitFtlStepAction(FTLStepName.all_set))
      .subscribe(() => {
        if (this.userService.getUserRole() === USER_ROLE.LENDER) {
          this.asaplyticsService.sendEvent({ action: 'view_success_step_lender', category: 'ftl_lender_2024' });
          this.router.navigate([ROUTE.alias.DASHBOARD]);

          return;
        }

        if (this.userService.getUserRole() === USER_ROLE.AGENT) {
          this.asaplyticsService.sendEvent({ action: 'view_success_step_agent', category: 'ftl_agent_2024' });
        }

        this.router.navigate([ROUTE.alias.DASHBOARD_PRODUCTION]);
      });
  }

}
