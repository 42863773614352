<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Checkout</h4>
    <div class="close" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <img class="modal-body__icon" src="assets/common/dialog/credit-card-cancel.svg"/>
    <div class="modal-body__title">We were not able to complete your payment</div>
    <div class="modal-body__text">{{ error }}</div>
  </div>
  <div class="modal-footer text-center">
    <iq-button label="Try again" (onClick)="confirm()"></iq-button>
  </div>
</div>
