<div class="control" [formGroup]="form" [class.wide-prepend]="!readonlyTitle && !isBroadPrepend" [class.broad-prepend]="isBroadPrepend">
  <div class="control__prepend"
       [class.control__prepend--focus]="titleFocus"
       [class.control__prepend--invalid]="form.controls.title.invalid && form.controls.title.dirty"
       [class.control__prepend--readonly]="readonly"
       [class.control__prepend--readonly-title]="readonlyTitle">
    <input class="control__input"
           type="text"
           formControlName="title"
           data-lpignore="true"
           [readonly]="readonly || readonlyTitle"
           (focus)="focus('titleFocus')"
           (blur)="blur()"/>
  </div>
  <div class="control__content"
       [class.control__content--focus]="valueFocus"
       [class.control__content--invalid]="form.controls.value.invalid && form.controls.value.dirty"
       [class.control__content--readonly]="readonly"
       [class.control__content--readonly-title]="readonlyTitle">
    <div class="control__content__control-wrapper flex-center">
      <div class="flex-1 h-100">
        <input class="control__input p-r-0"
               *ngIf="isShowPercent"
               type="text"
               formControlName="value"
               data-lpignore="true"
               placeholder="%"
               suffix="%"
               mask="separator.4"
               separatorLimit="10"
               [dropSpecialCharacters]="false"
               [readonly]="readonly"
               (focus)="focus('valueFocus')"
               (blur)="blur()"/>
        <input class="control__input p-r-0"
               *ngIf="!isShowPercent"
               type="text"
               formControlName="value"
               data-lpignore="true"
               placeholder="$0"
               mask="separator.0"
               thousandSeparator=","
               prefix="$"
               [readonly]="readonly"
               (focus)="focus('valueFocus')"
               (blur)="blur()"/>
      </div>
      <div class="m-l-2x m-r-2x" *ngIf="!readonly">
        <div class="form-control-radio flex-center">
          <label class="form-control-radio__label">
            <input class="form-control-radio__control" type="radio" value="amount" formControlName="type"/>
            <div class="form-control-radio__caption">$</div>
          </label>
          <label class="form-control-radio__label">
            <input class="form-control-radio__control" type="radio" value="percent" formControlName="type"/>
            <div class="form-control-radio__caption">%</div>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
