import {
  Component,
  Input,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { WINDOW } from '@ng-web-apis/common';
import { ToastrService } from 'ngx-toastr';
import { isBoolean } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  Dictionary,
  isPermitted,
  ModalService,
  VaultService,
} from 'asap-team/asap-tools';

import type {
  ConfirmActionModalCopy,
  Profile,
  SellerDigestCollectionLead,
  SellerTrafficCollectionLead,
} from '@core/types';

// Consts
import { environment } from 'environments/environment';
import {
  ROUTE,
  PROFILE,
  ARCHIVE_LEAD_SESSION,
  PERMITTED_ACTION,
  LEAD,
  REPORT,
  CONFIRM_ACTION_MODAL_COPY,
  LEAD_STATUS,
} from '@consts/consts';

// Services
import { SellerDigestService } from '@core/services/collections/seller-digest/seller-digest.service';

// Components
import { ArchiveConfirmComponent } from '@commons/modals/archive-confirm/archive-confirm.component';
import { CompleteProfileComponent } from '@commons/modals/complete-profile/complete-profile.component';
import { ActivateReportConfirmComponent } from '@commons/modals/activate-report/activate-report-confirm.component';
import { ConfirmActionCommonComponent } from '@commons/modals/confirm-action-common/confirm-action-common.component';

type Lead = SellerDigestCollectionLead | SellerTrafficCollectionLead;

@UntilDestroy()
@Component({
  selector: 'lead-actions',
  templateUrl: './lead-actions.component.html',
})
export class LeadActionsComponent {

  @Input() lead: Lead;

  @Output() reload: EventEmitter<void> = new EventEmitter<void>();

  PERMITTED_ACTION: Dictionary<string> = PERMITTED_ACTION;

  action$: Subscription;

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private modalService: ModalService,
    private toastr: ToastrService,
    private vaultService: VaultService,
    private sellerDigestService: SellerDigestService,
  ) {}

  isShowQuickActions(row: Lead): boolean {
    return !!row.permitted_actions.length;
  }

  isRowPermitted({ permitted_actions }: Lead, action: string): boolean {
    return isPermitted(permitted_actions, action);
  }

  archive(id: string): void {
    if (!this.vaultService.get(ARCHIVE_LEAD_SESSION, 'session')) {
      this
        .modalService
        .open(ArchiveConfirmComponent)
        .pipe(
          filter(isBoolean),
          untilDestroyed(this),
        )
        .subscribe(
          () => this.archiveLead(id),
        );
    } else {
      this.archiveLead(id);
    }
  }

  archiveLead(id: string): void {
    this.action$ = this
      .sellerDigestService
      .archivedLead(id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(LEAD.ARCHIVED);
          this.reload.emit();
        },
      );
  }

  sendReport(id: string, email_notify: boolean, sms_notify: boolean): void {
    this.action$ = this
      .sellerDigestService
      .sendReport(id, email_notify, sms_notify)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(REPORT.SENT);
          this.reload.emit();
        },
      );
  }

  activate(id: string): void {
    const profile: Profile = this.vaultService.get(PROFILE);

    if (profile.apps.seller_digest.needs_plan_upgrade) {
      this.router.navigate([ROUTE.alias.UPGRADE_SUBSCRIPTION]);

      return;
    }

    if (isPermitted(this.lead.permitted_actions, [PERMITTED_ACTION.ACTIVATE_NEED_COMPLETE_ACCOUNT])) {
      this
        .modalService
        .open(CompleteProfileComponent)
        .pipe(
          untilDestroyed(this),
        )
        .subscribe();

      return;
    }

    this.action$ = this
      .modalService
      .open(ActivateReportConfirmComponent)
      .pipe(
        untilDestroyed(this),
        filter(Boolean),
        switchMap(() => this.sellerDigestService.activateDigest(id)),
      )
      .subscribe(
        () => {
          this.reload.emit();
          this.toastr.success(REPORT.ACTIVATED);
        },
      );
  }

  deactivate(id: string): void {
    const modalData: ConfirmActionModalCopy = CONFIRM_ACTION_MODAL_COPY.DEACTIVATE_HOME_REPORT;

    this.action$ = this
      .modalService
      .open(ConfirmActionCommonComponent, { modalData })
      .pipe(
        untilDestroyed(this),
        filter(Boolean),
        switchMap(() => this.sellerDigestService.deactivateDigest(id)),
      )
      .subscribe(
        () => {
          this.reload.emit();
          this.toastr.success(REPORT.DEACTIVATED);
        },
      );
  }

  getHgLink(uid: string): string {
    return `${environment.hgUrl}/${uid}?preview`;
  }

  open(uid: string): void {
    if ((this.lead as SellerDigestCollectionLead).status === LEAD_STATUS.DRAFT) {
      this.router.navigate([ROUTE.alias.HOMEOWNERS_REPORT_NEW], { queryParams: { id: uid } });

    } else {
      this.window.open(this.getHgLink(uid), '_blank');
    }

  }

}
