<div class="cards" *ngIf="!methodsLoading else loading">
  <payment-method *ngFor="let card of methods"
                  [card]="card"
                  [isEditModeOn]="isEditModeOn"
                  [isSelectable]="isSelectable"
                  [selected]="isCardSelected(card)"
                  [expired]="isCardExpired(card)"
                  [ngStyle]="getCardStyle(card)"
                  (select)="selectCard($event)"
                  (edit)="editCard($event)"
                  (delete)="deleteCard($event)"></payment-method>
  <div class="new-card" (click)="addCard()">
    <i class="new-card--icon inline-svg inline-svg--16" [inlineSVG]="'#icon-plus'"></i> Add new card
  </div>
</div>
<ng-template #loading>
  <loading-dots class="loading-dots--lg visible-flex loading"></loading-dots>
</ng-template>
