import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ModalService, Dictionary, isPermitted } from 'asap-team/asap-tools';

// Consts
import {
  ROUTE,
  USER_ROLE,
  PERMITTED_ACTION,
  BILLING_PLAN,
} from '@consts/consts';
import type { Profile } from '@core/types/Profile';

// Services
import { UserService } from '@core/services/user/user.service';

// Components
import { GetInviteLinkComponent } from '@commons/modals/get-invite-link/get-invite-link.component';

@UntilDestroy()
@Component({
  selector: 'traffic-banner-collection-placeholder',
  templateUrl: 'traffic-banner-collection-placeholder.component.html',
  styleUrls: ['traffic-banner-collection-placeholder.component.scss'],
})
export class TrafficBannerCollectionPlaceholderComponent implements OnInit {

  copy: Dictionary;

  profile: Profile;

  PERMITTED_ACTION: Dictionary<string> = PERMITTED_ACTION;

  ROUTE: any = ROUTE;

  constructor(
    private userService: UserService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.userService.profile$
      .pipe(
        distinctUntilChanged(isEqual),
        untilDestroyed(this),
      ).subscribe((profile: Profile) => {
        this.profile = profile;
      });
  }

  get isAgentTrial(): boolean {
    // eslint-disable-next-line max-len
    return this.profile?.role === USER_ROLE.agent && (this.profile?.plan_name === BILLING_PLAN.TRIAL || this.profile?.plan_name === BILLING_PLAN.SOLO_PLAN);
  }

  get isLenderSubIsPaused(): boolean {
    return isPermitted(this.profile?.permitted_actions, PERMITTED_ACTION.HOMEOWNER_BANNERS_LOCK_SUBSCRIPTION_RENEW);
  }

  showGetLinkModal(): void {
    this.modalService
      .open(GetInviteLinkComponent)
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe();
  }

}
