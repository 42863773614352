import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { filter, switchMap, tap } from 'rxjs/operators';

import type { NavItem } from '@core/types';

// Services
import { NavigationBuilderService } from '@core/helpers/navigation-builder/navigation-builder.service';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'tabs-nav',
  templateUrl: './tabs-nav.component.html',
  styleUrls: ['./tabs-nav.component.scss'],
})
export class TabsNavComponent implements OnInit {

  navigation: NavItem[] = [];

  constructor(
    private router: Router,
    private navigationBuilderService: NavigationBuilderService,
  ) {}

  ngOnInit(): void {
    this.buildTabsNavigation().subscribe();

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        switchMap(() => this.buildTabsNavigation()),
      )
      .subscribe();
  }

  private buildTabsNavigation(): Observable<NavItem[]> {
    return this.navigationBuilderService.buildTabsNavigation(this.router?.routerState?.snapshot?.url)
      .pipe(
        tap((navigation: NavItem[]) => {
          this.navigation = [...navigation || []];
        }),
        untilDestroyed(this),
      );
  }

}
