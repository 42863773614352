import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'iq-input-filter',
  templateUrl: './iq-input-filter.component.html',
  styleUrls: ['./iq-input-filter.component.scss'],
})
export class IqInputFilterComponent implements OnInit, OnChanges {

  @Input() placeholder: string = '';

  @Input() filterName: string;

  @Input() stateValue: string;

  @Input() minSearchPhraseLengthForSearch: number = 3;

  inputControl: UntypedFormControl = new UntypedFormControl('');

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  get isClearButtonVisible(): boolean {
    return this.inputControl.value?.length > 0;
  }

  ngOnInit(): void {
    this.inputControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((value: string) => value?.length >= this.minSearchPhraseLengthForSearch || value?.length === 0),
        untilDestroyed(this),
      )
      .subscribe((value: string) => {
        this.emitFilterValue.emit({ filterName: this.filterName, value });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { stateValue } = changes;

    if (stateValue) {
      const { previousValue, currentValue } = stateValue;

      if (currentValue !== previousValue) {
        this.inputControl.patchValue(stateValue?.currentValue || '');
      }
    }
  }

  clearFilter(): void {
    this.inputControl.patchValue('');
  }

}
