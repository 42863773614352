import { Component } from '@angular/core';
import { RouteDictionary } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

@Component({
  selector: 'import-database-btn',
  templateUrl: './import-database-btn.component.html',
  styleUrls: ['./import-database-btn.component.scss'],
})
export class ImportDatabaseBtnComponent {

  ROUTE: RouteDictionary = ROUTE;

}
