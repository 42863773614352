import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingsService } from '@core/services/settings/settings.service';
import { ToastrService } from 'ngx-toastr';
import { IDX_LINK } from '@consts/toasts';
@Component({
  selector: 'idx-link',
  templateUrl: './idx-link.component.html',
  styleUrls: ['./idx-link.component.scss'],
})
export class IdxLinkComponent implements OnInit, OnChanges {

  @Input() link!: string | null;

  @Input() isEnabled: boolean = false;

  form: UntypedFormGroup;

  private linkValueChanged: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private toastr: ToastrService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { link } = changes;

    if (!link.firstChange) {
      this.form.patchValue({
        idx_enabled: !!link.currentValue,
        idx_url: link.currentValue || 'https://your-personal-link.com',
      });
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      idx_enabled: [true],
      idx_url: [{ value: this.link, disabled: false }, [Validators.required]],
    });

    this.subscribeToSwitchChange();
    this.subscribeToInputChange();

  }

  private subscribeToSwitchChange(): void {
    this
      .form
      .controls
      .idx_enabled
      .valueChanges
      .subscribe((value: boolean) => {
        if (!value) {
          if (this.form.controls.idx_url.value) {
            this.form.controls.idx_url.patchValue('', { emitEvent: false });
            this.updateIdx();
          }

          this.form.controls.idx_url.disable();

          return;
        }

        this.form.controls.idx_url.enable();
      });
  }

  private subscribeToInputChange(): void {
    const input: AbstractControl = this.form.get('idx_url');

    input
      .valueChanges
      .subscribe((value: string) => {
        this.linkValueChanged = value !== this.link;
      });
  }

  public get isActionEnabled(): boolean {
    return this.linkValueChanged && this.form.valid && this.isEnabled;
  }

  updateIdx(): void {
    const input: AbstractControl = this.form.get('idx_url');
    const { idx_enabled, idx_url } = this.form.getRawValue();

    input.markAsPending();

    this
      .settingsService
      .updateIdxLink(idx_enabled ? idx_url : null)
      .subscribe(
        () => {
          if (idx_enabled) {
            this.toastr.success(IDX_LINK.SAVED);
            this.link = idx_url;
            input.markAsPristine();
            input.markAsUntouched();
          } else {
            this.link = null;
            this.isEnabled = false;
            this.toastr.success(IDX_LINK.DISABLED);
          }
        },
        () => {
          this.form.get('idx_url').setErrors({ incorrect: true });
        },
      );
  }

}
