import { TourStepItem } from '@core/types';
import type { Dictionary } from 'asap-team/asap-tools';

export const FTL_SIDEBAR_ILLUSTRATION_TEMPLATE: Dictionary = {
  ILLUSTRATION_1: 'illustration_1',
  ILLUSTRATION_2: 'illustration_2',
  ILLUSTRATION_3: 'illustration_3',
  ILLUSTRATION_5: 'illustration_5',
  ILLUSTRATION_6: 'illustration_6',
  ILLUSTRATION_7: 'illustration_7',
  ILLUSTRATION_8: 'illustration_8',
  ILLUSTRATION_9: 'illustration_9',
  ILLUSTRATION_2_0: 'illustration_2_0',
  ILLUSTRATION_2_1: 'illustration_2_1',
  ILLUSTRATION_2_2: 'illustration_2_2',
  ILLUSTRATION_2_3: 'illustration_2_3',
  ILLUSTRATION_2_4: 'illustration_2_4',
  ILLUSTRATION_2_5: 'illustration_2_5',
  ILLUSTRATION_2_6: 'illustration_2_6',
};

export const FTL_WELCOME_TOURS: Dictionary<TourStepItem> = {
  agent_seller_traffic: {
    subtitle: 'How to generate',
    title: 'Seller leads',
    image: '/assets/components/ftl/tours/seller_report.png',
    event_for_analytic: 'FTL.agent.manual.go_to_seller_leads',
  },
  agent_buyer_traffic: {
    subtitle: 'How to generate',
    title: 'Buyer leads',
    image: '/assets/components/ftl/tours/buyer_traffic.png',
    event_for_analytic: 'FTL.agent.manual.go_to_buyer_leads',
  },
  agent_seller_report: {
    subtitle: 'How to nurture your',
    title: 'Database',
    image: '/assets/components/ftl/tours/database.png',
    event_for_analytic: 'FTL.agent.manual.go_to_database',
  },
  lender_partnership: {
    subtitle: 'How to nurture your',
    title: 'Realtors',
    image: '/assets/components/ftl/tours/realtors.png',
  },
  lender_buyer_traffic: {
    subtitle: 'How to generate',
    title: 'Buyer leads',
    image: '/assets/components/ftl/tours/buyer_traffic.png',
  },
  lender_seller_report: {
    subtitle: 'How to nurture your',
    title: 'Database',
    image: '/assets/components/ftl/tours/database.png',
  },
  lender_seller_traffic: {
    subtitle: 'How to generate',
    title: 'Refi leads',
    image: '/assets/components/ftl/tours/certificate.png',
  },

};
