import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Input } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';
import { ModalService } from 'asap-team/asap-tools';

import type { ConfirmActionModalCopy, ImportDatabaseDetails } from '@core/types';

// Consts
import { COMMON_TOAST, CONFIRM_ACTION_MODAL_COPY } from '@consts/consts';

// Components
import { ConfirmActionRareComponent } from '@commons/modals/confirm-action-rare/confirm-action-rare.component';

// Services
import { CsvService } from '@core/services/csv/csv.service';

@UntilDestroy()
@Component({
  selector: 'import-database-members',
  templateUrl: './import-database-members.component.html',
  styleUrls: ['./import-database-members.component.scss'],
})
export class ImportDatabaseMembersComponent {

  @Input() details: ImportDatabaseDetails;

  constructor(
    private csvService: CsvService,
    private modalService: ModalService,
    private toastr: ToastrService,
  ) {}

  upgradePlan(id: string): void {
    const modalData: ConfirmActionModalCopy = CONFIRM_ACTION_MODAL_COPY.UPGRADE_PLAN_ON_IMPORT;

    this
      .modalService
      .open(ConfirmActionRareComponent, { modalData })
      .pipe(
        filter(Boolean),
        switchMap(() => this.csvService.upgradeUserPlan(id)),
        untilDestroyed(this),
      )
      .subscribe(() => this.toastr.success(COMMON_TOAST.SUCCESS));
  }

}
