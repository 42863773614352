import { Component, Input } from '@angular/core';

import type { ViralImportInfo } from '@core/types';

@Component({
  selector: 'viral-import-info-result',
  templateUrl: './viral-import-info-result.component.html',
  styleUrls: ['./viral-import-info-result.component.scss'],
})
export class ViralImportInfoResultComponent {

  @Input() info: ViralImportInfo;

}
