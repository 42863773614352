/**
 * Returns message for remaining limits counter at report
 *
 */

export function getSubscriptionMessage(remaining_limits: number): string {
  const limit: number = remaining_limits > 0 ? remaining_limits : 0;

  return `${limit} automated report${limit !== 1 ? 's' : ''} left`;
}
