import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

// Consts
import { environment } from 'environments/environment';

@Component({
  selector: 'ftl-error',
  templateUrl: './ftl-error.component.html',
  styleUrls: ['./ftl-error.component.scss'],
})
export class FtlErrorComponent {

  @Output() tryAgain: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(WINDOW) private window: Window,
  ) {}

  reload(): void {
    this.tryAgain.next(true);
  }

  contactSupport(): void {
    if (!environment.production) { return }

    (this.window as any).Intercom('show');
  }

}
