import { Injectable } from '@angular/core';

// Consts
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class CacheRegistrationService {

  private urls: any[] = [];

  isAddedToCache(serviceUri: string): boolean {
    return !!~this.urls.indexOf(serviceUri);
  }

  addToCache(serviceUri: string): void {
    const url: string = `${environment.api}/v2/${serviceUri}`;

    if (this.isAddedToCache(url)) { return; }

    this.urls.push(url);
  }

}
