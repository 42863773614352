import { Route } from '@angular/router';

// Consts
import { ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';
import { RegistrationCompletedGuard } from '@core/guards/registration-completed/registration-completed.guard';
import { AccountStatusGuard } from '@core/guards/account-status/account-status.guard';

export const HOMEOWNERS: Route = {
  path: ROUTE.name.HOMEOWNERS,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, AccountStatusGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, AccountStatusGuard],
  loadChildren: () => import('@modules/user/collections/homeowners/homeowners.module').then((m: any) => m.HomeownersModule),
  data: { canBeActivatedBy: [USER_ROLE.agent, USER_ROLE.lender, USER_ROLE.title_user] },
};
