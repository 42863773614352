import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

import type { Alert, Profile, RouteDictionary } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { AlertsService } from '@core/services/alerts/alerts.service';
import { UserService } from '@core/services/user/user.service';

@UntilDestroy()
@Component({
  templateUrl: './payment-problem.component.html',
  styleUrls: ['../../alerts.component.scss'],
})
export class PaymentProblemComponent {

  @Input() type: Alert;

  ROUTE: RouteDictionary = ROUTE;

  profile$: Observable<Profile> = this.userService.profile$;

  constructor(
    private alertsService: AlertsService,
    private userService: UserService,
  ) {}

  closeAlert(type: Alert): void {
    this.alertsService.dismiss(type);
  }

}
