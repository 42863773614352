import { Injectable } from '@angular/core';
import { format } from 'date-fns';

// Types
import { IUserWorkingHours } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class WorkingHoursService {

  constructor(private userService: UserService) {}

  get workingHours(): IUserWorkingHours {
    return this.userService.getWorkingHours();
  }

  get getUserTimeUsingTimeZone(): Date {
    const userTimeZone: string = this.userService.getTimeZone();

    return new Date(new Date().toLocaleString('en-US', { timeZone: userTimeZone }));
  }

  isWorkingHoursNow(): boolean {
    const now: Date = this.getUserTimeUsingTimeZone;

    const currentHour: number = now.getHours();

    if (!this.workingHours) {
      return true;
    }

    const { work_hours_start, work_hours_end } = this.workingHours;

    return currentHour >= work_hours_start && currentHour < work_hours_end;
  }

  get workingHoursTooltipText(): string {
    const from: string = this.format24HoursTo12Hours(this.workingHours.work_hours_start);
    const till: string = this.format24HoursTo12Hours(this.workingHours.work_hours_end);

    return `We send the reports from ${from} to ${till} to not disturb homeowners in their rest time. If you want to send the report right away, you are able to do it manually`;
  }

  get nextAvailableWorkingSlot(): string {
    const date: Date = this.nextAvailableDate;

    return `${format(date, 'MMMM dd')} after ${this.format24HoursTo12Hours(this.workingHours.work_hours_start)}`;
  }

  get nextAvailableDate(): Date {
    const date: Date = this.getUserTimeUsingTimeZone;

    if (date.getHours() > this.workingHours.work_hours_end) {
      date.setDate(date.getDate() + 1);
    }

    return date;
  }

  format24HoursTo12Hours(time: number): string {
    const date: Date = new Date((new Date()).setHours(time));

    const formattedTime: string = date.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true });

    return formattedTime;
  }

  formatScheduledTime(time: string): string {
    const date: Date = new Date(time);

    return `${format(date, 'MMMM dd')} after ${date.toLocaleTimeString('en-US', {
      hour: 'numeric', hour12: true, timeZone: this.userService.getTimeZone(),
    })}`;
  }

}
