import { ROUTE, USER_ROLE, PERMITTED_ACTION } from '@consts/consts';
import { NavItem } from '@core/types';

/**
 * Navigation configuration for the Marketing section.
 * Defines accessible routes based on user roles and permissions.
 *
 * Current sections include:
 * - Posts for listings (Agents only)
 * - Posts for refinance (Lenders only)
 * - Posts for homeowners (Home Wealth Banners) (Agents and Lenders)
 * - Buyer Banners (Agents and Lenders)
 * - Flyers (Agents and Lenders)
 *
 * @type {NavItem[]}
 */

export const Marketing: NavItem[] = [
  {
    label: 'Posts for listings',
    routerLink: ROUTE.alias.MARKETING_SELLER_TRAFFIC_BANNERS,
    roles: [USER_ROLE.agent],
  },
  {
    label: 'Posts for refinance',
    routerLink: ROUTE.alias.MARKETING_SELLER_TRAFFIC_BANNERS,
    roles: [USER_ROLE.lender],
  },
  {
    label: 'Posts for homeowners',
    routerLink: ROUTE.alias.MARKETING_HOME_WEALTH_TRAFFIC_BANNERS,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.HOMEOWNER_WEALTH_BANNERS_VIEW],
  },
  {
    label: 'Posts for buyers',
    routerLink: ROUTE.alias.MARKETING_BUYER_TRAFFIC_BANNERS,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
  },
  {
    label: 'Flyers',
    routerLink: ROUTE.alias.MARKETING_FLYERS,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
  },
];
