import { pickBy, identity } from 'lodash-es';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { ParamMap } from '@angular/router';
import {
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  Observable,
  BehaviorSubject,
  forkJoin,
} from 'rxjs';
import {
  CollectionHelpersService,
  Dictionary,
  propertyImagePlaceholder,
  CollectionApi,
  CollectionResponse,
} from 'asap-team/asap-tools';
import { formatFees } from '@core/utils/fees/fees';

import * as RequestParams from '@core/types/request';
import type {
  Profile,
  PropertyDigest,
  SellerDigestLeadsStats,
  SellerDigestCollectionLead,
  Lead,
  LeadManualCreate,
  PROFILE_APPS,
  LeadLandingCreate,
  LandingParams,
  LandingParamsResponse,
  IDraftLeadDto,
  IDraftLeadWelcomeEmailSettings,
  UpdateLeadHomeDetailsForm,
  LoanFormPayload,
  LeadContactDetails,
  ReportSettings,
} from '@core/types';

// Consts
import { LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER } from '@consts/consts';

// Context tokens
import { CACHE_WITH_ERRORS } from '@core/interceptors/request-caching/request-caching.interceptor';

// Services
import { UserService } from '@core/services/user/user.service';
import { MortgageCheckupsService } from '@core/services/collections/mortgage-checkup/mortgage-checkup.service';
import { LeadDetailsService } from '@core/services/details/lead/lead-details.service';
import { CacheRegistrationService } from '@core/helpers/cache-registration/cache-registration.service';

@Injectable({ providedIn: 'root' })
export class SellerDigestService implements CollectionApi<SellerDigestCollectionLead> {

  private leadStatusChangeAction: BehaviorSubject<boolean> = new BehaviorSubject(false);

  leadStatusChangeAction$: Observable<boolean> = this.leadStatusChangeAction.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private mortgageCheckupsService: MortgageCheckupsService,
    private leadDetailsService: LeadDetailsService,
    private cacheRegistrationService: CacheRegistrationService,
    private collectionHelpersService: CollectionHelpersService,
  ) { }

  getSellerDigestCollectionStats(params?: Dictionary): Observable<SellerDigestLeadsStats> {
    const queryParams: unknown = params ? { params: new HttpParams({ fromObject: pickBy(params, identity) }) } : {};

    return this
      .http
      .get('v2/seller_digest/leads/stats', queryParams)
      .pipe(
        map((response: any) => response.data),
      );
  }

  getCollection(params: Dictionary): Observable<CollectionResponse<SellerDigestCollectionLead>> {
    return this
      .http
      .get('v2/seller_digest/leads', { params: new HttpParams({ fromObject: pickBy(params, identity) }) })
      .pipe(
        this.collectionHelpersService.mapResponseFilters(),
        propertyImagePlaceholder<SellerDigestCollectionLead>(LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER),
        this.collectionHelpersService.retrowError(),
      );
  }

  getLead(id: string): Observable<Lead> {
    return this
      .http
      .get(`v2/seller_digest/leads/${id}`)
      .pipe(
        map(formatFees),
        map((lead: Lead) => this.leadDetailsService.emitLeadUpdate(lead)),
        tap((lead: Lead) => {
          this.mortgageCheckupsService.emitLeadMortgageCheckupsUpdate(lead.mortgage_checkups);
        }),
      );
  }

  editHomeValue(id: string, homeValue: number): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${id}/update_home_value`, { home_value: homeValue }).pipe(
        map((newLead: Lead) => {
          const currentLead: Lead = this.leadDetailsService.getLead();

          const lead: Lead = {
            ...currentLead,
            home_value: newLead.home_value,
            selling: {
              ...currentLead.selling,
              closing_fees_amount: newLead.selling.closing_fees_amount,
            },
            refinance: {
              ...currentLead.refinance,
              closing_fees_amount: newLead.refinance.closing_fees_amount,
            },
          };

          return this.leadDetailsService.emitLeadUpdate(lead);
        }),
      );
  }

  isLeadAccessible(id: string): Observable<any> {
    this.cacheRegistrationService.addToCache(`seller_digest/leads/${id}`);

    const context: HttpContext = new HttpContext().set(CACHE_WITH_ERRORS, true);

    return this.http.head(`v2/seller_digest/leads/${id}`, { context });
  }

  verifyHomeValue(id: string, formData: FormData): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/leads/${id}/verify_home_value`, formData)
      .pipe(
        map(formatFees),
      );
  }

  createLead(formData: LeadManualCreate): Observable<string> {
    return this
      .http
      .post('v2/seller_digest/leads', formData)
      .pipe(
        map((lead: Lead) => lead.id),
      );
  }

  createDraftLead(formData: IDraftLeadDto): Observable<Lead> {
    return this
      .http
      .post('v2/seller_digest/drafts', formData).pipe(
        map((lead: Lead) => this.leadDetailsService.emitLeadUpdate(lead)),
      );
  }

  getDraftLead(uid: string): Observable<Lead> {
    return this
      .http
      .get(`v2/seller_digest/drafts/${uid}`, {}).pipe(
        map((lead: Lead) => this.leadDetailsService.emitLeadUpdate(lead)),
      );
  }

  recalculateFees(uid: string): Observable<Lead> {
    return this
      .http
      .post(`v2/seller_digest/drafts/${uid}/recalculate_fees`, {}).pipe(
        map((lead: Lead) => this.leadDetailsService.emitClosingFeesUpdate(lead)),
      );
  }

  sendReport(id: string, email_notify: boolean, sms_notify: boolean): Observable<void> {
    return this.http.post<void>(
      'v2/seller_digest/leads/digest_send',
      {
        id,
        email_notify,
        sms_notify,
      },
    );
  }

  activateDigest(id: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/leads/${id}/activate`, null)
      .pipe(
        withLatestFrom(this.userService.profile$),
        switchMap(([apps, profile]: [PROFILE_APPS, Profile]) => this.userService.updateProfile({ ...profile, apps }, false)),
        switchMap(() => this.getLead(id)),
        tap(() => this.emitLeadStatusChangeAction()),
      );
  }

  activateDraftLead(id: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${id}/activate`, null).pipe(
        map(() => this.leadDetailsService.emitLeadUpdate(null)),
      );
  }

  deactivateDigest(id: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/leads/${id}/deactivate`, null)
      .pipe(
        withLatestFrom(this.userService.profile$),
        switchMap(([apps, profile]: [PROFILE_APPS, Profile]) => this.userService.updateProfile({ ...profile, apps }, false)),
        switchMap(() => this.getLead(id)),
        tap(() => this.emitLeadStatusChangeAction()),
      );
  }

  archivedLead(id: string): Observable<void> {
    return this
      .http
      .patch<void>(`v2/leads/${id}/archive`, null);
  }

  activateMultipleLeads(selected: RequestParams.SelectedLeads): Observable<void> {
    return this.http.patch<void>(
      `v2/seller_digest/leads/activate/${selected.type}`,
      { included_ids: selected.included_ids },
    );
  }

  deactivateMultipleLeads(selected: RequestParams.SelectedLeads): Observable<void> {
    return this.http.patch<void>(
      `v2/seller_digest/leads/deactivate/${selected.type}`,
      { included_ids: selected.included_ids },
    );
  }

  archiveMultipleLeads(selected: RequestParams.SelectedLeads): Observable<void> {
    return this.http.patch<void>(
      `v2/leads/archive/${selected.type}`,
      { included_ids: selected.included_ids },
    );
  }

  sendMultipleLeads(selected: RequestParams.SelectedLeads, email_notify: boolean, sms_notify: boolean): Observable<void> {
    return this.http.patch<void>(
      `v2/seller_digest/leads/digest_send/${selected.type}`,
      {
        email_notify,
        sms_notify,
        included_ids: selected.included_ids,
      },
    );
  }

  validateMultipleLeadsAction(selected: RequestParams.SelectedLeads, action: string): Observable<void> {
    return this.http.get<void>(
      `v2/seller_digest/leads/${action}_validate/${selected.type}`,
      { params: new HttpParams({ fromObject: { included_ids: selected.included_ids } }) },
    );
  }

  validateArchiveAction(selected: RequestParams.SelectedLeads): Observable<void> {
    return this.http.get<void>(
      `v2/leads/archive_validate/${selected.type}`,
      { params: new HttpParams({ fromObject: { included_ids: selected.included_ids } }) },
    );
  }

  getPropertyDigests(): Observable<PropertyDigest[]> {
    return this
      .http
      .get<PropertyDigest[]>('v2/seller_digest/property_digests')
      .pipe(
        map((digests: PropertyDigest[]) => digests.map((item: PropertyDigest): PropertyDigest => {
          if (!item.image) {
            // eslint-disable-next-line no-param-reassign
            item.image = PROPERTY_IMAGE_PLACEHOLDER;
          }

          return item;
        })),
      );
  }

  createPropertyDigestLead(form: RequestParams.createPropertyDigestLead): Observable<void> {
    const params: Dictionary = pickBy(form, identity);

    return this
      .http
      .post<void>('v2/seller_digest/property_digests', params);
  }

  createLeadFromLanding(data: LeadLandingCreate, paramMap: ParamMap): Observable<LandingParams> {
    return this
      .http
      .post('v2/seller_digest/leads/create_from_landing', data)
      .pipe(
        map((response: LandingParamsResponse) => {
          return {
            paramMap,
            response,
          };
        }),
      );
  }

  recalculate(currentLead: Lead, { fees, leadValue }: { fees?: boolean; leadValue?: boolean }): Observable<Lead> {
    let observe: Observable<Lead>[] = [];

    if (leadValue) {
      observe = [this.recalculateLeadValue(currentLead.id)];
    }

    if (fees) {
      observe = [...observe, this.recalculateLeadFees(currentLead.id)];
    }

    return forkJoin(observe)
      .pipe(
        map((response: Lead[]) => {
          return {
            ...currentLead,
            ...response[0],
            ...response[1],
          };
        }),
        tap((lead: Lead) => {
          this.leadDetailsService.emitLeadUpdate(lead);
        }),
      );
  }

  recalculateLeadFees(id: string): Observable<Lead> {
    return this
      .http
      .get(`v2/leads/${id}/fees`)
      .pipe(
        map(formatFees),
      );
  }

  recalculateLeadValue(id: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/leads/${id}/recalculate`, {})
      .pipe(
        map(formatFees),
      );
  }

  activateEmailReportNotifications(
    id: string,
  ): Observable<Lead> {
    return this.http
      .patch(
        `v2/seller_digest/leads/${id}/email/activate`,
        {},
      )
      .pipe(switchMap(() => this.getLead(id)));
  }

  activateEmailDraftReportNotifications(
    id: string,
  ): Observable<void> {
    return this.http
      .patch<void>(
      `v2/seller_digest/drafts/${id}/email/activate`,
      {},
    );
  }

  deactivateEmailReportNotifications(
    id: string,
  ): Observable<Lead> {
    return this.http
      .patch(
        `v2/seller_digest/leads/${id}/email/deactivate`,
        {},
      )
      .pipe(switchMap(() => this.getLead(id)));
  }

  deactivateEmailDraftReportNotifications(
    id: string,
  ): Observable<void> {
    return this.http
      .patch<void>(
      `v2/seller_digest/drafts/${id}/email/deactivate`,
      {},
    );
  }

  activateSmsReportNotifications(
    id: string,
  ): Observable<Lead> {
    return this.http
      .patch<Lead>(
      `v2/seller_digest/leads/${id}/sms/activate`,
      {},
    )
      .pipe(switchMap(() => this.getLead(id)));
  }

  activateSmsDraftReportNotifications(
    id: string,
  ): Observable<void> {
    return this.http
      .patch<void>(
      `v2/seller_digest/drafts/${id}/sms/activate`,
      {},
    );
  }

  deactivateSmsReportNotifications(
    id: string,
  ): Observable<Lead> {
    return this.http
      .patch<Lead>(
      `v2/seller_digest/leads/${id}/sms/deactivate`,
      {},
    )
      .pipe(switchMap(() => this.getLead(id)));
  }

  deactivateSmsDraftReportNotifications(
    id: string,
  ): Observable<void> {
    return this.http
      .patch<void>(
      `v2/seller_digest/drafts/${id}/sms/deactivate`,
      {},
    );
  }

  getWelcomeEmailSettings(uid: string): Observable<IDraftLeadWelcomeEmailSettings> {
    return this
      .http
      .get<IDraftLeadWelcomeEmailSettings>(`v2/seller_digest/leads/${uid}/welcome_email`);
  }

  updateWelcomeEmailSettings(uid: string, welcomeEmailSettings: IDraftLeadWelcomeEmailSettings): Observable<Lead> {
    return this
      .http
      .patch<Lead>(`v2/seller_digest/leads/${uid}/welcome_email`, welcomeEmailSettings);
  }

  updateDraftLeadImage(id: string, formData: FormData): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${id}/image`, formData).pipe(
        map((lead: Lead) => this.leadDetailsService.emitLeadUpdate(lead)),
      );
  }

  reloadDraftAvm(id: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${id}/reloads/avm`, {})
      .pipe(
        switchMap(() => this.getDraftLead(id)),
      );
  }

  reloadDraftLoans(id: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${id}/reloads/loans`, {})
      .pipe(
        switchMap(() => this.getDraftLead(id)),
      );
  }

  reloadDraftHomeDetails(id: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${id}/reloads/home_details`, {})
      .pipe(
        switchMap(() => this.getDraftLead(id)),
      );
  }

  reloadDraftClosingFees(id: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${id}/reloads/fees`, {})
      .pipe(
        switchMap(() => this.getDraftLead(id)),
        map((lead: Lead) => this.leadDetailsService.emitClosingFeesUpdate(lead)),
      );
  }

  editDraftLeadHomeDetails(id: string, homeDetails: UpdateLeadHomeDetailsForm): Observable<Lead> {
    const homeDetailsFormData: FormData = new FormData();

    Object.keys(homeDetails).forEach((key: string) => {
      homeDetailsFormData.append(key, homeDetails[key]);
    });

    return this
      .http
      .patch(`v2/seller_digest/drafts/${id}/home_details`, homeDetailsFormData).pipe(
        map((lead: Lead) => this.leadDetailsService.emitLeadUpdate(lead)),
      );
  }

  addNewLoanToDraftLead(uid: string, payload: LoanFormPayload): Observable<Lead> {
    return this
      .http
      .post(`v2/seller_digest/drafts/${uid}/loans`, { loan_data: { ...payload } })
      .pipe(
        tap((lead: Lead) => { this.leadDetailsService.emitLeadUpdate(lead) }),
      );
  }

  updateLoanForDraftLead(uid: string, loan_uid: string, payload: LoanFormPayload): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${uid}/loans/${loan_uid}`, { loan_data: { ...payload } })
      .pipe(
        tap((lead: Lead) => { this.leadDetailsService.emitLeadUpdate(lead) }),
      );
  }

  deleteLoanOfDraftLead(uid: string, loan_uid: string): Observable<Lead> {
    return this
      .http
      .delete(`v2/seller_digest/drafts/${uid}/loans/${loan_uid}`)
      .pipe(
        tap((lead: Lead) => { this.leadDetailsService.emitLeadUpdate(lead) }),
      );
  }

  updateDraftLeadContactDetails(form: LeadContactDetails): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${form.id}/contact_details`, form)
      .pipe(
        map(formatFees),
        map((lead: Lead) => this.leadDetailsService.emitLeadUpdate(lead)),
      );
  }

  updateLoanExpiredDateForDraftLead(id: string, hide_refi_block_expired_date: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/drafts/${id}/hide_refi_block_expired_date`, { hide_refi_block_expired_date })
      .pipe(
        tap((lead: Lead) => { this.leadDetailsService.emitLeadUpdate(lead) }),
      );
  }

  removeDraftLeadMember(lead_id: string, id: string): Observable<Lead> {
    return this
      .http
      .delete(`v2/seller_digest/drafts/${lead_id}/members/${id}/removing`)
      .pipe(
        map((lead: Lead) => this.leadDetailsService.emitLeadUpdate(lead)),
      );
  }

  addMemberToDraftLead(lead_id: string, payload: { user_id: string; permission: string; primary: string }): Observable<Lead> {
    return this
      .http
      .post(`v2/seller_digest/drafts/${lead_id}/members`, payload)
      .pipe(
        tap((lead: any) => this.leadDetailsService.emitLeadUpdate(lead)),
      );
  }

  getAffordableCalculatorDisclaimer(): Observable<ReportSettings> {
    return this
      .http
      .get<ReportSettings>('v2/seller_digest/report_settings', {});
  }

  updateAffordableCalculatorDisclaimer(affordable_calculator_disclaimer_text: string): Observable<ReportSettings> {
    return this
      .http
      .patch<ReportSettings>('v2/seller_digest/affordable_calculator', { affordable_calculator_disclaimer_text });
  }

  updateAutoAvm(auto_avm_update: boolean): Observable<any> {
    return this.http.patch<void>('v2/seller_digest/auto_avm_update', { auto_avm_update });
  }

  validateReportActivation(id: string): Observable<void> {
    return this
      .http
      .get<void>(`v2/seller_digest/leads/${id}/activate_validate`, {});
  }

  emitLeadStatusChangeAction(): void {
    this.leadStatusChangeAction.next(true);
  }

}
