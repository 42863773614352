import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import {
  CollectionHelpersService,
  propertyImagePlaceholder,
  BaseHttpService,
  CollectionResponse,
  CollectionApi,
} from 'asap-team/asap-tools';

import type { Observable } from 'rxjs';
import type { ArchiveCollectionLead } from '@core/types';

// Consts
import { LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class ArchiveLeadService implements CollectionApi<ArchiveCollectionLead> {

  constructor(
    private http: BaseHttpService,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(params: Params): Observable<CollectionResponse<ArchiveCollectionLead>> {
    return this
      .http
      .get('v2/leads/archived', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        propertyImagePlaceholder<ArchiveCollectionLead>(LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER),
        this.collectionHelpersService.retrowError(),
      );
  }

  deleteLead(id: string): Observable<void> {
    return this.http.delete(`v2/leads/${id}`);
  }

  restoreLead(id: string): Observable<void> {
    return this
      .http
      .patch(`v2/leads/${id}/restore`, null);
  }

}
