<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <form class="control__content" [formGroup]="form">
    <div class="d-flex justify-content-center align-items-center">
      <div class="flex-1">
        <input class="control__input"
               #nativeInput=""
               type="text"
               data-lpignore="true"
               mask="(000) 000-0000"
               placeholder="(000) 000 0000"
               formControlName="phone"
               (focus)="focused = true"
               (blur)="blur()"/>
      </div>
      <ng-content></ng-content>
    </div>
  </form>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
