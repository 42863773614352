import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { Params } from '@angular/router';
import { RouteDictionary } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

@Component({
  selector: 'collection-cell-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionCellUserInfoComponent {

  @Input() type: 'new-account' | 'trial-account' | 'uploads';

  @Input() row: any;

  @Input() prevQueryParams: Params;

  ROUTE: RouteDictionary = ROUTE;

  constructor(
    private location: Location,
  ) {}

  get isUserBlocked(): boolean {
    return this.row?.status === 'blocked';
  }

  get accountLink(): string[] {
    if (this.type === 'uploads') {
      return [ROUTE.alias.ACCOUNT, this.row?.user_id];
    }

    return [ROUTE.alias.ACCOUNT, this.row?.id];
  }

  get prevCollectionLink(): string {
    const [path] = this.location.path().split('?');

    return path;
  }

}
