import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  Arc, arc, DefaultArcObject, pie, select,
} from 'd3';
import { isEqual } from 'lodash-es';

export type HalfDonutData = {
  type: 'default' | 'hidden';
  value: number;
  color: 'transparent' | string;
};

const PROGRESS_EMPTY_DATA: HalfDonutData[] = [
  {
    value: 1, color: '#DAE1E6', type: 'default',
  },
  {
    value: 1, color: 'transparent', type: 'hidden',
  },
];

@Component({
  selector: 'half-donut-chart',
  templateUrl: './half-donut-chart.component.html',
  styleUrls: ['./half-donut-chart.component.scss'],
})
export class HalfDonutChartComponent implements AfterViewInit, OnChanges {

  @Input() data!: HalfDonutData[];

  @Input() loading: boolean = false;

  @ViewChild('svg') svg!: ElementRef<HTMLElement>;

  graph!: any;

  arcGen!: Arc<any, DefaultArcObject>;

  backProgressBar!: any;

  width: number = 288;

  height: number = 190;

  bgGradColor: string = '#D9F5D9';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data) {
      const { currentValue, previousValue } = changes.data;

      this.bgGradColor = currentValue ? '#D9F5D9' : '#F4F8F9';

      if (!isEqual(previousValue, currentValue) && this.graph) {
        const graphData: HalfDonutData[] = currentValue || PROGRESS_EMPTY_DATA;

        this.setData(graphData);
      }
    }
  }

  ngAfterViewInit(): void {
    this.graph = select(this.svg.nativeElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2 + 47})`);

    this.arcGen = arc()
      .innerRadius(135)
      .cornerRadius(10)
      .outerRadius(120)
      .startAngle(-108 * (Math.PI / 180))
      .endAngle(108 * (Math.PI / 180));

    // show gradient background
    const gradContainer: any = this.graph.append('g').attr('class', 'gradient');

    const linearGradient: any = gradContainer
      .append('defs')
      .append('linearGradient')
      .attr('id', 'linearGrad')
      .attr('x1', '0')
      .attr('y1', '0')
      .attr('x2', '0')
      .attr('y2', '40')
      .attr('gradientUnits', 'userSpaceOnUse');

    linearGradient
      .append('stop')
      .attr('stop-color', this.bgGradColor)
      .attr('stop-opacity', 0.6)
      .attr('offset', '0%');

    linearGradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', this.bgGradColor)
      .attr('stop-opacity', 0);

    gradContainer
      .append('circle')
      .attr('cx', '-1')
      .attr('cy', '0')
      .attr('r', '113')
      .attr('fill', 'url(#linearGrad)');

    // show back progress bar
    this.backProgressBar = this.graph
      .append('path')
      .attr('d', this.arcGen)
      .attr('fill', '#F4F8F9')
      .attr('stroke', '#F4F8F9')
      .attr('stroke-width', 2);

    this.setData(PROGRESS_EMPTY_DATA);
  }

  setData(donutData: HalfDonutData[]): void {
    const angleGen: any = pie()
      .startAngle(-108 * (Math.PI / 180))
      .endAngle(108 * (Math.PI / 180))
      // @ts-ignore
      .sort((a: HalfDonutData) => (a.type === 'hidden' ? 1 : -1))
      .value((d: any) => d.value);

    const dataSet: any = angleGen(donutData as any);

    const arcGen: any = arc()
      .innerRadius(135)
      .cornerRadius(10)
      .outerRadius(120);

    this.graph
      .selectAll('.slice')
      .data(dataSet)
      .join('path')
      .attr('class', 'slice')
      .attr('d', (d: any) => {
        if (!d.value) { return null; }

        return arcGen(d);
      })
      .attr('fill', ({ data }: { data: HalfDonutData }) => data.color)
      .attr('stroke', ({ data }: { data: HalfDonutData }) => (data.type === 'hidden' ? 'transparent' : '#fff'))
      .attr('stroke-width', 2);

    this.graph
      .selectAll('#linearGrad')
      .selectAll('stop')
      .attr('stop-color', this.bgGradColor);
  }

}
