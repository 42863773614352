export const INVITE_AGENT_MESSAGE: string[] = [
  'Hi %first_name%,\n\n',
  'I hope this message finds you well. I’ve recently discovered a fantastic tool called myhomeIQ that’s been instrumental in helping me stay connected with past clients and generate new business. Seeing its remarkable benefits, I immediately thought of you and how it could propel your business growth.\n\n',
  'myhomeIQ isn’t just another platform; it’s a game-changer, particularly with its predictive analytics feature. This tool identifies potential clients who are likely to sell their homes in the next 6-12 months, offering you a significant edge in the market.\n\n',
  'But there’s more – myhomeIQ equips you with a suite of tools to enhance your marketing efforts and deepen client relationships. From creating personalized reports to utilizing co-branded marketing materials, this platform is all about boosting your professional presence and effectiveness.\n\n',
  'The best part? There’s absolutely no cost to you as an agent to use this service. \n\n',
  'Click this link to join and start exploring its features: %invitation_link%\n\n',
  'Looking forward to seeing you leverage this platform for your continued success.\n\n',
  'Warm regards.',
];

export const INVITE_LENDER_MESSAGE: string[] = [
  'Hi %first_name%,\n\n',
  'I hope you’re doing well. I recently started using myhomeIQ, and it’s quickly proving to be one of the best tools for staying top of mind with past clients - crucial for repeat and referral business. The more I use it, the more I see its potential for our partnership.\n\n',
  'I’d like to invite you to be my preferred loan officer on this platform. This way, any financing-related inquiries from the leads and clients I manage through myhomeIQ would come directly to you, ensuring we both have the opportunity to provide seamless service to our clients.\n\n',
  'It’s a fantastic opportunity for us to collaborate more closely and efficiently, making sure we’re both actively engaged with our clients and maximizing our business opportunities.\n\n',
  'Click this link to join me: %invitation_link%\n\n',
  'Let’s catch up soon to discuss this further. Are you available for a chat this week?\n\n',
  'Warm regards.',
];
