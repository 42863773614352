import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required, invalidPhone } from './conditions';

export const invitationCardErrors: FormErrors = {
  owner_name: [
    required,
    apiError(),
  ],
  email: [
    required,
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  phone: [
    invalidPhone,
    apiError(),
  ],
  global: [],
};
