import { FTLStep } from '@core/types';

export enum FTLStepName {
  enter_password = 'enter_password',
  agent_completed_profile = 'agent_completed_profile',
  lender_completed_profile = 'lender_completed_profile',
  demo_book = 'demo_book',
  agent_invited = 'agent_invited',
  all_set = 'all_set',
}

export class FTLStateModel {

  steps: FTLStepName[] = [];

  currentStep: FTLStepName = null;

  ftlStepData: FTLStep = null;

}
