import mobileDetect from 'mobile-detect';

/**
 * Returns whether or not the current device is an mobile device.
 *
 * @return {boolean} Is an Mobile device
 */

export function isMobile(): boolean {
  // eslint-disable-next-line new-cap
  const md: mobileDetect = new mobileDetect(window.navigator.userAgent);

  return !!md.mobile();
}
