import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import type { Plan, ConfirmActionModalCopy } from '@core/types';

// Consts
import { COMMON_MODAL_TYPE } from '@consts/consts';

@Component({
  templateUrl: './break-partnership.component.html',
  styleUrls: ['./break-partnership.component.scss'],
})
export class BreakPartnership extends SimpleModalComponent< { modalData: ConfirmActionModalCopy }, Plan[] | boolean> {

  modalData: ConfirmActionModalCopy;

  contactsFee: string = COMMON_MODAL_TYPE.CONTACTS_FEE;

  confirm(): void {
    this.result = true;
    this.close();
  }

}
