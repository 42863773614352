<label class="search-filter">
  <input
    class="search-filter__input"
    type="text"
    [placeholder]="placeholder"
    data-lpignore="true"
    [formControl]="inputControl"/>
    <div
      class="inline-svg"
      *ngIf="isClearButtonVisible"
      (click)="clearFilter()"
      [inlineSVG]="'#icon-close'">
    </div>
</label>
