import { UntilDestroy } from '@ngneat/until-destroy';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'transaction-side-cell',
  templateUrl: './transaction-side-cell.component.html',
  styleUrls: ['./transaction-side-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionSideCellComponent {

  @Input() transactionSides: Array<'Buyer' | 'Seller'>;

  get isSellerSide(): boolean {
    return this.transactionSides.includes('Seller') && this.transactionSides.length < 2;
  }

  get isBuyerSide(): boolean {
    return this.transactionSides.includes('Buyer') && this.transactionSides.length < 2;
  }

  get isAllSides(): boolean {
    return this.transactionSides.includes('Seller') && this.transactionSides.includes('Buyer');
  }

}
