// eslint-disable-next-line max-classes-per-file
import { Company } from '@core/types';

const ACTION_SCOPE: string = '[Archived Accounts]';

export namespace ArchivedAccountsActions {

  export class GetCollectionData {

    static readonly type: string = `${ACTION_SCOPE} Get Collections Data`;

    constructor(
      public reset: boolean = false,
    ) {}

  }

  export class ChangePage {

    static readonly type: string = `${ACTION_SCOPE} Change Page Collections Data`;

    constructor(
      public page: number,
    ) {}

  }

  export class Search {

    static readonly type: string = `${ACTION_SCOPE} Search Collections Data`;

    constructor(
      public search: string,
    ) {}

  }

  export class SetCompanyState {

    static get type(): string {
      return `${ACTION_SCOPE} Set company state`;
    }

    constructor(
      public company: Company,
    ) {
    }

  }

}
