import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Observable } from 'rxjs';

import type { Dictionary } from 'asap-team/asap-tools';
import type { UserCard, Lead, UserRole } from '@core/types';

// Consts
import {
  COMMON_TOAST,
  USER_ROLE,
  USER_LABEL,
  ROUTE,
  USER_CARD_SIDEBAR_TYPE,
  USER_CARD_SIDEBAR_RESULT,
} from '@consts/consts';

// Services
import { PartnershipService } from '@core/services/partnership/partnership.service';
import { LeadMembershipsService } from '@core/services/lead-memberships/lead-memberships.service';

@UntilDestroy()
@Component({
  templateUrl: './user-card-sidebar.component.html',
  styleUrls: ['./user-card-sidebar.component.scss'],
})
export class UserCardSidebarComponent
  extends SimpleModalComponent<{
    user: UserCard;
    type?: string;
    observerRole?: string;
    lead?: Lead;
  }, string> {

  user: UserCard;

  type: string;

  observerRole: string;

  lead: Lead;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  USER_LABEL: any = USER_LABEL;

  ROUTE: any = ROUTE;

  modalType: Dictionary<string> = USER_CARD_SIDEBAR_TYPE;

  modalResult: Dictionary<string> = USER_CARD_SIDEBAR_RESULT;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private partnershipService: PartnershipService,
    private leadMembershipsService: LeadMembershipsService,
  ) {
    super();
  }

  isPercent(type: string): boolean {
    return type === 'percent';
  }

  get isShowMenu(): boolean {
    const { partner, outgoing, member } = USER_CARD_SIDEBAR_TYPE;

    // Check if assign member details
    if (!this.observerRole && this.isMember) { return false; }

    // Check if ObserverRole is Agent
    if (this.compareObserverRole(this.USER_ROLE.agent) && this.compareMemberRole(this.USER_ROLE.lender)) { return false; }

    // Check if ObserverRole is Lender
    if (
      this.compareObserverRole(this.USER_ROLE.lender)
      && this.compareMemberRole(this.USER_ROLE.agent)
      && this.user.owner
    ) { return false; }

    return [partner, outgoing, member].includes(this.type);
  }

  get isShowMemberRemoveAction(): boolean {
    if (this.observerRole === this.user.role && !this.user.owner) {
      return true;
    }

    if (this.compareObserverRole(this.USER_ROLE.lender) && this.compareMemberRole(this.USER_ROLE.agent) && !this.user.owner) {
      return true;
    }

    return false;
  }

  get isMember(): boolean {
    return this.type === this.modalType.member;
  }

  compareObserverRole(role: string): boolean {
    return this.observerRole === role;
  }

  compareMemberRole(role: string): boolean {
    return this.user.role === role;
  }

  isShowBreakPartnershipButton(role: string): boolean {
    return role === 'Realtor';
  }

  breakPartnership(): void {
    this.result = USER_CARD_SIDEBAR_RESULT.breakPartnership;
    this.close();
  }

  resendInvitation(id: string): void {
    this
      .partnershipService
      .resendInvite(id)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.toastr.success(COMMON_TOAST.SUCCESS);
        },
      );
  }

  setPrimary(partner: UserCard): void {
    this
      .partnershipService
      .setPrimary(partner.username)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          // eslint-disable-next-line no-param-reassign
          partner.primary = true;
          this.result = USER_CARD_SIDEBAR_RESULT.changePrimary;
        },
      );
  }

  unsetPrimary(partner: UserCard): void {
    this
      .partnershipService
      .unsetPrimary()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          // eslint-disable-next-line no-param-reassign
          partner.primary = false;
          this.result = USER_CARD_SIDEBAR_RESULT.unsetPrimary;
        },
      );
  }

  removeFromLead(): void {
    let isLeave: boolean = false;
    let observable: Observable<void | Lead>;

    if (this.observerRole === this.user.role && !this.user.owner) {
      observable = this.leadMembershipsService.leaveLead(this.lead.id);
      isLeave = true;
    } else {
      observable = this.leadMembershipsService.removeLeadMember(this.lead.id, this.user.id);
    }

    observable
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.close();
          this.toastr.success(COMMON_TOAST.SUCCESS);

          if (isLeave) {
            this.router.navigate([ROUTE.alias.HOMEOWNERS_REPORTS]);
          }
        },
      );
  }

  goToEdit(term: 'profile' | 'partnership'): void {
    this.close();

    if (term === 'profile') {
      this.router.navigate([this.ROUTE.alias.SETTINGS]);
    }

    if (term === 'partnership') {
      this.router.navigate([this.ROUTE.alias.PARTNERSHIP]);
    }
  }

  closeModal(result: string): void {
    this.result = result;
    this.close();
  }

}
