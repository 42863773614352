import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';
import { RefinanceOpportunityExampleComponent } from '@commons/modals/refinance-opportunity-example/refinance-opportunity-example.component';
import { IqSidebarService } from '@commons/iq-sidebar/iq-sidebar.service';
import { RefinanceScannerData } from '@modules/user/collections/homeowners/report-details/components/features/refinance-scanner/types/refinance-scanner-data.type';
import { LeadMortgageCheckupsItem } from '@core/types';
import { FEATURES } from '@consts/feature-flags';
import { DOCUMENT } from '@angular/common';

interface RateControl {
  [key: string]: FormControl<number>;
}

@Component({
  selector: 'refinance-scanner-sidebar',
  templateUrl: './refinance-scanner-sidebar.component.html',
  styleUrls: ['./refinance-scanner-sidebar.component.scss'],
})
export class RefinanceScannerSidebarComponent implements OnInit {

  FEATURES: Record<string, string> = FEATURES;

  refinanceData: RefinanceScannerData;

  loansFormGroup: FormGroup<RateControl>;

  editRateForTerm: string = null;

  maskPatterns: Record<string | number, { pattern: RegExp }> = {
    M: { pattern: /-/ },
    P: { pattern: /\./ },
    0: { pattern: /[0-9]/ },
  };

  constructor(
    private iqSidebarService: IqSidebarService,
    private iqModalService: IqModalService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
    const controls: RateControl = this.refinanceData.mortgage_checkups
      .reduce((acc: RateControl, { loan_term }: LeadMortgageCheckupsItem) => {
        return {
          ...acc,
          [`${loan_term}`]: new FormControl(0, [Validators.required, Validators.min(0.01), Validators.max(100)]),
        };
      }, {});


    this.loansFormGroup = new FormGroup(controls);
  }

  downloadPdfCertificate(forLoanTerm: number): void {
    this.iqSidebarService.setRawResult({ type: 'pdf', value: forLoanTerm });
  }

  email(forLoanTerm: number): void {
    this.iqSidebarService.setRawResult({ type: 'email', value: forLoanTerm });
  }

  sms(forLoanTerm: number): void {
    this.iqSidebarService.setRawResult({ type: 'sms', value: forLoanTerm });
  }

  openInfoModal(): void {
    this.iqModalService.open(RefinanceOpportunityExampleComponent);
  }

  dismissOpportunity(forLoanTerm: number): void {
    this.iqSidebarService.setRawResult({ type: 'dismiss', value: forLoanTerm });
  }

  editRate(loanId: string, rateToEdit: number): void {
    this.editRateForTerm = loanId;
    this.loansFormGroup.get(loanId).setValue(rateToEdit ?? 0);
    setTimeout(() => this.document.querySelector<HTMLInputElement>(`#m_${loanId}`).focus());
  }

  acceptRate(loanTerm: number): void {
    const rateControl: AbstractControl = this.loansFormGroup.get(`${loanTerm}`);
    const newRate: number = +rateControl.value;

    if (Number.isFinite(newRate) && rateControl.valid) {
      const currentLoan: LeadMortgageCheckupsItem =
        this.refinanceData.mortgage_checkups.find((m: LeadMortgageCheckupsItem) => m.loan_term === loanTerm);

      if (currentLoan?.new_interest_rate !== newRate) {
        this.iqSidebarService.setRawResult({ type: 'update_rate', value: { newRate, loanTerm } });
      }

      this.editRateForTerm = null;
    }
  }

  closeModal(): void {
    this.iqSidebarService.close();
  }

}
