import momentMini from 'moment-mini';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { isEqual } from 'lodash-es';

import type { DateRange } from '@core/types';

// Consts
import { API_DATE_FORMAT, DEFAULT_MOMENT_DATE_FORMAT } from '@consts/consts';

// Utils
import { getDefaultDateRanges } from '@core/utils/utils';

@UntilDestroy()
@Component({
  selector: 'iq-date-range-filter',
  templateUrl: './iq-date-range-filter.component.html',
  styleUrls: ['./iq-date-range-filter.component.scss'],
})
export class IqDateRangeFilterComponent implements OnInit {

  @Input() title: string;

  @Input() filterName: string = '';

  @Input() stateValueFrom: string;

  @Input() stateValueTo: string;

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  defaultDateRanges: DateRange[] = getDefaultDateRanges();

  bsInlineRangeValue: Date[] = [];

  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,
    containerClass: 'date-range',
    dateInputFormat: DEFAULT_MOMENT_DATE_FORMAT,
    maxDate: new Date(),
    showPreviousMonth: true,
  };

  isShow: boolean = false;

  ngOnInit(): void {
    this.bsInlineRangeValue = [
      this.stateValueFrom ? new Date(this.stateValueFrom) : null,
      this.stateValueTo ? new Date(this.stateValueTo) : null,
    ];
  }

  formatDate(date: Date): string {
    return momentMini(date).format(API_DATE_FORMAT);
  }

  get selected(): string {
    if (this.stateValueFrom && this.stateValueTo && !isEqual(this.stateValueFrom, this.stateValueTo)) {
      return [
        this.stateValueFrom,
        ' - ',
        this.stateValueTo,
      ].join('');
    }

    return '';
  }

  toggle(): void {
    this.isShow = !this.isShow;
  }

  hide(): void {
    this.isShow = false;
  }

  apply([date_from, date_to]: string[]): void {
    this.emitFilterValue.emit({
      filterName: this.filterName,
      value: {
        [`${this.filterName}_from`]: date_from,
        [`${this.filterName}_to`]: date_to,
      },
    });

    this.hide();
  }

  cancel(): void {
    this.emitFilterValue.emit({
      filterName: this.filterName,
      value: {
        [`${this.filterName}_from`]: null,
        [`${this.filterName}_to`]: null,
      },
    });

    this.hide();
  }

  onValueChange(values: Date[]): void {
    const [date_from, date_to] = values;

    const fromValue: string = this.formatDate(date_from);
    const toValue: string = this.formatDate(date_to);

    if (!values.length || fromValue === toValue || (this.stateValueFrom === fromValue && this.stateValueTo === toValue)) { return; }

    this.apply([fromValue, toValue]);
  }

}
