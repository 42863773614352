import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

// Consts
import { profileErrors } from '@consts/form-errors';
import { ADD_MLS_ID_MODAL_DISPLAYED } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import {
  APIErrorResponse,
  COMMON_TOAST,
  FormErrors,
  ResponseErrorHandler,
  VaultService,
} from 'asap-team/asap-tools';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';

@UntilDestroy()
@Component({
  templateUrl: './add-mls-agent-id.component.html',
  styleUrls: ['./add-mls-agent-id.component.scss'],
})
export class AddMlsAgentIdComponent extends SimpleModalComponent<null, boolean> implements OnInit {

  form: UntypedFormGroup;

  formErrors: FormErrors = profileErrors;

  updateProfileAction$: Subscription;

  todayDate: Date = new Date();

  constructor(
    private builder: UntypedFormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private responseErrorHandler: ResponseErrorHandler,
    private vaultService: VaultService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.builder.group({ mls_number: ['', [Validators.required]] });

    this.vaultService.set(ADD_MLS_ID_MODAL_DISPLAYED, this.todayDate.setDate(+1));
  }

  completeProfile(): void {
    this.updateProfileAction$ = this
      .userService
      .updateProfile(this.form.value)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(COMMON_TOAST.CHANGES_SAVED);
          this.close();
        },
        (error: APIErrorResponse) => {
          this.responseErrorHandler.process(error, this.form, this.formErrors);
        },
      );
  }

}
