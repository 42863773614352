import type { Dictionary } from 'asap-team/asap-tools';
import type { BillingPlan, CheckboxItem, ReservationStatuses, SubscriptionStatus } from '@core/types';

export const BILLING_PLAN: Dictionary<BillingPlan> = {
  PROFESSIONAL_PLAN: 'professional_plan',
  LENDER_LIMITED_PLAN: 'lender_limited_plan',
  BASIC_PLAN: 'basic_plan',
  SOLO_PLAN: 'solo_plan',
  CONNECTED_PLAN: 'connected_plan',
  CUSTOM: 'custom',
  TRIAL: 'trial',
  LENDER_MONTHLY_PLAN: 'lender_monthly_plan',
  GROWTH_PLAN: 'growth_plan',
  PARTNER_PLAN: 'partner_plan',
  STARTER_PLAN: 'starter_plan',
};

export const SUBSCRIPTION_BLOCKS_VISIBILITY_RULES: { plan_name: BillingPlan; block_name: string[] }[] = [
  {
    plan_name: BILLING_PLAN.PROFESSIONAL_PLAN,
    block_name: [
      'plus_sign',
      'additional_fee',
      'equals_sign',
      'estimated_amount',
      'scheduled_payment',
      'payment_method',
      'payment_method_button',
    ],
  },
  {
    plan_name: BILLING_PLAN.LENDER_LIMITED_PLAN,
    block_name: ['payment_method'],
  },
  {
    plan_name: BILLING_PLAN.BASIC_PLAN,
    block_name: [
      'scheduled_payment',
      'payment_method',
      'payment_method_button',
    ],
  },
  {
    plan_name: BILLING_PLAN.SOLO_PLAN,
    block_name: ['payment_method'],
  },
  {
    plan_name: BILLING_PLAN.CONNECTED_PLAN,
    block_name: ['payment_method'],
  },
  {
    plan_name: BILLING_PLAN.CUSTOM,
    block_name: [
      'plus_sign',
      'additional_fee',
      'equals_sign',
      'estimated_amount',
      'scheduled_payment',
      'payment_method',
      'payment_method_button',
    ],
  },
  {
    plan_name: BILLING_PLAN.LENDER_MONTHLY_PLAN,
    block_name: [
      'payment_method',
    ],
  },
  {
    plan_name: BILLING_PLAN.GROWTH_PLAN,
    block_name: [
      'payment_method',
    ],
  },
  {
    plan_name: BILLING_PLAN.PARTNER_PLAN,
    block_name: [
      'payment_method',
    ],
  },
  {
    plan_name: BILLING_PLAN.STARTER_PLAN,
    block_name: [
      'payment_method',
    ],
  },
];

export const AGENT_RESERVATION_PLANS: CheckboxItem[] = [{
  code: BILLING_PLAN.TRIAL,
  name: 'Trial',
}, {
  code: BILLING_PLAN.SOLO_PLAN,
  name: 'Solo Plan',
}, {
  code: BILLING_PLAN.CONNECTED_PLAN,
  name: 'Connected Plan',
}];

export const LENDER_RESERVATION_PLANS: CheckboxItem[] = [{
  code: BILLING_PLAN.PARTNER_PLAN,
  name: 'Partner Plan',
}, {
  code: BILLING_PLAN.LENDER_MONTHLY_PLAN,
  name: 'Monthly Plan',
}, {
  code: BILLING_PLAN.GROWTH_PLAN,
  name: 'Growth Plan',
}, {
  code: BILLING_PLAN.STARTER_PLAN,
  name: 'Starter Plan',
}];

export const RESERVE_STATUS: ReservationStatuses = {
  APPROVED: 'approved',
  REQUEST: 'request',
  PENDING: 'pending',
  UNPAID: 'unpaid',
  PAID: 'paid',
};

export const PAYMENT_STATUS: Dictionary = {
  PENDING: 'pending',
  PAID: 'paid',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  REQUEST_CANCEL: 'request_cancel',
};

export const SUBSCRIPTION_STATUS: Dictionary<SubscriptionStatus> = {
  TRIAL: 'trial',
  PAID: 'paid',
  PAUSED: 'paused',
  MONITORING_PROGRAM: 'monitoring_program',
  REQUEST_CANCEL: 'request_cancel',
};

export const TRIAL_LIMITATIONS: string[] = [
  'Customer support',
  'Pre-mover score monitoring',
  'Home value updates every month',
  'Buyer funnel with landing page',
  'Refi funnel with landing page',
  'Marketing banner suite',
  'Realtor onboarding',
  'Refi scanner',
];

export const AGENT_LIMITATIONS: string[] = [
  'Pre-mover score monitoring',
  'Home value updates every month',
  'Buyer funnel with landing page',
  'Seller funnel with landing page',
  'Marketing banner suite',
  'Customer support',
  'Refi scanner',
];

export const PAUSED_LIMITATIONS: string[] = [
  'Home value updates every month',
  'Pre-mover score monitoring',
  'Buyer funnel with landing page',
  'Refi funnel with landing page',
  'Marketing banner suite',
  'Realtor onboarding',
  'Customer support',
  'Refi scanner',
];
