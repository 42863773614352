import { InjectionToken } from '@angular/core';

export const NEW_ACCOUNTS: InjectionToken<unknown> = new InjectionToken('NEW_ACCOUNTS');
export const HOMEOWNERS_BANNERS: InjectionToken<unknown> = new InjectionToken('HomeownersBannersCollectionStoreService');
export const HOMEOWNERS_WEALTH_BANNERS: InjectionToken<unknown> = new InjectionToken('HomeownersWealthBannersCollectionStoreService');
export const KUDOS_COLLECTION: InjectionToken<unknown> = new InjectionToken('KudosCollectionStoreService');
export const BUYERS_BANNERS: InjectionToken<unknown> = new InjectionToken('BuyersBannersCollectionStoreService');
export const ADMIN_HOMEOWNERS_BANNERS: InjectionToken<unknown> = new InjectionToken('AdminHomeownersBannersCollectionStoreService');
export const ADMIN_BUYERS_BANNERS: InjectionToken<unknown> = new InjectionToken('AdminBuyersBannersCollectionStoreService');
export const LEAD_SERVICE: InjectionToken<unknown> = new InjectionToken('LeadService');
export const LEAD_ACTIVITY: InjectionToken<unknown> = new InjectionToken('LeadActivityService');
export const LEADS_ACTIVITY: InjectionToken<unknown> = new InjectionToken('LeadsActivityService');
export const SELLER_DIGEST_COLLECTION: InjectionToken<unknown> = new InjectionToken('SellerDigestService');
export const HISTORY_PENDING_COLLECTION: InjectionToken<unknown> = new InjectionToken('HistoryPendingService');
export const HISTORY_PROCESSED_COLLECTION: InjectionToken<unknown> = new InjectionToken('HistoryPrecessedService');
export const ARCHIVE_LEAD_COLLECTION: InjectionToken<unknown> = new InjectionToken('ArchiveLeadService');
export const CSV_UPLOADS_COLLECTION: InjectionToken<unknown> = new InjectionToken('CsvUploadsService');
export const PROMO_CODES: InjectionToken<unknown> = new InjectionToken('PromoCodesService');
export const STAFF_COLLECTION: InjectionToken<unknown> = new InjectionToken('StaffService');
export const SUBSCRIPTIONS_COLLECTION: InjectionToken<unknown> = new InjectionToken('SubscriptionsService');
export const NEW_ACCOUNTS_STATS: InjectionToken<unknown> = new InjectionToken('NewAccountsStatsService');
export const VIRAL_ACCOUNTS_COLLECTION: InjectionToken<unknown> = new InjectionToken('ViralAccountsService');
export const VIRAL_ACCOUNTS_STATS: InjectionToken<unknown> = new InjectionToken('ViralStatsService');
export const TRIAL_ACCOUNTS_STATS: InjectionToken<unknown> = new InjectionToken('TrialAccountsStatsService');
export const ARCHIVED_ACCOUNTS_COLLECTION: InjectionToken<unknown> = new InjectionToken('ArchivedAccountService');
export const ACTIVE_PARTNERS_COLLECTION: InjectionToken<unknown> = new InjectionToken('ActivePartnersCollection');
export const ACCOUNT_ACTIVE_PARTNERS_COLLECTION: InjectionToken<unknown> = new InjectionToken('AccountActivePartnersCollection');
export const INCOMING_INVITES_COLLECTION: InjectionToken<unknown> = new InjectionToken('IncomingInvitesCollection');
export const OUTGOING_INVITES_COLLECTION: InjectionToken<unknown> = new InjectionToken('OutgoingInvitesCollection');
