import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DonutChartData } from '@commons/diagrams';

@Component({
  selector: 'loan-type-diagram',
  templateUrl: './loan-type-diagram.component.html',
  styleUrls: ['./loan-type-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanTypeDiagramComponent {

  @Input() loanTypesChartData: DonutChartData[] = [];

  get total(): number {
    return this.loanTypesChartData?.reduce((acc: number, curr: DonutChartData) => acc + curr?.value, 0) || 0;
  }

}
