import { Injectable, Injector } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { NETWORK_INFORMATION } from '@ng-web-apis/common';
import { EMPTY, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PreloadingStrategyService implements PreloadingStrategy {

  constructor(
    private injector: Injector,
  ) {}

  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    const connection: any = this.injector.get(NETWORK_INFORMATION);

    // Don't preload lazy modules if the connection is slower than '3g' and the data saving mode is enabled
    if (connection?.saveData || (connection?.effectiveType || '').includes('2g')) {
      return EMPTY;
    }

    return fn();
  }

}
