<ul class="desktop-nav__menu">
  <li
    class="desktop-nav__menu-item"
    [class.d-flex]="item.counterName"
    *ngFor="let item of navigation">
    <a class="desktop-nav__menu-link"
       [class.p-r-1x]="item.counterName"
       [routerLink]="item.routerLink"
       routerLinkActive="active"
       #i="routerLinkActive"
       [class.desktop-nav__menu-link--overlayed]="!i.isActive">
      {{ item.label }}
    </a>
    <notifications-counter
      *ngIf="item.counter"
      [counterKey]="item.counter"
      [counterName]="item.counterName">
    </notifications-counter>
  </li>
</ul>
