import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';

export type RangeSettings = {
  max: number;
  max_default: number;
  min: number;
  min_default: number;
};

export type Range = {
  min: number;
  max: number;
};

@UntilDestroy()
@Component({
  selector: 'iq-range-filter',
  templateUrl: './iq-range-filter.component.html',
  styleUrls: ['./iq-range-filter.component.scss'],
})
export class IqRangeFilterComponent implements OnChanges {

  @Input() settings: RangeSettings = null;

  @Input() title: string = '';

  @Input() maxValue: number = 0;

  @Input() minValue: number = 0;

  @Output() rangePicked: EventEmitter<Range> = new EventEmitter();

  options: Options = {};

  selected: string;

  defaults: Options = {
    hideLimitLabels: true,
    hidePointerLabels: true,
    floor: 0,
    ceil: 10,
    step: 0.25,
  };

  ngOnChanges(changes: SimpleChanges): void {
    const settings: RangeSettings = changes?.settings?.currentValue;

    if (!settings) {
      return;
    }

    if (!changes?.model?.previousValue && !(this.minValue || this.maxValue)) {
      this.maxValue = settings.max || settings.max_default;
      this.minValue = settings.min || settings.min_default;
    }

    this.options = {
      ...this.defaults,
      ceil: settings.max || this.defaults.ceil,
      floor: settings.min || this.defaults.floor,
    };

    if (this.minValue !== this.settings.min_default || this.maxValue !== this.settings.max_default) {
      this.selected = this.formatSelected(this.minValue, this.maxValue);
    }
  }

  reset(): void {
    this.selected = undefined;
    this.maxValue = this.settings.max || this.settings.max_default;
    this.minValue = this.settings.min || this.settings.min_default;
  }

  clear(): void {
    this.reset();
    this.rangePicked.emit({
      min: this.settings.min_default,
      max: this.settings.max_default,
    });
  }

  select({ value, highValue }: ChangeContext): void {
    if (this.selected === this.formatSelected(value, highValue)) {
      return;
    }

    this.selected = this.formatSelected(value, highValue);

    this.rangePicked.emit({
      min: value,
      max: highValue,
    });
  }

  formatSelected(value: number, highValue: number): string {
    return `${value}% - ${highValue}%`;
  }

}
