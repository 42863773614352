import * as Sentry from '@sentry/browser';
import { Component, OnInit, Inject } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import {
  take,
  map,
  filter,
  startWith,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import {
  Event,
  Router,
  NavigationEnd,
  NavigationStart,
  RouterEvent,
} from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { uID } from 'asap-team/asap-tools';

import type { Profile, RouteDictionary } from '@core/types';

// Consts
import { environment } from 'environments/environment';
import { ROUTE, USER_ROLE, BILLING_PLAN } from '@consts/consts';
// Services
import { UserService } from '@core/services/user/user.service';
import { IntercomService } from '@core/vendors/intercom/intercom.service';
import { ProfileHashService } from '@core/helpers/profile-hash/profile-hash.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReleaseWardenService } from '@core/helpers/release-warden/release-warden.service';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';
import { BillingService } from '@core/services/billing/billing.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  ROUTE: RouteDictionary = ROUTE;

  currentUrl: string = null;

  previousUrl: string = null;

  get isLoggedIn$(): Observable<boolean> {
    return this.userService.isLoggedIn();
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private userService: UserService,
    private intercomService: IntercomService,
    private asaplyticsService: AsaplyticsService,
    private profileHashService: ProfileHashService,
    private clipboardService: ClipboardService,
    private toastrService: ToastrService,
    private releaseWardenService: ReleaseWardenService,
    private billingService: BillingService,
  ) {}

  ngOnInit(): void {
    // init tracking (ga and gtag)
    this.asaplyticsService.init();

    const profile$: Observable<Profile> = this.userService.profile$.pipe(shareReplay(1));

    profile$.pipe(
      switchMap((profile: Profile) => {
        this.asaplyticsService.setCustomDimensions(profile);

        if (profile.role === USER_ROLE.lender &&
            (profile.plan_name === BILLING_PLAN.PARTNER_PLAN || profile.plan_name === BILLING_PLAN.STARTER_PLAN)) {
          return this.billingService.handlePaymentIssue().pipe(untilDestroyed(this));
        }

        return [];
      }),
    ).subscribe();

    this
      .router
      .events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.checkRouterEvent(event));

    // init profile hash tracking
    this
      .profileHashService
      .startProfileHashObservation()
      .subscribe();

    // Initialization of clipboard success copy listener
    this
      .clipboardService
      .copyResponse$
      .subscribe(() =>  this.toastrService.success('Copied to clipboard'));

    // Intercom
    if (environment.production) {
      combineLatest([
        profile$,
        this.intercomService.intercom$,
      ])
        .pipe(
          take(2),
        )
        .subscribe(([profile, status]: [Profile, string]) => {
          if (!status) { return }

          this.intercomService.start({
            email: profile.email,
            user_id: profile.id,
          });
        });

      this.intercomService.init();
    }

    // Sentry
    if (environment.sentryDSN) {
      Sentry.configureScope((scope: Sentry.Scope) => {
        scope.setUser({ id: uID('unauthorized_user') });
      });

      this
        .userService
        .profile$
        .subscribe(
          ({ id, email, username }: Profile) => {
            Sentry.configureScope((scope: Sentry.Scope) => {
              scope.setUser({
                id, email, username,
              });
            });
          },
        );
    }

    // init updates checker
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event: RouterEvent) => event instanceof NavigationStart),
        map((route: NavigationStart) => route.url),
        startWith(this.router.routerState.snapshot.url),
      )
      .subscribe(() => this.releaseWardenService.enlist());
  }

  /**
  * This is used to intercept and show Loading bar based on the current state of Router navigation
  *
  * @param {Event} event
  */

  private checkRouterEvent(event: NavigationEnd): void {
    this.previousUrl = this.currentUrl;
    this.currentUrl = event.url;
    this.window.scrollTo(0, 0);
    this.asaplyticsService.trackPageForUrl(event.urlAfterRedirects);
  }

}
