<div class="currency-filter" dropdown="" [insideClick]="true" [container]="appendSelector">
  <iq-button dropdownToggle
             [label]="filterLabelName"
             size="40"
             color="grey"
             iconRight="#icon-chevron"></iq-button>
  <div [formGroup]="form" class="currency-filter__menu dropdown-menu" *dropdownMenu>
    <iq-form-control-amount class="alternative-label m-b-1x"
                            [label]="minInputLabel"
                            [prefix]="inputsMask"
                            [mask]="'0*'"
                            formControlName="min"></iq-form-control-amount>
    
    <iq-form-control-amount class="alternative-label"
                            [label]="maxInputLabel"
                            [prefix]="inputsMask"
                            [mask]="'0*'"
                            formControlName="max"></iq-form-control-amount>
    <iq-button class="m-t-2x"
               *ngIf="form.value?.min || form.value?.max"
               label="Clear"
               size="24"
               color="grey"
               iconRight="#icon-close"
               (click)="clear()"></iq-button>
  </div>
</div>
