<form [formGroup]="form" (ngSubmit)="updateIdx()">
  <div class="form-switch__group m-t-40 m-b-4">
    <label class="form-switch">
      <input class="form-switch__control" type="checkbox" id="idx_link" formControlName="idx_enabled"/>
      <div class="form-switch__slider"></div>
      <div class="form-switch__bg"></div>
    </label>
    <label class="form-switch__group-title subtitle" for="idx_link">IDX Link</label>
  </div>
  <p class="description m-t-4 m-b-16">Add a link to your IDX search or current listings. A section on the BuyerIQ result
    page and in the myHomeIQ report will be added with a link to your website.</p>
  <iq-form-control-text label="Link" placeholder="https://your-personal-link.com" formControlName="idx_url">
    <div class="m-l-1x m-r-2x m-b-auto m-t-auto">
      <iq-button label="Save" size="24" type="submit" [style]="'outline'" [disabled]="!isActionEnabled"></iq-button>
    </div>
  </iq-form-control-text>
</form>
