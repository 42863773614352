import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'countdown-modal',
  templateUrl: './countdown-modal.component.html',
  styleUrls: ['./countdown-modal.component.scss'],
})
export class CountdownModalComponent extends SimpleModalComponent<null, null> implements OnInit {

  progress: string = '100%';

  ngOnInit(): void {
    let percent: number = 100;

    timer(1000, 100)
      .pipe(
        take(100),
        tap(() => {
          this.progress = `${percent}%`;
          percent -= 1;
        }),
      )
      .subscribe();
  }

  onCountdownEnd(): void {
    this.close();
  }

}
