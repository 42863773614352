import { Component, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { FormError, provideRefs } from 'asap-team/asap-tools';
import { ContentChange } from 'ngx-quill';

@Component({
  selector: 'iq-form-control-editor',
  templateUrl: './iq-form-control-editor.component.html',
  styleUrls: ['./iq-form-control-editor.component.scss'],
  providers: provideRefs(IqFormControlEditorComponent),
})
export class IqFormControlEditorComponent implements ControlValueAccessor {

  @Input() errorsModel: FormError[];

  @Input() formControlName: string;

  @Input() readonly: boolean = false;

  form: UntypedFormGroup = this.fb.group({ editor: [''] });

  focused: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
  ) {}

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string): void {
    if (!value) { return; }

    this.form.patchValue({ editor: value });
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  validate(): ValidationErrors | null {
    return null;
  }

  focus(): void {
    this.focused = true;
  }

  blur(): void {
    this.focused = false;
    this.onTouched();
  }

  onEditorContentChange(event: ContentChange): void {
    this.onChange(this.sanitizeInputValue(event.html));
  }

  private sanitizeInputValue(value: string): string {
    if (!value) { return ''; }

    return value
      .replace(/&nbsp;+/gi, ' ')
      .trim()
      .replace(/\s+/gi, ' ')
      .replace(/^\s+/gi, '');
  }

}
