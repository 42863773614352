<div class="leads">
  <div class="import-errors" *ngIf="info.unsubscribed_items">
    <div class="leads-failed text m-b-5">
      <span>Unsubscribed</span><b>{{ info.unsubscribed_items }}</b>
    </div>
    <button class="btn link m-l-0 text-left" (click)="toggle()">
      {{ isOpen ? 'Less' : 'More'}} info
    </button>
    <div class="leads-failed-info m-t-5" *ngIf="isOpen">
      <p class="emails text-overflow" *ngFor="let item of info.unsubscribed">{{ item }}</p>
    </div>
  </div>
</div>
