import { Route } from '@angular/router';

// Consts
import { ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';
import { RegistrationCompletedGuard } from '@core/guards/registration-completed/registration-completed.guard';
import { AccountStatusGuard } from '@core/guards/account-status/account-status.guard';

export const PARTNERSHIP: Route = {
  path: ROUTE.name.PARTNERSHIP,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, AccountStatusGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, AccountStatusGuard],
  loadChildren: () => import('@modules/user/collections/partnerships/partnerships.module').then((m: any) => m.PartnerShipsModule),
  data: { canBeActivatedBy: [USER_ROLE.agent, USER_ROLE.lender] },
};
