import {
  Type,
  Input,
  OnInit,
  Component,
  ViewChild,
  ComponentRef,
  ViewContainerRef,
} from '@angular/core';

import type { Alert } from '@core/types';

// Consts
import { ALERTS_TYPE } from '@consts/alerts';

// Components
import {
  ConfirmEmailComponent,
  UpgradeSubscriptionComponent,
  SubscriptionStatusWarningComponent,
  PaymentProblemComponent,
} from './index';

type Union = ConfirmEmailComponent |
UpgradeSubscriptionComponent |
SubscriptionStatusWarningComponent |
PaymentProblemComponent;

@Component({
  selector: 'global-alert-item',
  templateUrl: './alerts.component.html',
})
export class AlertsComponent implements OnInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

  @Input() type: Alert;

  readonly templates: any = {
    [ALERTS_TYPE.CONFIRM]: ConfirmEmailComponent,
    [ALERTS_TYPE.UPGRADE_SUBSCRIPTION]: UpgradeSubscriptionComponent,
    [ALERTS_TYPE.PAUSED_SUBSCRIPTION]: SubscriptionStatusWarningComponent,
    [ALERTS_TYPE.MONITORING_PROGRAM]: SubscriptionStatusWarningComponent,
    [ALERTS_TYPE.PAYMENT_PROBLEM]: PaymentProblemComponent,
  };

  ngOnInit(): void {
    const component: Type<Union> = this.getComponentForItemType(this.type);

    if (!component) { return; }

    const componentRef: ComponentRef<Union> = this.createComponent(component);

    (<Union>componentRef.instance).type = this.type;
  }

  private createComponent(component: Type<Union>): ComponentRef<Union> {
    const viewContainerRef: ViewContainerRef = this.container;

    viewContainerRef.clear();

    return viewContainerRef.createComponent(component);
  }

  private getComponentForItemType(type: string): Type<Union> {
    return this.templates[type];
  }

}
