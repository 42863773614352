<form [formGroup]="form">
  <quill-editor [class.focus]="focused"
                [class.invalid]="form.controls.editor.invalid && form.controls.editor.touched"
                [class.valid]="form.controls.editor.valid && form.controls.editor.touched"
                [class.readonly]="readonly"
                [placeholder]="readonly ? '' : 'Enter text here...'"
                formControlName="editor"
                [readOnly]="readonly"
                [modules]="readonly && { toolbar: null }"
                (onFocus)="focus()"
                (onBlur)="blur()"
                (onContentChanged)="onEditorContentChange($event)"></quill-editor>
</form>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
