import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterContentChecked,
} from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';

@Component({
  selector: 'form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormListComponent implements AfterContentChecked {

  @Input() list: FormArray;

  @Input() isReadonly: boolean = false;

  @Input() addButtonIcon?: string;

  @Output() remove: EventEmitter<any> = new EventEmitter();

  @Output() add: EventEmitter<any> = new EventEmitter();

  get controls(): AbstractControl[] {
    return this.list?.controls;
  }

  constructor(
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  addControl(): void {
    this.add.emit(true);
  }

  removeControl(index: number): void {
    this.remove.emit(index);
  }

}
