import type { Dictionary } from 'asap-team/asap-tools';

export const COMMON_TOAST: Dictionary = {
  SAVED: 'Saved',
  REMOVED: 'Removed',
  SUCCESS: 'Success',
  DONE: 'Done',
  HOME_DETAILS_UPDATED: 'Home details was updated',
  CHANGES_SAVED: 'Changes saved',
  SETTINGS_SAVED: 'Settings saved',
  BG_IMAGE_SAVED: 'Background image was saved',
  BG_IMAGE_REMOVED: 'Background image was removed',
  LOGO_IMAGE_SAVED: 'Logo of company was saved',
  LOGO_IMAGE_REMOVED: 'Logo of company was removed',
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  CONFIRMATION_CODE_SENT: 'Confirmation code was sent to your phone',
  INVITATION_SENT_TO_USER: 'The invitation has been sent to this user',
  CONFIRMATION_LINK_EXPIRED: 'This email confirmation link has expired',
  NET_SHEET_SENT: 'Net Sheet sent',
  SENT: 'Request sent',
  SENT_TO_ADMIN: 'Request was send to an admin',
  ACCESS_GRANTED: 'You’ve granted access to admin panel for 60 minutes',
  ACCEPT_TERMS: 'You have to accept our Terms of Service to continue',
  SMS_REPORTS_IS_ACTIVATED: 'SMS Reports is activated',
  SMS_REPORTS_IS_DEACTIVATED: 'SMS Reports is deactivated',
  UPDATED: 'Successfully updated',
};

export const PROMO_CODE: Dictionary = {
  ADD: 'Promo code was added',
  UPDATED: 'Promo code updated',
  ACTIVATED: 'Promo code was activated',
  DEACTIVATED: 'Promo code was deactivated',
};

export const COMMISSIONS: Dictionary = {
  UPDATED: 'Commissions updated',
  NOT_VALID: 'Commissions is not valid',
  REMOVE_FAILED: 'At least one commission should be present',
};

export const PASSWORD: Dictionary = {
  SENT: 'The password was sent to your email',
  CHANGE: 'Your password was changed',
};

export const LEAD: Dictionary = {
  CREATED: 'Lead was created',
  UPDATED: 'Lead was updated',
  ARCHIVED: 'Lead was archived',
  RESTORED: 'Lead was restored',
  DELETED: 'Lead was deleted',
  DRAFT_SAVED: 'Draft report saved',
};

export const LOAN: Dictionary = {
  CREATED: 'Loan was created',
  UPDATE: 'Loan was updated',
  DELETED: 'Loan was deleted',
  EXPIRED_DATE_UPDATED: 'Date has been successfully updated',
};

export const ACCOUNT: Dictionary = {
  CREATED: 'Account created',
  DELETED: 'Account was deleted',
  RESTORED: 'Account was restored',
  DISABLED: 'Account is archived',
  BLOCKED: 'Account blocked',
  UNBLOCKED: 'Account unblocked',
};

export const DATABASE: Dictionary = {
  DELETED: 'Database was deleted',
  LAST_IMPORT_DELETED: 'Leads from the latest import were deleted',
};

export const REPORT: Dictionary = {
  SENT: 'Report was sent successfully',
  ACTIVATED: 'Report was activated',
  DEACTIVATED: 'Report was deactivated',
  PROCESSING: 'Reports are processing',
};

export const ORDER: Dictionary = {
  ADD: 'Order was added',
  COMPLETE: 'Order completed',
  DELETE: 'Order was deleted',
  UPDATE: 'Order updated',
  ERROR_RESERVED: 'Order already reserved',
};

export const SUBSCRIPTION: Dictionary = {
  UPDATED: 'Subscription updated',
  CANCEL: 'Subscription cancelled',
  RESUME: 'Subscription renewed',
  PAUSE: 'Subscription paused',
  ENROLL: 'Monitoring program enrolled',
  REVOKE: 'Monitoring program revoked',
};

export const PAYMENT_METHOD: Dictionary = {
  UPDATED: 'Card updated',
  DELETED: 'Card removed',
  PRIMARY_CHANGED: 'Primary payment method updated',
  ERROR_ALREADY_SELECTED: 'Payment method already applied as primary',
};

export const MORTGAGE_CHECKUP: Dictionary = {
  SENT: 'Offer sent',
  DISMISS: 'Offer dismissed',
  NEW_RATE: 'New rate was updated',
  PDF_GENERATED: 'The PDF file was generated',
};

export const INVOICE: Dictionary = {
  SENT: 'Invoice sent',
  UPDATED: 'Invoice updated',
};

export const APP_STATUS_MESSAGE: Dictionary = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const BANNER: Dictionary = {
  CREATED: 'Banner was created',
  UPDATED: 'Banner was updated',
  DELETED: 'Banner was deleted',
  POST_CREATED: 'Post created',
  POST_ERROR: 'Something went wrong',
};

export const PARTNER: Dictionary = {
  CREATED: 'Partner was created',
  UPDATED: 'Partner was updated',
};

export const INVITE: Dictionary = {
  SENT: 'Invitation has been sent',
  RESEND: 'Invitation has been resent',
  DECLINED: 'Invitation has been declined',
  ACCEPTED: 'Invitation has been accepted',
  CANCELED: 'Invitation has been canceled',
};

export const APP_LINK: Dictionary = {
  SAVED: 'Application link saved',
  DISABLED: 'Application link disabled',
};

export const IDX_LINK: Dictionary = {
  SAVED: 'IDX link saved',
  DISABLED: 'IDX link disabled',
};

export const THANK_YOU_VIDEO_LINK: Dictionary = {
  SAVED: 'Thank you video link saved',
  DISABLED: 'Thank you video link disabled',
};

export const REFERRALS: Dictionary = {
  SENT: 'Referral invitation sent',
  ERROR: "Error: Referral invitation wasn't sent",
};

export const CANCELATION_SURVEY: Dictionary = { SEND: 'Thank you! Your survey was sent' };
