<div *ngIf="loading; else filtersContent" class="filters-skeleton p-t-2x p-b-3x">
  <ng-container *ngIf="mobileMode; else filterSkeletonContent">
    <ngx-skeleton-loader appearance="line"
                         [theme]="{ width: '100px', height: '22px', margin: 0, 'border-radius': '8px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader class="m-l-auto"
                         appearance="line"
                         [theme]="{ width: '40px', height: '40px', margin: 0, 'border-radius': '8px'}"></ngx-skeleton-loader>
  </ng-container>
  <ng-template #filterSkeletonContent>
    <ngx-skeleton-loader appearance="line"
                         [theme]="{ width: '100px', height: '40px', margin: 0, 'border-radius': '8px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line"
                         [theme]="{ width: '100px', height: '40px', margin: 0, 'border-radius': '8px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line"
                         [theme]="{ width: '100px', height: '40px', margin: 0, 'border-radius': '8px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line"
                         [theme]="{ width: '100px', height: '40px', margin: 0, 'border-radius': '8px'}"></ngx-skeleton-loader>
  </ng-template>
</div>

<ng-template #filtersContent>
  <div class="iq-filters" [class.mobile-view]="mobileMode">
    <div class="iq-filters-total">
      {{ totalItems }} {{ totalItemsLabel }}
      <i *ngIf="totalTooltip && !mobileMode"
         class="inline-svg inline-svg--12 pointer"
         [inlineSVG]="'#icon-question'"
         tooltip="The number of transactions may differ from those in the Details, as double-sided transactions are counted as a single transaction in this table."
         container="body"></i>
    </div>

    <iq-button *ngIf="isShowClearBtn && !hideToggleButton"
               class="iq-filters-hidden-element m-r-auto"
               [label]="'Clear'"
               [style]="'outline'"
               [color]="'grey'"
               [size]="'40'"
               [labelAlignClass]="'m-left'"
               [iconLeft]="'#icon-close'"
               (onClick)="onFiltersClear.emit()"></iq-button>

    <div class="iq-filters-container" (clickOutside)="hideMobileFilters()">
      <div class="iq-filters-dropdown" [class.open]="showMobileFilters">
        <div class="iq-filters-dropdown__header iq-filters-hidden-element">
          <span class="f-24-600 l-h-36">Filters</span>
          <button class="iq-filters-dropdown-close" (click)="hideMobileFilters()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.63606 18.7071C6.02659 19.0976 6.65975 19.0976 7.05028 18.7071L18.364 7.3934C18.7545 7.00287 18.7545 6.36971 18.364 5.97918C17.9735 5.58866 17.3403 5.58866 16.9498 5.97918L5.63606 17.2929C5.24554 17.6834 5.24554 18.3166 5.63606 18.7071Z"
                    class="svg-fill-color"/>
              <path fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.05025 5.70711C6.65972 5.31658 6.02656 5.31658 5.63603 5.70711C5.24551 6.09763 5.24551 6.7308 5.63603 7.12132L16.9497 18.435C17.3403 18.8256 17.9734 18.8256 18.364 18.435C18.7545 18.0445 18.7545 17.4113 18.364 17.0208L7.05025 5.70711Z"
                    class="svg-fill-color"/>
            </svg>
          </button>
        </div>

        <!-- Filters components projection -->
        <div class="iq-filters-dropdown__content">
          <ng-content></ng-content>
          <iq-button *ngIf="isShowClearBtn && !hideDesktopClearBtn"
                     class="iq-filters-clear-btn"
                     [label]="'Clear all'"
                     [style]="'outline'"
                     [color]="'grey'"
                     [size]="'40'"
                     [labelAlignClass]="'m-left'"
                     [iconLeft]="'#icon-close'"
                     (onClick)="onFiltersClear.emit()"></iq-button>
        </div>

        <div class="iq-filters-dropdown__footer iq-filters-hidden-element">
          <div class="m-b-1x text-center f-16-400 l-h-24 f-c-slate">{{ totalItems }} {{ totalItemsLabel }}</div>
          <div class="iq-filters-dropdown__actions">
            <iq-button [label]="'Clear'"
                       [style]="'outline'"
                       [color]="'grey'"
                       [size]="'40'"
                       [labelAlignClass]="'m-left'"
                       [iconLeft]="'#icon-close'"
                       (onClick)="hideMobileFilters(true)"></iq-button>

            <iq-button [label]="'Apply'" [size]="'40'" (onClick)="hideMobileFilters()"></iq-button>
          </div>
        </div>
      </div>

      <div class="iq-filters-controls">
        <!--Mobile filter toggle-->
        <button *ngIf="!hideToggleButton"
                class="iq-filters-square-ctrl toggle iq-filters-hidden-element"
                [class.active]="isShowClearBtn && !isShowSearchControl"
                (click)="showMobileFilters = !showMobileFilters">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"
                  class="svg-fill-color"/>
          </svg>
        </button>

        <div *ngIf="showSearch"
             class="iq-filters-search"
             [class.open]="isShowSearchControl"
             (clickOutside)="showSearchControl = false">
          <button class="iq-filters-square-ctrl iq-filters-search__toggle"
                  (click)="showSearchControl = true; searchElementRef.focus()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M21 21L15.8917 15.8917M18.1111 10.5556C18.1111 14.7284 14.7284 18.1111 10.5556 18.1111C6.38274 18.1111 3 14.7284 3 10.5556C3 6.38274 6.38274 3 10.5556 3C14.7284 3 18.1111 6.38274 18.1111 10.5556Z"
                    class="svg-stroke-color"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </button>

          <input #searchElementRef
                 class="iq-filters-search__input"
                 type="text"
                 placeholder="Search"
                 data-lpignore="true"
                 [formControl]="searchControl"/>

          <button *ngIf="!!searchElementRef.value"
                  class="iq-filters-square-ctrl iq-filters-search__close"
                  (click)="clearSearchInput()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                    class="svg-fill-color"/>
            </svg>
          </button>
        </div>

        <button *ngIf="showColumnsPicker" class="iq-filters-square-ctrl">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M5 21L5 15M5 15C6.10457 15 7 14.1046 7 13C7 11.8954 6.10457 11 5 11C3.89543 11 3 11.8954 3 13C3 14.1046 3.89543 15 5 15ZM5 7V3M12 21V15M12 7V3M12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7ZM19 21V17M19 17C20.1046 17 21 16.1046 21 15C21 13.8954 20.1046 13 19 13C17.8954 13 17 13.8954 17 15C17 16.1046 17.8954 17 19 17ZM19 9V3"
                  class="svg-stroke-color"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</ng-template>
