import { last } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { APIError } from '@core/types';

// Consts
import { COMMON_TOAST } from '@consts/consts';

// Services
import { BuyerSettingsService } from '@core/services/settings/buyer-settings/buyer-settings.service';
import { HomeownerSettingsService } from '@core/services/settings/homeowner-settings/homeowner-settings.service';

@UntilDestroy()
@Component({
  selector: 'landing-background',
  templateUrl: './landing-background.component.html',
  styleUrls: ['./landing-background.component.scss'],
})
export class LandingBackgroundComponent implements OnInit, OnChanges {

  @Input() image: string;

  @Input() type: string;

  @Output() imageChanged: EventEmitter<any> = new EventEmitter();

  fileName: string;

  fileError: APIError<string>[];

  isFileLoading: boolean = false;

  svgStyle: any = { fill: '#536880' };

  constructor(
    private toastr: ToastrService,
    private buyerSettingsService: BuyerSettingsService,
    private homeownerSettingsService: HomeownerSettingsService,
  ) {}

  ngOnInit(): void {
    if (this.image) {
      this.setFileName(this.image);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.image && changes.image.currentValue) {
      this.setFileName(changes.image.currentValue);
    }
  }

  onImageSelected(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target.files && target.files.length > 0) {
      this.upload((target.files as FileList)[0]);
    }
  }

  upload(image: File): void {
    this.isFileLoading = true;

    const formData: FormData = new FormData();

    formData.append('background', image);

    const observe: Observable<string> = this.type === 'buyer' ? this.buyerSettingsService.uploadBackgroundImage(formData) : this.homeownerSettingsService.uploadBackgroundImage(formData);

    observe
      .pipe(
        untilDestroyed(this),
        catchError((error: any) => {
          this.isFileLoading = false;
          this.fileError = error.error.errors;

          return throwError(error);
        }),
      )
      .subscribe(
        (landing_photo_url: string) => {
          this.imageChanged.emit(true);
          this.isFileLoading = false;
          this.setFileName(landing_photo_url);
          this.toastr.success(COMMON_TOAST.BG_IMAGE_SAVED);
        },
      );
  }

  private setFileName(image: string): void {
    if (image) {
      this.fileName = last(image.split('/'));
    }
  }

  remove(): void {
    this.isFileLoading = true;
    this.clearError();

    const observe: Observable<void> = this.type === 'buyer' ? this.buyerSettingsService.deleteBackgroundImage() : this.homeownerSettingsService.deleteBackgroundImage();

    observe
      .pipe(
        untilDestroyed(this),
        catchError((error: any) => {
          this.isFileLoading = false;
          this.fileError = error.error.errors;

          return throwError(error);
        }),
      )
      .subscribe(
        () => {
          this.imageChanged.emit(true);
          this.fileName = null;
          this.isFileLoading = false;
          this.toastr.success(COMMON_TOAST.BG_IMAGE_REMOVED);
        },
      );
  }

  clearError(): void {
    this.fileError = [];
  }

}
