import { formatCardExpiry } from 'payment';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { catchError } from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormErrors, toDashCase } from 'asap-team/asap-tools';

import type { APIError, APIErrorResponse, PaymentMethod } from '@core/types';

// Consts
import { updateCardErrors } from '@consts/consts';

// Validators
import { cardExpiryValidator } from '@core/validators/card-expiry.validator';

// Services
import { BillingService } from '@core/services/billing/billing.service';

@UntilDestroy()
@Component({
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.scss'],
})
export class UpdateCardComponent
  extends SimpleModalComponent<{ card: PaymentMethod }, { success: boolean; isPrimary: boolean }>
  implements OnInit {

  @ViewChild('update_card_date') update_card_date: ElementRef;

  card: PaymentMethod;

  form: UntypedFormGroup;

  errors: APIError<any>[];

  action: Subscription;

  formErrors: FormErrors = updateCardErrors;

  constructor(
    private builder: UntypedFormBuilder,
    private billingService: BillingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.builder.group({
      date: [this.formatedCardDate || '', [Validators.required, cardExpiryValidator]],
      primary: [''],
    });

    this.subscribes();
  }

  nameToDash(param: any): string {
    return toDashCase(param);
  }

  submit(): void {
    this.action = this.billingService
      .updatePaymentMethod(this.card.id, this.form.value)
      .pipe(
        catchError((error: APIErrorResponse) => {
          this.errors = error.error.errors;

          return throwError(error);
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.billingService.paymentMethodsChange();
        this.result = {
          success: true,
          isPrimary: !!this.form.get('primary').value,
        };
        this.close();
      });
  }

  private subscribes(): void {
    this
      .form
      .controls
      .date
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.errors = [];
        formatCardExpiry((this.update_card_date as any).getNativeElementRef().nativeElement);
      });
  }

  private get formatedCardDate(): string {
    const date: string = this.card.expiration;
    const month: string = parseInt(date.split('-')[0], 10).toString();
    const year: string = parseInt(date.split('-')[1].slice(-2), 10).toString();

    return `${month} / ${year}`;
  }

  public get showSetPrimaryCheckbox(): boolean {
    return !this.card.primary;
  }

}
