import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { isBoolean, fromPairs } from 'lodash-es';
import {
  Dictionary,
  lodashToTitle,
  SentinelErrorHandler,
  uID,
  BaseHttpService,
} from 'asap-team/asap-tools';

import type {
  BuyerPrograms,
  BuyerProgram,
  BuyerLandingSettings,
  LandingTheme,
} from '@core/types';

// Consts
import { LANDING_THEMES } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class BuyerSettingsService {

  constructor(
    private http: BaseHttpService,
    private sentinelErrorHandler: SentinelErrorHandler,
  ) {}

  getPrograms(): Observable<BuyerProgram[]> {
    return this
      .http
      .get('v2/buyer_traffic/programs')
      .pipe(
        map((response: BuyerPrograms) => Object
          .keys(response)
          .filter((key: string) => isBoolean(response[key]))
          .reduce(
            (previous: any[], current: string) => (
              previous.push({
                id: uID(current),
                name: current,
                value: response[current],
                title: lodashToTitle(current),
              // eslint-disable-next-line no-sequences
              }),
              previous
            ),
            [],
          )),
      );
  }

  getLandingSettings(): Observable<BuyerLandingSettings> {
    return this
      .http
      .get('v2/buyer_traffic/landing')
      .pipe(
        map((response: BuyerLandingSettings) => {
          const { theme } = response;
          const defaultTheme: LandingTheme = LANDING_THEMES[0];
          const data: any = {
            ...response,
            theme: theme || defaultTheme.name,
          };

          return data;
        }),
      );
  }

  updatePrograms(programs: BuyerProgram[]): Observable<BuyerLandingSettings> {
    const payload: (string | boolean)[][] = programs.map((program: BuyerProgram) => [program.name, program.value]);
    const params: Dictionary<any> = fromPairs(payload);

    return this
      .http
      .patch('v2/buyer_traffic/programs', null, { params })
      .pipe(
        catchError((error: any) => {
          this.sentinelErrorHandler.handleError(error);

          return throwError(error);
        }),
      );
  }

  updateExternalApp(payload: { external_app_url: string; external_app_enabled: string }): Observable<void> {
    return this
      .http
      .patch('v2/buyer_traffic/landing/external_app', null, { params: new HttpParams({ fromObject: payload }) });
  }

  updateThankYouVideo(payload: { thank_you_video_url: string; thank_you_video_enabled: string }): Observable<void> {
    return this
      .http
      .patch('v2/buyer_traffic/landing/thank_you_video', null, { params: new HttpParams({ fromObject: payload }) });
  }

  uploadLogo(formData: FormData): Observable<string> {
    return this
      .http
      .patch('v2/buyer_traffic/landing/logo', formData);
  }

  uploadBackgroundImage(formData: FormData): Observable<string> {
    return this
      .http
      .patch('v2/buyer_traffic/landing/background', formData);
  }

  updateLandingTheme(theme: string): Observable<void> {
    return this
      .http
      .patch('v2/buyer_traffic/theme', { theme });
  }

  deleteLogo(): Observable<void> {
    return this
      .http
      .delete('v2/buyer_traffic/landing/logo');
  }

  deleteBackgroundImage(): Observable<void> {
    return this
      .http
      .delete('v2/buyer_traffic/landing/background');
  }

}
