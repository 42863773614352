<ng-container *ngIf="!(steps$ | async).includes(FTLStepName.agent_invited)">
  <div class="greeting">
    <particles></particles>
    <span class="greeting-emoji m-b-1x">🎉</span>
    <h2 class="ftl-title text-center">
      Congratulations!
      <br>
      Your trial account is ready.
    </h2>
  </div>
</ng-container>
