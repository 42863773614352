import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModalService } from 'asap-team/asap-tools';

import type { UserBanner } from '@core/types';

// Consts
import { MODAL_CONFIG_WITHOUT_FOCUS } from '@consts/consts';

// Components
import { PromoBannerPreviewComponent } from '@commons/modals/promo-banner-preview/promo-banner-preview.component';

@Component({
  selector: 'promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoBannerComponent {

  @Input() banner: UserBanner;

  @Input() type: string;

  constructor(
    private modalService: ModalService,
  ) { }

  openPreview(banner: UserBanner): void {
    this.modalService.open(
      PromoBannerPreviewComponent,
      { modalData: { banner, type: this.type } },
      { ...MODAL_CONFIG_WITHOUT_FOCUS },
    );
  }

}
