<ng-container *ngIf="isShowNotification">
  <div
    class="notification-badge"
    *ngIf="counterName && counterValue else notification">
    <div class="notification-badge__counter f-12-600">
      {{ counterValue }} {{counterValue > 1 ? counterName + 's' : counterName}}
    </div>
  </div>

  <ng-template #notification>
    <div class="notification"></div>
  </ng-template>
</ng-container>
