import { Component, OnInit, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import type { CollectionCounters } from '@core/types';

// Services
import { FirebaseService } from '@core/vendors/firebase/firebase.service';

@UntilDestroy()
@Component({
  templateUrl: './notifications-counter.component.html',
  selector: 'notifications-counter',
  styleUrls: ['./notifications-counter.component.scss'],
})
export class NotificationsCounterComponent implements OnInit {

  @Input() counterKey: string | string [];

  @Input() counterName: string;

  isShowNotification: boolean;

  counterValue: number;

  constructor(
    private firebaseService: FirebaseService,
  ) {}

  ngOnInit(): void {
    const isMultipleCountersTracked: boolean = Array.isArray(this.counterKey);

    this
      .firebaseService
      .getCounters()
      .pipe(
        untilDestroyed(this),
        filter(Boolean),
      )
      .subscribe(
        (counters: CollectionCounters) => {
          this.counterValue = counters[this.counterKey as string];

          if (!isMultipleCountersTracked) {
            this.isShowNotification = !!counters[this.counterKey as string];
          } else {
            this.isShowNotification = this.isNewCounters(counters, this.counterKey as string[]);
          }
        },
      );
  }

  private isNewCounters(counters: CollectionCounters, counterKeys: string []): boolean {
    return counterKeys.some((counterName: string) => !!counters[`new_${counterName}_counter`]);

  }

}
