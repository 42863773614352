import { FeatureFlagsConfig } from '@core/types/FeatureFlags';

export const FEATURE_FLAGS_CONFIG: FeatureFlagsConfig = {
  training_video_guides: {
    enabled_on: {
      development: true,
      staging: true,
      production: true,
    },
  },
  training_growing_your_business: {
    enabled_on: {
      development: true,
      staging: true,
      production: true,
    },
  },
  training_realtor_connection_kit: {
    enabled_on: {
      development: true,
      staging: true,
      production: true,
    },
  },
  training_marketing_flyers: {
    enabled_on: {
      development: true,
      staging: true,
      production: true,
    },
  },
  training_coaching: {
    enabled_on: {
      development: true,
      staging: true,
      production: true,
    },
  },
  intelligence: {
    enabled_on: {
      development: true,
      staging: true,
      production: true,
    },
  },
  new_report_flow: {
    enabled_on: {
      development: true,
      staging: true,
      production: true,
    },
  },
};
