import { Route } from '@angular/router';

// Consts
import { ROUTE, USER_ROLE, PERMITTED_ACTION } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';

export const UPLOADS: Route = {
  path: ROUTE.name.UPLOADS,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard],
  loadChildren: () => import('@modules/admin/collections/uploads/uploads.module').then((m: any) => m.UploadsModule),
  data: {
    permitted_actions: [
      PERMITTED_ACTION.DATABASES_UPLOAD,
    ],
    canBeActivatedBy: [USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.customer_success],
  },
};
