<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <form class="control__content" [formGroup]="form">
    <input class="control__input"
           #nativeInput=""
           type="text"
           data-lpignore="true"
           formControlName="value"
           [prefix]="prefix"
           [suffix]="suffix"
           [mask]="mask"
           [patterns]="patterns"
           [separatorLimit]="separatorLimit"
           [dropSpecialCharacters]="dropSpecialCharacters"
           [attr.placeholder]="placeholder"
           [attr.maxlength]="maxlength"
           (focus)="focus($event)"
           (blur)="blur()"/>
  </form>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
