import { SimpleModalComponent } from 'ngx-simple-modal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  APIErrorResponse,
  Dictionary,
  ModalService,
  ResponseErrorHandler,
} from 'asap-team/asap-tools';

import type { Subscription } from 'rxjs';
import type { UserRole } from '@core/types';

// Consts
import { USER_LABEL, USER_ROLE } from '@consts/consts';

// Services
import { PartnershipService } from '@core/services/partnership/partnership.service';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { UserService } from '@core/services/user/user.service';
import { ToastrService } from 'ngx-toastr';

// Components
import { InviteProcessModalComponent } from '@commons/modals/invite-process-modal/invite-process-modal.component';
import { InvitePartnerFormComponent } from '@commons/forms/form-components/invite-partner-form/invite-partner-form.component';

@UntilDestroy()
@Component({
  templateUrl: './invite-partner-sidebar.component.html',
  styleUrls: ['./invite-partner-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitePartnerSidebarComponent
  extends SimpleModalComponent<{ userRole: string; query: string; source?: string }, boolean>
  implements AfterViewInit {

  @ViewChild(InvitePartnerFormComponent) invitePartnerForm!: InvitePartnerFormComponent;

  userRole: string;

  query: string;

  source!: string;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  USER_LABEL: any = USER_LABEL;

  inviteState: boolean = true;

  submitAction: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private partnershipService: PartnershipService,
    private modalService: ModalService,
    private asaplyticsService: AsaplyticsService,
    private userService: UserService,
    private responseErrorHandler: ResponseErrorHandler,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.sendGAEvent('invite_by_email');
  }

  invite(): void {
    const role: string = this.userRole === USER_ROLE.agent ? USER_ROLE.lender : USER_ROLE.agent;

    this.submitAction = this
      .partnershipService
      .invitePartner({
        ...this.invitePartnerForm.form.value,
        ...(this.source ? { source: this.source } : {}),
        role,
      })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.inviteState = false;
          this.result = true;
          this.sendGAEvent('send_email_invite');
          this.cdRef.detectChanges();
          this.sendGAEvent('invite_success');
        },
        (errorResponse: APIErrorResponse) => {
          this.responseErrorHandler.process(errorResponse, this.invitePartnerForm.form, this.invitePartnerForm.formErrors);

          const message: string = this.responseErrorHandler.format(errorResponse.error.errors);

          this.toastr.error(message);
          this.cdRef.detectChanges();
        },
      );
  }

  showInviteProcessModal(): void {
    this.modalService.open(InviteProcessModalComponent);
  }

  sendGAEvent(label: string): void {
    this.asaplyticsService.sendEvent({
      action: `invite_by_${this.userService.getUserRole()}`,
      category: 'Invite_sidebar',
      label,
    });
  }

}
