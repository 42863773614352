<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Invitation link</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <div class="modal-body__text" [innerHTML]="generateInviteText()"></div>
    <div class="p-t-24 p-b-24">
      <div class="control__prepend pointer"
           ngxClipboard
           [cbContent]="invitationLink"
           (cbOnSuccess)="showSuccessToast()"
           tooltip="Copy">
        <div class="control__label">
          <div class="control__label-text invite-link">{{ filterLinkView(invitationLink) }}</div>
          <div class="icon inline-svg p-8" [inlineSVG]="'#icon-copy'"></div>
        </div>
      </div>
    </div>
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block" label="Close" color="grey" [style]="'outline'" (onClick)="close()"></iq-button>
      </div>
      <div class="col col-fix">
        <iq-button class="block"
                   label="Copy link"
                   [busy$]="getLink$"
                   ngxClipboard
                   [cbContent]="invitationLink"
                   (cbOnSuccess)="showSuccessToast()"
                   tooltip="Copy link"></iq-button>
      </div>
    </div>
  </div>
</div>
