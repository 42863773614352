import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { pickBy, identity } from 'lodash-es';
import { map, filter, shareReplay, switchMap } from 'rxjs/operators';
import { HttpResponse, HttpParams, HttpClient } from '@angular/common/http';
import {
  CollectionHelpersService, Pagination, getFileName, RowsCollection, Dictionary, VaultService,
} from 'asap-team/asap-tools';

// Types
import * as RequestParams from '@core/types/request';
import type {
  Invoice,
  PaymentMethod,
  ActiveSubscription,
  PaymentHistory,
  PlansResponse,
  Checkout,
  ProfileResponse,
} from '@core/types';

// Interfaces
import { BillingHistoriesCombination, SubscriptionStats } from '@core/interfaces';

// Consts
import { INVOICE_STATUS, UNPAID_SLAVE_MODAL_DISPLAYED } from '@consts/consts';

// Services
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

// Components
import { UnpaidSlaveStatusComponent } from '@commons/modals/unpaid-slave-status/unpaid-slave-status.component';

@Injectable({ providedIn: 'root' })
export class BillingService {

  // eslint-disable-next-line rxjs/no-exposed-subjects
  paymentMethodsChanged$: Subject<void> = new Subject<void>();

  private activeSubscriptions: BehaviorSubject<ActiveSubscription> = new BehaviorSubject<ActiveSubscription>(null);

  activeSubscriptions$: Observable<ActiveSubscription> = this.activeSubscriptions.asObservable().pipe(
    filter(Boolean),
    shareReplay<ActiveSubscription>({ refCount: false, bufferSize: 1 }),
  );

  constructor(
    private http: HttpClient,
    private collectionHelpersService: CollectionHelpersService,
    private iqModalService: IqModalService,
    private vaultService: VaultService,
  ) {}

  private setUnpaidSlaveModalDisplayed(): void {
    const todayDate: Date = new Date();

    this.vaultService.set(UNPAID_SLAVE_MODAL_DISPLAYED, todayDate.setDate(todayDate.getDate() + 1));
  }

  private shouldShowUnpaidSlaveModal(): boolean {
    const modalDisplayed: number = this.vaultService.get(UNPAID_SLAVE_MODAL_DISPLAYED);
    const dateNow: number = new Date().setDate(0);

    return dateNow > modalDisplayed;
  }

  /**
   * Returns unpaid invoices
   */
  private remapInvoicesList(response: { data: Invoice[]; pagination: Pagination }): Invoice[] {
    return response.data.filter((invoice: Invoice) => invoice.status !== INVOICE_STATUS.PAID);
  }

  /**
   * Emit payment methods change event
   */
  paymentMethodsChange(): void {
    this.paymentMethodsChanged$.next();
  }

  /**
   * Emit subscription update event
   */
  subscriptionChange(subscription: ActiveSubscription): void {
    this.activeSubscriptions.next(subscription);
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>('v2/billing/payment_methods');
  }

  getPaymentHistories(combination: BillingHistoriesCombination): Observable<RowsCollection<PaymentHistory>> {
    const { page, per_page } = combination;
    const params: HttpParams = new HttpParams({
      fromObject: {
        page: `${page + 1}`,
        per_page: `${per_page}`,
      },
    });

    return this.http.get('v2/payment_histories', { params }).pipe(this.collectionHelpersService.mapToCollection<PaymentHistory>(page + 1));
  }

  getPdfInvoice(id: string): Observable<any> {
    return this.http
      .get(`v2/payment_histories/${id}/download_receipt`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return {
            file: response.body,
            name: getFileName(response.headers) || 'property_data_stats.pdf',
          };
        }),
      );
  }

  savePaymentMethod(token: string, primary: boolean): Observable<PaymentMethod[]> {
    return this.http.post<PaymentMethod[]>('v2/billing/payment_methods', { token, primary });
  }

  updatePaymentMethod(id: string, data: any): Observable<any> {
    const expiration_month: string = parseInt(data.date.split('/')[0], 10).toString();
    const expiration_year: string = parseInt(data.date.split('/')[1].slice(-2), 10).toString();

    return this.http.put(`v2/billing/payment_methods/${id}`, {
      expiration_month, expiration_year, primary: data.primary,
    });
  }

  deletePaymentMethod(id: string): Observable<any> {
    return this.http.delete(`v2/billing/payment_methods/${id}`);
  }

  getSubscription(): Observable<ActiveSubscription> {
    return this.http.get<ActiveSubscription>('v2/billing/subscriptions');
  }

  getSubscriptionStats(): Observable<SubscriptionStats> {
    return this.http.get<SubscriptionStats>('v2/billing/subscriptions/stats');
  }

  enrollMonitoringProgram(): Observable<ActiveSubscription> {
    return this.http.patch<ActiveSubscription>('v2/billing/subscriptions/enroll', {});
  }

  enrollBackMonitoringProgram(): Observable<ActiveSubscription> {
    return this.http.patch<ActiveSubscription>('v2/billing/subscriptions/enroll_back', {});
  }

  setDiscountedSubscription(): Observable<ActiveSubscription> {
    return this.http.patch<ActiveSubscription>('v2/billing/subscriptions/apply_discount', {});
  }

  sendCanselationSurvey(survey: Dictionary[]): Observable<Dictionary[]> {
    const formData: FormData = new FormData();

    formData.append('survey', JSON.stringify(survey));

    return this.http.post<Dictionary[]>('v2/billing/subscriptions/survey', formData);
  }

  resumeSubscription(): Observable<ActiveSubscription> {
    return this.http.patch<ActiveSubscription>('v2/billing/subscriptions/resume', {});
  }

  pauseSubscription(pause_reason?: string): Observable<ActiveSubscription> {
    return this.http.patch<ActiveSubscription>('v2/billing/subscriptions/pause', { ...(pause_reason && { pause_reason }) });
  }

  cancelSubscription(): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>('v2/billing/subscriptions', { observe: 'body' });
  }

  updateSubscription(payment_method_id: string): Observable<any> {
    return this.http.put('v2/billing/subscriptions', { payment_method_id });
  }

  getInvoices(params: { page: string; per_page: string }): Observable<Invoice[]> {
    return this.http
      .get('v2/invoices', { params })
      .pipe(map((response: { data: Invoice[]; pagination: Pagination }) => this.remapInvoicesList(response)));
  }

  getCheckout(promo_code?: string): Observable<any> {

    return this.http.get('v2/billing/subscriptions/checkout', { params: { ...(!!promo_code && { promo_code }) } });
  }

  sendInvoice(data: RequestParams.newInvoice): Observable<any> {
    return this.http.post('v2/admin/invoices', data);
  }

  updateInvoice(id: string, form: RequestParams.updateInvoice): Observable<any> {
    return this.http.patch(`v2/admin/invoices/${id}`, form);
  }

  deleteInvoice(id: string): Observable<any> {
    return this.http.delete(`v2/admin/invoices/${id}`);
  }

  resendInvoice(id: string): Observable<any> {
    return this.http.post(`v2/admin/invoices/${id}/resend`, { id });
  }

  finalizeInvoice(id: string): Observable<any> {
    return this.http.patch(`v2/admin/invoices/${id}/finalize`, { id });
  }

  getAvailablePlans(): Observable<PlansResponse> {
    return this.http.get<PlansResponse>('v2/plans');
  }

  checkout(name: string, promo_code?: string): Observable<Checkout> {
    return this.http.get<Checkout>(`v2/plans/${name}/checkout`, { params: { ...(!!promo_code && { promo_code }) } });
  }

  purchase(params: { token: string; invoice_ids: string }): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>('v2/billing/subscriptions/purchase', pickBy(params, identity));
  }

  reservationsUpgrade(params: { plan_name: string; token?: string; promo_code?: string }): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>('v2/billing/subscriptions/upgrade', pickBy(params, identity));
  }

  handlePaymentIssue(): Observable<void> {
    return this.getSubscription().pipe(
      filter((subscription: ActiveSubscription) => subscription.slave_status === 'unpaid_slave'),
      switchMap(() => {
        if (this.shouldShowUnpaidSlaveModal()) {
          this.setUnpaidSlaveModalDisplayed();

          return this.iqModalService
            .open(UnpaidSlaveStatusComponent, {}, { disableClose: true })
            .closed;
        }

        return EMPTY;
      }),
      filter(Boolean),
    );
  }

}
