import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Consts
import { ROUTE } from '@consts/consts';

@Component({
  templateUrl: './ftl-uth-by-uid.component.html',
  styleUrls: ['./ftl-uth-by-uid.component.scss'],
})
export class FTLAthByUIDComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (!this.route.snapshot?.data?.profile) {
      this.router.navigate([ROUTE.name.SIGN_IN]);

      return;
    }

    this.router.navigate([ROUTE.alias.SIGN_UP]);
  }

}
