<div class="enter-promo">
  <div class="enter-promo-description" *ngIf="!message">Enter a promo code if you have one.</div>
  <form [formGroup]="form">
    <iq-form-control-text class="m-t-8"
                          placeholder="Type code"
                          formControlName="promo_code"
                          [errorsModel]="formErrors.promo_code">
      <div class="m-r-2x m-t-auto m-b-auto">
        <iq-button label="Apply"
                   *ngIf="!!form.get('promo_code').value && !isPromoApplied"
                   size="24"
                   [style]="'outline'"
                   [busy$]="loading"
                   (onClick)="applyPromo()"></iq-button>
        <iq-button label="Remove"
                   *ngIf="!!form.get('promo_code').value && isPromoApplied"
                   size="24"
                   [style]="'outline'"
                   [busy$]="loading"
                   (onClick)="removePromo()"></iq-button>
      </div>
    </iq-form-control-text>
    <div class="control-error-message" *ngFor="let error of formErrors.global">{{ error }}</div>
    <div class="checkout-message" *ngIf="message">{{ message }}</div>
  </form>
</div>
