import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

// Const
import { ROUTE } from '@consts/routes';
import { USER_ROLE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

// State
import { FTLActions } from '../state/ftl.actions';
import { FtlState } from '../state/ftl.state';
import { FTLStepName } from '../state/ftl.model';

@Component({
  selector: 'ftl',
  templateUrl: './ftl.component.html',
  styleUrls: ['./ftl.component.scss'],
})
export class FtlComponent implements OnInit {

  @Select(FtlState.steps) steps$: Observable<string[]>;

  @Select(FtlState.currentStep) currentStep$: Observable<string>;

  @Select(FtlState.ftlProgress) ftlProgress$: Observable<number>;

  FTL_STEP_NAME: typeof FTLStepName = FTLStepName;

  constructor(
    private store: Store,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    if (this.userService.syncGetProfile()?.registration_completed) {
      if (this.userService.getUserRole() === USER_ROLE.LENDER) {
        this.router.navigate([ROUTE.alias.DASHBOARD]);
      } else {
        this.router.navigate([ROUTE.alias.DASHBOARD_PRODUCTION]);
      }
    } else {
      this.store.dispatch(new FTLActions.GetFtlStepsList());
    }
  }

}
