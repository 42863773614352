import momentMini from 'moment-mini';

export const EMPTY_STATE_DATA: any[] = [
  {
    xAxisValue: '1',
    total: 110,
    buyers_total: 90,
    homeowners_total: 20,
  },
  {
    xAxisValue: '2',
    total: 130,
    buyers_total: 90,
    homeowners_total: 40,
  },
  {
    xAxisValue: '2018',
    total: 145,
    buyers_total: 100,
    homeowners_total: 45,
  },
  {
    xAxisValue: '3',
    total: 130,
    buyers_total: 90,
    homeowners_total: 40,
  },
  {
    xAxisValue: '4',
    total: 110,
    buyers_total: 90,
    homeowners_total: 20,
  },
  {
    xAxisValue: '2019',
    total: 110,
    buyers_total: 90,
    homeowners_total: 20,
  },
  {
    xAxisValue: '5',
    total: 130,
    buyers_total: 90,
    homeowners_total: 40,
  },
  {
    xAxisValue: '6',
    total: 145,
    buyers_total: 100,
    homeowners_total: 45,
  },
  {
    xAxisValue: '2020',
    total: 130,
    buyers_total: 90,
    homeowners_total: 40,
  },
  {
    xAxisValue: '7',
    total: 110,
    buyers_total: 90,
    homeowners_total: 20,
  },
  {
    xAxisValue: '8',
    total: 130,
    buyers_total: 90,
    homeowners_total: 40,
  },
  {
    xAxisValue: '2021',
    total: 145,
    buyers_total: 100,
    homeowners_total: 45,
  },
  {
    xAxisValue: '9',
    total: 130,
    buyers_total: 90,
    homeowners_total: 40,
  },
  {
    xAxisValue: '10',
    total: 110,
    buyers_total: 90,
    homeowners_total: 20,
  },
  {
    xAxisValue: '2022',
    total: 110,
    buyers_total: 90,
    homeowners_total: 20,
  },
  {
    xAxisValue: '11',
    total: 145,
    buyers_total: 100,
    homeowners_total: 45,
  },
  {
    xAxisValue: '12',
    total: 110,
    buyers_total: 90,
    homeowners_total: 20,
  },
  {
    xAxisValue: '2023',
    total: 130,
    buyers_total: 90,
    homeowners_total: 40,
  },
  {
    xAxisValue: '13',
    total: 145,
    buyers_total: 100,
    homeowners_total: 45,
  },
  {
    xAxisValue: '14',
    total: 110,
    buyers_total: 90,
    homeowners_total: 20,
  },
].map((item: any, index: number) => {
  return { ...item, xAxisValue: momentMini().subtract(index, 'months').format('MMM YYYY') };
}).reverse();
