<div class="pre-mover-count" [ngClass]="{'desktop-only' : desktopOnly}" [tooltip]="tooltipTemplate" container="body">
  <p class="pre-mover-count__title">Pre-mover<br/>score</p>
  <div class="pre-mover-count__progress">
    <img class="pre-mover-count__progress-image"
         [src]="'assets/images/pre-mover/' + range + '.svg'"
         alt="Pre-mover score"/>
    <p class="pre-mover-count__progress-value" [ngClass]="{ 'f-c-cloudy-blue': rate < 70 }">
      {{ rate / 100 | percent }}
    </p>
  </div>
</div>
<ng-template #low>
  <p>The chances of this homeowner to sell<br/>their home in the next 12 months are
    <b>low</b>
  </p>
</ng-template>
<ng-template #medium>
  <p>The chances of this homeowner to sell their<br/>home in the next 12 months are
    <b>medium</b>
  </p>
</ng-template>
<ng-template #likely>
  <p>
    This homeowner is
    <b>likely to sell</b><br/>their home in the next 12 months
  </p>
</ng-template>
<ng-template #high>
  <p>
    There is a
    <b>high</b>
    probability that his homeowner<br/>will sell their home in the next 12 months
  </p>
</ng-template>
