import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {
  CollectionHelpersService,
  Dictionary,
  BaseHttpService,
  CollectionApi,
  CollectionResponse,
} from 'asap-team/asap-tools';

import type { Observable } from 'rxjs';
import type { ViralCollectionAccount } from '@core/types';

@Injectable({ providedIn: 'root' })
export class ViralAccountsService implements CollectionApi<ViralCollectionAccount> {

  constructor(
    private http: BaseHttpService,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(params: Dictionary<string>): Observable<CollectionResponse<ViralCollectionAccount>> {
    return this
      .http
      .get('v2/admin/users/viral', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.mapResponseFilters(),
        this.collectionHelpersService.retrowError(),
      );
  }

  exportAccounts(): Observable<Blob> {
    return this.http.get('v2/admin/users/export_viral', { responseType: 'blob' });
  }

}
