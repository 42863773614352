import {
  Component,
  OnChanges,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
} from '@angular/core';

import type { Dictionary } from 'asap-team/asap-tools';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnChanges {

  @Input() current: number;

  progress: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.current?.currentValue) {
      this.progress = changes.current.currentValue;
    }
  }

  getProgressBarStyle(progress: any): Dictionary {
    return { width: `${progress}%` };
  }

  getValueStyle(progress: any): Dictionary {
    return { 'clip-path': `polygon(0 0, ${progress}% 0%, ${progress}% 100%, 0 100%)` };
  }

}
