import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FeatureFlagsService } from '@core/services/feature-flags/feature-flags.service';

@Directive({ selector: '[featureFlag]' })
export class FeatureFlagDirective implements OnInit {

  @Input() public featureFlag: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private featureFlagService: FeatureFlagsService,
  ) {}

  public ngOnInit(): void {
    const isEnabled: boolean = this.featureFlagService.isFeatureEnabled(this.featureFlag);

    if (isEnabled) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
