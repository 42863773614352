import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { has } from 'lodash-es';

import { FEATURE_FLAGS_CONFIG } from '@consts/feature-flags/feature-flags';
import type { FeatureFlagsConfig } from '@core/types/FeatureFlags';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {

  private config: FeatureFlagsConfig = null;

  constructor() {
    this.config = FEATURE_FLAGS_CONFIG;
  }

  public isFeatureEnabled(flag: string): boolean {
    if (this.config && has(this.config, flag)) {
      return this.config[flag]?.enabled_on[environment.name];
    }

    return false;
  }

}
