import {
  Component,
  OnInit,
  ViewContainerRef,
  Input,
  ViewChild,
  ComponentRef,
} from '@angular/core';

import type { Dictionary } from 'asap-team/asap-tools';
import type { LeadActivity } from '@core/types';

// Consts
import { LeadsActivityModules } from './leads-activity-component-modules';

@Component({
  selector: 'leads-activity-item',
  template: '<ng-container #container=""></ng-container>',
})
export class LeadsActivityItemComponent implements OnInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

  @Input() data: LeadActivity;

  modules: Dictionary<{ load: () => Promise<unknown>; icon?: string }> = LeadsActivityModules;

  ngOnInit(): void {
    this.resolveActivity(this.data.activity_type);
  }

  private async createComponent(componentModule: { load: () => Promise<any>; icon?: string }): Promise<void> {
    if (!componentModule) { return }

    this.container.clear();

    const componentRef: ComponentRef<unknown> = this.container.createComponent(await componentModule.load());

    (componentRef.instance as any).data = this.data;

    if (!componentModule.icon) { return }

    (componentRef.instance as any).icon = componentModule.icon;
  }

  private resolveActivity(type: string): void {
    if (!this.modules[type]) {
      console.log(`no component factory provided for "${type}" activity type`);
    }

    this.createComponent(this.modules[type] || this.modules.not_implemented);
  }

}
