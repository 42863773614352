<form class="staff-form" [formGroup]="form" (ngSubmit)="submit()">
  <div class="staff-form__avatar">
    <user-avatar [avatar]="preview"
                 [username]="user?.name"
                 [loading]="loading"
                 (imageSelected)="onImageSelected($event)"></user-avatar>
  </div>
  <iq-form-control-custom-select class="m-b-2x"
                                 *ngIf="!isHomeiq"
                                 label="Role"
                                 placeholder="Choose role"
                                 filterPlaceholder="Type role"
                                 formControlName="role"
                                 [displayCode]="false"
                                 [model]="roles"></iq-form-control-custom-select>
  <iq-form-control-text class="m-b-2x" label="Name*" placeholder="name" formControlName="name"></iq-form-control-text>
  <iq-form-control-text class="m-b-2x"
                        label="Email*"
                        placeholder="email"
                        formControlName="email"></iq-form-control-text>
  <iq-form-control-password class="m-b-2x"
                        label="Password"
                        placeholder="password"
                        formControlName="password"></iq-form-control-password>
  <iq-form-control-custom-select class="m-b-5x"
                                 formControlName="time_zone"
                                 label="Time zone"
                                 placeholder="Choose timezone"
                                 [model]="(timeZones$ | async)"
                                 [errorsModel]="formErrors.time_zone">
    <iq-button size="56"
               color="grey"
               [style]="'transparent'"
               iconLeft="#icon-copy"
               (onClick)="setCopyStatus()"
               placement="top"
               ngxClipboard=""
               [cbContent]="user?.zapier_token"
               tooltip="Copy"></iq-button>
  </iq-form-control-custom-select>
  <div class="row row-fix m-t-4x">
    <div class="col col-fix" *ngIf="type === 'edit'">
      <iq-button class="block"
                 label="Delete"
                 color="red"
                 [style]="'outline'"
                 [busy$]="deleteAction$"
                 (onClick)="deleteUser()"></iq-button>
    </div>
    <div class="col col-fix">
      <iq-button class="block"
                 *ngIf="type !== 'edit'"
                 type="submit"
                 label="Create"
                 [disabled]="form.pristine || form.invalid"
                 [busy$]="saveAction$"
                 (onClick)="createUser()"></iq-button>
      <iq-button class="block"
                 *ngIf="type === 'edit'"
                 type="submit"
                 label="Save"
                 [disabled]="form.pristine || form.invalid"
                 [busy$]="saveAction$"
                 (onClick)="editUser()"></iq-button>
    </div>
  </div>
</form>
