<div class="modal-content">
  <div class="modal-header">
    <h2 class="modal-title">Payment problem</h2>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
  </div>
  <div class="modal-body">
    <img src="assets/components/billing/unpaid-status.svg" alt="Payment problem">
    <p class="f-16-600 m-b-2x">Payment for additional reports was declined.</p>
    <p class="message">To ensure uninterrupted access to your reports, please update your payment method.</p>
  </div>
  <div class="modal-footer">
    <iq-button class="block m-b-2x"
               label="Update Card"
               (click)="navigateToBilling()"></iq-button>
    <iq-button class="block"
               label="Cancel"
               color="grey"
               (click)="closeModal()"></iq-button>
  </div>
</div> 
