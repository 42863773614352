import { Component, Input, EventEmitter, Output } from '@angular/core';

import type { PaymentMethod } from '@core/types';

// Utils
import { getCardIcon } from '@core/utils/utils';

// Consts
import { PERMITTED_ACTION } from '@consts/consts';

@Component({
  selector: 'payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent {

  @Input() card: PaymentMethod;

  @Input() isEditModeOn: boolean;

  @Input() isSelectable: boolean = true;

  @Input() selected: boolean;

  @Input() expired: boolean;

  @Output() edit: EventEmitter<PaymentMethod> = new EventEmitter<PaymentMethod>();

  @Output() delete: EventEmitter<PaymentMethod> = new EventEmitter<PaymentMethod>();

  @Output() selectMethod: EventEmitter<PaymentMethod> = new EventEmitter<PaymentMethod>();

  PERMITTED_ACTION: any = PERMITTED_ACTION;

  getCardIcon: (icon: string) => string = getCardIcon;

  getExpiration(card: PaymentMethod): string {
    if (!card) { return '' }

    if (!card.expired) {
      return `Exp. ${card.expiration}`;
    }

    return 'Expired';
  }

  editCard(card: PaymentMethod): void {
    this.edit.emit(card);
  }

  deleteCard(card: PaymentMethod): void {
    this.delete.emit(card);
  }

  selectCard(card: PaymentMethod): void {
    if (this.isSelectable) {
      this.selectMethod.emit(card);
    }
  }

  isPermittedAction(action: string): boolean {
    return this.card?.permitted_actions.some((permittedAction: string) => permittedAction === action);
  }

}
