import { SimpleModalComponent } from 'ngx-simple-modal';
import { Component } from '@angular/core';

import type { Dictionary } from 'asap-team/asap-tools';
import type { UserRole } from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';

@Component({
  templateUrl: './invite-process-modal.component.html',
  styleUrls: ['./invite-process-modal.component.scss'],
})
export class InviteProcessModalComponent extends SimpleModalComponent<null, null> {

  step: number = 1;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  changeStep(step: number, type: 'prev' | 'next'): Promise<any> {
    switch (type) {
      case 'next': {
        if (step === 3) { return this.close(); }

        this.step = step + 1;

        break;
      }
      case 'prev': {
        if (step === 1) { return this.close(); }

        this.step = step - 1;

        break;
      }
      default: {
        break;
      }
    }

    return null;
  }

}
