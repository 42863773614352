<div class="flex align-items-center m-t-24 m-b-16">
  <div class="flex-1 f-16-500 cell-title">Total rows</div>
  <div class="flex-1 text-right f-16-bold">{{ info?.total_items || '-' }}</div>
</div>
<div class="flex align-items-center bordered p-t-17 p-b-17">
  <div class="flex-1 f-11-normal cell-title">FAILED LINES</div>
  <div class="flex-1 text-right f-16-normal">{{ info?.failed_items || '-' }}</div>
</div>
<div class="flex align-items-center bordered p-t-17 p-b-17">
  <div class="flex-1 f-11-normal cell-title">DUPLICATES</div>
  <div class="flex-1 text-right f-16-normal">{{ info?.duplicated_items || '-' }}</div>
</div>
<div class="flex align-items-center m-t-16">
  <div class="flex-1 f-16-500 cell-title">Available for import</div>
  <div class="flex-1 text-right f-16-bold">{{ info?.success_items || '-' }}</div>
</div>
