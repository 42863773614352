import { SimpleModalComponent } from 'ngx-simple-modal';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE } from '@consts/routes';

@Component({
  templateUrl: './transactions-report-modal.component.html',
  styleUrls: ['./transactions-report-modal.component.scss'],
})
export class TransactionsReportModalComponent extends SimpleModalComponent<null, null> {

  reportId: string = '';

  errors: string[] = [];

  constructor(
    private router: Router,
  ) {
    super();
  }

  viewReport(): void {
    this.router.navigate([ROUTE.alias.HOMEOWNERS_LEAD, this.reportId]);
    this.close();
  }

}
