import { Component, Input } from '@angular/core';

import type { ImportDatabaseInfo } from '@core/types';

@Component({
  selector: 'import-database-info',
  templateUrl: './import-database-info.component.html',
  styleUrls: ['./import-database-info.component.scss'],
})
export class ImportDatabaseInfoComponent {

  @Input() info: ImportDatabaseInfo;

}
