import { Route } from '@angular/router';

// Consts
import { PERMITTED_ACTION, ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';

export const HEALTH_METRICS: Route = {
  path: ROUTE.name.HEALTH_METRICS,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard],
  loadChildren: () => import('@modules/admin/collections/health-metrics/health-metrics.module').then((m: any) => m.HealthMetricsModule),
  data: {
    permitted_actions: [PERMITTED_ACTION.SYSTEM_METRICS_VIEW],
    canBeActivatedBy: [USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.marketing],
  },
};
