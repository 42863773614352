import type { NavItem } from '@core/types';

import { ROUTE, USER_ROLE, COLLECTION_COUNTER_NAME, PERMITTED_ACTION } from '@consts/consts';

export const Buyers: NavItem[] = [
  {
    label: 'Leads',
    routerLink: ROUTE.alias.BUYERS_TRAFFIC,
    counter: COLLECTION_COUNTER_NAME.BUYER_TRAFFIC,
    roles: null,
    permitted_action: PERMITTED_ACTION.BUYERS_VIEW,
    icon: null,
  },
  {
    label: 'Banners',
    routerLink: ROUTE.alias.BUYERS_BANNERS,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_action: PERMITTED_ACTION.BUYERS_VIEW,
    icon: null,
  },
];
