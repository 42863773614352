import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Inject,
  Input,
  OnChanges, Output, SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { DOCUMENT } from '@angular/common';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  axisBottom,
  axisLeft,
  scaleBand,
  ScaleBand,
  scaleLinear,
  ScaleLinear,
  select,
} from 'd3';
import { chunk, isEqual, max, pickBy } from 'lodash-es';

import { EMPTY_STATE_DATA } from './empty-state';

export type StackbarGraphData = {
  xAxisValue: string;
  total: number;
  [key: string]: any;
};

export type StackBarMeta = { colors: string[]; keys: string[]; tooltipLabels?: string[] };

type MarginSides = { top: number; left: number; right: number; bottom: number };

@Component({
  selector: 'stackbar-diagram',
  templateUrl: './stackbar.component.html',
  styleUrls: ['./stackbar.component.scss'],
})
export class StackbarChartComponent implements OnChanges, AfterViewInit {

  @Input() data!: StackbarGraphData[];

  @Input() stackBarMeta!: StackBarMeta;

  @Input() loading: boolean = false;

  @Input() dateFormat: string = 'MMM YYYY';

  @Output() changeTooltipData: EventEmitter<any> = new EventEmitter();

  @ViewChild('svg') svg!: ElementRef<HTMLElement>;

  @ViewChild('tooltip') tooltip!: ElementRef<HTMLElement>;

  // Graph
  graph!: any;

  width: number = 720;

  height: number = 200;

  margin: MarginSides = {
    top: 40,
    left: 32,
    right: 32,
    bottom: 40,
  };

  xScale!: ScaleBand<string>;

  yScale!: ScaleLinear<number, number, never>;

  bars!: any;

  xAxis!: any;

  yAxis!: any;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data) {
      const { previousValue, currentValue } = changes.data;

      if (!isEqual(previousValue, currentValue) && this.graph) {
        const graphData: StackbarGraphData[] = currentValue || EMPTY_STATE_DATA;

        this.setData(graphData);
      }
    }
  }

  ngAfterViewInit(): void {
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      this.width = entries[0].contentRect.width;

      this.init();
    }).observe(this.svg.nativeElement);
  }

  setData(data: StackbarGraphData[]): void {
    const maxY: number = max(data.map((item: StackbarGraphData) => item.total)) || 100;

    // Update scales.
    this.xScale = scaleBand()
      .range([0, this.svg.nativeElement.offsetWidth - this.margin.left])
      .domain(data.map((obj: StackbarGraphData) => obj.xAxisValue));
    this.yScale.domain([0, this.data ? (Math.ceil(maxY / 2) * 2) : 300]);

    // update axis
    this.updateXAxis(data);
    this.updateYAxis();

    // Reset Bars
    this.bars.selectAll('.bar-item').remove();

    // Set containers with data
    this.bars
      .selectAll('g')
      .data(data)
      .join(
        (enter: any) => {
          const bar: any = enter.append('g').attr('class', 'bar-container');

          return bar;
        },
        () => {},
        (exit: any) => exit.remove(),
      );

    // render bars
    const maxWidthMediaQuery: number = 500;
    const BAR_WIDTH: number = this.width > maxWidthMediaQuery ? 15 : 8;

    this.bars.selectAll('.bar-container')
      .append('foreignObject')
      .attr('class', 'bar-item')
      .attr('x', (d: StackbarGraphData) => this.xScale(d.xAxisValue) as any + (this.xScale.bandwidth() / 2 - (BAR_WIDTH / 2)))
      .attr('y', (d: StackbarGraphData) => this.yScale(d.total))
      .attr('width', BAR_WIDTH)
      .attr('height', (d: any) => this.yScale(0) - this.yScale(d.total))
      // Bar stack items
      .append((d: StackbarGraphData) => this.createBarMarkup(d));
  }

  private init(): void {
    select(this.svg.nativeElement).selectAll('svg').remove();

    // Set main svg container
    this.graph = select(this.svg.nativeElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
    // set the scales
    this.yScale = scaleLinear().range([this.height, 0]);

    // Set xAxis
    this.xAxis = this.graph.append('g').attr('class', 'x-axis').attr('transform', `translate(0, ${this.height})`);

    // Set yAxis
    this.yAxis = this.graph.append('g').attr('class', 'y-axis');

    // Create bars container
    this.bars = this.graph.append('g').attr('class', 'bars');

    this.setData(this.data || EMPTY_STATE_DATA);
  }

  private updateXAxis(data: StackbarGraphData[]): void {
    this.xAxis
      .call(axisBottom(this.xScale));

    const maxWidthForBar: number = 60;
    const maxBarsPerX: number = Math.round(this.width / maxWidthForBar);

    this.xAxis.selectAll('line').remove();
    this.xAxis.selectAll('.domain').remove();
    this.xAxis.selectAll('text')
      .attr('fill', '#9CACBE')
      .attr('font-size', 11)
      .attr('x', 0)
      .attr('y', 0)
      .attr('dy', 0)
      // eslint-disable-next-line func-names
      .each((text: any, i: number, list: any) => {
        const maxWordPerLine: number = 2;
        const node: any = select(list[i]);
        const createTspan = (word: string): void => {
          node.append('tspan')
            .attr('x', 0)
            .attr('dy', '14px')
            .text(word);
        };

        node.text(null).attr('opacity', '1');

        chunk(text.split(' '), maxWordPerLine)
          .map((value: string[]) => value.join(' '))
          .forEach((value: string) => createTspan(value));

        if ((i + 1) % Math.ceil(data.length / maxBarsPerX) || (this.width <= 720 && data.length - 1 === i)) {
          node.attr('opacity', 0);
        }
      });
  }

  private updateYAxis(): void {
    this.yAxis.call(axisLeft(this.yScale).ticks(4));

    this.yAxis.selectAll('.domain').remove();
    this.yAxis.selectAll('text')
      .attr('fill', '#9CACBE')
      .attr('font-size', 11);

    this.yAxis.selectAll('line')
      .attr('x2', this.svg.nativeElement.offsetWidth - this.margin.left)
      .attr('stroke-dasharray', '5 6')
      .attr('stroke', '#DAE1E6');
  }

  private setBarTransition(bar: any): void {
    bar.attr('opacity', 0).transition().delay((_: any, i: number) => i * 50).duration(400)
      .attr('opacity', 1);
  }

  private createBarMarkup(data: StackbarGraphData): HTMLDivElement {
    const colContent: HTMLDivElement = this.document.createElement('div');

    colContent.setAttribute('class', 'bar');

    const pickData: { [p: string]: any } = pickBy(data, (value: any, key: string) => !(key === 'xAxisValue' || key === 'total'));

    Object.keys(pickData)
      .sort((a: string, b: string) => this.stackBarMeta.keys.indexOf(a) - this.stackBarMeta.keys.indexOf(b))
      .forEach((key: string, index: number) => {
        const height: number = (data[key] * 100) / data.total || 0;
        const subBar: HTMLDivElement = this.document.createElement('div');

        subBar.setAttribute('style', `height: ${height}%; background-color: ${this.stackBarMeta.colors[index]};`);
        colContent.append(subBar);
      });

    // Bar events
    const events$: Observable<Event>[] = [
      fromEvent(colContent, 'mouseover'),
      fromEvent(colContent, 'mouseout'),
    ];

    merge(...events$)
      .pipe(debounceTime(100))
      .subscribe(
        (event: any) => {
          const tooltip: HTMLElement = this.tooltip.nativeElement;
          const TOGGLE_CLASS_NAME: string = 'show';

          if (event.type === 'mouseover') {
            // Set new tooltip data
            this.changeTooltipData.emit(data);

            // Set tooltip coords
            const x: number = this.getTooltipX(colContent.getBoundingClientRect().x + (colContent.offsetWidth / 2), tooltip.offsetWidth);
            const y: number = event.y - tooltip.offsetHeight <= 0 ? event.y + 16 : event.y - (tooltip.offsetHeight + 16);

            tooltip.setAttribute('style', `top: ${y}px; left: ${x}px`);
            tooltip.classList.add(TOGGLE_CLASS_NAME);
          }

          if (event.type === 'mouseout') {
            this.changeTooltipData.emit(null);
            tooltip.classList.remove(TOGGLE_CLASS_NAME);
          }
        },
      );

    return colContent;
  }

  private getTooltipX(eventX: number, tooltipOffsetLeft: number): number {
    if (eventX - (tooltipOffsetLeft / 2) <= 0) {
      return 16;
    }

    if (eventX + (tooltipOffsetLeft / 2) >= this.window.innerWidth) {
      return this.window.innerWidth - (tooltipOffsetLeft + 16);
    }

    return eventX - (tooltipOffsetLeft / 2);
  }

}
