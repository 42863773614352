import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'lodash-es';
import { map as rxjsMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { chain, Dictionary, CollectionFilter } from 'asap-team/asap-tools';

import { Store } from '@core/types/Store';

@UntilDestroy()
@Component({
  selector: 'radio-filter',
  templateUrl: './radio-filter.component.html',
  styleUrls: ['./radio-filter.component.scss'],
})
export class RadioFilterComponent implements OnInit, OnChanges {

  @ViewChild('items') items: ElementRef;

  @Input() provider: Store;

  @Input() model: CollectionFilter[];

  @Input() title: string;

  @Input() filterName: string;

  innerModel: CollectionFilter[] = [];

  isFirstChange: boolean = true;

  get selected(): string {
    return chain(this.innerModel, { filter, map })
      .filter((item: CollectionFilter) => item.selected)
      .map((item: CollectionFilter) => item.title)
      .value()
      .join('');
  }

  get isShowLabel(): boolean {
    const selected: CollectionFilter[] = filter(this.innerModel, (item: CollectionFilter) => item.selected);
    const isAllSelected: boolean = selected.length === this.innerModel.length;

    return !this.selected || isAllSelected;
  }

  ngOnInit(): void {
    this.rehydrateFilters().subscribe();
  }

  rehydrateFilters(): Observable<void> {
    return this
      .provider
      .queryParams$
      .pipe(
        untilDestroyed(this),
        rxjsMap((filters: Dictionary<string>) => {
          if (!filters) { return; }

          if (!filters[this.filterName]) {
            this.reset();

            return;
          }

          this.innerModel = map(this.model, (item: CollectionFilter) => {
            if (item.name === filters[this.filterName]) {
              // eslint-disable-next-line no-param-reassign
              item.selected = true;
            }

            return item;
          });
        }),
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.model?.currentValue && this.isFirstChange) {
      this.innerModel = changes.model.currentValue;
      this.isFirstChange = false;

      this.rehydrateFilters().subscribe().unsubscribe();
    }
  }

  reset(): void {
    this.innerModel = map(this.innerModel, (item: CollectionFilter) => {
      // eslint-disable-next-line no-param-reassign
      item.selected = false;

      return item;
    });
  }

  select(selectedItem: CollectionFilter): void {
    this.innerModel = map(this.innerModel, (item: CollectionFilter) => {
      // eslint-disable-next-line no-param-reassign
      item.selected = false;

      if (selectedItem.id === item.id) {
        // eslint-disable-next-line no-param-reassign
        item.selected = true;
      }

      return item;
    });

    const selected: any = chain(this.innerModel, { filter, map })
      .filter((item: CollectionFilter) => item.selected)
      .map((item: CollectionFilter) => item.name)
      .value()
      .join('');

    this.provider.emitFilters({ [this.filterName]: selected });
  }

  clear(): void {
    this.reset();
    this.provider.emitFilters({ [this.filterName]: '' });
  }

  // Used on mobile resolution
  close(event: Event, checkboxDropdown: any): void {
    const targetElement: HTMLElement = event.target as HTMLElement;

    if (targetElement && !this.items.nativeElement.contains(targetElement)) {
      checkboxDropdown.hide();
    }
  }

}
