<div class="transactions-header">
  <ng-content select="[headerSlot]"></ng-content>
</div>

<div class="stats">
  <ng-content select="[statsSlot]"></ng-content>
</div>

<stackbar-diagram [data]="graphData"
                  [loading]="loading"
                  [stackBarMeta]="{ keys: ['others_count', 'purchases_count', 'refinances_count'], colors:['#7AF3D6', '#0DAAFF', '#0C509E'] }"
                  (changeTooltipData)="setTooltipData($event)">
  <div class="tooltip-title">{{ tooltipData?.xAxisValue }}</div>
  <div class="tooltip-row">
    <div class="tooltip-mark" [ngStyle]="{ 'background-color': '#0DAAFF' }"></div>
    <div class="tooltip-label">Purchase</div>
    <div class="tooltip-count">{{ tooltipData?.purchases_count }}</div>
    <div class="tooltip-percent">
      {{ (tooltipData?.purchases_count * 100 / (tooltipData?.purchases_count + tooltipData?.refinances_count +
      tooltipData?.others_count)) | number:'1.0-0' }}
      %
    </div>
  </div>
  <div class="tooltip-row">
    <div class="tooltip-mark" [ngStyle]="{ 'background-color': '#0C509E' }"></div>
    <div class="tooltip-label">Refinance</div>
    <div class="tooltip-count">{{ tooltipData?.refinances_count }}</div>
    <div class="tooltip-percent">
      {{ (tooltipData?.refinances_count * 100 / (tooltipData?.purchases_count + tooltipData?.refinances_count +
      tooltipData?.others_count)) | number:'1.0-0' }}
      %
    </div>
  </div>
  <div class="tooltip-row">
    <div class="tooltip-mark" [ngStyle]="{ 'background-color': '#7AF3D6' }"></div>
    <div class="tooltip-label">Other</div>
    <div class="tooltip-count">{{ tooltipData?.others_count }}</div>
    <div class="tooltip-percent">
      {{ (tooltipData?.others_count * 100 / (tooltipData?.purchases_count + tooltipData?.refinances_count +
      tooltipData?.others_count)) | number:'1.0-0' }}
      %
    </div>
  </div>
</stackbar-diagram>
