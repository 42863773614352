import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpEvent,
  HttpErrorResponse,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';

// Services
import { CacheRegistrationService } from '@core/helpers/cache-registration/cache-registration.service';


/**
  For @type { RequestCachingInterceptor }, indicates should request cache responses with errors
 */
export const CACHE_WITH_ERRORS: HttpContextToken<boolean> = new HttpContextToken(() => false);

@Injectable()
export class RequestCachingInterceptor implements HttpInterceptor {

  private readonly acceptedMethods: string[] = ['GET', 'HEAD'];

  private cachedData: Map<string, any> = new Map<string, any>();

  constructor(
    private cacheRegistrationService: CacheRegistrationService,
  ) {}

  intercept(
    httpRequest: HttpRequest<any>,
    httpHandler: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Don't cache if
    // 1. It's not an ACCEPTED request method
    // 2. If URI is not supposed to be cached
    if (!this.acceptedMethods.includes(httpRequest.method) || !this.cacheRegistrationService.isAddedToCache(httpRequest.url)) {
      return httpHandler.handle(httpRequest);
    }

    // Also leave scope of resetting already cached data for a URI
    if (httpRequest.headers.get('reset-cache')) {
      this.cachedData.delete(httpRequest.urlWithParams);
    }

    // Checked if there is cached data for this URI
    const lastResponse: any = this.cachedData.get(httpRequest.urlWithParams);

    if (lastResponse) {
      // In case of parallel requests to same URI,
      // return the request already in progress
      // otherwise return the last cached data
      return (lastResponse instanceof Observable) ? lastResponse : of(lastResponse.clone());
    }

    // If the request of going through for first time
    // then let the request proceed and cache the response
    const requestHandler: Observable<HttpEvent<any>> = httpHandler
      .handle(httpRequest)
      .pipe(
        tap({
          next: (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.cachedData.set(
                httpRequest.urlWithParams,
                event.clone(),
              );
            }
          },
          error: (error: HttpErrorResponse) => {
            if (httpRequest.context.get(CACHE_WITH_ERRORS)) {
              this.cachedData.set(
                httpRequest.urlWithParams,
                new HttpErrorResponse(error),
              );
            }
          },
        }),
        share(),
      );

    // Meanwhile cache the request Observable to handle parallel request
    this.cachedData.set(httpRequest.urlWithParams, requestHandler);

    return requestHandler;
  }

}
