import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from 'ngx-simple-modal';

// Consts
import { ROUTE } from '@consts/consts';

@Component({
  templateUrl: './limit-attempts.component.html',
  styleUrls: ['./limit-attempts.component.scss'],
})
export class LimitAttemptsComponent extends SimpleModalComponent<null, null> {

  constructor(
    private route: Router,
  ) {
    super();
  }

  async confirm(): Promise<void> {
    try {
      await this.close();
      this.route.navigate([ROUTE.alias.SIGN_UP]);
    } catch (error) {
      console.warn(error);
    }
  }

}
