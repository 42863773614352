import { NgModule } from '@angular/core';
import { IqLoadingBarComponent } from '@commons/iq-loading-bar/components/iq-loading-bar/iq-loading-bar.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    IqLoadingBarComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    IqLoadingBarComponent,
  ],
})
export class IqLoadingBarModule {
}

