import type { NavItem } from '@core/types';

import { ROUTE, USER_ROLE } from '@consts/consts';

export const Analytics: NavItem[] = [
  {
    label: 'Active Buyers',
    routerLink: ROUTE.alias.ANALYTICS_ACTIVE_BUYERS,
    counter: null,
    isNewCounterDot: true, // todo:: ??
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    icon: null,
  },
];
