<iq-button *ngIf="isShow"
           label="Clear all"
           [size]="size"
           [style]="borderStyle"
           color="grey"
           iconLeft="#icon-close"
           tooltip="Clear filters"
           container="body"
           (onClick)="clear()">
</iq-button>
