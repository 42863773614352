<label class="search-filter" (clickOutside)="hide()">
  <input #searchElementRef
         class="search-filter__input"
         type="text"
         placeholder="Search"
         data-lpignore="true"
         [class.active]="isShow"
         (transitionend)="transitionEnd($event)"/>
  <div class="inline-svg" (click)="show()" [inlineSVG]="'#icon-' + buttonIcon"></div>
</label>
