import momentMini from 'moment-mini';

/**
 * Date Range Picker utils
 *
 */

import type { DateRange } from '@core/types';

export function getDefaultDateRanges(): DateRange[] {
  return [
    {
      name: 'Last Month',
      date_from: new Date(momentMini().subtract(1, 'months').format()),
      date_to: new Date(),
    },
    {
      name: 'Last 3 Months',
      date_from: new Date(momentMini().subtract(3, 'months').format()),
      date_to: new Date(),
    },
    {
      name: 'Last 6 Months',
      date_from: new Date(momentMini().subtract(6, 'months').format()),
      date_to: new Date(),
    },
    {
      name: 'This Year',
      date_from: new Date(momentMini().startOf('year').format()),
      date_to: new Date(momentMini().endOf('year').format()),
    },
    {
      name: 'Last Year',
      date_from: new Date(momentMini().subtract(1, 'year').format()),
      date_to: new Date(),
    },
  ];
}
