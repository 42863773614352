<div class="countdown-modal">
  <div class="countdown-modal-body">
    <div class="icons">
      <span class="phone">📱</span>
      <span class="comment">💬</span>
    </div>
    <p class="f-26-bold m-t-1x">Check your phone,<br/>we just sent you an SMS with a link to the report</p>
    <p class="f-16-normal m-t-6x">
      You will be redirected in <span (countdown)="onCountdownEnd()">10</span>..
    </p>
  </div>
  <div class="progress m-t-6x">
    <div class="progress-gauge" [style.width]="progress"></div>
  </div>
</div>
