<div class="control-dropdown" dropdown="" #checkboxDropdown="bs-dropdown" [insideClick]="true">
  <iq-button label="{{ selected || title }}"
             size="40"
             color="grey"
             iconRight="#icon-chevron"
             dropdownToggle=""></iq-button>
  <div class="control-dropdown__menu dropdown-menu" *dropdownMenu (click)="close($event, checkboxDropdown)">
    <div class="filter-dropdown__content scrollbar" #items="">
      <div class="control-dropdown__item" *ngIf="!isShowLabel">
        <div class="control-dropdown__label text-overflow" (click)="clear()">All</div>
      </div>
      <div class="control-dropdown__item" *ngFor="let item of innerModel">
        <div class="control-dropdown__label d-flex align-items-center" (click)="select(item)">
          <ng-container *ngTemplateOutlet="selectOptionTemplateRef || defaultOptionTemplateRef ; context: { $implicit: item }"></ng-container>
          <ng-template #defaultOptionTemplateRef>
            <div class="text-overflow">{{ item.title }}</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
