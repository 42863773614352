<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'report'">
    <div class="home-info_report">
      <a class="lead-address-link" [routerLink]="leadDetailsLink" [queryParams]="{ id: lead.id }">
        <div class="lead-address-preview" [class.overlimit]="lead?.overlimit_trial_lead">
          <img class="lead-address-image"
               lazy-image
               [class.contain]="isLeadContainsPreview()"
               [src]="getPreviewImage()"/>
          <ng-container *ngIf="!lead?.overlimit_trial_lead">
            <div class="lead-without-fees"
                 *ngIf="lead?.tooltips?.financing_data?.length || lead?.tooltips?.contacts?.length"
                 [inlineSVG]="'#icon-alert_round'"
                 [tooltip]="tipIncorrectFinancingData"
                 container="body"></div>
            <ng-template #tipIncorrectFinancingData="">
              <p *ngFor="let error of lead?.tooltips?.financing_data">- {{ error }}</p>
              <p *ngFor="let error of lead?.tooltips?.contacts">- {{ error }}</p>
            </ng-template>
          </ng-container>
        </div>
        <div class="lead-address">
          <span class="text-overflow owner" *ngIf="lead?.owner_name">{{ lead?.owner_name }}</span>
          <span class="address"
                [innerHTML]="formatAddress(lead?.full_address, lead?.overlimit_trial_lead, lead?.unit)">
          </span>
          <ng-container *permittedIf="PERMITTED_ACTION.HOME_SHOPPER">
            <div *ngIf="lead.active_shopper" class="active-shopper">
              <img class="active-shopper__fire" src="assets/images/fire.png" alt="fire">
              Active Buyer
            </div>
          </ng-container>
        </div>
      </a>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'traffic'">
    <a class="lead-address-link" [routerLink]="[ROUTE.alias.HOMEOWNERS_LEAD, lead?.id]">
      <div class="lead-address-preview" [class.overlimit]="lead?.overlimit_trial_lead">
        <img class="lead-address-image"
             lazy-image=""
             [class.contain]="isLeadContainsPreview()"
             [src]="getPreviewImage()"/>
        <div class="lead-without-fees"
             *ngIf="lead?.tooltips?.financing_data?.length"
             [inlineSVG]="'#icon-alert_round'"
             [tooltip]="tipIncorrectFinancingData"
             container="body">
          <ng-template #tipIncorrectFinancingData="">
            <p *ngFor="let error of lead?.tooltips?.financing_data">- {{ error }}</p>
            <p *ngFor="let error of lead?.tooltips?.contacts">- {{ error }}</p>
          </ng-template>
        </div>
      </div>
      <div class="lead-address traffic">
        <ng-container *ngIf="lead?.owner_name">
          <span class="text-overflow owner">{{ lead?.owner_name }}</span>
        </ng-container>
        <br/>
        <span class="address"
              [innerHTML]="formatAddress(lead?.full_address, lead?.overlimit_trial_lead, lead?.unit)"></span>
      </div>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'mortgage'">
    <a class="lead-address-link" [routerLink]="leadDetailsLink">
      <div class="lead-address-preview" [class.overlimit]="lead?.overlimit_trial_lead">
        <img class="lead-address-image"
             lazy-image=""
             [class.contain]="isLeadContainsPreview()"
             [src]="getPreviewImage()"/>
      </div>
      <div class="lead-address">
        <span class="text-overflow owner"
              *ngIf="lead?.owner_name">{{ lead?.owner_name }}</span>
        <span class="address"
              [innerHTML]="formatAddress(lead?.full_address, lead?.overlimit_trial_lead, lead?.unit)"></span>
      </div>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'archived'">
    <div class="lead">
      <img class="lead__image"
           lazy-image
           [class.contain]="isLeadContainsPreview()"
           [src]="getPreviewImage()"/>
      <div class="lead__address">
        <span class="text-overflow owner f-14-600"
              *ngIf="lead?.owner_name">{{ lead?.owner_name }}</span>
        <span class="address f-14-400"
              [innerHTML]="formatAddress(lead?.full_address, lead?.overlimit_trial_lead, lead?.unit)"></span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'closings'">
    <div class="lead" [class.f-c-cloudy-blue]="lead?.lead_status === 'Dismissed'">
      <img class="lead__image"
           lazy-image=""
           [class.contain]="isLeadContainsPreview()"
           [src]="getPreviewImage()"/>
      <div class="lead__address f-14-600"
           [innerHTML]="formatAddress(lead?.full_address, lead?.overlimit_trial_lead, lead?.unit)"></div>
      <div class="lead__date f-14-normal f-c-grey-blue">{{ lead?.sale_date ? 'Sold:' : 'Loan date:' }} {{ getDate(lead) | date }}</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'home_shopper'">
    <div class="home-info_report">
      <a class="lead-address-link"
         *ngIf="lead.app_name !== 'iqi' else staticActiveBuyerAddress"
         (click)="viewDetailsClicked()"
         [routerLink]="getActiveBuyerRouterLink"
         [queryParams]="getActiveBuyerQueryParams">
        <ng-container *ngTemplateOutlet="activeBuyerAddress"></ng-container>
      </a>
      
      <ng-template #staticActiveBuyerAddress>
        <div class="flex items-center">
          <ng-container *ngTemplateOutlet="activeBuyerAddress"></ng-container>
        </div>
      </ng-template>
      
      <ng-template #activeBuyerAddress>
        <div class="lead-address-preview" [class.overlimit]="lead?.overlimit_trial_lead">
          <img class="lead-address-image"
               lazy-image=""
               [class.contain]="isLeadContainsPreview()"
               [src]="getPreviewImage() || PROPERTY_IMAGE_PLACEHOLDER"/>
        </div>
        <div class="lead-address">
          
          <ng-container *ngIf="lead?.owner_name; else addressExtended">
            <span class="text-overflow owner">{{ lead?.owner_name }}</span>
            <br/>
            <span class="address"
                  [innerHTML]="formatAddress(lead?.full_address, lead?.overlimit_trial_lead, lead?.unit)"></span>
          </ng-container>
          
          <ng-template #addressExtended>
            <span [container]="'home_shopper'"
                  [tooltip]="getIQIActiveBuyerAddressFirstLine"
                  class="text-overflow owner">{{ getIQIActiveBuyerAddressFirstLine }}</span>
            <br/>
            <span [container]="'home_shopper'"
                  [tooltip]="getIQIActiveBuyerAddressSecondLine"
                  class="text-overflow address">{{ getIQIActiveBuyerAddressSecondLine }}</span>
          </ng-template>
          
          <div *ngIf="lead.occupancy === 'Renter'" class="renter-label">
            <span>Renter</span>
          </div>
        
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
