import { Component, EventEmitter, Input, Output } from '@angular/core';

import type { IntegrationDetails } from '@core/types';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pre-approve-form',
  templateUrl: './pre-approve-form.component.html',
  styleUrls: ['./pre-approve-form.component.scss'],
})
export class PreApproveFormComponent {

  @Input() preApproveMe: IntegrationDetails;

  @Input() isConnected: boolean;

  @Input() isReadyToConnect: boolean;

  @Output() activateIntegration: EventEmitter<void> = new EventEmitter<void>();

  @Output() disableIntegration: EventEmitter<void> = new EventEmitter<void>();

  @Output() generateNewKey: EventEmitter<void> = new EventEmitter<void>();

  handleGenerateNewKey(): void {
    this.generateNewKey.emit();
  }

  handleActivateIntegration(): void {
    this.activateIntegration.emit();
  }

  handleDisableIntegration(): void {
    this.disableIntegration.emit();
  }

}
