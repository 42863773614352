import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CollectionResponse,
  CollectionHelpersService,
} from 'asap-team/asap-tools';

import type { AdminBanner } from '@core/types';
import { Params } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AdminHomeownerBannersService {

  constructor(
    private http: HttpClient,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(params: Params): Observable<CollectionResponse<AdminBanner>> {
    return this
      .http
      .get('v2/admin/seller_traffic/brands', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.retrowError(),
      );
  }

}
