import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE } from '@consts/routes';

// Types
import type { Transaction } from '@core/types';

// Utils
import { truncateText } from '@core/utils/truncate-text/truncate-text';

@Component({
  selector: 'transaction-home-info',
  templateUrl: './transaction-home-info.component.html',
  styleUrls: ['./transaction-home-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionHomeInfoComponent {

  @Input() transaction: Transaction;

  @Input() withExternalIdLink: boolean = false;

  constructor(
    private router: Router,
  ) { }

  get isInternalUser(): boolean {
    return !!(this.withExternalIdLink && this.transaction.external_id);
  }

  truncateText: (text: string, maxLength: number) => string = truncateText;

  getAddress(): string {
    if (this.transaction.unit_number) {
      return `${this.transaction.address}, ${this.transaction.unit_number}`;
    }

    return this.transaction.address;
  }

  viewLeadDetails(transaction: Transaction): void {
    let redirectUrl: any[];

    if (transaction.report_status === 'draft') {
      redirectUrl = [ROUTE.alias.HOMEOWNERS_REPORT_NEW];
    } else {
      redirectUrl = [ROUTE.alias.HOMEOWNERS_LEAD, transaction.external_id];
    }

    this.router.navigate(redirectUrl, { queryParams: { id: transaction.external_id } });
  }

  getFullAddress(truncate: boolean = false): string {
    const city: string = this.transaction.city || '';
    const formattedCity: string = truncate ? this.truncateText(city, 16) : city;
    const state: string = this.transaction.state || '';
    const zipCode: string = this.transaction.zip_code || '';

    return `${formattedCity}, ${state} ${zipCode}`;
  }

}
