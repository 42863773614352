import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';
import { FormError, provideRefs } from 'asap-team/asap-tools';

import type { Option } from '@core/types';

@Component({
  selector: 'iq-form-control-native-select',
  templateUrl: './iq-form-control-native-select.component.html',
  styleUrls: ['./iq-form-control-native-select.component.scss'],
  providers: provideRefs(IqFormControlNativeSelectComponent),
})
export class IqFormControlNativeSelectComponent implements ControlValueAccessor {

  @ViewChild('nativeSelect', { static: true, read: ElementRef }) nativeSelect: ElementRef<HTMLInputElement>;

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() placeholder: string | null = null;

  @Input() model: Option[] = [];

  @Input() errorsModel: FormError[];

  @Input() formControlName: string;

  private selected: string = '';

  focused: boolean = false;

  constructor(
    private renderer2: Renderer2,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: string): void => { /* Replaced by Angular at runtime */ };

  onTouched = (): void => { /* Replaced by Angular at runtime */ };

  writeValue(value: string): void {
    this.selected = value;
    this.renderer2.setProperty(this.nativeSelect.nativeElement, 'value', value);
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(this.nativeSelect.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  iSelected(value: string): boolean | null {
    return this.selected === value ? true : null;
  }

  blur(): void {
    this.focused = false;

    this.onTouched();
  }

  change(value: string): void {
    this.selected = value;

    this.onChange(value);
  }

  getNativeElementRef(): ElementRef<HTMLInputElement> {
    return this.nativeSelect;
  }

}
