import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { required } from './conditions';

export const signInErrors: FormErrors = {
  email: [
    required,
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
  ],
  password: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 6',
      rules: ['touched', 'dirty'],
    },
  ],
  global: [],
};
