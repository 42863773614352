import { Component } from '@angular/core';
import { RouteDictionary } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

@Component({
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.scss'],
})
export class ExpiredLinkComponent {

  ROUTE: RouteDictionary = ROUTE;

}
