import { catchError, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';

import type { PlansResponse } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { BillingService } from '@core/services/billing/billing.service';

@Injectable({ providedIn: 'root' })
export class BillingPlansResolve implements Resolve<PlansResponse> {

  constructor(
    private router: Router,
    private billingService: BillingService,
  ) {}

  resolve(): Observable<PlansResponse> {
    return this
      .billingService
      .getAvailablePlans()
      .pipe(
        tap((response: PlansResponse) => {
          if (!response.current_plan) {
            this.router.navigate([ROUTE.alias.BILLING]);
          }
        }),
        catchError(() => {
          this.router.navigate([ROUTE.alias.BILLING]);

          return EMPTY;
        }),
      );
  }

}
