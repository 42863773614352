import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import type { ConfirmActionModalCopy } from '@core/types';

@Component({ templateUrl: './confirm-action-rare.component.html' })
export class ConfirmActionRareComponent extends SimpleModalComponent<{ modalData: ConfirmActionModalCopy }, boolean> {

  modalData: ConfirmActionModalCopy;

  confirm(): void {
    this.result = true;
    this.close();
  }

}
