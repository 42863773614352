import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({ templateUrl: './checkout-error.component.html' })
export class CheckoutErrorComponent extends SimpleModalComponent<{ error: string }, boolean> {

  error: string;

  confirm(): void {
    this.result = true;
    this.close();
  }

}
