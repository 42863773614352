import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, OnInit, ViewChild } from '@angular/core';

import type { PaymentMethod, ActiveSubscription } from '@core/types';

// Consts
import { PAYMENT_METHODS_MODAL_RESULT, PAYMENT_METHOD } from '@consts/consts';

// Services
import { BillingService } from '@core/services/billing/billing.service';
import { PaymentMethodsListComponent } from '@commons/components/payment-methods-list/payment-methods-list.component';
import { DialogRef } from '@angular/cdk/dialog';

type EditPaymentModalEvent = { card: PaymentMethod; event: string; canBePrimary?: boolean };

@UntilDestroy()
@Component({
  selector: 'payment-methods',
  templateUrl: './edit-payment-methods.component.html',
  styleUrls: ['./edit-payment-methods.component.scss'],
})
export class EditPaymentMethodsComponent implements OnInit {

  @ViewChild(PaymentMethodsListComponent) paymentMethodsListComponent: PaymentMethodsListComponent;

  subscription: ActiveSubscription;

  action: Subscription;

  selectedCard: PaymentMethod;

  constructor(
    private toastr: ToastrService,
    private billingService: BillingService,
    private dialogRef: DialogRef,
  ) {
  }

  ngOnInit(): void {
    this.billingService.activeSubscriptions$.subscribe(
      (subscription: ActiveSubscription) => {
        this.subscription = subscription;
      },
    );
  }

  savePaymentMethod(): void {
    if (this.selectedCard.id === this.subscription.payment_method?.id) {
      this.toastr.error(PAYMENT_METHOD.ERROR_ALREADY_SELECTED);
    } else {
      const observable: Observable<any> = this.billingService.updatePaymentMethod(
        this.selectedCard.id,
        {
          date: this.selectedCard.expiration.replace('-', '/'),
          primary: true,
        },
      );

      this.action = observable
        .subscribe(() => {
          this.closeModal();
          this.billingService.paymentMethodsChange();
          this.toastr.success(PAYMENT_METHOD.PRIMARY_CHANGED);
        });
    }
  }

  selectCard(card: PaymentMethod): void {
    if (!card) { return }

    this.selectedCard = card;
  }

  addCard(): void {
    this.closeModal({
      card: null,
      event: PAYMENT_METHODS_MODAL_RESULT.EVENT.SHOW_ADD_CARD,
      canBePrimary: this.paymentMethodsListComponent?.methods.length === 0,
    });
  }

  editCard(card: PaymentMethod): void {
    this.closeModal({
      card,
      event: PAYMENT_METHODS_MODAL_RESULT.EVENT.SHOW_EDIT_CARD,
    });
  }

  deleteCard(card: PaymentMethod): void {
    this.closeModal({
      card,
      event: PAYMENT_METHODS_MODAL_RESULT.EVENT.SHOW_DELETE_CARD,
    });
  }

  closeModal(result?: EditPaymentModalEvent): void {
    this.dialogRef.close(result);
  }

}
