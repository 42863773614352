import { filter, map } from 'lodash-es';
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ContentChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { chain, CollectionFilter } from 'asap-team/asap-tools';

@Component({
  selector: 'iq-select-filter',
  templateUrl: './iq-select-filter.component.html',
  styleUrls: ['./iq-select-filter.component.scss'],
})
export class IqSelectFilterComponent implements OnChanges {

  @ViewChild('items') items: ElementRef;

  @ContentChild('selectOptionTemplate') selectOptionTemplateRef: TemplateRef<any>;

  @Input() model: CollectionFilter[];

  @Input() stateValue: any;

  @Input() title: string;

  @Input() filterName: string;

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  innerModel: CollectionFilter[] = [];

  get selected(): string {
    return chain(this.innerModel, { filter, map })
      .filter((item: CollectionFilter) => item.selected)
      .map((item: CollectionFilter) => item.title)
      .value()
      .join('');
  }

  get isShowLabel(): boolean {
    const selected: CollectionFilter[] = filter(this.innerModel, (item: CollectionFilter) => item.selected);
    const isAllSelected: boolean = selected.length === this.innerModel.length;

    return !this.selected || isAllSelected;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { stateValue } = changes;

    if (stateValue) {
      this.innerModel = this.model.map(
        (item: CollectionFilter) => (item.name === stateValue.currentValue ? { ...item, selected: true } : { ...item, selected: false }),
      );
    }
  }

  select(selectedItem: CollectionFilter): void {
    this.emitFilterValue.emit({ filterName: this.filterName, value: selectedItem.name });
  }

  clear(): void {
    this.emitFilterValue.emit({ filterName: this.filterName, value: null });
  }

  // Used on mobile resolution
  close(event: Event, checkboxDropdown: any): void {
    const targetElement: HTMLElement = event.target as HTMLElement;

    if (targetElement && !this.items.nativeElement.contains(targetElement)) {
      checkboxDropdown.hide();
    }
  }

}
