import { AbstractControl, ValidationErrors } from '@angular/forms';
import { fns } from 'payment';

export function cardExpiryValidator(control: AbstractControl): ValidationErrors {
  if (control.value !== undefined && !fns.validateCardExpiry(control.value)) {
    return { cardExpiryInvalid: true };
  }

  return null;
}
