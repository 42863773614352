import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { UserBanner } from '@core/types';

// Consts
import { COMMON_TOAST, BANNER } from '@consts/consts';

// Services
import { FacebookSDKService } from '@core/vendors/fb-sdk/fb-sdk.service';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';

@UntilDestroy()
@Component({
  templateUrl: './promo-banner-preview.component.html',
  styleUrls: ['./promo-banner-preview.component.scss'],
})
export class PromoBannerPreviewComponent
  extends SimpleModalComponent<{ modalData: { banner: UserBanner; type: string } }, boolean>
  implements OnInit {

  modalData: { banner: UserBanner; type: string };

  shareButton$: Subscription;

  constructor(
    private toastr: ToastrService,
    private asaplyticsService: AsaplyticsService,
    private facebookSDKService: FacebookSDKService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.shareButton$ = this
      .facebookSDKService
      .init()
      .pipe(
        filter(Boolean),
        take(1),
        untilDestroyed(this),
      )
      .subscribe();
  }

  download(url: string): void {
    saveAs(url, this.getImageName(url));
    this.asaplyticsService.trackBannerDownload(this.modalData.banner.id);
  }

  getImageName(url: string): string {
    if (!url) { return ''; }

    return url.split('/').reverse()[0];
  }

  setCopyStatus(): void {
    this.toastr.success(COMMON_TOAST.COPIED_TO_CLIPBOARD);
  }

  share(): void {
    const { landing_url } = this.modalData.banner;

    this.asaplyticsService.trackBannerShare(this.modalData.banner.id);

    this
      .facebookSDKService
      .sdk()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((FB: any) => {
        FB.ui(
          {
            method: 'share',
            href: landing_url,
          },
          (response: any) => {
            if (response && !response.error_message) {
              this.toastr.success(BANNER.POST_CREATED);
              this.close();
            }

            if (response && response.error_message) {
              this.toastr.error(BANNER.POST_ERROR);
            }
          },
        );
      });
  }

  getDescription(banner: UserBanner): string {
    if (!banner) { return ''; }

    return banner.description;
  }

}
