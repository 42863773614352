import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

type TMode = 'email' | 'certificate';

@Component({
  selector: 'refinance-opportunity-example',
  templateUrl: './refinance-opportunity-example.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./refinance-opportunity-example.component.scss'],
})
export class RefinanceOpportunityExampleComponent {

  mode: TMode = 'email';

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  setMode(mode: TMode): void {
    this.mode = mode;
  }

}
