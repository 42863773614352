import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const importPropertiesErrors: FormErrors = {
  tag: [
    {
      name: 'maxlength',
      text: 'Must be less than 20 symbols',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  global: [],
};
