<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.content-prepend]="labelStyle && labelStyle === 'content'"
     [class.control--focus]="focused"
     [class.control--disable]="disabled"
     [class.control--readonly]="readonly">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <ng-content select="[prepend-content]"></ng-content>
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <div class="control__content">
    <div class="d-flex align-items-center">
      <label *ngIf="labelPrefix" class="label-prefix" (click)="nativeInput.focus()">{{ labelPrefix }}</label>
      <div class="flex-1 h-100">
        <input class="control__input"
               #nativeInput
               [attr.type]="type"
               [attr.placeholder]="placeholder"
               [attr.data-lpignore]="lpignore"
               [attr.min]="minNumber"
               [attr.max]="maxNumber"
               [readonly]="readonly"
               (focus)="focus($event)"
               (blur)="blur()"
               (input)="input($any($event).target.value)"/>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
