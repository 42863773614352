import {
  trigger,
  transition,
  style,
  animate,
  group,
  query,
  animateChild,
  AnimationTriggerMetadata,
} from '@angular/animations';

export const routeTransitionAnimation: AnimationTriggerMetadata = trigger('routeTransitionAnimation', [
  transition('* <=> *', [
    group([
      query(
        ':enter',
        [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 })), animateChild()],
        { optional: true },
      ),
      query(
        ':leave',
        [animate('0.2s', style({ opacity: 0 })), animateChild()],
        { optional: true },
      ),
    ]),
  ]),
]);
