import { FormErrors } from 'asap-team/asap-tools';

export const confirmPasswordErrors: FormErrors = {
  password: [
    {
      name: 'minlength',
      text: 'Min length is 6',
      rules: ['touched', 'dirty'],
    },
  ],
  password_confirmation: [
    {
      name: 'minlength',
      text: 'Min length is 6',
      rules: ['touched', 'dirty'],
    },
  ],
  global: [],
};
