<div class="control-dropdown" dropdown="" #checkboxDropdown="bs-dropdown" [insideClick]="true" [container]="appendSelector">
  <iq-button label="{{ selected || title }}"
             iconRight="#icon-chevron"
             color="grey"
             [size]="toggleButtonSize"
             [style]="toggleButtonStyle"
             dropdownToggle=""></iq-button>
  <div class="control-dropdown__menu dropdown-menu" *dropdownMenu (click)="close($event, checkboxDropdown)">
    <div class="filter-dropdown__content scrollbar" #items>
      <div class="control-dropdown__item" *ngIf="!hideAllOption && !isShowLabel">
        <div class="control-dropdown__label text-overflow" (click)="clear()">All</div>
      </div>
      <div class="control-dropdown__item" *ngFor="let item of innerModel">
        <div class="control-dropdown__label text-overflow" (click)="select(item)">{{ item.title }}</div>
      </div>
    </div>
  </div>
</div>
