import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'progress-line',
  template: `
    <div class="progress-line">
      <span class="progress-line__bar" [ngStyle]="{ backgroundColor: lineProgressColor, width: value + '%' }"></span>
    </div>
  `,
  styleUrls: ['./progress-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressLineComponent {

  @Input() value: number = 0;

  @Input() lineProgressColor: string = '#0389FF';

}
