import { Params } from '@angular/router';

export type BackBtnLink = {
  url: string;
  title?: string;
  data?: Params;
};

export class BackBtnStateModel {

  linksStash: BackBtnLink[] = [];

}
