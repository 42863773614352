import { LookUpPartner } from '@core/types';

export class SearchPartnerSidebarModel {

  rows: LookUpPartner[] = [];

  loading: boolean = false;

  query: string = undefined;

  page: number = 1;

  total_pages: number = 0;

}
