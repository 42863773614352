import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Transaction } from '@core/types';

@Component({
  selector: 'transaction-loan-officer',
  templateUrl: './transaction-loan-officer.component.html',
  styleUrls: ['./transaction-loan-officer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionLoanOfficerComponent {

  @Input() row: Transaction;

  @Input() nameClasses: string[] = [];

}
