import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { USER_SERVICE_TOKEN } from 'asap-team/asap-tools';

import type { Observable } from 'rxjs';
import type { Profile } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class ProfileGuard implements CanActivate, CanLoad {

  constructor(
    private injector: Injector,
    private router: Router,
  ) {}

  private doCheck(): Observable<boolean> {
    const userService: any = this.injector.get(USER_SERVICE_TOKEN);

    return userService
      .profile$
      .pipe(
        take(1),
        map((profile: Profile) => {
          if (!profile) {
            this.router.navigate([ROUTE.alias.SIGN_IN]);

            return false;
          }

          return true;
        }),
      );
  }

  canLoad(): Observable<boolean> {
    return this.doCheck();
  }

  canActivate(): Observable<boolean> {
    return this.doCheck();
  }

}
