import { Injectable, ViewContainerRef } from '@angular/core';
import { MenuComponent } from '@commons/components/toolbar/menu/menu.component';
import { NavItem, PermittedAction, SideNavItem } from '@core/types';
import { SettingsSidebarNav } from '@consts/nav-config/settings-sidebar';
import { ProfileComponent } from '@commons/components/toolbar/profile/profile.component';
import { DocumentService } from 'asap-team/asap-tools';
import { PERMITTED_ACTION } from '@consts/permitted-actions';

@Injectable({ providedIn: 'root' })
export class MenuService {

  menuContainer: ViewContainerRef;

  navigation: NavItem[] = [];

  profileMenu: SideNavItem[] = SettingsSidebarNav;

  discountPermittedActions: PermittedAction[] = [
    PERMITTED_ACTION.REFERRAL_MANAGE,
    PERMITTED_ACTION.REFERRAL_VIEW,
    PERMITTED_ACTION.REFERRAL_EXPIRED,
  ];

  constructor(
    private documentService: DocumentService,
  ) {
  }

  attachContainer(viewContainerRef: ViewContainerRef): void {
    this.menuContainer = viewContainerRef;
  }

  attachNavigation(navigation: NavItem[]): void {
    this.navigation = navigation;
  }

  openMobileMenu(): void {
    this.menuContainer.clear();
    this.menuContainer.createComponent(MenuComponent);
    this.documentService.appendOverflowBody();
  }

  closeAllMenus(): void {
    this.menuContainer.clear();
    this.documentService.removeOverflowBody();
  }

  openProfileMenuMobile(): void {
    this.menuContainer.clear();
    this.menuContainer.createComponent(ProfileComponent);
    this.documentService.appendOverflowBody();
  }

}
