import { Injectable } from '@angular/core';
import { isBoolean } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { isPermitted } from 'asap-team/asap-tools';

import type { Alert, Profile } from '@core/types';

// Consts
import { BILLING_PLAN, USER_ROLE, SUBSCRIPTION_STATUS, PERMITTED_ACTION } from '@consts/consts';
import { ALERTS_TYPE } from '@consts/alerts';

// Utils
import { UserService } from '@core/services/user/user.service';

type AlertParams = {
  invite?: boolean;
};

@Injectable({ providedIn: 'root' })
export class AlertsService {

  private alerts: Alert[] = [];

  private removed: Alert[] = [];

  private alertStream: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>([]);

  alerts$: Observable<Alert[]> = this
    .alertStream
    .asObservable()
    .pipe(
      filter<Alert[]>(Boolean),
      shareReplay(1, 500),
    );

  constructor(private userService: UserService) {
    this.init();
  }

  publish({ invite }: AlertParams): void {
    this.manageAlert(ALERTS_TYPE.PENDING_INVITE, invite);
  }

  dismiss(alert: Alert): void {
    this.remove(alert, true);
  }

  dismissAll(action: boolean): void {
    if (!action) { return }

    this.alerts = [];
    this.removed = [];
    this.alertStream.next([]);
  }

  private init(): void {
    this
      .userService
      .profile$
      .subscribe((profile: Profile) => this.processAlerts(profile));

    this
      .userService
      .logoutAction$
      .subscribe((action: boolean) => this.dismissAll(action));
  }

  private add(type: Alert): void {
    if (!type) { return }

    if (!this.isAlertPresent(type) && !this.isRemovedForThisSession(type)) {
      this.alerts = [...this.alerts, type];
      this.alertStream.next(this.alerts);
    }
  }

  private remove(type: Alert, removeForThisSession: boolean): void {
    if (!type) { return }

    const index: number = this.alerts.indexOf(type);

    if (~index) {
      this.alerts = [
        ...this.alerts.slice(0, index),
        ...this.alerts.slice(index + 1),
      ];

      if (removeForThisSession) {
        this.removed = [...this.removed, type];
      }

      this.alertStream.next(this.alerts);
    }
  }

  private isAlertPresent(type: Alert): boolean {
    return this.alerts.includes(type);
  }

  private isRemovedForThisSession(type: Alert): boolean {
    return this.removed.includes(type);
  }

  private processAlerts(profile: Profile): void {
    const {
      role,
      plan_name,
      email_confirmed,
      remaining_limits,
      registration_completed,
      subscription_status,
      permitted_actions,
    } = profile;

    if (!registration_completed) { return }

    const isUser: boolean = role === USER_ROLE.agent || role === USER_ROLE.lender;
    const isSuitable: boolean = (plan_name === BILLING_PLAN.TRIAL && remaining_limits <= 5);
    const isPausedSubscription: boolean = subscription_status === SUBSCRIPTION_STATUS.PAUSED;
    const isMonitoringProgram: boolean = subscription_status === SUBSCRIPTION_STATUS.MONITORING_PROGRAM;
    const isPaymentProblem: boolean = isPermitted(permitted_actions, PERMITTED_ACTION.PAYMENT_PROBLEM);

    this.manageAlert(ALERTS_TYPE.UPGRADE_SUBSCRIPTION, isUser && isSuitable);
    this.manageAlert(ALERTS_TYPE.CONFIRM, !email_confirmed);
    this.manageAlert(ALERTS_TYPE.PAUSED_SUBSCRIPTION, isPausedSubscription);
    this.manageAlert(ALERTS_TYPE.MONITORING_PROGRAM, isMonitoringProgram);
    this.manageAlert(ALERTS_TYPE.PAYMENT_PROBLEM, isPaymentProblem);
  }

  private manageAlert(type: Alert, action: boolean): void {
    if (!isBoolean(action)) { return }

    if (action) {
      this.add(type);

      return;
    }

    this.remove(type, false);
  }

}
