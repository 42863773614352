import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { DOCUMENT } from '@angular/common';
import { TRACKING_PROVIDER } from 'asap-team/asap-tools';
import { environment } from '../../../../environments/environment';

type TrackingProvider = {
  id: string;
  name: string;
};

@Injectable({ providedIn: 'root' })
export class TrackingScriptsService {

  private readonly analyticsProviders: TrackingProvider[] = [
    {
      id: environment.analytics.gtm,
      name: TRACKING_PROVIDER.GTM,
    },
  ];

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  setupTrackingScripts(): void {
    this.analyticsProviders.forEach((provider: TrackingProvider) => this.setupAnalyticsProvider(provider));
  }

  /* eslint-disable */
  private setupAnalyticsProvider({ id, name }: TrackingProvider): void {
    const window = this.window as any;
    const document = this.document as any;

    switch (name) {
      case TRACKING_PROVIDER.GTM:
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

          w.gtag = function () {
            w[l].push(arguments);
          };

          const f = d.getElementsByTagName(s)[0];
          const j = d.createElement(s);
          const dl = l !== 'dataLayer' ? `&l=${l}` : '';

          j.async = true;
          j.src = `https://www.googletagmanager.com/gtm.js?ver=2&id=${i}${dl}`;
          f.parentNode.insertBefore(j, f);
        }(window, document, 'script', 'dataLayer', id));
        break;
      default:
        break;
    }
  }

  /* eslint-enable */

}
