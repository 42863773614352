import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ROUTE } from '@consts/consts';
import { Router } from '@angular/router';

@Injectable()
export class APIUnavailableInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {

      // If the request is unavailable, redirect to the maintenance page
      if (error.status === 503) {
        this.router.navigate([ROUTE.name.MAINTENANCE]);

        return of(null);
      }

      return throwError(() => error);
    }));
  }

}
