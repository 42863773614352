<h2 class="ftl-title m-b-1x text-center">We need to verify your identity to proceed.</h2>
<p class="ftl-regular-text m-b-5x text-center">
  To utilize our intelligence tools and ensure accurate syncing of transactional data, you need to complete your profile
  verification with myhomeIQ
</p>
<form class="profile-form" [formGroup]="form" (ngSubmit)="submitLenderProfile()">
  <div class="profile-avatar">
    <user-avatar [avatar]="profile?.avatar?.medium"
                 [role]="profile?.role"
                 [username]="profile?.username"
                 [canEdit]="true"
                 [loading]="avatarLoading"
                 (imageSelected)="uploadAvatar($event)"></user-avatar>
    <div class="profile-avatar__info">
      <span class="profile-avatar__title m-t-1x">
        Adding your photo will increase<br />Agent connection by 3X
      </span>
      <img class="profile-avatar__arrow" src="assets/components/ftl/avatar_arrow.svg">
    </div>
  </div>

  <iq-form-control-phone class="alternative-label m-b-2x"
                         label="Cell Phone*"
                         formControlName="phone"
                         [errorsModel]="formErrors.phone">
    <div class="m-l-1x m-r-2x">
      <div class="confirmed inline-svg inline-svg--16"
           *ngIf="profile?.phone_confirmed"
           [inlineSVG]="'#icon-check_done'"></div>
      <iq-button *ngIf="!profile?.phone_confirmed"
                 label="Confirm"
                 size="24"
                 [style]="'outline'"
                 [disabled]="isConfirmPhoneDisabled"
                 (onClick)="confirmPhoneNumber()"></iq-button>
    </div>
  </iq-form-control-phone>

  <h4 class="m-b-2x f-16-700 l-h-24">License</h4>

  <iq-form-control-text class="alternative-label m-b-2x"
                        label="Your NMLS Number*"
                        placeholder="12345678"
                        formControlName="license_number"
                        [errorsModel]="formErrors.license_number"></iq-form-control-text>

  <iq-form-control-custom-multiselect class="alternative-label m-b-2x"
                                      *ngIf="states"
                                      label="State Licensed*"
                                      placeholder="Select licensed states"
                                      filterPlaceholder="Type state name"
                                      formControlName="license_states"
                                      [displayCode]="true"
                                      [model]="states"
                                      [errorsModel]="formErrors.license_states"></iq-form-control-custom-multiselect>

  <h4 class="m-b-2x p-t-1x f-16-700 l-h-24">Employment</h4>

  <iq-form-control-text class="alternative-label m-b-2x"
                        label="Job Title*"
                        placeholder="Job Title"
                        formControlName="job_title"
                        [errorsModel]="formErrors.job_title"></iq-form-control-text>

  <iq-form-control-text class="alternative-label m-b-2x"
                        label="Brokerage Name*"
                        placeholder="Acme Ltd."
                        formControlName="company_name"
                        [readonly]="'true' | permittedUnless : 'ENTERPRISE_RESTRICTIONS' : 'swap' | async"
                        [errorsModel]="formErrors.company_name"></iq-form-control-text>

  <iq-form-control-text class="alternative-label m-b-2x"
                        [label]="isAgentRole ? 'Brokerage License Number*' : 'Brokerage NMLS Number*'"
                        [placeholder]="isAgentRole ? 'Brokerage License Number' : 'Brokerage NMLS Number'"
                        formControlName="company_license_number"
                        [readonly]="'true' | permittedUnless : 'ENTERPRISE_RESTRICTIONS' : 'swap' | async"
                        [errorsModel]="formErrors.company_license_number"></iq-form-control-text>

  <h4 class="m-b-10 p-t-1x f-16-700 l-h-24">Time zone</h4>
  <p class="m-b-10 f-14-normal l-h-22 f-c-steel">
    Timezone settings in myhomeIQ affect when we send out email and SMS notifications to your past clients.
    Ensure it's correctly set!
  </p>
  <iq-form-control-custom-select class="alternative-label m-b-5x"
                                 formControlName="time_zone"
                                 label="Time zone*"
                                 placeholder="Choose timezone"
                                 [model]="(timeZones$ | async)"
                                 [errorsModel]="formErrors.time_zone"></iq-form-control-custom-select>

  <iq-button class="block"
             type="submit"
             label="Continue"
             [busy$]="action$"
             [disabled]="form.invalid"></iq-button>
</form>
