import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';

import type { Observable } from 'rxjs';

// Services
import { ProfileHashService } from '@core/helpers/profile-hash/profile-hash.service';

@Injectable({ providedIn: 'root' })
export class ProfileHashInterceptor implements HttpInterceptor {

  constructor(
    private profileHashService: ProfileHashService,
  ) {}

  intercept(
    httpRequest: HttpRequest<any>,
    httpHandler: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return httpHandler
      .handle(httpRequest)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (!(event instanceof HttpResponse)) { return; }

          const hash: string = event?.body?.hash || event?.body?.meta?.hash;

          if (!hash) { return; }

          this.profileHashService.emitProfileHashUpdate(hash);
        }),
      );
  }

}
