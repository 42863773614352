import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CollectionHelpersService,
  CollectionResponse,
} from 'asap-team/asap-tools';

import type { UploadsCollectionItem } from '@core/types';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CsvUploadsService {

  constructor(
    private http: HttpClient,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(params: Params): Observable<CollectionResponse<UploadsCollectionItem>> {
    return this
      .http
      .get('v2/admin/imports', { params })
      .pipe(
        this.collectionHelpersService.mapResponseFilters(),
        map((collection: CollectionResponse<UploadsCollectionItem>) => {
          return {
            ...collection,
            data: collection.data.map((row: UploadsCollectionItem) => {
              return {
                ...row,
                completeAction: null,
                claimAction: null,
              };
            }),
          };
        }),
        this.collectionHelpersService.retrowError(),
      );
  }

}
