export const EMPTY_DATA: any = [
  {
    date: 'Jan',
    value: 12.5,
  },
  {
    date: 'Feb',
    value: 25,
  },
  {
    date: 'Mar',
    value: 37.5,
  },
  {
    date: 'Apr',
    value: 50,
  },
  {
    date: 'May',
    value: 62.5,
  },
  {
    date: 'Jun',
    value: 75,
  },
  {
    date: 'Jul',
    value: 87.5,
  },
  {
    date: 'Aug',
    value: 100,
  },
  {
    date: 'Sep',
    value: 112.5,
  },
  {
    date: 'Oct',
    value: 125,
  },
  {
    date: 'Nov',
    value: 137.5,
  },
  {
    date: 'Dec',
    value: 150,
  },
];
