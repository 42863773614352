import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { required } from './conditions';

export const updateCardErrors: FormErrors = {
  date: [
    required,
    {
      name: 'cardExpiryInvalid',
      text: 'Expiration date is invalid',
      rules: ['dirty'],
    },
  ],
  global: [],
};
