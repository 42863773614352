<div class="backdrop" (click)="closeMenu()">
  <div class="menu-nav" (click)="$event.stopPropagation()">
    <div class="menu-header">
      <div class="spacer"></div>
      <div (click)="closeMenu()" class="close-button pointer"></div>
    </div>
    <div class="menu-content">
      <ul>
        <li *ngFor="let menuItem of menuService.navigation">
          <a [routerLink]="[menuItem.routerLink]" (click)="closeMenu()" routerLinkActive="active-nav-link"
             class="nav-link">
            <div class="icon">
              <img *ngIf="menuItem.icon" alt="{{menuItem.label}}" [src]="menuItem.icon" width="20" height="20">
            </div>
            <div class="title m-l-16">
              {{ menuItem.label }}
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
