import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Consts
import { COMMON_TOAST, USER_ROLE, ROUTE } from '@consts/consts';
import type { UserRole } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';
import { DialogRef } from '@angular/cdk/dialog';

@UntilDestroy()
@Component({
  templateUrl: './get-invite-link.component.html',
  styleUrls: ['./get-invite-link.component.scss'],
})
export class GetInviteLinkComponent implements OnInit {

  getLink$: Subscription;

  invitationLink: string = 'Generating invite link ..';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private dialogRef: DialogRef,
  ) {
  }

  ngOnInit(): void {
    this.subscribes();
  }

  subscribes(): void {
    this.getLink$ = this
      .userService
      .getInviteLink()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe({
        next: ({ referral_link }: { referral_link: string }) => {
          const splittedUrl: Array<string> = referral_link.split('?');

          this.invitationLink = `${splittedUrl[0]}/?${splittedUrl[1]}`;
        },
        error: () => {
          this.router.navigate([ROUTE.alias.UPGRADE_SUBSCRIPTION]);
          this.dialogRef.close();
        },
      });
  }

  generateInviteText(): string {
    const role: UserRole = this.userService.getUserRole();
    const roleString: string = role === USER_ROLE.lender ? 'agents' : 'lenders';

    return `Share this link with ${roleString}. Once they register by the link, they will become your <i>my</i>homeIQ partners automatically.`;
  }

  filterLinkView(url: string): string {
    return url.replace(/(^\w+:|^)\/\//, '');
  }

  showSuccessToast(): void {
    this.toastr.success(COMMON_TOAST.COPIED_TO_CLIPBOARD);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
