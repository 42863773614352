import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import {
  CollectionHelpersService,
  BaseHttpService,
  CollectionResponse,
} from 'asap-team/asap-tools';

import { Observable } from 'rxjs';
import { ArchivedAccount } from '@core/types';

@Injectable({ providedIn: 'root' })
export class ArchivedAccountService {

  constructor(
    private http: BaseHttpService,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(code: string, queryParams: Params): Observable<CollectionResponse<ArchivedAccount>> {
    const endpoint: string = code ? `v2/admin/companies/${code}/users/archived` : 'v2/admin/users/archived';

    return this
      .http
      .get(endpoint, { params: new HttpParams({ fromObject: queryParams }) })
      .pipe(
        this.collectionHelpersService.mapResponseFilters(),
        this.collectionHelpersService.retrowError(),
      );
  }

}
