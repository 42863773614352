import { AnalyticsEventsList } from './analytics-events.interface';

export const ANALYTICS_EVENTS: AnalyticsEventsList = {
  FTL: {
    agent: {
      manual: {
        welcome_step: {
          action: 'View welcome step (Agent)',
          category: 'FTL Agent (Manual)',
        },
        go_to_seller_leads: {
          action: 'Click SELLER LEADS (Agent)',
          category: 'FTL Agent (Manual)',
        },
        go_to_buyer_leads: {
          action: 'Click BUYER LEADS (Agent)',
          category: 'FTL Agent (Manual)',
        },
        go_to_database: {
          action: 'Click DATABASE (Agent)',
          category: 'FTL Agent (Manual)',
        },
        skip_tour_from_welcome: {
          action: 'SKIP tour (Agent)',
          category: 'FTL Agent (Manual)',
        },
        seller_leads_step: {
          action: 'View seller leads step (Agent)',
          category: 'FTL Agent (Manual)',
        },
        continue_from_seller_leads: {
          action: 'Click CONTINUE (Agent)',
          category: 'FTL Agent (Manual)',
          label: 'Seller leads step',
        },
        go_back_from_seller_leads: {
          action: 'Click GO BACK (Agent)',
          category: 'FTL Agent (Manual)',
          label: 'Seller leads step',
        },
        buyer_leads_step: {
          action: 'View buyer leads step (Agent)',
          category: 'FTL Agent (Manual)',
        },
        continue_from_buyer_leads: {
          action: 'Click CONTINUE (Agent)',
          category: 'FTL Agent (Manual)',
          label: 'Buyer leads step',
        },
        go_back_from_buyer_leads: {
          action: 'Click GO BACK (Agent)',
          category: 'FTL Agent (Manual)',
          label: 'Buyer leads step',
        },
        database_step: {
          action: 'View database step (Agent)',
          category: 'FTL Agent (Manual)',
        },
        go_to_try_your_address: {
          action: 'Click TRY YOUR ADDRESS (Agent)',
          category: 'FTL Agent (Manual)',
          label: 'Buyer leads step',
        },
        go_to_sample_report: {
          action: 'Click SAMPLE REPORT (Agent)',
          category: 'FTL Agent (Manual)',
          label: 'Buyer leads step',
        },
        go_back_from_database: {
          action: 'Click GO BACK (Agent)',
          category: 'FTL Agent (Manual)',
          label: 'Database step',
        },
        skip_tour_from_databse: {
          action: 'SKIP on database step (Agent)',
          category: 'FTL Agent (Manual)',
        },
        report_address_step: {
          action: 'View report address step (Agent)',
          category: 'FTL Agent (Manual)',
        },
        go_to_view_report: {
          action: 'Click VIEW REPORT (Agent)',
          category: 'FTL Agent (Manual)',
        },
        skip_tour_report_address: {
          action: 'SKIP on report address (Agent)',
          category: 'FTL Agent (Manual)',
        },
        generating_report_step: {
          action: 'View generating report (Agent)',
          category: 'FTL Agent (Manual)',
        },
        sms_popup_step: {
          action: 'View check sms popup (Agent)',
          category: 'FTL Agent (Manual)',
        },
        sms_popup_ended: {
          action: 'Redirect from sms popup (Agent)',
          category: 'FTL Agent (Manual)',
        },
        account_step: {
          action: 'View account step (Agent)',
          category: 'FTL Agent (Manual)',
        },
        go_to_schedule_demo_from_acount: {
          action: 'Click SCHEDULE DEMO on account step (Agent)',
          category: 'FTL Agent (Manual)',
        },
        go_to_access_account_from_account: {
          action: 'Click ACCESS ACCOUNT on account step (Agent)',
          category: 'FTL Agent (Manual)',
        },
      },
      viral: {
        closings_step: {
          action: 'View closings step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        continue_from_closings_step_click: {
          action: 'Click CONTINUE on closings step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        continue_from_closings_step_auto: {
          action: 'AUTO CONTINUE (no data) on closings step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        lost_closings_step: {
          action: 'View lost step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        continue_from_lost_closings_step_click: {
          action: 'Click CONTINUE on lost step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        continue_from_lost_closings_step_auto: {
          action: 'AUTO CONTINUE (no data) on lost step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        pre_mover_step: {
          action: 'View pre-movers step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        continue_from_pre_mover_step_click: {
          action: 'Click CONTINUE on pre-movers step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        continue_from_pre_mover_step_auto: {
          action: 'AUTO CONTINUE (no data) on pre-movers step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        video_step: {
          action: 'View video step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        skip_video_step: {
          action: 'SKIP video step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        get_report_step: {
          action: 'View get report step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        click_get_report_from_get_report_step: {
          action: 'Click GET REPORT on get report step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        skip_get_report_step: {
          action: 'SKIP on get report step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        generate_report_step: {
          action: 'View generating report step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        confirm_identity_step: {
          action: 'View confirmation step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        focus_form_control_from_confirm_identity_step: {
          action: 'Form focus on confirmation step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        form_error_from_confirm_identity_step: {
          action: 'Error on confirmation step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        cta_click_from_confirm_identity_step: {
          action: 'Click CTA on confirmation step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        redirect_from_sms_popup_on_confirm_identity_step: {
          action: 'Redirect from sms popup (Agent)',
          category: 'FTL Agent (Viral)',
        },
        check_sms_step: {
          action: 'View check sms popup (Agent)',
          category: 'FTL Agent (Viral)',
        },
        benefits_step: {
          action: 'View lender benefits step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        continue_on_benefits_step: {
          action: 'Click CONTINUE on lender benefit step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        skip_benefits_step: {
          action: 'SKIP lender benefits step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        invite_step: {
          action: 'View lenders step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        search_on_invite_step: {
          action: 'Search lenders step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        click_select_on_invite_step: {
          action: 'Click SELECT on lenders step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        click_invite_on_invite_step: {
          action: 'Click INVITE on lenders step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        click_send_on_invite_step: {
          action: 'Click SEND on manual lenders step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        skip_on_invite_step: {
          action: 'SKIP on lenders step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        select_crm_step: {
          action: 'View CRM step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        click_select_on_select_crm_step: {
          action: 'Click SELECT on CRM step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        skip_select_crm_step: {
          action: 'SKIP CRM step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        search_on_select_crm_step: {
          action: 'Search CRM step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        onboarding_step: {
          action: 'View on onboarding step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        submit_on_onboarding_step: {
          action: 'User scheduled demo on onboarding step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        skip_onboarding_step: {
          action: 'SKIP on onboarding step (Agent)',
          category: 'FTL Agent (Viral)',
        },
        complete_registration: {
          action: 'Complete registration (Agent)',
          category: 'FTL Agent (Viral)',
        },
      },
      ftl_2024: {
        enter_password: {
          action: 'view_password_step_agent',
          category: 'ftl_agent_2024',
        },
        agent_completed_profile: {
          action: 'view_profile_step_agent',
          category: 'ftl_agent_2024',
        },
        demo_book: {
          action: 'view_calendar_step_agent',
          category: 'ftl_agent_2024',
        },
        demo_book_skip: {
          action: 'click_skip_calendar_step_agent',
          category: 'ftl_agent_2024',
        },
        agent_invited: {
          action: 'view_success_step_invited_agent',
          category: 'ftl_agent_2024',
        },
        all_set: {
          action: 'view_success_step_agent',
          category: 'ftl_agent_2024',
        },
      },
    },
    lender: {
      viral: {
        closings_step: {
          action: 'View closings step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        continue_from_closings_step_click: {
          action: 'Click CONTINUE on closings step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        continue_from_closings_step_auto: {
          action: 'AUTO CONTINUE (no data) on closings step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        lost_closings_step: {
          action: 'View lost step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        continue_from_lost_closings_step_click: {
          action: 'Click CONTINUE on lost step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        continue_from_lost_closings_step_auto: {
          action: 'AUTO CONTINUE (no data) on lost step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        pre_mover_step: {
          action: 'View pre-movers step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        continue_from_pre_mover_step_click: {
          action: 'Click CONTINUE on pre-movers step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        continue_from_pre_mover_step_auto: {
          action: 'AUTO CONTINUE (no data) on pre-movers step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        video_step: {
          action: 'View video step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        skip_video_step: {
          action: 'SKIP video step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        get_report_step: {
          action: 'View get report step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        click_get_report_from_get_report_step: {
          action: 'Click GET REPORT on get report step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        skip_get_report_step: {
          action: 'SKIP on get report step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        generate_report_step: {
          action: 'View generating report step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        confirm_identity_step: {
          action: 'View confirmation step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        focus_form_control_from_confirm_identity_step: {
          action: 'Form focus on confirmation step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        form_error_from_confirm_identity_step: {
          action: 'Error on confirmation step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        cta_click_from_confirm_identity_step: {
          action: 'Click CTA on confirmation step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        redirect_from_sms_popup_on_confirm_identity_step: {
          action: 'Redirect from sms popup (Lender)',
          category: 'FTL Lender (Viral)',
        },
        check_sms_step: {
          action: 'View check sms popup (Lender)',
          category: 'FTL Lender (Viral)',
        },
        benefits_step: {
          action: 'View lender benefits step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        continue_on_benefits_step: {
          action: 'SKIP lender benefits step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        invite_step: {
          action: 'View lenders step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        search_on_invite_step: {
          action: 'Search lenders step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        click_select_on_invite_step: {
          action: 'Click SELECT on lenders step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        click_invite_on_invite_step: {
          action: 'Click INVITE on lenders step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        click_send_on_invite_step: {
          action: 'Click SEND on manual lenders step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        skip_send_on_invite_step: {
          action: 'SKIP on lenders step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        select_crm_step: {
          action: 'View CRM step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        click_select_on_select_crm_step: {
          action: 'Click SELECT on CRM step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        skip_select_crm_step: {
          action: 'SKIP CRM step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        search_on_select_crm_step: {
          action: 'Search CRM step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        onboarding_step: {
          action: 'View on onboarding step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        submit_on_onboarding_step: {
          action: 'User scheduled demo on onboarding step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        skip_onboarding_step: {
          action: 'SKIP on onboarding step (Lender)',
          category: 'FTL Lender (Viral)',
        },
        complete_registration: {
          action: 'Complete registration (Lender)',
          category: 'FTL Lender (Viral)',
        },
      },
      ftl_2024: {
        enter_password: {
          action: 'view_password_step_lender',
          category: 'ftl_lender_2024',
        },
        lender_completed_profile: {
          action: 'view_profile_step_lender',
          category: 'ftl_lender_2024',
        },
        demo_book: {
          action: 'view_calendar_step_lender',
          category: 'ftl_lender_2024',
        },
        all_set: {
          action: 'view_success_step_lender',
          category: 'ftl_lender_2024',
        },
      },
    },
  },
};
