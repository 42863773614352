<div class="profile-support">
  <p class="profile-support__title">Need help?</p>
  <div class="profile-support__wrapper">
    <img class="profile-support__image"
         *roleIf="USER_ROLE.agent"
         src="assets/components/settings/em.png"/>
    <img class="profile-support__image"
         *roleIf="USER_ROLE.lender"
         src="assets/components/settings/evan.png"/>
    <p class="profile-support__name" *roleIf="USER_ROLE.agent">Emanuela</p>
    <p class="profile-support__name" *roleIf="USER_ROLE.lender">Evan Perov</p>
    <p class="profile-support__position">Customer Support</p>
    <p class="profile-support__text">
      If you need help setting up your account schedule a call with our onboarding team
    </p>
    <a class="btn btn-blue"
       *roleIf="USER_ROLE.lender"
       href="https://calendly.com/evan-homeiq/15"
       target="_blank">
      <iq-button label="Book a call" size="40" [style]="'solid'"></iq-button>
    </a>
    <a class="btn btn-blue"
       *roleIf="USER_ROLE.agent"
       href="https://calendly.com/em-homeiq/new-agent-group"
       target="_blank">
      <iq-button label="Book a call" size="40" [style]="'solid'"></iq-button>
    </a>
  </div>
</div>
