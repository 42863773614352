import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';

// State
import { BackBtnLink, BackBtnStateModel } from './back-btn.model';
import { BackButtonActions } from './back-btn.actions';

@State<BackBtnStateModel>({
  name: 'backBtnState',
  defaults: new BackBtnStateModel(),
})
@Injectable()
export class BackBtnState {

  @Selector()
  static backLinks(state: BackBtnStateModel): BackBtnLink[] {
    return state.linksStash;
  }

  @Action(BackButtonActions.SetItem)
  setItem({ patchState, setState }: StateContext<BackBtnStateModel>, action: BackButtonActions.SetItem): BackBtnStateModel {
    const { item, isCreateNewStash } = action;

    if (isCreateNewStash) {
      patchState({ linksStash: [] });
    }

    return setState(patch({ linksStash: insertItem(item) }));
  }

}
