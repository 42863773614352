import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  map,
  debounceTime,
  filter,
  pairwise,
  startWith,
} from 'rxjs/operators';

import type { Dictionary } from 'asap-team/asap-tools';
import { Store } from '@core/types/Store';

@UntilDestroy()
@Component({
  selector: 'search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {

  @ViewChild('searchElementRef', { static: true }) searchElementRef: ElementRef;

  @Input() provider: Store;

  isShow: boolean = false;

  isShowSearchIcon: boolean = true;

  ngOnInit(): void {
    this.subscribes();
  }

  private subscribes(): void {
    this
      .provider
      .queryParams$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (filters: Dictionary<string>) => {
          if (!filters) { return; }

          if (!filters.search) {
            this.reset();
          }
        },
      );

    fromEvent(this.searchElementRef.nativeElement, 'input')
      .pipe(
        map((event: any) => event.currentTarget.value),
        filter((value: string) => value.length > 2 || value.length === 0),
        startWith(''),
        pairwise(),
        filter(([prev, current]: string[]) => prev !== current),
        map(([, current]: string[]) => current),
        debounceTime(500),
        untilDestroyed(this),
      )
      .subscribe(this.search.bind(this));
  }

  private reset(): void {
    if (!this.searchElementRef.nativeElement.value) { return; }

    this.searchElementRef.nativeElement.value = null;
    this.isShow = false;
    this.isShowSearchIcon = true;
  }

  get buttonIcon(): 'search' | 'close' {
    return this.isShowSearchIcon ? 'search' : 'close';
  }

  show(): void {
    if (!this.isShow) {
      this.isShow = true;

      return;
    }

    this.reset();
    this.provider.emitFilters({ search: '' });
  }

  hide(): void {
    if (!this.searchElementRef.nativeElement.value) {
      this.isShow = false;
    }
  }

  transitionEnd(event: TransitionEvent): void {
    if (event.propertyName === 'visibility') {
      this.searchElementRef.nativeElement.focus();
    }
  }

  search(value: string): void {
    this.isShowSearchIcon = !value;
    this.provider.emitFilters({ search: value ? value.trim() : '' });
  }

}
