import { Directive, HostListener, Input } from '@angular/core';
import { get } from 'lodash-es';

import { SingleAnalyticEvent } from '@consts/analytics-events.interface';
import { ANALYTICS_EVENTS } from '@consts/analytics';

import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';

@Directive({ selector: '[analyticAnchor]' })
export class AnalyticAnchorDirective {

  constructor(private asaplyticsService: AsaplyticsService) {}

  @Input() analyticAnchor: SingleAnalyticEvent | string;

  @HostListener('click') onClick(): void {
    if (!this.analyticAnchor) {
      return;
    }
    let event: any;

    if (typeof this.analyticAnchor === 'string') {
      event = get(ANALYTICS_EVENTS, this.analyticAnchor);
    } else {
      event = this.analyticAnchor;
    }

    this.asaplyticsService.sendEvent(event);
  }

}
