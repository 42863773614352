import * as Sentry from '@sentry/browser';
import { DOCUMENT } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import { Inject, Injectable } from '@angular/core';

// Consts
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class ReleaseWardenService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private swUpdate: SwUpdate,
  ) { }

  async enlist(): Promise<void> {
    const swIsAvailable: boolean = this.swUpdate.isEnabled;

    if (environment.production && !swIsAvailable) {
      Sentry.withScope((scope: any) => {
        scope.setTag('Info', 'Service worker is disabled.');
      });
    }

    if (!environment.production && !swIsAvailable) {
      return;
    }

    try {
      const newVersionIsAvailable: any = await this.swUpdate.checkForUpdate();

      if (newVersionIsAvailable) {
        await this.swUpdate.activateUpdate();

        Sentry.withScope((scope: any) => {
          scope.setTag('Info', 'The application is updated.');
        });

        this.document.location.reload();
      }
    } catch (error) {
      if (environment.sentryDSN) { return; }

      Sentry.withScope((scope: any) => {
        scope.setTag('Error', 'ClientError');
        Sentry.captureException(error);
      });
    }
  }

}
