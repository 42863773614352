import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const restorePasswordErrors: FormErrors = {
  email: [
    {
      name: 'required',
      text: 'This filed is required',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  global: [],
};
