import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseHttpService, Dictionary } from 'asap-team/asap-tools';
import { map } from 'rxjs/operators';
import { values } from 'lodash-es';

// Types
import { CheckboxItem, TimeZone } from '@core/types';

@Injectable({ providedIn: 'root' })
export class DictionaryService {

  private timeZones: BehaviorSubject<TimeZone[]> = new BehaviorSubject<TimeZone[]>(null);

  public readonly timeZones$: Observable<CheckboxItem[]> = this.timeZones
    .asObservable()
    .pipe(
      map((timeZones: TimeZone[]) => this.mapTimeZoneArray(timeZones)),
    );

  constructor(private http: BaseHttpService) {
    this.getAvailableTimeZones().subscribe((timeZones: TimeZone[]) => {
      this.timeZones.next(timeZones);
    });
  }

  private mapTimeZoneArray(timeZones: TimeZone[]): CheckboxItem[] {
    return (
      timeZones?.map((option: TimeZone) => {
        return {
          code: option.code,
          name: option.title,
        };
      }) || []
    );
  }

  getAvailableTimeZones(): Observable<TimeZone[]> {
    return this.http.get('v2/dictionary/time_zones').pipe(
      map((response: Dictionary<TimeZone | any>) => {
        const timeZones: TimeZone [] = values(response).reduce(
          (filteredResponse: TimeZone[], element: any) => {
            if (element.code && element.title) {
              filteredResponse.push(element);
            }

            return filteredResponse;
          }, [],
        );

        return timeZones;
      }),
    );
  }

}
