import { fromEvent, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CalendlyEventModel, CalendlyEventPayload } from '@core/services/calendly/models/calendly-event.model';
import { CalendlyEventName } from '@core/services/calendly/enums/calendly-event-name.enum';
import { CalendlyModalCallback } from '@core/services/calendly/models/calendly-modal-callback.model';

/**
 * Initializes a listener for the SOA-LABS Calendly widget in modal mode and provides observables
 * for tracking modal state events.
 *
 * @function calendlyWindowListener
 * @returns {CalendlyModalCallback} An object with observables that emit events when:
 * - The Calendly modal is opened (`afterOpened`).
 * - The modal is closed (`afterClosed`).
 * - An appointment is successfully scheduled (`onScheduled`).
 *
 * @observable
 * @property {Subject<void>} afterOpened - Emits once when the modal content becomes visible to the user.
 * @property {Subject<void>} afterClosed - Emits once when the modal is closed, either via the cross-button or backdrop.
 * @property {Subject<CalendlyEventPayload>} onScheduled - Emits when a user successfully schedules an appointment.
 *
 * @example
 * const { afterOpened, afterClosed, onScheduled } = calendlyWindowListener();
 *
 * afterOpened.subscribe(() => console.log('Modal opened'));
 * afterClosed.subscribe(() => console.log('Modal closed'));
 * onScheduled.subscribe(payload => console.log('Appointment scheduled', payload));
 */
export function calendlyWindowListener(): CalendlyModalCallback {
  const afterOpened: Subject<void> = new Subject<void>();
  const afterClosed: Subject<void> = new Subject<void>();
  const onScheduled: Subject<CalendlyEventPayload> = new Subject<CalendlyEventPayload>();

  fromEvent(globalThis, 'message')
    .pipe(
      takeUntil(afterClosed),
      filter(({ data }: MessageEvent) => !!data?.event && Object.values(CalendlyEventName).includes(data.event)),
      map(({ data }: MessageEvent) => data),
    )
    .subscribe({
      next: (message: CalendlyEventModel) => {

        if (message.event === CalendlyEventName.viewed) {
          afterOpened.next();
          afterOpened.complete();

          const closeButton: HTMLElement = document.querySelector('.calendly-popup-close');
          const overlay: HTMLElement = document.querySelector('.calendly-close-overlay');
          const callback = (): void => {
            afterClosed.next();
            afterClosed.complete();

            overlay.removeEventListener('click', callback);
            closeButton.removeEventListener('click', callback);
          };

          closeButton.addEventListener('click', callback);
          overlay.addEventListener('click', callback);
        }

        if (message.event === CalendlyEventName.scheduled) {
          onScheduled.next(message.payload);
          onScheduled.complete();
        }
      },
    });

  return {
    afterOpened,
    afterClosed,
    onScheduled,
  };
}
