import momentMini from 'moment-mini';
import { timer, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownComponent implements OnInit, OnChanges {

  @Input() from: string = null;

  @Input() label: string = null;

  @Input() showWarning: boolean = false;

  time: string = '';

  timer: Subscription;

  ngOnInit(): void {
    this.startCountdown();
  }

  ngOnChanges(): void {
    this.startCountdown();
  }

  private startCountdown(): void {
    const difference: string = this.getTimeDifference(this.from);

    if (this.timer) {
      this.timer.unsubscribe();
    }

    if (!difference) {
      if (!this.label) {
        return;
      }

      this.time = this.label;

      return;
    }

    this.timer = timer(0, 1000)
      .pipe(
        untilDestroyed(this),
        map(() => {
          const diff: string = this.getTimeDifference(this.from);

          if (!this.label) {
            return diff;
          }

          if (!diff) {
            return this.label;
          }

          return diff;
        }),
      )
      .subscribe((result: string) => {
        this.time = result;
      });
  }

  getTimeDifference(time: string): string {
    const from: momentMini.Moment = momentMini(time, 'YYYY-MM-DDTHH:mm:ss:SSSZ');
    const to: momentMini.Moment = momentMini();
    const difference: number = from.diff(to);

    if (difference > 0) {
      const duration: momentMini.Duration = momentMini.duration(difference);

      // to show more than 24 hours
      return Math.floor(duration.asHours()) + momentMini.utc(difference).format(':mm:ss');
    }

    return '';
  }

  getTimerClass(time: string): boolean {
    if (!time || !this.showWarning) {
      return false;
    }

    const hour: number = momentMini(time, 'HH:mm:ss').hour();

    if (hour < 2) {
      return true;
    }

    return false;
  }

}
