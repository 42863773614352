import { Injectable } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  CollectionHelpersService,
  CollectionResponse,
  Dictionary,
} from 'asap-team/asap-tools';

import type { BuyerLead, BuyerLeadStepper, BuyersTrafficCollectionLead } from '@core/types';

@Injectable({ providedIn: 'root' })
export class BuyersTrafficService {

  constructor(
    private http: HttpClient,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(params: Dictionary<string>): Observable<CollectionResponse<BuyersTrafficCollectionLead>> {
    return this
      .http
      .get('v2/buyer_traffic/leads', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.mapResponseFilters(),
        this.collectionHelpersService.retrowError(),
      );
  }

  getLead(id: string): Observable<BuyerLead> {
    return this
      .http
      .get(`v2/buyer_traffic/leads/${id}`)
      .pipe(
        switchMap((response: BuyerLead) => this
          .reviewLeads(response.id)
          .pipe(
            map(() => response),
          )),
        map((response: BuyerLead) => {
          const stepper_answers: BuyerLeadStepper[] = response.stepper_answers
            .map((step: BuyerLeadStepper) => {
              if (step.question.includes('Your phone number')) {
                // eslint-disable-next-line no-param-reassign
                step.answer = step.answer ? new AsYouType('US').input(step.answer) : null;
              }

              return step;
            })
            .sort((a: BuyerLeadStepper, b: BuyerLeadStepper) => a.position - b.position);

          return { ...response, stepper_answers };
        }),
      );
  }

  reviewLeads(lead_ids: string): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('lead_ids', lead_ids);

    return this.http.patch('v2/buyer_traffic/leads/review', formData);
  }

  archiveLead(id: string): Observable<void> {
    return this
      .http
      .patch<void>(`v2/buyer_traffic/leads/${id}/archive`, null);
  }

}
