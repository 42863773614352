import { Injectable } from '@angular/core';
import {
  Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IqLoadingBarService {

  animationIntervalId: any;

  private progressSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  progress$: Observable<number> = this.progressSource.asObservable();

  constructor(
    private readonly router: Router,
  ) {
    this.init();
  }

  start(): void {
    // Clear any previous animations
    clearInterval(this.animationIntervalId);
    this.progressSource.next(0);

    this.animationIntervalId = setInterval(() => {
      this.progressSource.next(this.progressSource.value + 1);

      if (this.progressSource.value >= 100) {
        clearInterval(this.animationIntervalId);
      }
    }, 300);

  }

  complete(): void {
    this.progressSource.next(95);

    const outroTimeout: any = setTimeout(() => {
      this.stop();
      clearTimeout(outroTimeout);
    }, 1500);
  }

  stop(): void {
    clearInterval(this.animationIntervalId);
    this.progressSource.next(0);
  }

  private init(): void {
    this
      .router
      .events
      .subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.start();
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.complete();
        }
      });

  }

}
