import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

// Types
import { Integration } from '@core/types';

// Consts
import { INTEGRATION_STATUS } from '@consts/integrations';
import { Dictionary } from 'asap-team/asap-tools';
import { ROUTE } from '@consts/consts';
@Component({
  selector: 'integration-card',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationCardComponent implements OnChanges {

  @Input() integration: Integration;

  isColored: boolean = false;

  INTEGRATION_STATUS: Dictionary = INTEGRATION_STATUS;

  ngOnChanges(): void {
    this.isColored = [INTEGRATION_STATUS.CONNECTED, INTEGRATION_STATUS.RECEIVING_ONLY].includes(this.integration.status);
  }

  get integrationUrl(): string {
    if (this.integration.code === 'custom_webhook') {
      if (!this.integration?.integration_id) {
        return `${ROUTE.name.WEBHOOK}/${ROUTE.name.CUSTOM_WEBHOOK}/${ROUTE.name.NEW}`;
      }

      return `${ROUTE.name.WEBHOOK}/${ROUTE.name.CUSTOM_WEBHOOK}/${this.integration.integration_id}`;
    }

    if (this.integration.code === 'cerum_ai') {
      const { enabled, integration_id } = this.integration;

      if (!enabled || !integration_id) {
        return `${ROUTE.name.WEBHOOK}/${ROUTE.name.CERUM_AI}/${ROUTE.name.ENABLE}`;
      }

      return `${ROUTE.name.WEBHOOK}/${ROUTE.name.CERUM_AI}/${integration_id}`;
    }

    return this.integration.code;
  }

}
