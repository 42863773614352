import { AbstractControl, ValidationErrors } from '@angular/forms';

export function addressValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) { return control.errors }

  const errors: any = {};
  const isAllEmpty: boolean = [
    'city',
    'state',
    'street',
    'zip',
    'country',
  ]
    .map((name: string) => {
      if (!control.value[name]) {
        errors[name] = true;

        return true;
      }

      return false;
    })
    .every((item: boolean) => !!item);

  if (isAllEmpty) {
    return { incorrect: true };
  }

  if (Object.keys(errors).length) {
    return errors;
  }

  return null;
}
