import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { RelationshipsListItemComponent } from '@commons/components/intelligence';

@Component({
  selector: 'relationships-list',
  templateUrl: './relationships-list.component.html',
  styleUrls: ['./relationships-list.component.scss'],
})
export class RelationshipsListComponent {

  @ContentChildren(RelationshipsListItemComponent) items!: QueryList<RelationshipsListItemComponent>;

  @Input() loading: boolean = false;

  @Input() redirectLink: string = null;

  @Input() redirectLinkState: { title: string; route: string } = null;

  @Input() userColName: string = 'Loan Officer';

}
