import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dictionary } from 'asap-team/asap-tools';

import type { Profile } from '@core/types';

// Consts
import { PERMITTED_ACTION } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@Pipe({ name: 'permitted' })
export class PermittedPipe implements PipeTransform {

  private PERMITTED_ACTION: Dictionary = PERMITTED_ACTION;

  constructor(
    private userService: UserService,
  ) {}

  transform(value: string, permission: string): Observable<string> {
    if (!value) { return of(''); }

    if (!permission) { return of(value); }

    return this
      .userService
      .profile$
      .pipe(
        map((profile: Profile) => (profile?.permitted_actions?.includes(this.PERMITTED_ACTION[permission]) ? value : '')),
      );
  }

}
