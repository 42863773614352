<div class="sidebar" [formGroup]="form">
  <div class="modal-header">
    <div class="modal-header__title">
      Invite {{ USER_ROLE.agent === userRole ? USER_LABEL.loan_officer_title : USER_LABEL.agent_title }}
    </div>
    <div class="modal-close inline-svg" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="search">
    <iq-form-control-text class="m-b-2x"
                          #search
                          placeholder="Type {{ USER_ROLE.agent === userRole ? USER_LABEL.loan_officer : USER_LABEL.agent }}s name"
                          formControlName="query"></iq-form-control-text>
    <div class="inline-svg search__search-icon" [inlineSVG]="'#icon-search'"></div>
  </div>
  <div class="modal-body partner-search" #collection overflowShadow>
    <div class="partner-search__info">
      <ng-container *ngIf="!queryValue && !(loading$ | async); else partnersCollection">
        <div class="partner-search__info--icon">
          <img src="assets/images/intelligence/intelligence-search.svg"/>
        </div>
        <div class="partner-search__info--info-text" *roleIf="USER_ROLE.agent">
          Search our network to see if the lender you want to invite is already a user of <i>my</i> homeIQ
        </div>
        <div class="partner-search__info--info-text" *roleIf="USER_ROLE.lender">
          Search our network to see if the agent you want to invite is already a user of <i>my</i> homeIQ
        </div>
      </ng-container>
      <div #ghost class="text-center">
        <loading-dots class="loading-dots--lg p-t-5x p-b-5x" [class.visible]="loading$ | async"></loading-dots>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a *ngIf="!(queryValue && !(rows$ | async)?.length)"
       class="partner-search__invite m-t-3x"
       (click)="closeModal(RESULT.manual); sendGAEvent('invite_by_email_click')">
      <i class="partner-search__invite--icon" [inlineSVG]="'#icon-mail'"></i>
      Invite {{ userRole === USER_ROLE.lender ? USER_LABEL.agent : USER_LABEL.loan_officer }} via email</a>
  </div>
</div>
<ng-template #partnersCollection>
  <!--SEARCH RESULTS-->
  <div class="partner-search__collection">
    <div class="partner-search__collection--item" *ngFor="let partner of rows$ | async">
      <partner-collection-item [partner]="partner" (emitAction)="emitResult()"></partner-collection-item>
    </div>
  </div>

  <!--EMPTY STATE-->
  <ng-container *ngIf="!(rows$ | async).length && !(loading$ | async)">
    <div class="partner-search__info--icon">
      <img src="assets/images/intelligence/empty-result.svg"/>
    </div>
    <div class="partner-search__info--info-text">
      <div *roleIf="USER_ROLE.agent">
        This lender is not a <i>my</i> homeIQ user yet – be the first to send them an invite.
      </div>
      <div *roleIf="USER_ROLE.lender">
        This agent is not a <i>my</i> homeIQ user yet – be the first to send them an invite.
      </div>
      <iq-button class="m-t-2x" label="Invite via email" (onClick)="closeModal(RESULT.manual); sendGAEvent('invite_by_email_empty_click')"></iq-button>
    </div>
  </ng-container>
</ng-template>
