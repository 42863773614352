export const IMPORT_DATABASE_COPY: any = {
  page_title: 'Import CSV for',
  select_file: {
    title: 'Import new file',
    desc: 'Download the verified and edited CSV file according to the installed template for import.',
    button: 'Choose file',
    link: 'Download Template',
  },
  upload_db_file_progress: { title: 'Uploading file' },
  validation_db_file_progress: { title: 'Validating file' },
  validation_db_file_failed: {
    title: 'Something went wrong!',
    button: 'Try again',
  },
  validation_info: {
    import_button: 'Import',
    restart_button: 'Select another file',
    sms_checkbox: 'Send SMS to homeowners',
    sms_info: 'Please Check or Uncheck the box above.',
  },
  import_progress: { message: 'Import in progress. It may take few minutes.' },
  import_success: {
    title: 'Import success.',
    button: 'Ok',
  },
  import_failed: {
    title: 'Import failed',
    restart: 'Try again',
  },
};

export const UPLOAD_DATABASE_COPY: any = {
  select_file: {
    step_title: 'How to upload your database',
    agent_video_url: 'https://player.vimeo.com/video/420167810',
    lender_video_url: 'https://player.vimeo.com/video/420171354',
    title: 'Choose File to Upload',
    desc: 'To ensure your database uploads correctly, please use one of the following formats .TXT .CSV .XLS or .XLSX. The file size should not exceed 25MB.',
    link: 'Download template',
    button: 'Upload database',
  },
  upload_validation: { step_title: 'Uploading your database', desc: 'Uploading file' },
  upload_failed: {
    step_title: 'Something went wrong!',
    icon: '😔',
    desc: 'Please make sure you are uploading an acceptable file format .TXT .CSV .XLS or .XLSX  – also, the file size should not exceed 25MB We recommend downloading a sample file and use it as a guide.',
    button: 'Try again',
  },
  allow_co_borrowers: {
    title: 'If your list has co-borrowers, would you like us to send a report to both?',
    text: 'Each report will be counted towards your limit.',
  },
  allow_fill_blanks: { title: 'If you do not provide all the loan information, would you like us to use public information to fill in the blanks?' },
  allow_sms: {
    title: 'For the homeowners you provided phone numbers, would you like us to send them a text message when the new report is ready for viewing?',
    checkbox_label: 'I consent to have a working relationship with these homeowners',
    text: 'If so, confirm you have a working relationship with the client by selecting the checkbox below',
    button: 'Continue',
  },
  upload_success: {
    step_title: 'Your database has been uploaded',
    text: 'Please allow up to <b>three-five business days.</b><br> You will receive a confirmation email once the reports are live.',
    button: 'Upload one more file',
  },
};
