<div class="modal-content">
  <div class="modal-header">
    <h4 class="custom-title">The propensity score</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
  </div>
  <div class="modal-body">
    <div class="info-block">
      <h4 class="title">Data</h4>
      <p class="text">The propensity score requires a comprehensive dataset consisting of both current and historical information on over 200 million homeowners.</p>
    </div>
    <div class="info-block">
      <h4 class="title">Methodology</h4>
      <p class="text">This dataset includes public records data, listings data, demographic data, consumer profile data, and geospatial tools to provide insights into location-based context.</p>
      <p class="text">The data is sourced from over 20 different sources and encompasses a broad spectrum of information.</p>
    </div>
    <div class="info-block scoring-system">
      <h4 class="title">Scoring system</h4>
      <div class="infographic">
        <div class="gauge unlikely">
          <img src="/assets/components/propensity-score/unlikely.svg" width="56" alt="gauge-icon">
          <div class="score">1-49</div>
          <div class="title">Unlikely</div>
        </div>
        <div class="gauge neutral">
          <img src="/assets/components/propensity-score/neutral.svg" width="56" alt="gauge-icon">
          <div class="score">50-69</div>
          <div class="title">Neutral</div>
        </div>
        <div class="gauge possible">
          <img src="/assets/components/propensity-score/possible.svg" width="56" alt="gauge-icon">
          <div class="score">70-89</div>
          <div class="title">Possible</div>
        </div>
        <div class="gauge very-likely">
          <img src="/assets/components/propensity-score/very_likely.svg" width="56" alt="gauge-icon">
          <div class="score">90-99</div>
          <div class="title">Very likely</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="flex flex-center">
      <iq-button class="block" label="Got It" [style]="'solid'" (onClick)="closeModal()"></iq-button>
    </div>
  </div>
</div>
