import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const bannerErrors: FormErrors = {
  file: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['dirty'],
    },
    {
      name: 'fileSize',
      text: 'File size must be less than 1MB',
      rules: ['dirty'],
    },
    apiError(),
  ],
  description: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['dirty'],
    },
    {
      name: 'minLength',
      text: 'Min length is 2',
      rules: ['dirty'],
    },
    {
      name: 'maxLength',
      text: 'Max length is 1200',
      rules: ['dirty'],
    },
    apiError(),
  ],
  published_on: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['dirty'],
    },
    apiError(),
  ],
  global: [],
};
