import {
  Component,
  Input,
  Renderer2,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'iframe-video',
  templateUrl: './iframe-video.component.html',
  styleUrls: ['./iframe-video.component.scss'],
})
export class IframeVideoComponent implements AfterViewInit {

  @ViewChild('frame') frame: ElementRef<HTMLIFrameElement>;

  @Input() src: string;

  @Input() width: string;

  @Input() height: string;

  @Input() autoplay: boolean;

  constructor(
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.renderer.setAttribute(this.frame.nativeElement, 'src', this.autoplay ? `${this.src}?autoplay=1&muted=1` : this.src);
  }

}
