import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import type { CheckboxItem } from '@core/types';

// Services
import { AvailableStatesService } from '@core/services/available-states/available-states.service';

@Injectable({ providedIn: 'root' })
export class StatesResolve implements Resolve<CheckboxItem[]> {

  constructor(
    private availableStatesService: AvailableStatesService,
  ) {}

  resolve(): Observable<CheckboxItem[]> {
    return this.availableStatesService.getAvailableStates();
  }

}
