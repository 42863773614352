<div
  class="total-counter"
  *ngIf="!loading; else filteredUsersLoadingIndicator"
>
  {{ total }}
  <ng-content></ng-content>
</div>

<ng-template #filteredUsersLoadingIndicator>
  <div class="empty-state"></div>
</ng-template>
