import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required, addressErrors } from './conditions';

export const manualAddressErrors: FormErrors = {
  city: [
    required,
    apiError(),
  ],
  street: [
    required,
    apiError(),
  ],
  zip: [
    required,
    {
      name: 'pattern',
      text: 'Code is not valid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  state: [
    required,
    apiError(),
  ],
  global: [],
};

export const googleAddressErrors: FormErrors = {
  address: [
    required,
    ...addressErrors,
  ],
  global: [],
};
