<div class="loan-officers">
  <div class="loan-officers__row header">
    <div class="loan-officers__col user">{{ userColName }}</div>
    <div class="loan-officers__col count">Transactions</div>
    <div class="loan-officers__col volume">Volume</div>
  </div>

  <ng-container *ngIf="loading; else table">
    <div>
      <ng-container *ngTemplateOutlet="rowSkeleton"></ng-container>
      <ng-container *ngTemplateOutlet="rowSkeleton; context: { opacity: '80%' }"></ng-container>
      <ng-container *ngTemplateOutlet="rowSkeleton; context: { opacity: '60%' }"></ng-container>
      <ng-container *ngTemplateOutlet="rowSkeleton; context: { opacity: '40%' }"></ng-container>
      <ng-container *ngTemplateOutlet="rowSkeleton; context: { opacity: '20%' }"></ng-container>
    </div>

    <ng-template #rowSkeleton let-opacity="opacity">
      <div class="loan-officers__row" [ngStyle]="{'opacity': opacity || '100%'}">
        <div class="loan-officers__col user">
          <div class="avatar">
            <ngx-skeleton-loader [theme]="{height: '40px', width: '40px', 'margin': 0}"
                                 appearance="circle"></ngx-skeleton-loader>
          </div>
          <div class="user-info" style="display: flex; align-items: center;">
            <ngx-skeleton-loader [theme]="{height: '8px', width: '97px', 'border-radius': '2px', 'margin': '0'}"
                                 appearance="line"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="loan-officers__col count">
          <ngx-skeleton-loader [theme]="{height: '8px', width: '36px', 'border-radius': '2px', 'margin': '0'}"
                               appearance="line"
                               class="m-0"></ngx-skeleton-loader>
        </div>
        <div class="loan-officers__col volume">
          <ngx-skeleton-loader [theme]="{height: '8px', width: '80px', 'border-radius': '2px', 'margin': '0'}" appearance="line"
                               class="m-0"></ngx-skeleton-loader>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #table>
    <ng-container *ngIf="items?.length; else emptyTable">
      <ng-content></ng-content>
    </ng-container>

    <ng-template #emptyTable>
      <div class="empty-data-table">
        <img src="assets/icons/data-table/empty_agent_table_img_v2.svg">
        <h2 class="empty-data-table__title">No relations found</h2>
      </div>
    </ng-template>
  </ng-template>
</div>
