import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required, invalidPhone } from './conditions';

export const enterpriseSignUpErrors: FormErrors = {
  name: [
    required,
    apiError(),
  ],
  email: [
    required,
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  phone: [
    required,
    invalidPhone,
    apiError(),
  ],
  password: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 6',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'pattern',
      text: 'Please include at least one uppercase letter, one digit, and one special character.',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  license_number: [
    required,
    apiError(),
  ],
  license_states: [
    required,
    apiError(),
  ],
  mls_number: [
    required,
    apiError(),
  ],
  time_zone: [
    required,
    apiError(),
  ],
  global: [],
};
