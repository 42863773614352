<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <div class="control__content">
    <select class="control__input"
            #nativeSelect=""
            (focus)="focused = true"
            (blur)="blur()"
            (change)="change($event.target.value)">
      <option default="" [attr.selected]="iSelected('')" value="">{{ placeholder }}</option>
      <option *ngFor="let item of model"
              [attr.selected]="iSelected(item.value)"
              value="{{ item.value }}">{{ item?.label }}</option>
    </select>
  </div>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
