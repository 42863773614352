import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import type { Profile } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class RegistrationCompletedGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  private doCheck(): Observable<boolean> {
    return this
      .userService
      .profile$
      .pipe(
        take(1),
        map((profile: Profile) => {
          if (profile && !this.userService.isRegistrationCompleted) {
            this.router.navigate([ROUTE.alias.SIGN_UP]);

            return false;
          }

          return true;
        }),
      );

  }

  canActivate(): Observable<boolean> {
    return this.doCheck();
  }

  canLoad(): Observable<boolean> {
    return this.doCheck();
  }

}
