<div class="invite-partner">
  <form [formGroup]="form">
    <iq-form-control-text class="m-b-2x alternative-label"
                          placeholder="First and last name"
                          formControlName="name"
                          [errorsModel]="formErrors.name"></iq-form-control-text>
    <iq-form-control-mask class="m-b-2x alternative-label"
                          placeholder="(000) 000 0000"
                          formControlName="phone"
                          mask="(000) 000-0000"
                          [errorsModel]="formErrors.phone"></iq-form-control-mask>
    <iq-form-control-text class="m-b-2x alternative-label"
                          [placeholder]="emailControlPlaceholder"
                          formControlName="email"
                          [errorsModel]="formErrors.email"></iq-form-control-text>
    <iq-form-control-textarea class="m-b-2x alternative-label"
                              placeholder="myname@example.com"
                              formControlName="invitation_text"
                              [errorsModel]="formErrors.invitation_text"></iq-form-control-textarea>
    <p class="shortcodes-title">
      Use personalization short-codes in your message:
    </p>
    <div class="shortcodes-container">
      <span class="shortcodes"
        *ngFor="let item of shortcodesList"
        ngxClipboard=""
        tooltip="Click to copy"
        [cbContent]="'%' + item + '%'">
        %{{ item }}%
      </span>
    </div>
  </form>
</div>
