import { Route } from '@angular/router';

// Consts
import { ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';

export const USERS: Route = {
  path: ROUTE.name.USERS,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard],
  loadChildren: () => import('@modules/admin/collections/users/users.module').then((m: any) => m.UsersModule),
  data: { canBeActivatedBy: [USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.customer_success] },
};
