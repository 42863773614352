import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import {
  Axis,
  axisBottom,
  axisLeft,
  curveMonotoneX,
  Line,
  line,
  NumberValue,
  ScaleLinear,
  scaleLinear,
  ScalePoint,
  scalePoint,
  select,
} from 'd3';
import { isEqual, max } from 'lodash-es';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EMPTY_DATA } from './data';

export type LineDateGraphData<T> = { date: string; value: any; data?: T };

@Component({
  selector: 'line-date-graph',
  templateUrl: './line-date-graph.component.html',
  styleUrls: ['./line-date-graph.component.scss'],
})
export class LineDateGraphComponent implements AfterViewInit, OnChanges {

  @Input() data!: LineDateGraphData<any>[];

  @Input() loading: boolean = false;

  @Output() changeTooltipData: EventEmitter<any> = new EventEmitter();

  @ViewChild('svg') svg!: ElementRef<HTMLElement>;

  @ViewChild('tooltip') tooltip!: ElementRef<HTMLElement>;

  graph: any;

  width: number = 768;

  height: number = 120;

  margin: any = {
    top: 16, left: 32, bottom: 24, right: 24,
  };

  lineColor: string = '#0389FF';

  constructor(
    @Inject(WINDOW) private window: Window,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data) {
      const { previousValue, currentValue } = changes.data;

      this.lineColor = currentValue ? '#0389FF' : '#DAE1E6';

      if (!isEqual(previousValue, currentValue) && this.svg?.nativeElement) {
        const graphData: LineDateGraphData<any>[] = currentValue || EMPTY_DATA;

        this.drawGraph(graphData);
      }
    }
  }

  ngAfterViewInit(): void {
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      this.width = entries[0].contentRect.width;

      this.drawGraph(this.data || EMPTY_DATA);
    }).observe(this.svg.nativeElement);
  }

  drawGraph(data: LineDateGraphData<any>[]): void {
    select(this.svg.nativeElement).selectAll('svg').remove();

    const graph: any = select(this.svg.nativeElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    const xScale: ScalePoint<string> = scalePoint()
      .domain(data.map((item: LineDateGraphData<any>) => item.date))
      .range([0, this.svg.nativeElement.offsetWidth - this.margin.left - this.margin.right]);

    const maxWidthForBar: number = 60;
    const maxBarsPerX: number = Math.round(this.width / maxWidthForBar);

    const xAxis: Axis<string> = axisBottom(xScale)
      .tickFormat((domainValue: string, index: number) => {
        if ((index + 1) % Math.ceil(data.length / maxBarsPerX)) {
          return '';
        }

        return domainValue;
      });

    const maxYValue: number = max(data.map((item: LineDateGraphData<any>) => item.value));
    const yScale: ScaleLinear<number, number, never> = scaleLinear()
      .domain([0, maxYValue])
      .range([(this.height), 0]);

    const yAxis: Axis<NumberValue> = axisLeft(yScale).ticks(4);

    // x axis
    graph
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${this.margin.left}, ${this.height + this.margin.top})`)
      .call(xAxis);

    // y axis
    graph
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
      .call(yAxis);

    graph
      .selectAll('.y-axis line')
      .attr('x2', this.width - this.margin.left - this.margin.right)
      .attr('stroke-dasharray', '5 6')
      .attr('stroke', '#DAE1E6');

    graph.selectAll('.y-axis text').attr('x', -5);

    // remove unused axis shapes
    graph.selectAll('.domain').remove();
    graph.selectAll('.x-axis line').remove();

    // canvas
    const canvas: any = graph
      .append('g')
      .attr('class', 'canvas')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    const curveLine: Line<[number, number]> = line()
      .x((d: any) => xScale(d.date) as any)
      .y((d: any) => yScale(d.value))
      .curve(curveMonotoneX);

    this.createGradient(canvas);
    this.drawLinePath(data, canvas, curveLine);
    this.createHoverBars(data, canvas, xScale, yScale);
  }

  private createGradient(selection: any): void {
    const gradient: any = selection
      .append('defs')
      .append('linearGradient')
      .attr('id', 'line-chart-gradient')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', 1);

    gradient
      .append('stop')
      .attr('stop-color', this.lineColor)
      .attr('stop-opacity', 0.6)
      .attr('offset', '0%');

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', this.lineColor)
      .attr('stop-opacity', 0);
  }

  private drawLinePath(data: LineDateGraphData<any>[], selection: any, curveLine: any): void {
    selection
      .selectAll('.line-gradient')
      .data([data])
      .enter()
      .append('path')
      .attr('class', 'line-gradient')
      .attr('d', (d: LineDateGraphData<any>[]) => {
        const lineValues: string | undefined = curveLine(d)?.slice(1);
        const splitValues: any = lineValues?.split(',');
        const pathHeight: number = this.height;

        return `M0,${pathHeight},${lineValues},l0,${pathHeight - splitValues[splitValues.length - 1]}`;
      })
      .attr('fill', 'url(#line-chart-gradient)');

    selection
      .selectAll('.line-path')
      .data([data])
      .enter()
      .append('path')
      .attr('class', 'line-path')
      .attr('d', curveLine)
      .attr('stroke-width', '2')
      .attr('stroke', this.lineColor)
      .attr('fill', 'transparent');
  }

  private createHoverBars(
    data: LineDateGraphData<any>[],
    selection: any,
    xScale: ScalePoint<string>,
    yScale: ScaleLinear<number, number, never>,
  ): void {
    const barsContainer: any = selection.append('g').attr('class', 'bars');
    const barWidth: number = 10;

    const bars: any = barsContainer
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'hover-bar');

    bars
      .append('rect')
      .attr('width', xScale.step())
      .attr('height', this.height)
      .attr('x', (d: LineDateGraphData<any>) => xScale(d.date) as any - (xScale.step() / 2))
      .attr('y', 0)
      .attr('fill', 'transparent');

    bars
      .append('rect')
      .attr('class', 'bar-line')
      .attr('width', 1)
      .attr('height', (d: LineDateGraphData<any>) => (this.height - yScale(d.value)) || 1)
      .attr('x', (d: LineDateGraphData<any>) => xScale(d.date) as any + barWidth)
      .attr('y', (d: LineDateGraphData<any>) => yScale(d.value))
      .attr('transform', `translate(-${barWidth}, 0)`)
      .attr('stroke-width', barWidth)
      .attr('stroke', 'transparent')
      .attr('fill', (d: LineDateGraphData<any>) => (d.value ? this.lineColor : 'none'));

    bars
      .append('circle')
      .attr('cx', (d: LineDateGraphData<any>) => xScale(d.date) as any)
      .attr('cy', (d: LineDateGraphData<any>) => yScale(d.value))
      .attr('r', 3)
      .attr('fill', this.lineColor);

    bars.each((itemData: LineDateGraphData<any>, index: number, list: any) => {
      const element: SVGRectElement = list[index];

      const events$: Observable<Event>[] = [
        fromEvent(element, 'mouseover'),
        fromEvent(element, 'mouseout'),
      ];

      merge(...events$)
        .pipe(debounceTime(100))
        .subscribe((event: any) => {
          const tooltip: HTMLElement = this.tooltip.nativeElement;
          const TOGGLE_CLASS_NAME: string = 'show';

          if (event.type === 'mouseover') {
            this.changeTooltipData.emit(itemData.data);

            // Set tooltip coords
            const x: number = this.getTooltipX(
              element.getBoundingClientRect().x + (element.getBoundingClientRect().width / 2),
              tooltip.offsetWidth,
            );
            const y: number = event.y - tooltip.offsetHeight <= 0 ? event.y + 16 : event.y - (tooltip.offsetHeight + 16);

            tooltip.setAttribute('style', `top: ${y}px; left: ${x}px`);
            tooltip.classList.add(TOGGLE_CLASS_NAME);
          }

          if (event.type === 'mouseout') {
            select(element).select('.hover-line').attr('opacity', 0);
            tooltip.classList.remove(TOGGLE_CLASS_NAME);
          }
        });
    });
  }

  private getTooltipX(eventX: number, tooltipOffsetLeft: number): number {
    if (eventX - (tooltipOffsetLeft / 2) <= 0) {
      return 16;
    }

    if (eventX + (tooltipOffsetLeft / 2) >= this.window.innerWidth) {
      return this.window.innerWidth - (tooltipOffsetLeft + 16);
    }

    return eventX - (tooltipOffsetLeft / 2);
  }

}
