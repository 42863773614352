import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import type { CheckboxItem } from '@core/types';
import { map, take } from 'rxjs/operators';
import { uID } from 'asap-team/asap-tools';

@Injectable({ providedIn: 'root' })
export class AvailableStatesService {

  // eslint-disable-next-line rxjs/no-exposed-subjects
  statesSource$: BehaviorSubject<CheckboxItem[]> = new BehaviorSubject<CheckboxItem[]>([]);

  constructor(
    private http: HttpClient,
  ) {}

  getAvailableStates(): Observable<CheckboxItem[]> {
    if (this.statesSource$.getValue().length !== 0) {
      return this.statesSource$.asObservable().pipe(take(1));
    }

    return this
      .http
      .get('v2/states')
      .pipe(
        map((response: { states: CheckboxItem[] }) => response.states),
        map((states: CheckboxItem[]) => {
          const result: CheckboxItem[] = states.map((state: CheckboxItem) => {
            return { ...state, uid: uID(state.code) };
          });

          this.statesSource$.next(result);

          return result;
        }),
      );
  }

}
