// eslint-disable-next-line max-classes-per-file
import { FTLStepName } from './ftl.model';

export namespace FTLActions {
  export class GetFtlStepsList {

    static get type(): string {
      return '[FTL] Get list of FTL steps';
    }

  }

  export class SubmitFtlStepAction {

    static get type(): string {
      return '[FTL] Submit FTL step action';
    }

    constructor(
      public step_code: FTLStepName,
      public payload?: any,
    ) {}

  }

  export class SkipFtlStepAction {

    static get type(): string {
      return '[FTL] Skip FTL step action';
    }

    constructor(
      public step_code: FTLStepName,
    ) {}

  }

  export class GetStepDataAction {

    static get type(): string {
      return '[FTL] Get step data';
    }

    constructor(
      public step_code: FTLStepName,
    ) {}

  }
}
