// eslint-disable-next-line @typescript-eslint/typedef
export const CALENDLY_LINKS = {
  agent: 'https://calendly.com/em-homeiq/new-agent-group',
  lender: 'https://calendly.com/shanamyhomeiq/15min',
  activeBuyer: 'https://calendly.com/myhomeiq-customer-success/active-buyer-upgrade',
  lenderTraining: 'https://calendly.com/myhomeiq-customer-success/lender-training',
  lenderOnboarding: 'https://calendly.com/myhomeiq-customer-success/lender-onboarding',
  loanOfficerOnboarding: 'https://calendly.com/myhomeiq-customer-success/loan-officer-onboarding',
  realtorTraining: 'https://calendly.com/myhomeiq-customer-success/realtor-training',
  realtorOnboarding: 'https://calendly.com/myhomeiq-customer-success/realtor-onboarding',
  tawdfrensleyIntelligence: 'https://calendly.com/tawdfrensley/myhomeiq-intelligence',
  default: 'https://calendly.com/soa-onboarding',
} as const;
