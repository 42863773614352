import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';

export interface VideoDialogData {
  id: string;
  hash?: string;
  onVideoCompleted?: () => void;
}

@Component({
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnDestroy {

  @ViewChild('vimeoPlayer') vimeoPlayerElement!: ElementRef;

  id: string;

  customUrl!: string;

  videoUrl: SafeResourceUrl;

  readonly playerId: string = `vimeo-player-${Math.random().toString(36).substr(2, 9)}`;

  private playerOrigin: string = 'https://player.vimeo.com';

  private messageHandler: any;

  constructor(
    private sanitizer: DomSanitizer,
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) private data: VideoDialogData,
  ) {
    this.id = data.id;
  }

  ngOnInit(): void {
    this.videoUrl = this.sanitizeVideoUrl(this.id);
    this.messageHandler = this.onMessageReceived.bind(this);
    window.addEventListener('message', this.messageHandler);
  }

  ngOnDestroy(): void {
    if (this.messageHandler) {
      window.removeEventListener('message', this.messageHandler);
    }
  }

  private sanitizeVideoUrl(id: string = '299319152'): SafeResourceUrl {
    const url: string = this.customUrl ||
      `https://player.vimeo.com/video/${id}?${this.data.hash ? `h=${this.data.hash}&` : ''}api=1&player_id=${this.playerId}&autopause=0&autoplay=1&title=0&byline=0&portrait=0`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private onMessageReceived(event: MessageEvent): void {
    if (event.origin !== this.playerOrigin) return;

    try {
      const data: any = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

      // When the player is ready, add event listeners
      if (data?.event === 'ready') {
        const iframe: HTMLIFrameElement | null = document.querySelector(`iframe[id="${this.playerId}"]`);

        if (iframe?.contentWindow) {
          iframe.contentWindow.postMessage({
            method: 'addEventListener',
            value: 'ended',
          }, this.playerOrigin);
        }
      }

      // Handle video completion event
      if (data?.event === 'ended' && data?.player_id === this.playerId) {
        if (this.data.onVideoCompleted) {
          this.data.onVideoCompleted();
        }

        this.closeModal();
      }
    } catch (e) {
      console.error('Error processing Vimeo message:', e);

      return;
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
