import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Params } from '@angular/router';
import { includes } from 'lodash-es';

// Types
import type { LeadInfo } from '@core/types';
import { Dictionary } from 'asap-team/asap-tools';

// Consts
import { LEAD_STATUS, PERMITTED_ACTION, PROPERTY_IMAGE_PLACEHOLDER, ROUTE } from '@consts/consts';
import { truncateText } from '@core/utils/utils';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';

@Component({
  selector: 'collection-cell-home-info',
  templateUrl: './home-info.component.html',
  styleUrls: ['./home-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionCellHomeInfoComponent {

  @Input() type: 'report' | 'traffic' | 'mortgage' | 'archived' | 'closings' | 'home_shopper';

  @Input() lead: LeadInfo;

  @Output() selectRowEmitter: EventEmitter<void> = new EventEmitter();

  PERMITTED_ACTION: Dictionary = PERMITTED_ACTION;

  PROPERTY_IMAGE_PLACEHOLDER: string = PROPERTY_IMAGE_PLACEHOLDER;

  ROUTE: any = ROUTE;

  truncateText: (text: string, maxLength: number) => string = truncateText;

  constructor(private asaplyticsService: AsaplyticsService) {
  }

  get leadDetailsLink(): string[] {
    if (this.lead?.status === LEAD_STATUS.DRAFT) {
      return [ROUTE.alias.HOMEOWNERS_REPORT_NEW];
    }

    const leadPageRouteCommands: any[] = [ROUTE.alias.HOMEOWNERS_LEAD, this.lead?.id];

    if (this.type === 'mortgage') {
      leadPageRouteCommands.push(ROUTE.alias.REFINANCE);
    }

    return this.lead?.overlimit_trial_lead ? [ROUTE.alias.UPGRADE_SUBSCRIPTION] : leadPageRouteCommands;
  }

  get getActiveBuyerRouterLink(): string[] {
    let result: any[];

    if (!this.lead.uid || this.lead.report_status === 'Draft') {
      result = [ROUTE.alias.HOMEOWNERS_REPORT_NEW];
    } else {
      result = [ROUTE.alias.HOMEOWNERS_LEAD, this.lead.uid, ROUTE.alias.NET_SHEET];
    }

    return result;
  }

  get getActiveBuyerQueryParams(): Params {
    return { ...(this.lead.uid && { id: this.lead.uid }) };
  }

  formatAddress(address: string, overlimit_trial_lead: boolean, unit?: string): string {
    if (overlimit_trial_lead) { return address; }

    if (!address) { return ''; }

    let aprt: string = '';

    if (unit) {
      aprt = `, ${unit}`;
    }

    const street: string = address.split(', ').shift();

    return `${street}${aprt}`;
  }

  public get getIQIActiveBuyerAddressFirstLine(): string {
    const address: string = this.lead.address || '';
    const unit: string = this.lead.unit || '';

    return `${address}${unit ? ', ' : ''}${unit}`;
  }

  public get getIQIActiveBuyerAddressSecondLine(): string {
    const city: string = this.lead.city || '';
    const formattedCity: string = this.truncateText(city, 16);
    const state: string = this.lead.state || '';
    const zipCode: string = this.lead.zip || '';

    return `${formattedCity}, ${state} ${zipCode}`;
  }

  isLeadContainsPreview(): boolean {
    if (!this.lead?.image) { return false; }

    return this.lead.image !== PROPERTY_IMAGE_PLACEHOLDER;
  }

  getPreviewImage(): string {
    return this.lead?.image || '';
  }

  isValid(type: string, lead: LeadInfo): boolean {
    return !lead[type] || includes(lead?.tooltips?.contacts, `${type.charAt(0).toUpperCase() + type.slice(1)} is incorrect`);
  }

  getDate(lead: LeadInfo): string {
    if (!lead) { return ''; }

    const { sale_date, loan_date } = lead;

    return sale_date || loan_date;
  }

  viewDetailsClicked(): void {
    this.asaplyticsService.sendDataLayerEvent('active_buyer_view_details', { category: 'active_buyers' });
  }

}
