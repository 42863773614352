import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, filter, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {
  CollectionHelpersService,
  Dictionary,
  getFileName,
  propertyImagePlaceholder,
  CollectionApi,
  CollectionResponse,
} from 'asap-team/asap-tools';

import type {
  LeadMortgageCheckupsItem,
  MortgageCheckupsCollectionItem,
  NewRateResponse,
} from '@core/types';

// Consts
import { LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class MortgageCheckupsService implements CollectionApi<MortgageCheckupsCollectionItem> {

  private mortgageCheckups: BehaviorSubject<LeadMortgageCheckupsItem[]> = new BehaviorSubject<LeadMortgageCheckupsItem[]>(null);

  mortgageCheckups$: Observable<LeadMortgageCheckupsItem[]> = this
    .mortgageCheckups
    .asObservable()
    .pipe(
      filter<LeadMortgageCheckupsItem[]>(Boolean),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  constructor(
    private http: HttpClient,
    private collectionHelpersService: CollectionHelpersService,
  ) { }

  getCollection(params: Dictionary<any>): Observable<CollectionResponse<MortgageCheckupsCollectionItem>> {
    const { queryParams, ...restParams } = params;

    // TODO: Temporary solution for https://loomlogic.atlassian.net/browse/SOA-10631.
    // Should be updated during collection refactoring

    return this
      .http
      .get('v2/seller_digest/mortgage_checkups', { params: new HttpParams({ fromObject: { ...restParams, ...(queryParams || {}) } }) })
      .pipe(
        this.collectionHelpersService.mapResponseFilters(),
        propertyImagePlaceholder<MortgageCheckupsCollectionItem>(LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER),
        this.collectionHelpersService.retrowError(),
      );
  }

  emitLeadMortgageCheckupsUpdate(payload: LeadMortgageCheckupsItem[]): void {
    this.mortgageCheckups.next(payload);
  }

  dismiss(id: string, loan_term: number): Observable<LeadMortgageCheckupsItem[]> {
    return this
      .http
      .patch(`v2/seller_digest/mortgage_checkups/${id}/dismiss`, { loan_term })
      .pipe(
        map(({ mortgage_checkups }: { mortgage_checkups: LeadMortgageCheckupsItem[] }) => {
          this.emitLeadMortgageCheckupsUpdate(mortgage_checkups);

          return mortgage_checkups;
        }),
      );
  }

  email(id: string, loan_term: number): Observable<void> {
    return this
      .http
      .post<void>(`v2/seller_digest/mortgage_checkups/${id}/email`, { loan_term });
  }

  sms(id: string, loan_term: number): Observable<void> {
    return this
      .http
      .post<void>(`v2/seller_digest/mortgage_checkups/${id}/sms`, { loan_term });
  }

  download(id: string, loan_term: number): Observable<{ file: Blob; name: string }> {
    const options: { observe: 'response'; responseType: 'blob' } = {
      observe: 'response',
      responseType: 'blob',
    };

    return this
      .http
      .get(`v2/seller_digest/mortgage_checkups/${id}/download?loan_term=${loan_term}`, options)
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return {
            file: response.body as Blob,
            name: getFileName(response.headers) || 'quote.pdf',
          };
        }),
      );
  }

  newInterestRate(id: string, payload: { loan_term: number; new_interest_rate: string }): Observable<NewRateResponse> {
    return this.http.put<NewRateResponse>(`v2/seller_digest/mortgage_checkups/${id}`, payload);
  }

}
