import { Injectable } from '@angular/core';
import { Database, ref, objectVal } from '@angular/fire/database';
import { isEmpty, isEqual } from 'lodash-es';
import { switchMap, map, filter, distinctUntilChanged } from 'rxjs/operators';
import type { Dictionary } from 'asap-team/asap-tools';

import type { Observable } from 'rxjs';
import type {
  Profile,
  ViralImportInfo,
  ImportDatabaseInfo,
  CollectionCounters,
  FirebaseImports,
  LeadProcessingStatus,
  LeadFromFirebase,
} from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';
import { environment } from 'environments/environment';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class FirebaseService {

  constructor(
    private userService: UserService,
    private db: Database,
  ) {}

  private getUserGroup(user: Profile): string {
    return (user.role === USER_ROLE.sales || user.role === USER_ROLE.owner) ? 'admins' : 'users';
  }

  getProfileHash(): Observable<string> {
    return this
      .userService
      .profile$
      .pipe(
        distinctUntilChanged((previousProfile: Profile, currentProfile: Profile) => previousProfile.uid === currentProfile.uid),
        switchMap((user: Profile) => {
          const { uid } = user;
          const group: string = this.getUserGroup(user);

          return objectVal(ref(this.db, `/${environment.firebaseCollection}/${group}/${uid}/profile`))
            .pipe(
              distinctUntilChanged(),
            );
        }),
        filter((response: { hash: string }) => !isEmpty(response)),
        map((response: { hash: string }) => response.hash),
      );
  }

  getCounters(): Observable<CollectionCounters> {
    return this
      .userService
      .profile$
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap((user: Profile) => {
          const { uid } = user;
          const group: string = this.getUserGroup(user);

          return objectVal(ref(this.db, `/${environment.firebaseCollection}/${group}/${uid}/counters`))
            .pipe(
              distinctUntilChanged(),
            );
        }),
      );
  }

  getLeadIndicator(uid: string): Observable<LeadProcessingStatus> {
    return objectVal(ref(this.db, `/${environment.firebaseCollection}/leads/${uid}`))
      .pipe(
        map((res: LeadFromFirebase) => { return { leadId: uid, processing: !!res?.processing }; }),
      );
  }

  getCountersByPath(path: string): Observable<number | unknown> {
    return objectVal(ref(this.db, path))
      .pipe(
        distinctUntilChanged(),
      );
  }

  getImportCSVInfo({ import_uid, user_uid, group }: Dictionary<string>): Observable<ViralImportInfo | ImportDatabaseInfo> {
    return objectVal(ref(this.db, `/${environment.firebaseCollection}/${group}/${user_uid}/imports`))
      .pipe(
        filter((response: FirebaseImports) => !isEmpty(response[import_uid])),
        map((response: FirebaseImports) => response[import_uid]),
      );
  }

  getFeesIndicator(uid: string): Observable<boolean> {
    return objectVal(ref(this.db, `${environment.firebaseCollection}/leads/${uid}/fees`))
      .pipe(
        filter((response: { fees_loading: boolean }) => !isEmpty(response)),
        map((response: { fees_loading: boolean }) => response.fees_loading),
      );
  }

}
