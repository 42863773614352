import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// Consts
import { LENDING_PAD_ID } from '@consts/consts';
import { ILendingPadDetails } from '@core/types';

@Component({
  selector: 'lending-pad-form',
  templateUrl: './lending-pad.component.html',
  styleUrls: ['./lending-pad.component.scss'],
})
export class LendingPadFormComponent implements OnInit {

  @Input() connectAction$: Subscription;

  @Input() lendingPad: ILendingPadDetails;

  @Input() isConnected: boolean;

  @Output() submitForm: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  @Output() disableIntegration: EventEmitter<void> = new EventEmitter<void>();

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      lending_pad_company_id: [this.lendingPad.lending_pad_company_id, [Validators.pattern(LENDING_PAD_ID)]],
      lending_pad_campaign_id: [this.lendingPad.lending_pad_campaign_id, [Validators.pattern(LENDING_PAD_ID)]],
    });
  }

  connect(): void {
    this.submitForm.emit(this.form);
  }

  disable(): void {
    this.disableIntegration.emit();
  }

}
