<a class="collection-cell-user-info"
   [routerLink]="accountLink"
   [state]="{ prevLink: { params: prevQueryParams, link: prevCollectionLink } }">
  <div class="collection-cell-user-info__avatar">
    <avatar-placeholder class="size-40"
                        *ngIf="row?.name && !isUserBlocked"
                        [name]="row?.name"
                        [image]="row?.avatar?.small"
                        [class.namb]="!!row?.namb_number"></avatar-placeholder>
    <ng-container>
      <div class="user-info__tooltip"
           *ngIf="row?.tooltips?.users?.length"
           [inlineSVG]="'#icon-alert_round'"
           [tooltip]="tipIncorrectUserData"
           container="body"></div>
      <ng-template #tipIncorrectUserData="">
        <p *ngFor="let error of row?.tooltips?.users">- {{ error }}</p>
      </ng-template>
    </ng-container>
    <div class="user-blocked inline-svg" *ngIf="isUserBlocked" [inlineSVG]="'#icon-lock'"></div>
  </div>
  <div class="collection-cell-user-info__details" [ngSwitch]="type">
    <div class="collection-cell-user-info__details-name f-14-600" [title]="row?.name">{{ row?.name || '–' }}</div>
    <ng-container *ngSwitchCase="'trial-account'">
      <div class="collection-cell-user-info__details-label">
        <span class="role"
              [ngClass]="row?.role">{{ row.role }}</span>
        <span class="promo-code"
              *ngIf="row?.promo_code_name">{{ row.promo_code_name }}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'new-account'">
      <div class="collection-cell-user-info__details-label">
        <span class="role"
              [ngClass]="row?.role">{{ row.role }}</span>
        <span class="promo-code"
              *ngIf="row?.promo_code_name"
              [tooltip]="tipSetupFee"
              container="body">{{ row.promo_code_name }}</span>
        <ng-template #tipSetupFee="">
          <div>
            <div>{{ row?.promo_code_owner_name }}</div>
            <div><b>{{ row?.promo_code_subscription_discount_percentage }}%</b>- Plan</div>
            <div><b>{{ row?.promo_code_setup_fee_discount_percentage }}%</b>- Setup fee</div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</a>
