<div class="iq-sidebar wrapper">
  <div class="modal-header">
    <div class="modal-header__title">
      <div>Refinance opportunity</div>
      <div class="subtitle">Notify your past client they may qualify for a refinance! Send a certificate via email or download the PDF to share.</div>
    </div>
    <div class="modal-close inline-svg" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
  </div>
  <div class="modal-body">
    <div class="person-info">
      <avatar-placeholder class="size-64 bold navy"
                          [image]="''"
                          [name]="refinanceData.owner_name"
                          [type]="'inverse'"></avatar-placeholder>
      <div class="data">
        <div class="name">{{ refinanceData.owner_name }}</div>
        <div class="address">{{ refinanceData.address }}</div>
      </div>
    </div>

    <ul *ngIf="refinanceData.mortgage_checkups?.length" class="refinance-cards">
      <ng-container *ngFor="let mortgage of refinanceData.mortgage_checkups" [formGroup]="loansFormGroup">
        <li *ngIf="mortgage.loan_term" class="refinance-record" [ngClass]="{good: mortgage.good_deal, bad: !mortgage.good_deal}">
          <div class="label">
            <img [src]="'/assets/components/refinance-scanner/' + (mortgage.good_deal ? 'done' : 'cross') + '-checkmark.svg'" alt="done">
            <div class="text">{{mortgage.good_deal ? 'QUALIFIED' : 'NOT QUALIFIED'}}</div>
          </div>
          <div class="indicators">
            <div class="row">
              <div class="rate">
                <div class="title">
                  <span>New Rate</span>
                </div>
                <div class="value editable">
                  <ng-container *ngIf="editRateForTerm !== mortgage.loan_term.toString(); else editRateTemplate">
                    <div>{{mortgage.new_interest_rate}}%</div>
                    <div (click)="editRate(mortgage.loan_term.toString(), mortgage.new_interest_rate)" [inlineSVG]="'#icon-edit-v2'" class="edit-rate inline-svg inline-svg--20 pointer"></div>
                  </ng-container>
                  <ng-template #editRateTemplate>
                    <div class="rate-control">
                      <input [id]="'m_' + mortgage.loan_term"
                             type="text"
                             [formControlName]="mortgage.loan_term.toString()"
                             (keyup.enter)="acceptRate(mortgage.loan_term)"
                             [mask]="'M?00?P000'"
                             [patterns]="maskPatterns"
                             [suffix]="'%'"
                             [dropSpecialCharacters]="false"
                             [validation]="true"
                             (blur)="acceptRate(mortgage.loan_term)" class="rate-input"
                             [class.fail-rate]="loansFormGroup.get(mortgage.loan_term.toString()).invalid">
                      <img (click)="acceptRate(mortgage.loan_term)" class="pointer done-checkmark" src="/assets/components/refinance-scanner-modal/done-blue-checkmark.svg" [hidden]="loansFormGroup.get(mortgage.loan_term.toString()).invalid" alt="done" width="24" height="24">
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="divider"></div>
              <div class="saving">
                <div class="title">Monthly savings</div>
                <div class="value" [class.negative]="!mortgage.good_deal">{{mortgage.good_deal ? '' : '-'}}{{mortgage.monthly_savings | currency: 'USD' : 'symbol' : '1.0-0'}}</div>
              </div>
              <div class="divider transparent"></div>
              <div class="actions">
                <div *ngIf="mortgage.good_deal"
                     (click)="downloadPdfCertificate(mortgage.loan_term)"
                     class="square-btn inline-svg pointer">
                  <img src="/assets/components/refinance-scanner/document.svg" width="24" height="24" alt="document">
                </div>
                <div *ngIf="!mortgage.good_deal"
                     [tooltip]="'Downloading and sending non-qualified refinance options is not yet available'"
                     class="square-btn disabled">
                  <img src="/assets/components/refinance-scanner/document.svg" width="24" height="24" alt="document">
                </div>
                <div class="control-dropdown" dropdown container="body" placement="bottom right">
                  <iq-button label="Send"
                             [disabled]="!mortgage.good_deal"
                             [style]="'solid'"
                             iconRight="#icon-chevron"
                             size="40"
                             dropdownToggle=""></iq-button>
                  <div class="control-dropdown__menu" *dropdownMenu>
                    <div class="control-dropdown__item"  (click)="email(mortgage.loan_term)">
                      <div class="control-dropdown__icon inline-svg--20" [inlineSVG]="'#icon-send-horizontal'"></div>
                      <span class="control-dropdown__label">Email refi certificate</span>
                    </div>
                    <div *featureFlag="FEATURES.SMS_NOTIFICATIONS" class="control-dropdown__item"  (click)="sms(mortgage.loan_term)">
                      <div class="control-dropdown__icon inline-svg--20" [inlineSVG]="'#sms-solid'"></div>
                      <span class="control-dropdown__label">SMS refi certificate</span>
                    </div>
                    <div class="control-dropdown__item"  (click)="openInfoModal()">
                      <div class="control-dropdown__icon inline-svg--20" [inlineSVG]="'#icon-question'"></div>
                      <span class="control-dropdown__label">See what your client receives</span>
                    </div>
                    <hr class="horizontal-divider">
                    <div class="control-dropdown__item" (click)="dismissOpportunity(mortgage.loan_term)">
                      <div class="control-dropdown__icon inline-svg inline-svg--20" [inlineSVG]="'#icon-close'"></div>
                      <span class="control-dropdown__label">Dismiss this opportunity</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-divider"></div>
            <div class="row">
              <div class="indicator">
                <div class="title">APR</div>
                <div class="value-mini">{{mortgage.apr || '-'}}{{mortgage.apr ? '%' : ''}}</div>
              </div>
              <div class="divider"></div>
              <div class="indicator">
                <div class="title">Term</div>
                <div class="value-mini">{{mortgage.loan_term}} year{{mortgage.loan_term > 1 ? 's' : ''}}</div>
              </div>
              <div class="divider"></div>
              <div class="indicator">
                <div class="title">Monthly payment</div>
                <div class="value-mini">{{mortgage.monthly_payment ? (mortgage.monthly_payment | currency: 'USD' : 'symbol' : '1.0-0') : '-'}}</div>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>

    <mortgage-details *ngIf="refinanceData.currentMortgage as currentMortgage" [mortgage]="currentMortgage"></mortgage-details>

    <div class="scroll-shadow"></div>
  </div>
</div>
