import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { CacheRegistrationService } from '@core/helpers/cache-registration/cache-registration.service';

@Injectable({ providedIn: 'root' })
export class AvatarService {

  constructor(
    private http: HttpClient,
    private cacheRegistrationService: CacheRegistrationService,
  ) {}

  getAvatarUrl(uid: string): Observable<any> {
    this.cacheRegistrationService.addToCache(`avatars/${uid}`);

    return this
      .http
      .get(`v2/avatars/${uid}`)
      .pipe(
        map((response: { avatar: { mediaum: string; tiny: string; small: string } }) => response.avatar.small),
      );
  }

}
