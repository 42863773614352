import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CollectionResponse,
  CollectionHelpersService,
  Dictionary,
  BaseHttpService,
} from 'asap-team/asap-tools';

import {
  AdminCompany,
  type AdminCompanyDetails,
  type AdminCompanyStats,
  type Company,
  CompanyNavigationItem,
} from '@core/types';
import { map } from 'rxjs/operators';
import { identity, pickBy } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class AdminCompaniesService {

  constructor(
    private http: BaseHttpService,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCompany(code: string): Observable<Company> {
    return this.http.get(`v2/companies/${code}`);
  }

  getAdminCompany(code: string): Observable<AdminCompanyDetails> {
    return this.http.get(`v2/admin/companies/${code}`);
  }

  getAdminCompaniesStats(): Observable<AdminCompanyStats> {
    return this
      .http
      .get('v2/admin/companies/stats');
  }

  createCompany(formData: FormData): Observable<void> {
    return this.http.post('v2/admin/companies', formData);
  }

  updateCompany(code: string, formData: FormData): Observable<void> {
    return this.http.patch(`v2/admin/companies/${code}`, formData);
  }

  getCollection(params: Dictionary<string>): Observable<CollectionResponse<AdminCompany>> {
    return this
      .http
      .get('v2/admin/companies', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.retrowError(),
      );
  }

  getPartnersCollection(params: Dictionary<string>): Observable<CollectionResponse<AdminCompany>> {
    return this
      .http
      .get('v2/admin/partners', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.retrowError(),
      );
  }

  getNavigationList(params: Dictionary<string>): Observable<CollectionResponse<CompanyNavigationItem>> {
    return this.getCollection(pickBy(params, identity)).pipe(
      map((response: CollectionResponse<AdminCompany>) => {
        const { pagination } = response;
        const data: CompanyNavigationItem[] = response.data.map((company: AdminCompany) => {
          const { name, code, users } = company;
          const logo: string = this.useCustomCompanyLogo(code);

          return {
            name,
            code,
            logo,
            collapsed: false,
            users,
          };
        });

        return { data, pagination };
      }),
    );
  }

  private useCustomCompanyLogo(code: string): string {
    const logoNames: string[] = ['supreme', 'cmg', 'envoy', 'easy-low-rate', 'goldstar'];

    return logoNames.includes(code) ? `/assets/components/sidebar-nav/${code}.svg` : '/assets/components/sidebar-nav/default_icon.svg';
  }

}
