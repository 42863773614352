import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const newInterestRateErrors: FormErrors = {
  new_interest_rate: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['dirty'],
    },
    {
      name: 'min',
      text: 'Must be more than 0.01%',
      rules: ['dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than 10.00%',
      rules: ['dirty'],
    },
    apiError(),
  ],
  global: [],
};
