import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor, FormBuilder, FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormError, provideRefs } from 'asap-team/asap-tools';

@UntilDestroy()
@Component({
  selector: 'iq-form-control-amount',
  templateUrl: './iq-form-control-amount.component.html',
  styleUrls: ['./iq-form-control-amount.component.scss'],
  providers: provideRefs(IqFormControlAmountComponent),
})
export class IqFormControlAmountComponent implements ControlValueAccessor, OnInit {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() placeholder: string | null = null;

  @Input() lpignore: boolean = true;

  @Input() errorsModel: FormError[];

  @Input() formControlName: string;

  @Input() readonly: boolean = false;

  @Input() mask: string = 'separator.2';

  @Input() thousandSeparator: string = ',';

  @Input() prefix: string = '$';

  form: FormGroup;

  focused: boolean = false;

  constructor(
    private renderer2: Renderer2,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({ value: [''] });

    this
      .form
      .controls
      .value
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((value: any) => {
        this.onChange(value);
      });
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string): void {
    this.form.patchValue({ value });
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  focus(): void {
    if (this.readonly) { return }

    this.focused = true;
  }

  blur(): void {
    this.focused = false;
    this.onTouched();
  }

  getNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeInput;
  }

}
