import type { Dictionary } from 'asap-team/asap-tools';

export const APP_STATUS: Dictionary = {
  TRIAL: 'trial',
  PAID: 'paid',
  FREE: 'free',
};

export const APP_TYPE: Dictionary = {
  SELLER_TRAFFIC: 'seller_traffic',
  SELLER_DIGEST: 'seller_digest',
  BUYER_TRAFFIC: 'buyer_traffic',
  WHITE_LABEL: 'white_label',
  REALTOR_PREMIUM: 'realtor_premium',
  INTELLIGENCE: 'intelligence',
};
