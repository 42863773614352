<div class="modal-content">
  <div class="modal-header modal-hide-caret">
    <h4 class="modal-title f-24-400">Your purchase was successful</h4>
    <div class="close inline-svg"
         aria-label="Close"
         [inlineSVG]="'#icon-close'"
         (click)="close()">
    </div>
  </div>
  <div class="modal-body">
    <img class="modal-body__icon" src="assets/common/dialog/gift-box.svg"/>
    <div class="modal-body__title f-20-600 l-h-30 m-b-2x">
      Give your friend $50 off their first month subscription.
    </div>
    <div class="modal-body__text f-14-400 l-h-22">
      Refer five loan officer colleagues, and secure a $250 one-time discount on your subscription. Each of your registered referrals will also receive $50 off their first month subscription.
      <br/>
      <br/>
      Offer expires in
      <discount-countdown class="m-t-2x" [dateFrom]="discountCountdownDate">
      </discount-countdown>
    </div>
  </div>
  <div class="modal-footer text-center">
    <div class="flex flex-center">
      <iq-button class="block" label="Refer colleagues" (click)="redirectToReferralDiscounts()"></iq-button>
      <iq-button class="block m-t-2x"
                 label="Skip and access my account"
                 color="grey"
                 (onClick)="skipReferralDiscount()">
      </iq-button>
    </div>
  </div>
</div>
