import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

// Services
import { WorkingHoursService } from '@core/services/details/working-hours/working-hours.service';

@Component({
  templateUrl: './activate-report-confirm.component.html',
  styleUrls: ['./activate-report-confirm.component.scss'],
})
export class ActivateReportConfirmComponent extends SimpleModalComponent<
{ modalData?: { isPlural: boolean; title: string } },
boolean
> {

  modalData: { isPlural: boolean; title: string } = null;

  // Disabled till backend changes
  // showScheduleAlert: boolean = !this.workingHoursService.isWorkingHoursNow();
  showScheduleAlert: boolean = false;

  constructor(private workingHoursService: WorkingHoursService) {
    super();
  }

  get scheduleAlertMessage(): string {
    return this.workingHoursService.nextAvailableWorkingSlot;
  }

  get scheduleAlertTooltip(): string {
    return `${this.workingHoursService.workingHoursTooltipText}.`;
  }

  confirm(): void {
    this.result = true;
    this.close();
  }

}
