import momentMini from 'moment-mini';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Component, Input, OnInit } from '@angular/core';
import { forEach, isEqual } from 'lodash-es';

import type { Dictionary } from 'asap-team/asap-tools';
import type { DateRange } from '@core/types';
import { Store } from '@core/types/Store';

// Consts
import { API_DATE_FORMAT, DEFAULT_MOMENT_DATE_FORMAT } from '@consts/consts';

// Utils
import { getDefaultDateRanges } from '@core/utils/utils';

@UntilDestroy()
@Component({
  selector: 'date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {

  @Input() provider: Store;

  @Input() title: string;

  defaultDateRanges: DateRange[] = getDefaultDateRanges();

  bsInlineRangeValue: Date[] = [];

  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,
    containerClass: 'date-range',
    dateInputFormat: DEFAULT_MOMENT_DATE_FORMAT,
    maxDate: new Date(),
    showPreviousMonth: true,
  };

  isShow: boolean = false;

  ngOnInit(): void {
    this.subscribes();
  }

  private subscribes(): void {
    this
      .provider
      .queryParams$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((filters: Dictionary<string>) => {
        if (!filters) { return }

        if (!filters.date_from && !filters.date_to) {
          this.resetRanges(true);

        }
      });
  }

  private resetRanges(isResetRanges: boolean): void {
    forEach(this.defaultDateRanges, (range: DateRange) => {
      // eslint-disable-next-line no-param-reassign
      range.active = false;
    });

    if (isResetRanges) {
      this.bsInlineRangeValue = [];
    }
  }

  selectCommonRange({ date_from, date_to }: DateRange, index: number): void {
    this.bsInlineRangeValue = [date_from, date_to];
    this.resetRanges(false);
    this.defaultDateRanges[index].active = true;
  }

  formatDate(date: Date): string {
    return momentMini(date).format(API_DATE_FORMAT);
  }

  get selected(): string {
    const [date_from, date_to] = this.bsInlineRangeValue;

    if (date_from && date_to && !isEqual(date_from, date_to)) {
      return [
        this.formatDate(date_from),
        ' - ',
        this.formatDate(date_to),
      ].join('');
    }

    return '';
  }

  toggle(): void {
    this.isShow = !this.isShow;
  }

  hide(): void {
    this.isShow = false;
  }

  apply([date_from, date_to]: Date[]): void {
    this.provider.emitFilters({
      date_from: momentMini(date_from).format(API_DATE_FORMAT),
      date_to: momentMini(date_to).format(API_DATE_FORMAT),
    });
    this.hide();
  }

  cancel(): void {
    this.provider.emitFilters({
      date_from: null,
      date_to: null,
    });
    this.resetRanges(true);
  }

}
