import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import type { CheckboxItem } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class PropertiesStatesResolve implements Resolve<CheckboxItem[]> {

  constructor(
    private userService: UserService,
  ) {}

  resolve(): Observable<CheckboxItem[]> {
    return this.userService.getAvailablePropertiesStates();
  }

}
