import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { FTLStep, Profile, UserRole } from '@core/types';

// Const
import { USER_ROLE } from '@consts/consts';

// State
import { FTLActions } from '@modules/auth/sign-up/ftl/state/ftl.actions';
import { FTLStepName } from '@modules/auth/sign-up/ftl/state/ftl.model';
import { FtlState } from '@modules/auth/sign-up/ftl/state/ftl.state';
import { Dictionary } from 'asap-team/asap-tools';
import { HttpParams } from '@angular/common/http';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { ANALYTICS_EVENTS } from '@consts/analytics';
import { CalendlyService } from '@core/services/calendly/calendly.service';

@Component({
  selector: 'ftl-demo',
  templateUrl: './ftl-demo.component.html',
  styleUrls: ['../ftl.component.scss', './ftl-demo.component.scss'],
})
export class FtlDemoComponent implements OnInit {

  @Select(FtlState.ftlStepData) ftlStepData$: Observable<FTLStep>;

  profile: Profile;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  demoUrlParams: any = {};

  constructor(
    private store: Store,
    private asaplyticsService: AsaplyticsService,
    private calendlyService: CalendlyService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new FTLActions.GetStepDataAction(FTLStepName.demo_book)).subscribe(() => {
      const { profile } = this.store.selectSnapshot(FtlState.ftlStepData);

      this.profile = profile;
      this.demoUrlParams = {
        hide_event_type_details: 1,
        hide_gdpr_banner: 1,
        a1: `+1${profile?.phone}`,
        email: profile?.email,
        name: profile?.name,
      };
    });
  }

  get agentDemoUrl(): string {
    return `https://calendly.com/myhomeiq-customer-success/realtor-onboarding?${new HttpParams({ fromObject: this.demoUrlParams }).toString()}`;
  }

  get lenderDemoUrl(): string {
    const lenderWebinarUrl: string = this.calendlyService.getTrainingUrl();

    return `${lenderWebinarUrl}?${new HttpParams({ fromObject: this.demoUrlParams }).toString()}`;
  }

  skipStep(): void {
    this.store.dispatch(new FTLActions.SkipFtlStepAction(FTLStepName.demo_book));
  }

  submitDemo(isSkip: boolean = false): void {
    if (isSkip) {
      this.asaplyticsService.sendEvent(ANALYTICS_EVENTS.FTL.agent.ftl_2024.demo_book_skip);
    }

    this.store.dispatch(new FTLActions.SubmitFtlStepAction(FTLStepName.demo_book));
  }

}
