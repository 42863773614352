import { FormError } from 'asap-team/asap-tools';

// Consts
import { GOOGLE_STREET_ERROR } from '../../errors';

export const addressErrors: FormError[] = [
  {
    name: 'incorrect',
    text: GOOGLE_STREET_ERROR,
    rules: ['touched', 'dirty'],
  },
  {
    name: 'city',
    text: 'City: is required',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'state',
    text: 'State: is required',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'street',
    text: 'Street: is required',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'zip',
    text: 'Zip: is required',
    rules: ['touched', 'dirty'],
  },
  {
    name: 'country',
    text: 'Country: is required',
    rules: ['touched', 'dirty'],
  },
];
