import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { b64toBlob } from 'asap-team/asap-tools';
import { CropperPosition, ImageTransform } from 'ngx-image-cropper/lib/interfaces';
import { Dimensions } from 'ngx-image-cropper/lib/interfaces/dimensions.interface';
import { DialogRef } from '@angular/cdk/dialog';

export type CropDialogMeta = {
  title: string;
  subtitle: string;
  confirm_action_label: string;
};

export type CropParams = {
  imageChangedEvent: Event;
  maintainAspectRatio?: boolean;
  roundCropper?: boolean;
  hideResizeSquares?: boolean;
  cropWidth?: number;
  cropHeight?: number;
  typo?: CropDialogMeta;
};

@UntilDestroy()
@Component({
  templateUrl: './crop-avatar.component.html',
  styleUrls: ['./crop-avatar.component.scss'],
})
export class CropAvatarComponent {

  b64toBlob: any = b64toBlob;

  imageChangedEvent: Event;

  roundCropper: boolean = true;

  maintainAspectRatio: boolean = true;

  hideResizeSquares: boolean = false;

  cropWidth: number = 0;

  cropHeight: number = 0;

  transform: ImageTransform = {};

  cropperPosition: CropperPosition = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  };

  scale: number = 1;

  typo: CropDialogMeta;

  croppedImage: any = '';

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  imageCropped(event: any): void {
    this.croppedImage = event.base64;
  }

  zoom({ deltaY }: WheelEvent): void {
    const maxScale: number = 3;
    const minScale: number = 0.2;
    const scaleStep: number = 0.1;

    if (deltaY < 0) {
      const diff: number = this.scale + scaleStep;

      this.scale = diff > maxScale ? this.scale : diff;
    } else {
      const diff: number = this.scale - scaleStep;

      this.scale = diff < minScale ? this.scale : diff;
    }

    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  crop(): void {
    if (this.croppedImage) {
      this.b64toBlob(this.croppedImage)
        .pipe(untilDestroyed(this))
        .subscribe((blob: Blob) => this.closeModal(blob));
    }
  }

  setCropArea({ width, height }: Dimensions): void {
    if (this.maintainAspectRatio && !(this.cropWidth && this.cropHeight)) { return }

    const x1: number = width <= this.cropWidth ? 0 : (width - this.cropWidth) / 2;
    const x2: number = width <= this.cropWidth ? width : this.cropWidth + x1;
    const y1: number = height <= this.cropHeight ? 0 : (height - this.cropHeight) / 2;
    const y2: number = height <= this.cropHeight ? height : this.cropHeight + y1;

    this.cropperPosition = {
      x1, y1, x2, y2,
    };
  }

  closeModal(closeResult?: Blob): void {
    this.dialogRef.close(closeResult);
  }

}
