import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {
  CollectionHelpersService,
  Dictionary,
  BaseHttpService,
  CollectionResponse,
} from 'asap-team/asap-tools';
import momentMini from 'moment-mini';

import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { ActivePartner } from '@core/types';
import {
  ConnectedMonthStatsData,
  DateFilterParam,
} from '@modules/user/collections/partnerships/connected-agents/state/connection-partners.model';
import { LineDateGraphData } from '@commons/diagrams';

@Injectable({ providedIn: 'root' })
export class ActivePartnersService {

  constructor(
    private http: BaseHttpService,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(params: Dictionary<string | number>): Observable<CollectionResponse<ActivePartner>> {
    return this
      .http
      .get('v2/partnerships', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.retrowError(),
      );
  }

  getStatsMonthConnected(month: DateFilterParam): Observable<LineDateGraphData<ConnectedMonthStatsData>[]> {
    return this
      .http
      .get('v2/connected/stats', { params: new HttpParams({ fromObject: { month } }) })
      .pipe(
        map((response: ConnectedMonthStatsData[]) => response.map((item: ConnectedMonthStatsData) => {
          return {
            value: item.homeowners_count,
            date: momentMini(item.month).format('MMM YYYY'),
            data: item,
          };
        })),
      );
  }

}
