import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';
import { FormError, provideRefs } from 'asap-team/asap-tools';

@Component({
  selector: 'iq-form-control-text',
  templateUrl: './iq-form-control-text.component.html',
  styleUrls: ['./iq-form-control-text.component.scss'],
  providers: provideRefs(IqFormControlTextComponent),
})
export class IqFormControlTextComponent implements ControlValueAccessor {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @Input() type: string | null = 'text';

  @Input() label: string | null = null;

  @Input() labelPrefix: string = null;

  @Input() labelStyle: 'wide' | 'content' | null = null;

  @Input() placeholder: string | null = null;

  @Input() lpignore: boolean = true;

  @Input() errorsModel: FormError[];

  @Input() formControlName: string;

  @Input() readonly: boolean = false;

  @Input() minNumber: number = null;

  @Input() maxNumber: number = null;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFocus: EventEmitter<Event> = new EventEmitter<Event>();

  focused: boolean = false;

  disabled: boolean = false;

  constructor(
    private renderer2: Renderer2,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: string): void => { /* Replaced by Angular at runtime */ };

  onTouched = (): void => { /* Replaced by Angular at runtime */ };

  writeValue(value: string): void {
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'value', value);
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  focus(event: Event): void {
    if (this.readonly) { return }

    this.focused = true;
    this.onFocus.emit(event);
  }

  blur(): void {
    this.focused = false;
    this.onTouched();
  }

  input(value: string): void {
    this.onChange(value);
  }

  getNativeElementRef(): ElementRef<HTMLInputElement> {
    return this.nativeInput;
  }

}
