import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ResponseErrorHandler } from 'asap-team/asap-tools';

import type { APIErrorResponse, Profile } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class TokenResolve implements Resolve<Profile> {

  constructor(
    private userService: UserService,
    private responseErrorHandler: ResponseErrorHandler,
    private toastr: ToastrService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const token: any = route.queryParams.auth_token;

    if (!token) { return of(null); }

    return this
      .userService
      .loginWithToken(token)
      .pipe(
        catchError((error: APIErrorResponse) => {
          const message: string = this.responseErrorHandler.format(error.error.errors);

          this.toastr.error(message);

          return of(null);
        }),
      );
  }

}
