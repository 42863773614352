<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="modalData?.title">{{ modalData?.title }}</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <div class="modal-body__title" *ngIf="modalData?.subtitle" [innerHTML]="modalData?.subtitle | newline"></div>
    <div class="modal-body__text"
         *ngIf="modalData?.body"
         [innerHTML]="modalData?.body"
         [class.text-left]="modalData?.type === contactsFee"></div>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix" *ngIf="modalData?.actions.close.is_show" [ngClass]="modalData?.actions.close.class">
        <iq-button class="block"
                   label="{{ modalData?.actions?.close?.label }}"
                   [color]="modalData?.actions.close.color"
                   [style]="modalData?.actions.close.style"
                   (onClick)="confirm()"></iq-button>
      </div>
      <div class="col col-fix" *ngIf="modalData?.actions.dismiss.is_show" [ngClass]="modalData?.actions.dismiss.class">
        <iq-button class="block"
                   label="{{ modalData?.actions?.dismiss?.label }}"
                   [color]="modalData?.actions.dismiss.color"
                   [style]="modalData?.actions.dismiss.style"
                   (onClick)="close()"></iq-button>
      </div>
    </div>
  </div>
</div>
