<div class="loan-officer__avatar">
  <!--Cash Buyer-->
  <ng-container *ngIf="row?.cash_buyer; else loanOfficer">
    <img src="assets/components/collections/intelligence/cash-buyer.svg"/>
    <p class="loan-officer__name f-14-500 f-c-gray-main">Cash</p>
  </ng-container>
  
  <!--Loan Officer-->
  <ng-template #loanOfficer>
    <ng-container *ngIf="row?.name; else pendingStateLO">
      <avatar-placeholder class="size-40 f-14-bold"
                          [name]="row?.name"
                          [formattedInitials]="row?.initials"
                          [image]="row?.avatar_url?.medium"></avatar-placeholder>
      <div *ngIf="row?.internal_user" class="internal-user-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.98597 3.44517C2.84814 3.38701 2.70074 3.33959 2.54129 3.29704C2.51245 3.28953 2.49201 3.30249 2.47936 3.32781C2.47745 3.33164 2.47656 3.33606 2.47656 3.34033V9.21273C2.47656 9.21715 2.47745 9.22142 2.47936 9.22524C2.49201 9.25072 2.51245 9.26367 2.54129 9.25602C2.70074 9.21347 2.84814 9.16605 2.98597 9.10789C3.2328 9.00394 3.46757 8.83918 3.63571 8.65189C3.65086 8.6351 3.65336 8.622 3.65336 8.61375V3.93931C3.65336 3.93121 3.65086 3.9181 3.63571 3.90132C3.46757 3.71388 3.2328 3.54912 2.98597 3.44517ZM9.64241 7.8815C9.94793 7.41254 10.1236 6.85141 10.1178 6.24963C10.1024 4.64412 8.7882 3.33869 7.18422 3.335C5.53597 3.33132 4.19825 4.69197 4.23752 6.34961C4.27503 7.92906 5.56377 9.20313 7.1423 9.22169C7.38663 9.22463 7.62435 9.19739 7.8522 9.14365C7.87839 9.13746 7.88751 9.10492 7.86853 9.08593L6.98682 8.20352C6.86781 8.0841 6.72351 7.9937 6.56552 7.93495C5.89342 7.68552 5.413 7.03708 5.413 6.27849C5.413 5.28153 6.24264 4.47479 7.24718 4.51367C8.16832 4.54944 8.91088 5.29802 8.94074 6.22018C8.95001 6.50539 8.89117 6.77617 8.77937 7.01764L8.46252 6.70048C8.32528 6.56311 8.16303 6.45636 7.98665 6.38465C7.63994 6.23447 7.23188 6.14877 6.83972 6.13964C6.59053 6.13375 6.33325 6.15054 6.06053 6.18205L6.05891 6.1822C5.99684 6.19044 5.97198 6.26774 6.0164 6.31206L8.83042 9.12907C8.97001 9.26851 9.1355 9.37585 9.3154 9.44637C9.65888 9.592 10.0605 9.67489 10.4466 9.68343C10.6956 9.68903 10.9529 9.6721 11.2258 9.64044C11.2521 9.6372 11.2714 9.6263 11.284 9.60952C11.3054 9.58125 11.3004 9.5409 11.2755 9.51587L9.64241 7.8815Z"
                fill="white"/>
        </svg>
      </div>
      <div class="loan-officer__info">
        <h4 class="user-name f-14-400 f-c-grey-main l-h-22 text-overflow"
            [ngClass]="nameClasses"
            [tooltip]="row?.name"
            [container]="'body'">
          {{ row?.name }}
        </h4>
        <div class="f-12-400 f-c-steel l-h-18 text-overflow" [tooltip]="row?.company_name" [container]="'body'">
          {{ row?.company_name }}
        </div>
      </div>
    </ng-container>
    <ng-template #pendingStateLO>
      <img width="40" src="assets/components/collections/intelligence/sync.svg"/>
      <div class="loan-officer__name f-14-400 f-c-grey-blue">Pending</div>
    </ng-template>
  </ng-template>
</div>
