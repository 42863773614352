import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Directive,
  Input,
  ViewContainerRef,
  EmbeddedViewRef,
  TemplateRef,
  OnInit,
} from '@angular/core';
import { assertTemplate } from 'asap-team/asap-tools';

import type { Profile, PermittedAction } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
} from 'rxjs/operators';

@UntilDestroy()
@Directive({ selector: '[permittedIf]' })
export class PermittedIfDirective<T, M> implements OnInit {

  /**
   * The rule to evaluate as the condition for showing a template.
   * If permission is not assigned, then template will be shown.
   * If permission is assigned, then template will be shown,
   * if user has matching permitted_action in profile.
   */
  @Input('permittedIf') permission: PermittedAction;

  /**
   * A template to show if the permission is not present in profile.
   */
  @Input()
  set permittedIfElse(templateRef: TemplateRef<M> | null) {
    assertTemplate('permittedIfElse', templateRef);
    this.elseTemplateRef = templateRef;
    this.elseViewRef = null; // clear previous view if any.
  }

  private elseTemplateRef: TemplateRef<M> = null;

  private elseViewRef: EmbeddedViewRef<M> | null = null;

  constructor(
    private templateRef: TemplateRef<T>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {}

  ngOnInit(): void {

    this.userService.profile$.pipe(
      untilDestroyed(this),
      filter(Boolean),
      map((profile: Profile) => profile.permitted_actions.includes(this.permission)),
      distinctUntilChanged(),
      startWith(!!this.permission),
      pairwise(),
    ).subscribe((permissions: [boolean, boolean]) => this.updateView(permissions));

  }

  private updateView([previouslyPermitted, currentPermitted]: boolean[]): void {
    // Do nothing if permission value is not assigned, assuming that all users
    // has access to view that template.
    // Notice, that additional check for this.viewContainer.length needed for tests.
    // It will be true with AOT compiler.
    if (!this.permission) {
      if (!this.viewContainer.length) {
        this.renderGrantedTemplate();
      }

      return;
    }

    // If permission is granted - updating the view only if previously it was hidden(dynamic permission case),
    // otherwise do nothing because template already exist and no need to hide it or re-render.
    if (currentPermitted && (!previouslyPermitted || !this.viewContainer.length)) {
      this.renderGrantedTemplate();
    } else if (!currentPermitted) {
      // If permission wasn't granted then hide template. And show alternative template if provided.
      this.renderDeniedTemplateIfExist();
    }
  }

  private renderGrantedTemplate(): void {
    this.viewContainer.clear();
    this.elseViewRef = null;
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private renderDeniedTemplateIfExist(): void {
    this.viewContainer.clear();

    if (this.elseTemplateRef) {
      this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
    }
  }

}
