<input class="file-input"
       #nativeInput
       type="file"
       accept="image/gif,image/jpg,image/jpeg,image/svg,image/png"
       (change)="onImageSelected($event)"/>
<div class="control">
  <div class="control__prepend">
    <div class="control__label flex-center">
      <iq-button *ngIf="file || fileName"
                 size="24"
                 color="grey"
                 [style]="'transparent'"
                 iconLeft="#icon-close"
                 (onClick)="remove()"></iq-button>
      <div class="control__label-text flex-1">
        <div *ngIf="!file && !fileName; else fileNameField" class="text-overflow">Default logo</div>
        <ng-template #fileNameField>
          <div class="text-overflow">{{ file?.name || fileName }}</div>
        </ng-template>
      </div>
      <div class="m-l-1x">
        <iq-button label="Select image"
                   size="24"
                   [style]="'outline'"
                   [busy$]="action$"
                   (onClick)="nativeInput.click()"></iq-button>
      </div>
    </div>
  </div>
</div>
