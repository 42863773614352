<a class="integration-card text-center"
   [routerLink]="integration.code"
   [class.colored]="isColored">
  <i class="integration-card__logo inline-svg--40 m-b-8"
     [inlineSVG]="'#icon-' + integration?.code + '-logo'"></i>
  <div class="integration-card__name f-16-bold f-c-slate m-b-8">{{ integration.title }}</div>
  <div class="integration-card__status f-14-normal"
       *ngIf="integration.status_title"
       [class.f-c-steel-light]="integration.status === INTEGRATION_STATUS.READY_TO_CONNECT">
    <i class="inline-svg"
       [inlineSVG]="'#icon-check-circle-blue'"
       *ngIf="integration.status === INTEGRATION_STATUS.CONNECTED"></i>
    <i class="inline-svg"
       [inlineSVG]="'#icon-arrow-down-blue'"
       *ngIf="integration.status === INTEGRATION_STATUS.RECEIVING_ONLY"></i>
    <span>{{ integration.status_title }}</span>
  </div>
</a>
