import { Component, Input } from '@angular/core';

import type { ViralImportInfo } from '@core/types';

@Component({
  selector: 'viral-import-info',
  templateUrl: './viral-import-info.component.html',
  styleUrls: ['./viral-import-info.component.scss'],
})
export class ViralImportInfoComponent {

  @Input() info: ViralImportInfo;

  isInfoOpen: boolean = false;

  get isShowFailedCount(): number {
    return this.info.failed_items || this.info.duplicated_items;
  }

  toggle(): void {
    this.isInfoOpen = !this.isInfoOpen;
  }

  renderFailedItems(items: number[]): boolean | string {
    return items && items.length ? items.join(', ') : false;
  }

}
