import { InviteRecommendation, LookUpPartner, PaginationParams } from '@core/types';

export class SearchPartnerSidebarModel {

  rows: LookUpPartner[] = [];

  loading: boolean = false;

  query: string = undefined;

  page: number = 1;

  total_pages: number = 0;

  recommendations: InviteRecommendation[] = [];

  recommendationsLoading: boolean = false;

  recommendationsFetching: boolean = false;

  recommendationsPagination: PaginationParams = {
    limit: 10,
    offset: 0,
  };

}
