<ng-container [ngSwitch]="type">
  <div *ngSwitchCase="'inline'" class="calendar" #calendar></div>
  <ng-container *ngIf="!immediatelyOpen">
    <iq-button
      *ngSwitchCase="'dialog'"
      class="block"
      [label]="toggleBtnText"
      (click)="openDialog()"
    ></iq-button>
  </ng-container>
</ng-container>
