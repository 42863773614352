<div class="loan-officers__row">
  <div class="loan-officers__col user">
    <a class="avatar no-underline" [routerLink]="link" (click)="goToDetails()">
      <avatar-placeholder
        class="size-40 f-14-bold"
        [name]="relationship?.name"
        [formattedInitials]="relationship?.initials"
        [image]="(relationship?.name && relationship?.company_name) ? relationship?.avatar_url?.medium :
        'assets/components/collections/intelligence/sync.svg'"></avatar-placeholder>
      <div *ngIf="relationship?.internal_user"
           class="internal-user-icon"
           [inlineSVG]="'assets/components/intelligence/agent-details/icon-myhiq.svg'"
           [setSVGAttributes]="{ 'width': 16, 'height': 16 }"
           [resolveSVGUrl]="false"></div>
    </a>
    <div class="user-info">
      <div class="text-overflow f-14-600 f-c-gray-main l-h-20">
        {{ (relationship?.name | titlecase) || 'Unknown name' }}
      </div>
      <div *ngIf="relationship?.company_name; else noNameContent"
           class="text-overflow f-12-400 f-c-steel l-h-18"
           [tooltip]="relationship?.company_name | titlecase"
           [container]="'body'">
        {{ relationship?.company_name | titlecase }}
      </div>
      <ng-template #noNameContent>
        <div class="text-overflow f-12-400 f-c-steel l-h-18 font-style-italic">Pending details</div>
      </ng-template>
    </div>
  </div>
  <div class="loan-officers__col count">
    <div>{{ relationship?.buyers_count }}</div>
  </div>
  <div class="loan-officers__col volume">
    <div>
      {{ relationship?.buyers_volume | currency: 'USD':'symbol':'1.0-0' }}
    </div>
  </div>
</div>
