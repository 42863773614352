import { EMPTY, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';

import type { Profile } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

type CompleteAccountResult = {
  id: string;
  type: string;
  profile: Profile;
  hash: string;
};

@Injectable({ providedIn: 'root' })
export class CompleteAccountResolve implements Resolve<any> {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): false | Observable<CompleteAccountResult> {

    const { email, token } = route.queryParams;

    if (!email || !token) {
      this.router.navigate([ROUTE.alias.SIGN_UP_EXPIRED]);

      return false;
    }

    return this
      .userService
      .completeAccount({ token, email: email.replace(' ', '+') })
      .pipe(
        tap((data: CompleteAccountResult) => this.userService.setToken(data.id)),
        catchError(() => {
          this.router.navigate([ROUTE.alias.SIGN_UP_EXPIRED]);

          return EMPTY;
        }),
      );
  }

}
