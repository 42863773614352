import { every } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import type { APIErrorResponse } from '@core/types';

// Consts
import { ROUTE, COMMON_TOAST } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

type ConfirmParams = {
  email: string;
  token: string;
};

@Component({
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {

  readonly keys: string[] = ['email', 'token'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this
      .route
      .queryParamMap
      .subscribe(
        (response: Params) => {
          if (this.isParamsPresent(this.keys, response.params)) {
            this.makeRequest(response.params);
          } else {
            this.router.navigate([ROUTE.alias.DASHBOARD_PRODUCTION]);
          }
        },
      );
  }

  private isParamsPresent(keys: string[], params: ConfirmParams): boolean {
    return every(keys, (key: string) => !!params[key]);
  }

  private makeRequest({ email, token }: ConfirmParams): void {
    this
      .userService
      .confirmEmail(email, token)
      .subscribe(
        (response: { id: string; type: string }) => {
          this.userService.setToken(response.id);
          this.router.navigate([ROUTE.EMPTY]);
        },
        (error: APIErrorResponse) => {
          this.toastr.warning(error?.error?.errors[0]?.detail || COMMON_TOAST.CONFIRMATION_LINK_EXPIRED);
          this.router.navigate([ROUTE.EMPTY]);
        },
      );
  }

}
