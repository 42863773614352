import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { isEmpty, pickBy, identity } from 'lodash-es';

import type { Dictionary } from 'asap-team/asap-tools';
import { Store } from '@core/types/Store';

@UntilDestroy()
@Component({
  selector: 'clear-filters',
  templateUrl: './clear-filters.component.html',
  styleUrls: ['./clear-filters.component.scss'],
})
export class ClearFiltersComponent implements OnInit {

  @Input() provider: Store;

  isShow: boolean = false;

  ngOnInit(): void {
    this.subscribes();
  }

  private subscribes(): void {
    this
      .provider
      .queryParams$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (filters: Dictionary) => {
          if (isEmpty(pickBy(filters, identity))) {
            this.isShow = false;

            return;
          }

          this.isShow = true;
        },
      );
  }

  clear(): void {
    this.provider.clearFilters();
  }

}
