<div class="image"
     [class.visible]="isShowRobot || isShowAvatar">
  <avatar-placeholder class="size-40"
                      [name]="user_name || client_name || ''"
                      [image]="avatarUrl"
                      type="inverse"></avatar-placeholder>
</div>
<div class="icon inline-svg"
     *ngIf="isShowAlert"
     [inlineSVG]="'#icon-activity-alert'"></div>
<div class="icon inline-svg"
     *ngIf="isShowWarning"
     [inlineSVG]="'#icon-alert_round'"></div>
<div class="icon inline-svg"
     *ngIf="isShowClock"
     [inlineSVG]="'#icon-alarm_clock'"></div>
<div class="icon inline-svg"
     *ngIf="isShowDownload"
     [inlineSVG]="'#icon-download'"></div>
<div class="icon icon--social"
     *ngIf="isShowFacebook"
     [inlineSVG]="'#icon-facebook'"></div>
<div class="icon icon--social"
     *ngIf="isShowGoogle"
     [inlineSVG]="'#icon-google'"></div>
