import { toDashCase } from 'asap-team/asap-tools';

/**
 * Returns icon string
 *
 * @return {string}
 */
export function getCardIcon(icon: string): string {
  if (!icon) { return '' }

  const name: string = toDashCase(icon);

  return `assets/components/payment-methods/payment-method-icons/${name}.svg`;
}
