import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required, invalidPhone, invalidLink } from './conditions';

export const profileErrors: FormErrors = {
  username: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  name: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  company_name: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  license_number: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  company_license_number: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'maxlength',
      text: 'Max length is 50',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  email: [
    required,
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  phone: [
    required,
    invalidPhone,
    apiError(),
  ],
  fb_messenger: [
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  facebook_campaign_id: [
    {
      name: 'pattern',
      text: 'Campaign ID is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  facebook_lead_gen_campaign_id: [
    {
      name: 'pattern',
      text: 'Lead Gen. Camp. ID is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  lending_pad_company_id: [
    {
      name: 'pattern',
      text: 'Company ID is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  lending_pad_campaign_id: [
    {
      name: 'pattern',
      text: 'Campaign ID is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  license_states: [
    required,
    apiError(),
  ],
  job_title: [
    required,
    {
      name: 'maxlength',
      text: 'Max length is 50',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'pattern',
      text: 'Job title is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  mls_number: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError(),
  ],
  time_zone: [
    required,
    apiError(),
  ],
  facebook_url: [
    invalidLink,
    apiError(),
  ],
  instagram_url: [
    invalidLink,
    apiError(),
  ],
  linkedin_url: [
    invalidLink,
    apiError(),
  ],
  personal_website_url: [
    invalidLink,
    apiError(),
  ],
  realtor_url: [
    invalidLink,
    apiError(),
  ],
  youtube_url: [
    invalidLink,
    apiError(),
  ],
  zillow_url: [
    invalidLink,
    apiError(),
  ],
  global: [],
};
