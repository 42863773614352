import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import * as confetti from 'canvas-confetti';

@Component({
  selector: 'particles',
  templateUrl: './particles.component.html',
  styleUrls: ['./particles.component.scss'],
})
export class ParticlesComponent implements AfterViewInit {

  @ViewChild('canvas') canvas!: ElementRef<HTMLElement>;

  ngAfterViewInit(): void {
    const confettiInstance: any = confetti.create(this.canvas.nativeElement, { resize: true });

    this.renderConfetti(confettiInstance, { origin: { x: 0.05, y: 0.7 } });
    this.renderConfetti(confettiInstance, { origin: { x: 0.95, y: 0.7 } });
  }

  private renderConfetti(confettiInstance: any, defaults: { origin: { x: number; y: number } }): void {
    const count: number = 200;

    const fire = (particleRatio: number, opts: any): void => {
      confettiInstance({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    };

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    fire(0.2, { spread: 60 });

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

}
