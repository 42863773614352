import { Component, Input } from '@angular/core';

import type { ViralImportInfo } from '@core/types';

@Component({
  selector: 'viral-unsubscribed-emails',
  templateUrl: './viral-unsubscribed-emails.component.html',
  styleUrls: ['./viral-unsubscribed-emails.component.scss'],
})
export class ViralUnsubscribedEmailsComponent {

  @Input() info: ViralImportInfo;

  isOpen: boolean = false;

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

}
