import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CollectionHelpersService,
  Dictionary,
  propertyImagePlaceholder,
  BaseHttpService,
  CollectionResponse,
  CollectionApi,
} from 'asap-team/asap-tools';

import type {
  HistoryProcessedCollectionLead,
  HistoryPendingCollectionLead,
} from '@core/types';

// Consts
import { LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class HistoryProcessedService implements CollectionApi<HistoryProcessedCollectionLead> {

  constructor(
    private http: BaseHttpService,
    private collectionHelpersService: CollectionHelpersService,
  ) {}

  getCollection(params: Dictionary<string>): Observable<CollectionResponse<HistoryProcessedCollectionLead>> {
    return this
      .http
      .get('v2/history/leads/processed', { params: new HttpParams({ fromObject: params }) })
      .pipe(
        this.collectionHelpersService.mapResponseFilters(),
        propertyImagePlaceholder<HistoryProcessedCollectionLead>(LOCK_IMAGE_PLACEHOLDER, PROPERTY_IMAGE_PLACEHOLDER),
        this.collectionHelpersService.retrowError(),
      );
  }

  restore(uid: string): Observable<HistoryPendingCollectionLead> {
    return this
      .http
      .patch(`v2/history/leads/${uid}/restore`, null);
  }

}
