import { Observable } from 'rxjs';
import {
  Component,
  ViewChild,
  HostListener,
  ElementRef,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

import type { Profile, NavItem } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Utils
import { isMobile } from '@core/utils/utils';

// Services
import { UserService } from '@core/services/user/user.service';
import { MenuService } from '@core/services/menu/menu.service';

@UntilDestroy()
@Component({
  selector: 'profile-actions',
  templateUrl: './profile-actions.component.html',
  styleUrls: ['./profile-actions.component.scss'],
})
export class ProfileActionsComponent {

  @ViewChild('profileActionsDropdown', { static: true }) profileActionsDropdown: BsDropdownDirective;

  profile$: Observable<Profile> = this.userService.profile$;

  @Input() navigationItems: NavItem[] = [];

  @HostListener('click')
  toggle(): void {
    if (isMobile()) {
      this.menuService.openProfileMenuMobile();
    } else {
      this.profileActionsDropdown.toggle(true);
    }
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent): void {
    const targetElement: HTMLElement = event.target as HTMLElement;

    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.profileActionsDropdown.toggle(false);
    }
  }

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private userService: UserService,
    private menuService: MenuService,
  ) { }

  logout(): void {
    this
      .userService
      .logout(true)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.router.navigate([ROUTE.alias.SIGN_IN]);
      });
  }

}
