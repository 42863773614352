import { Injectable } from '@angular/core';
import {
  Action, Selector, State, StateContext, Store,
} from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { finalize, tap } from 'rxjs/operators';

// Types
import { RowsCollection } from 'asap-team/asap-tools';
import { InviteRecommendation, LookUpPartner, PaginationParams } from '@core/types';

// Services
import { PartnershipService } from '@core/services/partnership/partnership.service';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { UserService } from '@core/services/user/user.service';

// State
import { SearchPartnerSidebarModel } from './search-partner-sidebar.model';
import { SearchPartnerSidebarActions } from './search-partner-sidebar.actions';

@State<SearchPartnerSidebarModel>({
  name: 'searchPartnerSidebar',
  defaults: new SearchPartnerSidebarModel(),
})
@Injectable()
export class SearchPartnerSidebarState {

  constructor(
    private store: Store,
    private partnerService: PartnershipService,
    private asaplyticsService: AsaplyticsService,
    private userService: UserService,
  ) {}

  @Selector()
  static rows(state: SearchPartnerSidebarModel): LookUpPartner[] {
    return state.rows;
  }

  @Selector()
  static loading(state: SearchPartnerSidebarModel): boolean {
    return state.loading;
  }

  @Selector()
  static pagination(state: SearchPartnerSidebarModel): { page: number; total_pages: number } {
    return {
      page: state.page,
      total_pages: state.total_pages,
    };
  }

  @Selector()
  static recommendations(state: SearchPartnerSidebarModel): InviteRecommendation[] {
    return state.recommendations;
  }

  @Selector()
  static recommendationLoading(state: SearchPartnerSidebarModel): boolean {
    return state.recommendationsLoading;
  }

  @Selector()
  static recommendationsFetching(state: SearchPartnerSidebarModel): boolean {
    return state.recommendationsFetching;
  }

  @Selector()
  static isLastRecommendationLoaded(state: SearchPartnerSidebarModel): boolean {
    return state.recommendationsPagination.offset + state.recommendationsPagination.limit > state.recommendations.length;
  }

  @Action(SearchPartnerSidebarActions.LookupPartners)
  lookupPartners(ctx: StateContext<SearchPartnerSidebarModel>, action: SearchPartnerSidebarActions.LookupPartners): any {
    ctx.patchState({ loading: true, query: action.searchQuery });

    if (action.reset) {
      ctx.patchState({
        rows: new SearchPartnerSidebarModel().rows,
        page: new SearchPartnerSidebarModel().page,
      });
    }

    return this.partnerService.lookUpPartners([action.searchQuery, ctx.getState().page])
      .pipe(
        tap(({ rows, page }: RowsCollection<LookUpPartner>) => {
          ctx.patchState({
            rows: [...ctx.getState().rows, ...rows],
            total_pages: page.total_pages,
          });

          // GA events
          if (rows.length) {
            this.sendGAEvent('search_list');
          } else {
            this.sendGAEvent('search_empty');
          }
        }),
        finalize(() => ctx.patchState({ loading: false })),
      );
  }

  @Action(SearchPartnerSidebarActions.LoadMoreAction)
  loadMore(ctx: StateContext<SearchPartnerSidebarModel>): any {
    const { page, total_pages } = ctx.getState();

    if (page < total_pages) {
      ctx.patchState({ page: page + 1 });
      ctx.dispatch(new SearchPartnerSidebarActions.LookupPartners(ctx.getState().query, false));
    }
  }

  @Action(SearchPartnerSidebarActions.LoadRecommendations)
  loadRecommendations(ctx: StateContext<SearchPartnerSidebarModel>): any {
    const { recommendationsPagination }: SearchPartnerSidebarModel = ctx.getState();

    ctx.patchState({ recommendationsLoading: true });

    return this.partnerService.getInviteRecommendations(recommendationsPagination).pipe(
      tap((items: InviteRecommendation[]) => ctx.patchState({ recommendations: items })),
      finalize(() => ctx.patchState({ recommendationsLoading: false })),
    );
  }

  @Action(SearchPartnerSidebarActions.LoadMoreRecommendations)
  loadMoreRecommendations(ctx: StateContext<SearchPartnerSidebarModel>): any {
    const { recommendationsPagination }: SearchPartnerSidebarModel = ctx.getState();
    const payload: PaginationParams = {
      ...recommendationsPagination,
      offset: recommendationsPagination.offset + recommendationsPagination.limit,
    };

    ctx.patchState({ recommendationsFetching: true });

    return this.partnerService.getInviteRecommendations(payload).pipe(
      tap((items: InviteRecommendation[]) => {
        ctx.setState(patch<SearchPartnerSidebarModel>({
          recommendations: append(items),
          recommendationsPagination: payload,
        }));
      }),
      finalize(() => ctx.patchState({ recommendationsFetching: false })),
    );
  }

  @Action(SearchPartnerSidebarActions.ResetState)
  reset(ctx: StateContext<SearchPartnerSidebarModel>): any {
    ctx.setState(new SearchPartnerSidebarModel());
  }

  @Action(SearchPartnerSidebarActions.UpdateRowData)
  updateRowData(ctx: StateContext<SearchPartnerSidebarModel>, action: SearchPartnerSidebarActions.UpdateRowData): any {
    ctx.setState(
      patch({ rows: updateItem((item: LookUpPartner) => item.id === action.id, patch(action.data)) }),
    );
  }

  private sendGAEvent(label: string): void {
    this.asaplyticsService.sendEvent({
      action: `invite_by_${this.userService.getUserRole()}`,
      category: 'Invite_sidebar',
      label,
    });
  }

}
