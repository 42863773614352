import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE } from '@consts/consts';
import { DialogRef } from '@angular/cdk/dialog';
import { PaymentMethodService } from '@core/services/payment-method/payment-method.service';

@Component({
  selector: 'app-unpaid-slave-status',
  templateUrl: './unpaid-slave-status.component.html',
  styleUrls: ['./unpaid-slave-status.component.scss'],
})
export class UnpaidSlaveStatusComponent {

  constructor(
    private dialogRef: DialogRef,
    private router: Router,
    private paymentMethodService: PaymentMethodService,
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }

  navigateToBilling(): void {
    this.closeModal();
    this.router.navigate([ROUTE.alias.BILLING]).then(() => {
      this.paymentMethodService.changePaymentMethod();
    });
  }

}
