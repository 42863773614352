<div class="sidebar" [ngSwitch]="type">
  <div class="modal-header">
    <div class="modal-header__title">{{ isMember ? 'Member' : 'User Card' }}</div>
    <div class="f-14-normal" *ngSwitchCase="modalType.member">{{ lead.full_address }}</div>
    <div class="modal-close inline-svg" [inlineSVG]="'#icon-close'" (click)="close()"></div>
    <div class="user-card m-t-24">
      <div class="user-card__block">
        <div class="user-card__block--avatar">
          <avatar-placeholder class="size-80"
                              [name]="user.name"
                              [image]="user.avatar?.medium"
                              type="partner"></avatar-placeholder>
          <div class="icon-primary inline-svg inline-svg--20"
               *ngIf="user.primary"
               [inlineSVG]="'#icon-star_solid'"></div>
        </div>
        <div class="user-card__block--info" [ngSwitch]="type">
          <div class="name pointer"
               ngxClipboard=""
               tooltip="Click to copy"
               [delay]="1500"
               [cbContent]="user?.name">{{ user.name }}</div>
          <div class="company" *ngSwitchCase="modalType.outgoing">{{ user.company_name || user.email }}</div>
          <div class="company" *ngSwitchCase="modalType.invite">{{ user?.company_name }}</div>
          <div class="company" *ngSwitchCase="modalType.engaged">{{ user?.company_name }}</div>
          <div class="company" *ngSwitchDefault>
            <span *ngIf="user.primary && !isMember">
              Primary&#32;
            </span>
            <span *ngIf="type === modalType.incoming; else defaultRoleRef">
              {{ user.role | titlecase }}
            </span>
            <ng-template #defaultRoleRef>
              <span>
                {{ user.role === USER_ROLE.lender ? USER_LABEL.loan_officer_title : USER_LABEL.agent_title }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="user-card__block--control">
          <div class="user-card__block--control--title">
            <span *ngSwitchCase="modalType.partner">{{ user.primary ? 'PRIMARY PARTNER' : 'PARTNER'}}</span>
            <span *ngSwitchCase="modalType.outgoing">INVITATION SENT</span>
            <span *ngSwitchCase="modalType.incoming">INVITED YOU</span>
            <span *ngSwitchCase="modalType.invite">AVAILABLE</span>
            <span *ngSwitchCase="modalType.engaged">ENGAGED</span>
            <span *ngSwitchCase="modalType.member">{{ !observerRole ? '' : user.owner ? 'OWNER' : 'CAN EDIT' }}</span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isShowMenu">
        <ng-container *ngSwitchCase="modalType.partner">
          <div class="user-card__block--btn-group">
            <div *ngIf="user.primary" class="btn-col">
              <iq-button class="block"
                         label="Unset primary option"
                         color="grey"
                         [style]="'outline'"
                         (click)="unsetPrimary(user)"></iq-button>
            </div>
            <div *ngIf="!user.primary" class="btn-col">
              <iq-button class="block"
                         label="Set as primary"
                         color="grey"
                         [style]="'outline'"
                         (click)="setPrimary(user)"></iq-button>
            </div>
            <div *ngIf="isShowBreakPartnershipButton(user?.role)" class="btn-col">
              <iq-button class="block"
                         label="Break the partnership"
                         color="red"
                         [style]="'outline'"
                         (click)="breakPartnership()"></iq-button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="modalType.outgoing">
          <div class="user-card__block--btn-group">
            <div class="btn-col">
              <iq-button class="block"
                         label="Cancel invitation"
                         color="grey"
                         [style]="'outline'"
                         (click)="closeModal(modalResult.cancelInvitation)"></iq-button>
            </div>
            <div class="btn-col">
              <iq-button class="block"
                         color="blue"
                         label="Resend invitation"
                         [style]="'outline'"
                         (click)="resendInvitation(user.id)"></iq-button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="modalType.member">
          <div class="user-card__block--btn-group">
            <div *ngIf="observerRole === user.role" class="btn-col">
              <iq-button class="block"
                         label="Edit my profile"
                         color="blue"
                         [style]="'outline'"
                         (click)="goToEdit('profile')"></iq-button>
            </div>
            <div *ngIf="compareObserverRole(USER_ROLE.lender) && compareMemberRole(USER_ROLE.agent) && !user.owner" class="btn-col">
              <iq-button class="block"
                         label="Edit partnership"
                         color="blue"
                         [style]="'outline'"
                         (click)="goToEdit('partnership')"></iq-button>
            </div>
            <div *ngIf="isShowMemberRemoveAction" class="btn-col">
              <iq-button class="block"
                         label="Remove from lead"
                         color="red"
                         [style]="'outline'"
                         (click)="removeFromLead()"></iq-button>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="modalType.invite">
        <div class="user-card__block--btn-group">
          <iq-button class="block"
                     label="Send Invitation"
                     (click)="closeModal(modalResult.sentInvitation)"></iq-button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="modalType.incoming">
        <div class="user-card__block--btn-group">
          <div class="btn-col">
            <iq-button class="block"
                       label="Accept Invitation"
                       (click)="closeModal(modalResult.acceptInvitation)"></iq-button>
          </div>
          <div class="btn-col">
            <iq-button class="block"
                       label="Reject Invitation"
                       color="grey"
                       [style]="'outline'"
                       (click)="closeModal(modalResult.rejectInvitation)"></iq-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-body" overflowShadow>
    <div class="user-card__info">
      <div class="user-card__info--element">
        <span class="title">Email</span>
        <span class="value pointer"
              ngxClipboard=""
              tooltip="Click to copy"
              [delay]="1500"
              [cbContent]="user?.email">
          {{ user.email }}
        </span>
      </div>
      
      <div class="user-card__info--element">
        <span class="title">Phone</span>
        <span class="value pointer"
              ngxClipboard=""
              tooltip="Click to copy"
              [delay]="1500"
              [cbContent]="user?.phone">
          {{ user.phone | phone }}
        </span>
      </div>
      
      <div class="user-card__info--element" *ngIf="user?.job_title">
        <span class="title">Job title</span>
        <span class="value pointer"
              ngxClipboard=""
              tooltip="Click to copy"
              [delay]="1500"
              [cbContent]="user?.job_title">
          {{ user.job_title }}
        </span>
      </div>
  
      <ng-container *ngIf="type !== modalType.engaged">
        <div class="user-card__info--element">
          <span class="title">License</span>
          <span class="value pointer"
                ngxClipboard=""
                tooltip="Click to copy"
                [delay]="1500"
                [cbContent]="user?.license_number">{{ user.license_number }}</span>
        </div>
        <div class="user-card__info--element">
          <span class="title">Broker</span>
          <span class="value">{{ user.company_name }}</span>
        </div>
        <div class="user-card__info--element">
          <span class="title">Broker Licence</span>
          <span class="value">{{ user.company_license_number }}</span>
        </div>
        <div class="user-card__info--element" *ngFor="let item of user.commissions">
          <span class="title">{{ item.title }}</span>
          <ng-container *ngIf="isPercent(item.type)">
            <span class="value">{{ item.value / 100 | percent:'1.0-2' }}</span>
          </ng-container>
          <ng-container *ngIf="!isPercent(item.type)">
            <span class="value">{{ item.value | amount }}</span>
          </ng-container>
        </div>
      </ng-container>
      
      <ng-container *ngSwitchCase="modalType.engaged">
        <div class="user-card__info--element m-t-10" *ngFor="let name of user.partners">
          <span class="title">Partner</span>
          <span class="value">{{ name }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
