import { Component } from '@angular/core';

// Consts
import { ROUTE } from '@consts/consts';

@Component({
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.scss'],
})
export class ExpiredLinkComponent {

  ROUTE: any = ROUTE;

}
