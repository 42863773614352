import { Route } from '@angular/router';

// Consts
import { ROUTE } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RegistrationCompletedGuard } from '@core/guards/registration-completed/registration-completed.guard';

export const CSV: Route = {
  path: ROUTE.name.CSV,
  canActivate: [AuthGuard, ProfileGuard, RegistrationCompletedGuard],
  canLoad: [AuthGuard, ProfileGuard, RegistrationCompletedGuard],
  loadChildren: () => import('@modules/admin/csv/csv.module').then((m: any) => m.ImportModule),
};
