import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'info-tooltip',
  templateUrl: './info-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTooltipComponent {

  @Input() text: string = '';

  @Input() appendToCssSelector: string = '';

}
