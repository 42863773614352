import { DETECT_ID_IN_URL_PATTERN } from '@consts/regex';
import { ROUTE } from '@consts/routes';

const urlSegmentNamesBeforeId: string[] = [ROUTE.name.REPORT_DETAILS, ROUTE.name.LEAD, ROUTE.name.AGENT_DETAILS, ROUTE.name.LENDER_DETAILS];

export class AsaplyticsUrlProcessor {

  public static cleanUrlIfNeeded(url: string): string {
    const detectCleanableUrlPattern: RegExp = DETECT_ID_IN_URL_PATTERN(urlSegmentNamesBeforeId);
    const isNeedCleanup: boolean = detectCleanableUrlPattern.test(url);

    if (!isNeedCleanup) return url;

    // Query Params cleansing used only for cleanable paths.
    let [clearUrl] = url.split('?');

    clearUrl = clearUrl.replace(detectCleanableUrlPattern, (match: string, p1: string, p2: string) => match.replace(match, `${!p2 ? p1.slice(0, -1) : p1}`));

    return clearUrl;
  }

}
