import type { Dictionary } from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';

export const UPGRADE_SUBSCRIPTION: Dictionary<{}> = {
  agent: {
    solo_plan: {
      default_options: [
        {
          text: '80+ marketing banners',
          active: false,
        },
        {
          text: 'Seller lead funnels',
          active: false,
        },
        {
          text: 'Buyer lead funnels',
          active: false,
        },
        {
          text: 'Refinance certificates',
          active: false,
        },
        {
          text: 'Pre-qualification certificates',
          active: false,
        },
        {
          text: 'Pre-mover score',
          active: true,
        },
        {
          text: 'Onboarding and support',
          active: true,
        },
      ],
      details_link: '',
      button_text: 'Select this plan',
    },
    connected_plan: {
      default_options: [
        {
          text: '80+ marketing banners',
          active: true,
        },
        {
          text: 'Refinance website',
          active: true,
        },
        {
          text: 'Niche loan programs',
          active: true,
        },
        {
          text: 'Refinance certificates',
          active: true,
        },
        {
          text: 'Pre-qualification certificates',
          active: true,
        },
        {
          text: 'Pre-mover score',
          active: true,
        },
        {
          text: 'Onboarding and support',
          active: true,
        },
      ],
      details_link: environment.HomeIQLandingAgent,
      button_text: 'Invite your Lender',
    },
  },
  lender: {
    trial: {
      default_options: [
        {
          text: '60+ marketing banners',
          active: false,
        },
        {
          text: 'Refinance website',
          active: false,
        },
        {
          text: 'Niche loan programs',
          active: false,
        },
        {
          text: 'Refinance certificates',
          active: false,
        },
        {
          text: 'Pre-qualification certificates',
          active: false,
        },
        {
          text: 'Pre-mover score',
          active: false,
        },
        {
          text: 'Onboarding and support',
          active: false,
        },
      ],
      details_link: '',
      button_text: '',
    },
    basic_plan: {
      default_options: [
        {
          text: '500 homeowner reports',
          active: true,
        },
        {
          text: 'One realtor connection',
          active: true,
        },
        {
          text: '100+ marketing banners',
          active: true,
        },
        {
          text: 'Refinance website',
          active: true,
        },
        {
          text: 'Niche loan programs',
          active: true,
        },
        {
          text: 'Instant refinance certificates',
          active: true,
        },
        {
          text: 'Instant pre-qualification certificates',
          active: true,
        },
        {
          text: 'Pre-mover score',
          active: true,
        },
        {
          text: 'Onboarding and support',
          active: true,
        },
      ],
      details_link: environment.HomeIQLandingLender,
      button_text: 'Upgrade',
    },
    lender_limited_plan: {
      default_options: [
        {
          text: '100+ marketing banners',
          active: true,
        },
        {
          text: 'Refinance website',
          active: true,
        },
        {
          text: 'Niche loan programs',
          active: true,
        },
        {
          text: 'Instant refinance certificates',
          active: true,
        },
        {
          text: 'Instant pre-qualification certificates',
          active: true,
        },
        {
          text: 'Pre-mover score',
          active: true,
        },
        {
          text: 'Unlimited realtor connections',
          active: true,
        },
        {
          text: 'Onboarding and support',
          active: true,
        },
      ],
      details_link: '',
      button_text: '',
    },
    professional_plan: {
      default_options: [
        {
          text: '80+ marketing banners',
          active: true,
        },
        {
          text: 'Refinance website',
          active: true,
        },
        {
          text: 'Niche loan programs',
          active: true,
        },
        {
          text: 'Refinance certificates',
          active: true,
        },
        {
          text: 'Pre-qualification certificates',
          active: true,
        },
        {
          text: 'Pre-mover score',
          active: true,
        },
        {
          text: 'Onboarding and support',
          active: true,
        },
      ],
      details_link: environment.HomeIQLandingLender,
      button_text: 'Upgrade',
    },
  },
};
