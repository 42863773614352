import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const homeDetailsErrors: FormErrors = {
  year_built: [
    apiError(),
  ],
  home_sq_ft: [
    apiError(),
  ],
  bedrooms: [
    apiError(),
  ],
  bathrooms: [
    apiError(),
  ],
  sale_price: [
    apiError(),
  ],
  sale_date: [
    apiError(),
  ],
  global: [],
};
