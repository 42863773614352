import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormErrors, isPermitted } from 'asap-team/asap-tools';

import type { HistoryPendingCollectionLead } from '@core/types';

// Consts
import { EMAIL_PATTERN, historyPendingLeadErrors, PERMITTED_ACTION, ROUTE } from '@consts/consts';

// Validators
import { phoneValidator } from '@core/validators/phone/phone.validator';

// Services
import { HistoryPendingService } from '@core/services/collections/history-pending/history-pending.service';

@UntilDestroy()
@Component({
  selector: 'history-pending-lead',
  templateUrl: './history-pending-lead.component.html',
  styleUrls: ['./history-pending-lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryPendingLeadComponent implements OnChanges {

  @Input() lead: HistoryPendingCollectionLead;

  @Output() updateEvent: EventEmitter<'import' | 'dismiss'> = new EventEmitter<'import' | 'dismiss'>();

  form: UntypedFormGroup;

  formErrors: FormErrors = historyPendingLeadErrors;

  importAction$: Subscription;

  removeAction$: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private historyPendingService: HistoryPendingService,
  ) {}

  ngOnChanges(): void {
    this.initForm();
  }

  import(): void {
    if (!isPermitted(this.lead.permitted_actions, PERMITTED_ACTION.IMPORT)) {
      this.router.navigate([ROUTE.alias.UPGRADE_SUBSCRIPTION]);

      return;
    }

    this.importAction$ = this
      .historyPendingService
      .import(this.lead.id, this.form.value)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => this.updateEvent.emit('import'));
  }

  removeLead(): void {
    this.removeAction$ = this
      .historyPendingService
      .dismiss(this.lead.id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => this.updateEvent.emit('dismiss'));
  }

  private initForm(): void {
    this.form = this.fb.group({
      owner_name: [this.lead.owner_name, [Validators.required, Validators.minLength(3)]],
      email: [this.lead.email, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      phone: [this.lead.phone, [phoneValidator]],
    });
  }

}
