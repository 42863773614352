import { SimpleModalComponent } from 'ngx-simple-modal';
import {
  Component,
  Inject,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  ElementRef,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CollectionStoreService, Pagination, IntersectionObserverService } from 'asap-team/asap-tools';
import { Observable } from 'rxjs';

// Types
import type { Kudos } from '@core/types';

// Consts
import { KUDOS_COLLECTION } from '@consts/injection-tokens';

// Services
import { KudosService } from '@core/services/collections/kudos/kudos.service';

@UntilDestroy()
@Component({
  templateUrl: './rewards-sidebar.component.html',
  styleUrls: ['./rewards-sidebar.component.scss'],
})
export class RewardsSidebarComponent
  extends SimpleModalComponent<{ userRole: string }, boolean> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('ghost') ghost: ElementRef<HTMLInputElement>;

  rows$: Observable<Kudos[]> = this.collectionService.rows$;

  pagination$: Observable<Pagination> = this.collectionService.pagination$;

  constructor(
    @Inject(KUDOS_COLLECTION) private collectionService: CollectionStoreService<Kudos, KudosService>,
    private intersectionObserverService: IntersectionObserverService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.collectionService.load(0, 16);
  }

  ngAfterViewInit(): void {
    this.initScrollLoading();
  }

  ngOnDestroy(): void {
    this.collectionService.clearFilters(false);
  }

  private initScrollLoading(): void {
    this
      .intersectionObserverService
      .create({
        target: this.ghost.nativeElement,
        isOnlyIntersecting: true,
      })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.collectionService.loadMore();
        },
      );
  }

}
