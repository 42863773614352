<div class="form-error" *ngIf="name" [ngxErrors]="name">
  <div class="form-error__item"
       *ngFor="let error of model"
       [class]="error.name"
       [ngClass]="'error__message'"
       [ngxError]="error.name"
       [when]="error.rules">
    {{ error.text }}
  </div>
</div>
