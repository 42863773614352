<h4 class="title f-16-bold">Members</h4>
<div class="member m-t-16 m-b-16">
  <div class="member--card-block">
    <div class="member--avatar">
      <avatar-placeholder class="size-56"
                          [name]="details?.owner?.name"
                          [image]="details?.owner?.avatar?.medium"
                          type="partner"></avatar-placeholder>
    </div>
    <div class="member--info">
      <div class="name">{{ details?.owner?.name }}</div>
      <div class="company">
        <span>{{ details?.owner?.role }}</span>
      </div>
    </div>
  </div>
  <div class="member--control">
    <div class="date-sent">OWNER</div>
  </div>
</div>
<div class="p-l-30">
  <div class="flex align-items-center bordered p-t-17 p-b-17">
    <div class="flex-1 f-11-normal cell-title">CURRENT + IMPORTED LEADS:</div>
    <div class="flex-1 text-right f-16-normal">
      {{ details?.owner?.current_leads }} + {{ details?.owner?.imported_leads }}
    </div>
  </div>
</div>
<div class="p-l-30" *ngIf="details?.owner?.plan_name">
  <div class="flex align-items-center bordered p-t-17 p-b-17">
    <div class="flex-1 f-11-normal cell-title">ESTIMATED MONTHLY PAYMENT:</div>
    <div class="flex-1 text-right f-16-normal">{{ details?.owner?.amount }}$ + {{ details?.owner?.contacts_fee }}$</div>
  </div>
</div>
<div class="p-l-30" *ngIf="details?.owner?.plan_name">
  <div class="flex align-items-center bordered p-t-17 p-b-17">
    <div class="flex-1 f-11-normal cell-title">CURRENT PLAN:</div>
    <div class="flex-1 text-right f-16-normal">{{ details?.owner?.plan_name }}</div>
  </div>
</div>
<div class="p-l-30" *ngIf="!details?.owner?.plan_name">
  <div class="flex align-items-center bordered p-t-17 p-b-17">
    <div class="flex-1 f-11-normal cell-title">ESTIMATED COST:</div>
    <div class="flex-1 text-right f-16-normal">Free</div>
  </div>
</div>
<div class="upgrade-plan text-center m-t-16 m-b-16" *ngIf="details?.owner?.upgrade_needed">
  Need to&#32;<button class="btn link" type="button" (click)="upgradePlan(details?.owner?.id)">upgrade plan</button>&#32;to {{ details?.owner?.next_plan_title }}
</div>
<ng-container *ngIf="details?.partner">
  <div class="member m-t-16 m-b-16">
    <div class="member--card-block">
      <div class="member--avatar">
        <avatar-placeholder class="size-56"
                            [name]="details?.partner?.name"
                            [image]="details?.partner?.avatar?.medium"
                            type="partner"></avatar-placeholder>
      </div>
      <div class="member--info">
        <div class="name">{{ details?.partner?.name }}</div>
        <div class="company"><span>{{ details?.partner?.role }}</span></div>
      </div>
    </div>
    <div class="member--control">
      <div class="date-sent">PARTNER</div>
    </div>
  </div>
  <div class="p-l-30">
    <div class="flex align-items-center bordered p-t-17 p-b-17">
      <div class="flex-1 f-11-normal cell-title">CURRENT + IMPORTED LEADS:</div>
      <div class="flex-1 text-right f-16-normal">
        {{ details?.partner?.current_leads }} + {{ details?.partner?.imported_leads }}
      </div>
    </div>
  </div>
  <div class="p-l-30" *ngIf="details?.partner?.plan_name">
    <div class="flex align-items-center bordered p-t-17 p-b-17">
      <div class="flex-1 f-11-normal cell-title">ESTIMATED MONTHLY PAYMENT:</div>
      <div class="flex-1 text-right f-16-normal">
        {{ details?.partner?.amount }}$ + {{ details?.partner?.contacts_fee }}$
      </div>
    </div>
  </div>
  <div class="p-l-30" *ngIf="details?.partner?.plan_name">
    <div class="flex align-items-center bordered p-t-17 p-b-17">
      <div class="flex-1 f-11-normal cell-title">CURRENT PLAN:</div>
      <div class="flex-1 text-right f-16-normal">{{ details?.partner?.plan_name }}</div>
    </div>
  </div>
  <div class="p-l-30" *ngIf="!details?.partner?.plan_name">
    <div class="flex align-items-center bordered p-t-17 p-b-17">
      <div class="flex-1 f-11-normal cell-title">ESTIMATED COST:</div>
      <div class="flex-1 text-right f-16-normal">Free</div>
    </div>
  </div>
  <div class="upgrade-plan text-center m-t-16 m-b-16" *ngIf="details?.partner?.upgrade_needed">
    Need to&#32;<button class="btn link" type="button" (click)="upgradePlan(details?.partner?.id)">upgrade plan</button>
    &#32;to {{ details?.partner?.next_plan_title }}
  </div>
</ng-container>
