import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import type {
  LandingSettings,
  HomeownerReportSettings,
} from '@core/types';
import { map } from 'rxjs/operators';
import { HOMEOWNER_LANDING_API } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class HomeownerSettingsService {

  private landingApiUrl: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  get getLandingApiUrl(): string {
    return this.landingApiUrl.getValue();
  }

  setLandingApiUrl(urlSegment: string): void {
    this.landingApiUrl.next(urlSegment);
  }

  getLandingSettings(): Observable<LandingSettings> {
    return this.http.get<LandingSettings>(`v2/${this.getLandingApiUrl}/landing`);
  }

  getReportSettings(): Observable<HomeownerReportSettings> {
    return this.http.get<HomeownerReportSettings>('v2/profile/white_labels');
  }

  activateReportSettings(): Observable<null> {
    return this.http.post<null>('v2/profile/white_labels', null);
  }

  uploadReportLogo(formData: FormData): Observable<string> {
    return this.http
      .patch<string>('v2/profile/white_labels', formData)
      .pipe(map((response: any) => response?.report_logo));
  }

  deleteReportLogo(): Observable<void> {
    return this.http.delete<void>('v2/profile/white_labels');
  }

  uploadLogo(formData: FormData): Observable<string> {
    return this.http.patch<string>(
      `v2/${this.getLandingApiUrl}/landing/logo`,
      formData,
    );
  }

  uploadBackgroundImage(formData: FormData): Observable<string> {
    return this.http.patch<string>(
      `v2/${this.getLandingApiUrl}/landing/background`,
      formData,
    );
  }

  deleteLogo(): Observable<void> {
    return this.http.delete<void>(`v2/${this.getLandingApiUrl}/landing/logo`);
  }

  deleteBackgroundImage(): Observable<void> {
    return this.http.delete<void>(`v2/${this.getLandingApiUrl}/landing/background`);
  }

  updateLandingTheme(theme: string): Observable<void> {
    let url: string;

    if (this.getLandingApiUrl === HOMEOWNER_LANDING_API.HOME_WEALTH) {
      url = `${this.getLandingApiUrl}/landing`;
    } else {
      url = this.getLandingApiUrl;
    }

    return this.http.patch<void>(`v2/${url}/theme`, { theme });
  }

}
