<div class="intelligence-details-card">
  <div class="intelligence-details-card__header">
    <ng-content select="[headerSlot]"></ng-content>
  </div>
  <div class="intelligence-details-card__content content-row">
    <div class="chart">
      <donut-chart [data]="loanTypesChartData">
        <div class="text-center">
          <p class="f-12-400 f-c-slate l-h-18">Total Loans</p>
          <p class="f-24-600 f-c-gray-main l-h-36">{{ total }}</p>
        </div>
      </donut-chart>
    </div>
    
    <div class="stats">
      <ng-content select="[statsSlot]"></ng-content>
    </div>
  </div>
</div>
