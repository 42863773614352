<h2 class="f-20-bold f-c-charcoal-grey m-b-24">Order Summary</h2>
<order-summary class="m-b-40" [checkoutInfo]="checkoutInfo || checkout"></order-summary>
<ng-container>
  <h2 class="f-20-bold f-c-charcoal-grey m-t-24">Promo Code</h2>
  <apply-promo-code [planName]="plan?.name"
                    [checkout]="checkout || checkoutInfo"
                    (checkoutUpdate)="updateCheckout($event)"></apply-promo-code>
</ng-container>
<h2 class="f-20-bold f-c-charcoal-grey m-b-16 m-t-55">Payment method</h2>
<payment-methods-list [isEditModeOn]="false"
                      [currentCardId]="card?.id"
                      (select)="selectCard($event)"
                      (add)="addCardModal($event)"></payment-methods-list>
<iq-button class="block m-t-5x"
           label="Purchase order"
           [disabled]="!card?.id"
           [busy$]="action"
           (onClick)="submit()"></iq-button>
