import type { Dictionary } from 'asap-team/asap-tools';
import type { Alert } from '@core/types';

export const ALERTS_TYPE: Dictionary<Alert> = {
  CONFIRM: 'confirm',
  PENDING_INVITE: 'pending-invite',
  UPGRADE_SUBSCRIPTION: 'upgrade-subscription',
  PAUSED_SUBSCRIPTION: 'subscription-paused',
  PAYMENT_PROBLEM: 'payment-problem',
  MONITORING_PROGRAM: 'subscription-monitoring-program',
};
