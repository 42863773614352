<div *ngIf="dateFrom" class="discount-countdown__clock p-b-2x">
  <ng-container *ngIf="days > 0">
    <div class="discount-countdown__count">
      <p class="f-40-700 l-h-32">{{ days | pad }}</p>
      <p class="f-12-600 l-h-18">DAYS</p>
    </div>
    <p class="discount-countdown__dots f-40-700 l-h-32">:</p>
  </ng-container>
  <div class="discount-countdown__count">
    <p class="f-40-700 l-h-32">{{ hours | pad }}</p>
    <p class="f-12-600 l-h-18">HOURS</p>
  </div>
  <p class="discount-countdown__dots f-40-700 l-h-32">:</p>
  <div class="discount-countdown__count">
    <p class="f-40-700 l-h-32">{{ minutes | pad }}</p>
    <p class="f-12-600 l-h-18">MINUTES</p>
  </div>
  <p class="discount-countdown__dots f-40-700 l-h-32">:</p>
  <div class="discount-countdown__count">
    <p class="f-40-700 l-h-32">{{ seconds | pad }}</p>
    <p class="f-12-600 l-h-18">SECONDS</p>
  </div>
</div>
