import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { map, take } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { Profile } from '@core/types';

// Consts
import { PROFILE_STATUS, ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class AccountStatusGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  private doCheck(): Observable<boolean> {
    return this
      .userService
      .profile$
      .pipe(
        take(1),
        map((profile: Profile) => {
          const { status, blocked_type } = profile;

          if (status === PROFILE_STATUS.BLOCKED) {
            this.router.navigate([ROUTE.name.RESTRICTED_ACCESS], { state: { blocked_type } });

            return false;
          }

          return true;
        }),
      );
  }

  canLoad(): Observable<boolean> {
    return this.doCheck();
  }

  canActivate(): Observable<boolean> {
    return this.doCheck();
  }

}
