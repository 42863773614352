<div class="currency-filter" dropdown="" [insideClick]="true">
  <iq-button dropdownToggle
             label="{{ selected || title }}"
             size="40"
             color="grey"
             iconRight="#icon-chevron"></iq-button>
  <div class="currency-filter__menu dropdown-menu" *dropdownMenu [formGroup]="form">
    <iq-form-control-amount class="m-t-2x m-b-1x"
                            label="Min"
                            placeholder="Min"
                            formControlName="min"
                            [errorsModel]="formErrors.min"></iq-form-control-amount>
    <iq-form-control-amount class="m-b-2x"
                            label="Max"
                            placeholder="Max"
                            formControlName="max"
                            [errorsModel]="formErrors.max"></iq-form-control-amount>
    <iq-button class="m-b-1x"
               label="Clear"
               size="24"
               color="grey"
               iconRight="#icon-close"
               (onClick)="clearValues()"></iq-button>
  </div>
</div>
