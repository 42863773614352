// eslint-disable-next-line max-classes-per-file
import { TrialUsersCollectionFilters, TrialUsersCollectionSort } from '@modules/admin/collections/users/trial/state/trial-users.model';
import { Company } from '@core/types';

export namespace TrialUsersCollectionActions {

  export class GetTrialUsersCollection {

    static get type(): string {
      return '[TrialUsersCollection] Get TrialUsers Collection';
    }

    constructor(
      public firstLoad?: boolean,
    ) {
    }

  }

  export class ReloadTrialUsersCollection {

    static get type(): string {
      return '[TrialUsersCollection] Reload TrialUsers Collection';
    }

  }

  export class ResetTrialUsersCollection {

    static get type(): string {
      return '[TrialUsersCollection] Reset TrialUsers Collection';
    }

  }

  export class EmitChangePageTrialUsersCollection {

    static get type(): string {
      return '[TrialUsersCollection] Emit Change Page Number TrialUsers Collection';
    }

    constructor(public offset: number) {
    }

  }

  export class EmitSortTrialUsersCollection {

    static get type(): string {
      return '[TrialUsersCollection] Emit Sort TrialUsers Collection';
    }

    constructor(public payload: TrialUsersCollectionSort) {
    }

  }

  export class EmitFiltersUpdateTrialUsersCollection {

    static get type(): string {
      return '[TrialUsersCollection] Emit Filters Update TrialUsers Collection';
    }

    constructor(public payload: Partial<TrialUsersCollectionFilters>) {
    }

  }

  export class EmitClearFiltersTrialUsersCollection {

    static get type(): string {
      return '[TrialUsersCollection] Emit Clear Filters TrialUsers Collection';
    }

  }

  export class SetCompanyState {

    static get type(): string {
      return '[TrialUsersCollection] Set company state';
    }

    constructor(
      public company: Company,
    ) {
    }

  }

}
