import { isEqual } from 'lodash-es';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalComponent } from 'ngx-simple-modal';
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {
  distinctUntilChanged,
  debounceTime,
  filter,
} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dictionary, IntersectionObserverService, Page } from 'asap-team/asap-tools';

import type { LookUpPartner, UserRole } from '@core/types';

// Consts
import { USER_ROLE, DEFAULT_PAGE_CONFIG, USER_LABEL, PARTNERSHIP_SIDEBAR_RESULT } from '@consts/consts';

// Services
import { PartnershipService } from '@core/services/partnership/partnership.service';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { UserService } from '@core/services/user/user.service';

// State
import { SearchPartnerSidebarActions } from './state/search-partner-sidebar.actions';
import { SearchPartnerSidebarState } from './state/search-partner-sidebar.state';

@UntilDestroy()
@Component({
  templateUrl: './search-partner-sidebar.component.html',
  styleUrls: ['./search-partner-sidebar.component.scss'],
})
export class SearchPartnerSidebarComponent
  extends SimpleModalComponent<{ userRole: string }, { type: string | boolean; query?: string }>
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('search', { static: true }) search: ElementRef<HTMLDivElement>;

  @ViewChild('collection') collection: ElementRef<HTMLInputElement>;

  @ViewChild('ghost') ghost: ElementRef<HTMLDivElement>;

  @Select(SearchPartnerSidebarState.rows) rows$: Observable<LookUpPartner[]>;

  @Select(SearchPartnerSidebarState.loading) loading$: Observable<boolean>;

  userRole: string = '';

  form: UntypedFormGroup = null;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  USER_LABEL: any = USER_LABEL;

  DEBOUNCE_TIME: number = 500;

  RESULT: Dictionary = PARTNERSHIP_SIDEBAR_RESULT;

  partners: LookUpPartner[] = [];

  page: Page = DEFAULT_PAGE_CONFIG;

  constructor(
    private fb: UntypedFormBuilder,
    private partnerService: PartnershipService,
    private intersectionObserverService: IntersectionObserverService,
    private asaplyticsService: AsaplyticsService,
    private userService: UserService,
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({ query: [''] });

    if (this.search) {
      (this.search as any).getNativeElementRef().nativeElement.focus();
    }

    this.initCollection();
  }

  ngAfterViewInit(): void {
    this.sendGAEvent('sidebar_view');

    // LoadMore events
    this.intersectionObserverService.create({
      root: this.collection.nativeElement,
      target: this.ghost.nativeElement,
      isOnlyIntersecting: true,
    })
      .pipe(
        filter(() => !!this.queryValue),
        untilDestroyed(this),
      )
      .subscribe(() => {
        const { page, total_pages } = this.store.selectSnapshot(SearchPartnerSidebarState.pagination);

        if (page !== total_pages) {
          this.store.dispatch(new SearchPartnerSidebarActions.LoadMoreAction());
        }
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SearchPartnerSidebarActions.ResetState());
  }

  get queryValue(): string {
    return this.form.get('query')?.value || '';
  }

  private initCollection(): void {
    this.form.get('query')
      .valueChanges
      .pipe(
        debounceTime(this.DEBOUNCE_TIME),
        distinctUntilChanged(isEqual),
        untilDestroyed(this),
      )
      .subscribe(
        (value: string) => {
          if (value) {
            this.store.dispatch(new SearchPartnerSidebarActions.LookupPartners(value));
          } else {
            this.store.dispatch(new SearchPartnerSidebarActions.ResetState());
          }
        },
      );
  }

  closeModal(result?: string): void {
    this.result = { type: result, query: this.form.value.query };
    this.close();
  }

  emitResult(): void {
    this.result = { type: PARTNERSHIP_SIDEBAR_RESULT.update };
  }

  sendGAEvent(label: string): void {
    this.asaplyticsService.sendEvent({
      action: `invite_by_${this.userService.getUserRole()}`,
      category: 'Invite_sidebar',
      label,
    });
  }

}
