import type { NavItem } from '@core/types';

import { ROUTE, USER_ROLE } from '@consts/consts';

export const Partners: NavItem[] = [
  {
    label: 'Enterprise Partners',
    routerLink: ROUTE.alias.PARTNERS_ENTERPRISE,
    counter: null,
    roles: [USER_ROLE.sales, USER_ROLE.owner],
    permitted_action: null,
    icon: null,
  },
];
