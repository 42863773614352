import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SimpleModalComponent } from 'ngx-simple-modal';

import type { Profile } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@Component({
  templateUrl: './confirm-email-modal.component.html',
  styleUrls: ['./confirm-email-modal.component.scss'],
})
export class ConfirmEmailModalComponent extends SimpleModalComponent<null, null> {

  profile$: Observable<Profile> = this.userService.profile$;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    super();
  }

  goToProfile(): void {
    this.router.navigate([ROUTE.alias.PROFILE]);
    this.close();
  }

}
