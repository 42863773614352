import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash-es';
import { parseISO } from 'date-fns';
import { BaseHttpService } from 'asap-team/asap-tools';

import type { Lead, Loan, LoanFormPayload, UpdateLeadHomeDetailsForm } from '@core/types';

@Injectable({ providedIn: 'root' })
export class LeadDetailsService {

  private lead: BehaviorSubject<Lead> = new BehaviorSubject<Lead>(null);

  lead$: Observable<Lead> = this.lead.asObservable().pipe(
    distinctUntilChanged(isEqual),
  );

  constructor(
    private http: BaseHttpService,
  ) {}

  getLead(): Lead {
    return this.lead.value;
  }

  emitLeadUpdate = (lead: Lead | null): Lead => {
    if (!lead) {
      this.lead.next(null);

      return lead;
    }

    const clone: Lead = cloneDeep(lead);

    const updatedLead: Lead = {
      ...clone,
      sale_date: clone.sale_date ? parseISO(clone.sale_date).toISOString() : null,
      transactions: clone.transactions?.map((loan: Loan) => {
        return {
          ...loan,
          loan_date: loan.loan_date ? parseISO(loan.loan_date).toISOString() : null,
        };
      }) || [],
    };

    this.lead.next(updatedLead);

    return updatedLead;
  };

  emitClosingFeesUpdate = (closingFeesData: Partial<Lead>): Lead => {
    const updatedLead: Lead = {
      ...this.lead.value,
      selling: closingFeesData.selling,
      refinance: closingFeesData.refinance,
    };

    this.lead.next(updatedLead);

    return updatedLead;
  };

  clearLead(): void {
    this.lead.next(null);
  }

  addNewLoan(uid: string, payload: LoanFormPayload): Observable<Lead> {
    return this
      .http
      .post(`v2/seller_digest/leads/${uid}/loans`, { loan_data: { ...payload } })
      .pipe(
        tap((lead: Lead) => { this.emitLeadUpdate(lead); }),
      );
  }

  updateLoan(uid: string, loan_uid: string, payload: LoanFormPayload): Observable<Lead> {
    return this
      .http
      .patch(`v2/seller_digest/leads/${uid}/loans/${loan_uid}`, { loan_data: { ...payload } })
      .pipe(
        tap((lead: Lead) => { this.emitLeadUpdate(lead); }),
      );
  }

  deleteLoan(uid: string, loan_uid: string): Observable<Lead> {
    return this
      .http
      .delete(`v2/seller_digest/leads/${uid}/loans/${loan_uid}`)
      .pipe(
        tap((lead: Lead) => { this.emitLeadUpdate(lead); }),
      );
  }

  updateLoanExpiredDate(id: string, hide_refi_block_expired_date: string): Observable<Lead> {
    return this
      .http
      .patch(`v2/leads/${id}/hide_refi_block_expired_date`, { hide_refi_block_expired_date })
      .pipe(
        tap((lead: Lead) => { this.emitLeadUpdate(lead); }),
      );
  }

  updateLeadImage(id: string, formData: FormData): Observable<Lead> {
    return this
      .http
      .patch(`v2/leads/${id}/image`, formData).pipe(
        map(this.emitLeadUpdate),
      );
  }

  editLeadHomeDetails(id: string, homeDetails: UpdateLeadHomeDetailsForm): Observable<Lead> {
    const homeDetailsFormData: FormData = new FormData();

    Object.keys(homeDetails).forEach((key: string) => {
      homeDetailsFormData.append(key, homeDetails[key]);
    });

    return this
      .http
      .patch(`v2/leads/${id}/home_details`, homeDetailsFormData).pipe(
        map(this.emitLeadUpdate),
      );
  }

}
