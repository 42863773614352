<ng-container *ngIf="row?.name && row?.company_name; else noFullInfo">
  <div class="user">
    <avatar-placeholder class="size-40 f-14-bold f-c-slate"
                        [formattedInitials]="row?.initials"
                        [image]="row?.avatar_url?.medium"></avatar-placeholder>
    <div *ngIf="row?.internal_user" class="internal-user-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.48597 3.44321C2.34814 3.38505 2.20074 3.33764 2.04129 3.29509C2.01245 3.28758 1.99201 3.30054 1.97936 3.32586C1.97745 3.32969 1.97656 3.33411 1.97656 3.33838V9.21078C1.97656 9.21519 1.97745 9.21946 1.97936 9.22329C1.99201 9.24876 2.01245 9.26172 2.04129 9.25406C2.20074 9.21151 2.34814 9.1641 2.48597 9.10594C2.7328 9.00199 2.96757 8.83723 3.13571 8.64994C3.15086 8.63315 3.15336 8.62005 3.15336 8.6118V3.93735C3.15336 3.92925 3.15086 3.91615 3.13571 3.89936C2.96757 3.71193 2.7328 3.54716 2.48597 3.44321ZM9.14241 7.87955C9.44793 7.41059 9.62357 6.84945 9.61783 6.24768C9.60239 4.64217 8.2882 3.33673 6.68422 3.33305C5.03597 3.32937 3.69825 4.69002 3.73752 6.34766C3.77503 7.92711 5.06377 9.20118 6.6423 9.21973C6.88663 9.22268 7.12435 9.19544 7.3522 9.1417C7.37839 9.13551 7.38751 9.10297 7.36853 9.08398L6.48682 8.20156C6.36781 8.08215 6.22351 7.99175 6.06552 7.933C5.39342 7.68357 4.913 7.03512 4.913 6.27654C4.913 5.27957 5.74264 4.47284 6.74718 4.51171C7.66832 4.54749 8.41088 5.29606 8.44074 6.21823C8.45001 6.50344 8.39117 6.77421 8.27937 7.01569L7.96252 6.69853C7.82528 6.56116 7.66303 6.45441 7.48665 6.3827C7.13994 6.23251 6.73188 6.14682 6.33972 6.13769C6.09053 6.1318 5.83325 6.14859 5.56053 6.1801L5.55891 6.18024C5.49684 6.18849 5.47198 6.26579 5.5164 6.31011L8.33042 9.12712C8.47001 9.26655 8.6355 9.37389 8.8154 9.44442C9.15888 9.59004 9.56046 9.67294 9.9466 9.68148C10.1956 9.68708 10.4529 9.67014 10.7258 9.63849C10.7521 9.63525 10.7714 9.62435 10.784 9.60756C10.8054 9.57929 10.8004 9.53895 10.7755 9.51392L9.14241 7.87955Z" fill="white"/>
      </svg>
    </div>
    
    <div class="user-info">
      <div class="user-name f-14-400 l-h-22 f-c-gray-main text-overflow"
           [tooltip]="row?.name"
           placement="top left"
           container="body">{{ row?.name }}</div>
      <div class="f-12-400 l-h-18 f-c-steel text-overflow"
           [tooltip]="row?.company_name"
           placement="top left"
           container="body">{{ row?.company_name }}</div>
    </div>
  </div>
</ng-container>

<ng-template #noFullInfo>
  <ng-container [ngSwitch]="row?.loan_type">
    <div *ngSwitchCase="'refinance'" class="no-data-cell">
      <img src="assets/components/collections/intelligence/percent-icon.svg"/>
      <span class="f-14-400 f-c-gray-main l-h-22">Refinance</span>
    </div>
    <div *ngSwitchCase="'purchase'" class="no-data-cell"
         [tooltip]="'Some data may become available with delays.'"
         placement="top"
         container="body">
      <img src="assets/components/collections/intelligence/sync.svg"/>
      <span class="f-14-400 l-h-22 f-c-steel-light">Pending</span>
    </div>
    <div *ngSwitchCase="'other'"
         class="no-data-cell"
         [tooltip]="'This could be equity loan, HELOC, or other type of non-purchase loan'"
         placement="top"
         container="body">
      <img src="assets/components/collections/intelligence/cash-icon.svg"/>
      <span class="f-14-400 f-c-gray-main l-h-22">Other loan</span>
    </div>
  </ng-container>
</ng-template>
