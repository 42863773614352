import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { addressErrors, required } from './conditions';

export const viralSearchErrors: FormErrors = {
  address: [
    required,
    ...addressErrors,
  ],
  global: [],
};
