/* eslint-disable */
/**
 * Apply list of validators to formControl according to positive result of provided condition
 */

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import type { Dictionary } from 'asap-team/asap-tools';

export function conditionalValidator(
  predicate: () => boolean,
  validators: Dictionary<ValidatorFn>,
): (formControl: AbstractControl) => ValidationErrors {
  return ((formControl: AbstractControl) => {
    if (!predicate()) { return null; }

    const keys = Object.keys(validators);

    for (let i = 0; i < keys.length; i += 1) {
      const validator = validators[keys[i]];
      const result = validator(formControl);

      if (result) {
        return { [keys[i]]: true };
      }
    }

    return null;
  });
}
