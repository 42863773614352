import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import type { Profile } from '@core/types';

import { Dictionary, isPermitted, ModalService } from 'asap-team/asap-tools';

// Consts
import { ROUTE, PERMITTED_ACTION, FEATURES } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { FeatureFlagsService } from '@core/services/feature-flags/feature-flags.service';

// Components
import { CompleteProfileComponent } from '@commons/modals/complete-profile/complete-profile.component';

@Component({
  selector: 'create-report-btn',
  templateUrl: './create-report-btn.component.html',
  styleUrls: ['./create-report-btn.component.scss'],
})
export class CreateReportBtnComponent {

  PERMITTED_ACTION: Dictionary<string> = PERMITTED_ACTION;

  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  action(): void {
    this.userService.profile$
      .pipe(
        take(1),
        switchMap((profile: Profile) => {
          if (
            isPermitted(profile.permitted_actions, [
              PERMITTED_ACTION.CREATE_DRAFT_NEED_COMPLETE_PROFILE,
            ])
          ) {
            return this.modalService.open(CompleteProfileComponent);
          }

          return of(profile);
        }),
        filter(Boolean),
      )
      .subscribe((profile: Profile) => {
        let permitedActions: string[];
        let createReportRoute: string;

        if (
          this.featureFlagsService.isFeatureEnabled(FEATURES.NEW_REPORT_FLOW)
        ) {
          permitedActions = [PERMITTED_ACTION.CREATE_DRAFT];
          createReportRoute = ROUTE.alias.HOMEOWNERS_REPORT_NEW;
        } else {
          permitedActions = [
            PERMITTED_ACTION.CREATE_FREE,
            PERMITTED_ACTION.CREATE_PAID,
          ];
          createReportRoute = ROUTE.alias.HOMEOWNERS_LEAD_NEW;
        }

        const url: any = isPermitted(profile.permitted_actions, permitedActions) ? createReportRoute : ROUTE.alias.UPGRADE_SUBSCRIPTION;

        this.router.navigate([url]);
      });
  }

}
