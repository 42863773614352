import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { VaultService } from 'asap-team/asap-tools';

@Injectable({ providedIn: 'root' })
export class AuthTokenInterceptor implements HttpInterceptor {

  private config: string[] = [
    'assets',
    `${environment.api}/v1`,
    'vimeo',
    'amazonaws',
  ];

  constructor(
    private vaultService: VaultService,
  ) {}

  intercept(
    httpRequest: HttpRequest<any>,
    httpHandler: HttpHandler,
  ): Observable<HttpEvent<any>> {

    const shouldBypass: boolean = this.config.some((url: string) => httpRequest.url.includes(url));

    if (!shouldBypass) {
      const token: string = this.vaultService.get('jwt');

      return httpHandler
        .handle(
          httpRequest.clone({
            setHeaders: {
              ...!!token && { jwt: token },
              ...(httpRequest.method === 'POST') && { 'ngsw-bypass': 'true' },
            },
          }),
        );
    }

    return httpHandler.handle(httpRequest);
  }

}
