<div class="ftl-header">
  <img class="ftl-logo" src="assets/common/logo/my-homeiq-logo-dark.svg">
  <progress-line class="ftl-progress" [value]="ftlProgress$ | async"></progress-line>
</div>
<div class="steps">
  <ng-container *ngIf="currentStep$ | async as step">
    <ftl-pass-step *ngIf="step === FTL_STEP_NAME.enter_password"></ftl-pass-step>
    <ftl-agent-profile *ngIf="step === FTL_STEP_NAME.agent_completed_profile"></ftl-agent-profile>
    <ftl-lender-profile *ngIf="step === FTL_STEP_NAME.lender_completed_profile"></ftl-lender-profile>
    <ftl-demo *ngIf="step === FTL_STEP_NAME.demo_book"></ftl-demo>
    <agent-invited *ngIf="step === FTL_STEP_NAME.agent_invited"></agent-invited>
    <all-set *ngIf="step === FTL_STEP_NAME.all_set"></all-set>
  </ng-container>
</div>
