import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import momentMini, { Duration, Moment } from 'moment-mini';
import { Subscription, interval } from 'rxjs';
import { REFERRAL_COUNTDOWN_HOURS } from '@consts/consts';

@Component({
  selector: 'discount-countdown',
  templateUrl: './discount-countdown.component.html',
  styleUrls: ['./discount-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountCountdownComponent implements OnChanges, OnDestroy {

  @Input() dateFrom: string;

  timer: Subscription;

  downtimeEndTime: Moment;

  days: number = 0;

  hours: number = 0;

  minutes: number = 0;

  seconds: number = 0;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateFrom.currentValue) {
      const momentDateFrom: Moment = momentMini(changes.dateFrom.currentValue);

      this.downtimeEndTime = momentMini(momentDateFrom).add(REFERRAL_COUNTDOWN_HOURS, 'hours');
      this.calculateTimeRemaining();
    }
  }

  ngOnDestroy(): void {
    if (this.timer) {
      this.timer.unsubscribe();
    }
  }

  private calculateTimeRemaining(): void {
    this.recalculateTime(this.downtimeEndTime);
    this.timer = interval(1000).subscribe(() => this.recalculateTime(this.downtimeEndTime));
  }

  recalculateTime(endTime: Moment): void {
    const currentTime: Moment = momentMini();
    const time: number = endTime.diff(currentTime);

    const timeDuration: Duration = momentMini.duration(time);

    if (timeDuration.asMilliseconds() <= 0) {

      if (this.timer) {
        this.timer.unsubscribe();
      }

      this.changeDetectorRef.markForCheck();

      return;
    }

    if (timeDuration.asHours() >= 24) {
      this.days = Math.floor(timeDuration.asDays());
    } else {
      this.days = 0;
    }

    this.hours = timeDuration.hours();
    this.minutes = timeDuration.minutes();
    this.seconds = timeDuration.seconds();

    this.changeDetectorRef.markForCheck();
  }

}
