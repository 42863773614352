import { Component, Input } from '@angular/core';

import type { CheckoutInfo, CheckoutPayment } from '@core/types';

@Component({
  selector: 'order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent {

  PRORATED_ADJUSTMENT: string = 'prorated adjustment';

  ADDED_TO_ACCOUNT_BALANCE: string = 'added to your account balance';

  totalValues: string[] = [this.PRORATED_ADJUSTMENT, this.ADDED_TO_ACCOUNT_BALANCE];

  @Input() checkoutInfo: CheckoutInfo;

  get info(): CheckoutPayment[] {
    const result: CheckoutPayment[] = [];

    this.checkoutInfo?.order_summary.forEach((element: CheckoutPayment) => {
      if (element.frequency && !this.isTotalListItem(element)) {
        result.push(element);
      }
    });

    return result || [];
  }

  get total(): CheckoutPayment[] {
    const result: CheckoutPayment[] = [];

    this.checkoutInfo?.order_summary.forEach((element: CheckoutPayment) => {
      if (!element.frequency || this.isTotalListItem(element)) {
        result.push(element);
      }
    });

    return result || [];
  }

  get tooltipText(): string {
    return 'Amount credited to your myhomeIQ inner balance, available for subscription payments.';
  }

  isTotalListItem(item: CheckoutPayment): boolean {
    return item.title && this.totalValues.includes(item.title.toLowerCase());
  }

}
