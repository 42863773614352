import { Component, Input } from '@angular/core';

import type { NavItem } from '@core/types/misc';

@Component({
  selector: 'desktop-nav',
  templateUrl: './desktop-nav.component.html',
  styleUrls: ['./desktop-nav.component.scss'],
})
export class DesktopNavComponent {

  @Input() navigation: NavItem[];

}
