<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Delete card</h4>
    <div class="close" aria-label="Close" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <div class="modal-body__text">
      Are you sure you want to delete this card? You may have active subscriptions connected to this card.
    </div>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block"
                   type="submit"
                   label="Delete card"
                   color="red"
                   [busy$]="action"
                   (onClick)="submit()"></iq-button>
      </div>
      <div class="col col-fix">
        <iq-button class="block" label="Keep card" (onClick)="close()"></iq-button>
      </div>
    </div>
  </div>
</div>
