import { last } from 'lodash-es';
import {
  catchError, filter, tap, map, take, switchMap,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges, ViewChild, ElementRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { APIError, Profile } from '@core/types';

// Consts
import { COMMON_TOAST } from '@consts/consts';

// Components
import { CropAvatarComponent } from '@commons/modals/crop-avatar/crop-avatar.component';

// Services
import { BuyerSettingsService } from '@core/services/settings/buyer-settings/buyer-settings.service';
import { HomeownerSettingsService } from '@core/services/settings/homeowner-settings/homeowner-settings.service';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';
import { UserService } from '@core/services/user/user.service';

@UntilDestroy()
@Component({
  selector: 'landing-logo',
  templateUrl: './landing-logo.component.html',
  styleUrls: ['./landing-logo.component.scss'],
})
export class LandingLogoComponent implements OnInit, OnChanges {

  @ViewChild('nativeInput') nativeInput: ElementRef;

  @Input() image: string;

  @Input() type: string;

  @Output() imageChanged: EventEmitter<any> = new EventEmitter();

  fileName: string;

  fileError: APIError<string>[];

  isFileLoading: boolean = false;

  constructor(
    private toastr: ToastrService,
    private buyerSettingsService: BuyerSettingsService,
    private homeownerSettingsService: HomeownerSettingsService,
    private iqModalService: IqModalService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    if (this.image) {
      this.setFileName(this.image);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.image && changes.image.currentValue) {
      this.setFileName(changes.image.currentValue);
    }
  }

  onImageSelected(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target.files && target.files.length > 0) {
      const fileName: string = (Math.ceil(event.timeStamp)) + target.files[0].name;

      this
        .iqModalService
        .open(
          CropAvatarComponent,
          {
            imageChangedEvent: event,
            roundCropper: false,
            maintainAspectRatio: false,
            hideResizeSquares: false,
            cropWidth: 200,
            cropHeight: 40,
            typo: {
              title: 'Crop Logo',
              subtitle: 'Make the frame to fit the logo by width or height.',
              confirm_action_label: 'Save',
            },
          },
          { position: 'top', disableClose: true },
        )
        .closed
        .pipe(
          filter((value: Blob) => !!value),
          tap(() => {
            this.nativeInput.nativeElement.value = null;
          }),
          untilDestroyed(this),
        )
        .subscribe((image: Blob) => {
          this.upload(image, fileName);
        });
    }
  }

  private getUploadObservable(type: string, formData: FormData): Observable<string> {
    switch (type) {
      case 'profile': {
        return this.userService.profile$.pipe(
          take(1),
          switchMap((profile: Profile) => this.handleProfileLogoUpload(profile, formData)),
        );
      }
      case 'buyer': {
        return this.buyerSettingsService.uploadLogo(formData);
      }
      case 'homeowner-report': {
        return this.homeownerSettingsService.uploadReportLogo(formData);
      }
      default: {
        return this.homeownerSettingsService.uploadLogo(formData);
      }
    }
  }

  private handleProfileLogoUpload(profile: Profile, formData: FormData): Observable<string> {
    this.addProfileDataToFormData(profile, formData);

    return this.userService.updateProfile(formData)
      .pipe(
        map((updatedProfile: Profile) => updatedProfile.report_logo),
      );
  }

  private addProfileDataToFormData(profile: Profile, formData: FormData): void {
    formData.append('name', profile.name);
    formData.append('email', profile.email);
    formData.append('phone', profile.phone);
    formData.append('time_zone', profile.time_zone);
    formData.append('license_number', profile.license_number);
    formData.append('company_name', profile.company_name);
    formData.append('company_license_number', profile.company_license_number);
    formData.append('license_states', profile.license_states.join(','));
  }

  private getUploadFieldName(type: string): string {
    switch (type) {
      case 'profile':
      case 'homeowner-report': {
        return 'report_logo';
      }
      default: {
        return 'logo';
      }
    }
  }

  upload(image: Blob, fileName: string): void {
    this.isFileLoading = true;

    const formData: FormData = new FormData();

    formData.append(this.getUploadFieldName(this.type), image, fileName);

    const observe: Observable<string> = this.getUploadObservable(this.type, formData);

    observe
      .pipe(
        untilDestroyed(this),
        catchError((error: any) => {
          this.isFileLoading = false;
          this.fileError = error.error.errors;

          return throwError(error);
        }),
      )
      .subscribe(
        (file_url: string) => {
          this.imageChanged.emit(true);
          this.isFileLoading = false;
          this.setFileName(file_url);
          this.toastr.success(COMMON_TOAST.LOGO_IMAGE_SAVED);
        },
      );
  }

  private setFileName(image: string): void {
    if (image) {
      this.fileName = last(image.split('/'));
    }
  }

  private getRemoveObservable(type: string): Observable<void> {
    switch (type) {
      case 'buyer': {
        return this.buyerSettingsService.deleteLogo();
      }
      case 'homeowner-report': {
        return this.homeownerSettingsService.deleteReportLogo();
      }
      default: {
        return this.homeownerSettingsService.deleteLogo();
      }
    }
  }

  remove(): void {
    this.isFileLoading = true;
    this.clearError();

    const observe: Observable<void> = this.getRemoveObservable(this.type);

    observe
      .pipe(
        untilDestroyed(this),
        catchError((error: any) => {
          this.isFileLoading = false;
          this.fileError = error?.error?.errors || COMMON_TOAST.SOMETHING_WENT_WRONG;

          return throwError(() => error);
        }),
      )
      .subscribe(
        () => {
          this.imageChanged.emit(true);
          this.fileName = null;
          this.isFileLoading = false;
          this.toastr.success(COMMON_TOAST.LOGO_IMAGE_REMOVED);
        },
      );
  }

  clearError(): void {
    this.fileError = [];
  }

}
