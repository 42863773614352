import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'risky-email-notification',
  templateUrl: './risky-email-notification.component.html',
  styleUrls: ['./risky-email-notification.component.scss'],
})
export class RiskyEmailNotificationComponent {

  @Input() action$: Subscription;

  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

}
