import { Component } from '@angular/core';

import type { ConfirmActionModalCopy, Plan } from '@core/types';

// Consts
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  templateUrl: './break-partnership.component.html',
  styleUrls: ['./break-partnership.component.scss'],
})
export class BreakPartnershipComponent {

  modalData: ConfirmActionModalCopy;

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  confirm(): void {
    this.closeModal(true);
  }

  closeModal(closeResult?: Plan[] | boolean): void {
    this.dialogRef.close(closeResult);
  }

}
