<div class="stats" [ngClass]="align">
  <div class="stats-col">
    <div class="stats-label">
      <div class="stats-label-point" [ngStyle]="{ backgroundColor: color }"></div>
      {{ countTitle }}
    </div>
    <div class="stats-value f-24-700">
      <ngx-skeleton-loader *ngIf="loading"
                           [theme]="{height: '16px', width: '40px', 'border-radius': '2px', margin: 0}"
                           appearance="line"></ngx-skeleton-loader>
      <ng-container *ngIf="!loading">{{ countValue || 0 | number }}</ng-container>
    </div>
  </div>
  <div class="stats-col">
    <div class="stats-label">{{ volumeTitle }}</div>
    <div class="stats-value f-14-600">
      <ngx-skeleton-loader *ngIf="loading"
                           [theme]="{height: '8px', width: '57px', 'border-radius': '2px', margin: 0}"
                           appearance="line"></ngx-skeleton-loader>
      <ng-container *ngIf="!loading">{{ (volumeValue | currency: 'USD':'symbol':'1.0-0') || 0 }}</ng-container>
    </div>
  </div>
  <div class="stats-col">
    <div class="stats-label">{{ avgTitle }}</div>
    <div class="stats-value f-14-600">
      <ngx-skeleton-loader *ngIf="loading"
                           [theme]="{height: '8px', width: '57px', 'border-radius': '2px', margin: 0}"
                           appearance="line"></ngx-skeleton-loader>
      <ng-container *ngIf="!loading">{{ (avgValue | currency: 'USD':'symbol':'1.0-0') || 0 }}</ng-container>
    </div>
  </div>
</div>
