<div class="control-dropdown" dropdown [placement]="useCustomButton?'':'auto'" container="body">
  <div *ngIf="useCustomButton; else dots" dropdownToggle>
    <ng-content></ng-content>
  </div>
  <ng-template #dots>
    <div class="control-btn dots inline-svg" [ngClass]="class" dropdownToggle [inlineSVG]="'#icon-dots'"></div>
  </ng-template>
  <div class="control-dropdown__menu" *dropdownMenu>
    <div class="control-dropdown__item" (click)="archive(lead.id)">
      <span class="control-dropdown__label">Archive</span>
    </div>
  </div>
</div>
